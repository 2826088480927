export default {
  club_name: 'BK Häcken',
  club_name_short: 'BKH',
  stadium_name: 'Bravida Arena',
  staging_url: 'https://hacken.api.fangroup.io',
  prod_url: 'https://hacken.api.fangroup.io',
  api: '/api',
  facebook: 'bkhacken/',
  basename: '/hacken',
  club_logo: '/assets/teams/hacken.png',
  app_name: 'hacken',
  websale_name: 'hacken',
  ntb_team_id: 10024,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'hacken',
  monolithEnabled: false,
};
