export default {
  club_name: 'IK Comet Halden',
  club_name_short: 'COMET',
  stadium_name: 'Halden Ishall',
  staging_url: 'https://comet.api.fangroup.io',
  prod_url: 'https://comet.api.fangroup.io',
  api: '/api',
  facebook: 'CometHalden',
  basename: '/comet',
  club_logo: '/assets/teams/comet.png',
  app_name: 'comet',
  websale_name: 'comet',
  ntb_team_id: 10002,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'comet',
  monolithEnabled: false,
};
