export default {
  club_name: 'Degerfors IF',
  club_name_short: 'DIF',
  stadium_name: 'Stora Valla',
  staging_url: 'https://degerfors.api.fangroup.io',
  prod_url: 'https://degerfors.api.fangroup.io',
  api: '/api',
  facebook: 'DegerforsIF/',
  basename: '/degerfors',
  club_logo: '/assets/teams/degerfors.png',
  app_name: 'degerfors',
  websale_name: 'degerfors',
  ntb_team_id: 10026,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'degerfors',
  monolithEnabled: false,
};
