import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useConfig from '../../../../util/useConfig';
import Loading from '../../../shared/Spinner';
import Api from '../../../../util/api';
import Offer from './Offer';
import { Widget, WidgetHeader, EmptyGraphData } from '../shared';
import { partial } from '../../../../util/translation/translation';

const UpcomingOffers = () => {
    const dT = partial('DashboardPage');
    const { id } = useConfig();
    const { data, isLoading } = useQuery({ queryKey: ['upcoming-offers'], queryFn: () => Api.offers.fetchAllOffers(id, 1, 5, 'active') });

    const renderOffers = (
      data?.data?.offers?.length > 0 ? data?.data?.offers?.map((offer) => <Offer key={offer.id} offer={offer} />) : <EmptyGraphData message={dT('noData')} />
    );

    return (
      <Widget>
        <WidgetHeader title={`${dT('upcomingOffers')} (${data?.data?.total})`} isBorder={false} bottomSpace="sm">
          <Link to="/offers" className="events-link">{dT('viewAll')}</Link>
        </WidgetHeader>
        {isLoading ? <Loading /> : renderOffers}
      </Widget>
    );
};
export default UpcomingOffers;
