import React from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';

class Loading extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        { this.props.loading ?
          <div
            className={this.props.size ? 'loading-icon' : 'loading-full'}
          >
            <LoadingIcon
              size={this.props.size || '48'}
              color="#42e2b5"
            />
          </div>
          : ''
        }
      </div>
    );
  }
}

export default Loading;
