import { selector } from 'recoil';
import seasons from './atom';

const seasonsState = selector({
  key: 'seasonsState',
  get: ({ get }) => get(seasons),
  set: ({ set }, show) => set(seasons, show),

});
export default seasonsState;
