export default {
  club_name: 'Västerås IK',
  club_name_short: 'VIK',
  stadium_name: 'ABB Arena Nord',
  staging_url: 'https://vik.api.fangroup.io',
  prod_url: 'https://vik.api.fangroup.io',
  api: '/api',
  facebook: 'VIKHockey',
  basename: '/vik',
  club_logo: '/assets/teams/vik.png',
  app_name: 'vik',
  websale_name: 'vik',
  ntb_team_id: 3720,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'vik',
  monolithEnabled: false,
};
