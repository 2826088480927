import React, { Component } from 'react';
import {
  Container, Col, Card, CardBody,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import PropTypes, { any } from 'prop-types';
import { toast } from 'react-toastify';
import TeamForm from './components/TeamForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';

const p = partial('settingsObjectsShared');

class EditTeamPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        teamID: PropTypes.string,
      }),
    }).isRequired,
    teamValues: PropTypes.objectOf(any),
    history: PropTypes.shape({
      replace: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    teamValues: {},
  }

  state = {
    loading: false,
    team: undefined,
  };

  componentDidMount() {
    this.getTeam();
  }

  getTeam = () => {
    this.setState({ loading: true }, () => {
      Api.settings.getTeam(this.props.match.params.teamID).then((res) => {
        this.setState({
          team: res.data,
          loading: false,
        });
      }).catch((err) => {
        this.setState({
          loading: false,
        });
        toast.error(err || p('errorFetchingTeam'));
      });
    });
  };

  handleSubmit = () => {
    if (this.props.teamValues.logo[0] instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(this.props.teamValues.logo[0]);
      reader.onloadend = () => {
        this.updateTeam(reader.result);
      };
    } else {
      this.updateTeam();
    }
  }

  updateTeam = (icon = undefined) => {
    const payload = {
      id: this.state.team.id,
      logo: this.state.team.logo,
      logo_image_id: this.state.team.logo_image_id,
      shortname: this.props.teamValues.shortname,
      name: this.props.teamValues.name,
      color: this.props.teamValues.color,
      ntb_id: this.props.teamValues.ntb_id?.value,
      icon: icon || undefined,
    };
    this.setState({ loading: true }, () => {
      Api.settings.updateTeam(payload, this.state.team.id)
        .then(() => {
          this.setState({ loading: false });
          toast.success(p('teamUpdated'));
          this.props.history.replace('/settings?tab=1');
        }).catch((err) => {
          this.setState({ loading: false });
          toast.error(err || p('errorUpdatingTeam'));
        });
    });
  }

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <Col>
          <h3 className="page-title">{p('editTeam')}</h3>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <TeamForm
                team={this.state.team}
                onSubmit={this.handleSubmit}
                type="edit"
              />
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}

export default withRouter(connect(state => ({
  teamValues: getFormValues('create_or_update_team_form')(state),
}))(EditTeamPage));
