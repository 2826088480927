import moment from 'moment';
import { partial } from '../../../util/translation/translation';

const validateBalancePackageForm = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.name) {
    errors.name = `${p('titleFieldEmpty')}`;
  }

  if (!values.sale_start) {
    errors.sale_start = `${p('salesStartFieldEmpty')}`;
  }

  if (values.sale_start && values.sale_end && moment(values.sale_start).isAfter(moment(values.sale_end))) {
    errors.sale_end = `${p('salesEndFieldEmpty')}`;
  }

  if (!values.price) {
    errors.price = `${p('priceFieldEmpty')}`;
  }

  if (!values.balance) {
    errors.balance = `${p('balanceFieldEmpty')}`;
  }

  return errors;
};

export default validateBalancePackageForm;
