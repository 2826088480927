import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router';
import { Container, Col, Card, CardBody, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import RowsAndSeats from './components/RowsAndSeats';
import FieldForm from './components/FieldForm';
import { convertImageToBlob } from '../../util/image-functions';
import { partial } from '../../util/translation/translation';

const FieldFormPage = () => {
  const p = partial('FieldFormPage');
  const s = partial('shared');
  const [fieldTypes, setFieldTypes] = useState([]);
  const [field, setField] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');

  const { fieldID } = useParams();
  const history = useHistory();

  const initialize = async () => {
    setLoading(true);
    try {
      const [resFieldTypes, resField] = await Promise.all([
        Api.stadium.getFieldTypes(),
        fieldID && Api.stadium.getStadiumFieldByID(fieldID),
      ]);
      const mappedFieldTypes = resFieldTypes.data.map(fieldType => ({
        ...resFieldTypes,
        label: fieldType.display_name,
        color: fieldType.colour && fieldType.colour,
        value: fieldType.id.toString(),
      }));
      setFieldTypes(mappedFieldTypes);
      if (resField) {
        setField(resField.data);
      }
    } catch (err) {
      toast.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleSubmit = async (values) => {
    let overview = null;
    if (values.overview && values.overview[0]?.preview && !values.overview[0].url) {
      try {
        overview = await convertImageToBlob(values.overview[0]);
      } catch (err) {
        toast.error(err || p('errorUploadingOverviewPicture'));
        return;
      }
    }
    let panorama = null;
    if (values.panorama && values.panorama[0]?.preview && !values.panorama[0].url) {
      try {
        panorama = await convertImageToBlob(values.panorama[0]);
      } catch (err) {
        toast.error(err || p('errorUploading180Picture'));
        return;
      }
    }
    const payload = {
      name: values.name,
      field_type_id: values.fieldType.value,
      buyable: Number(values.buyable),
      sellable: Number(values.sellable),
      buyable_websale: Number(values.buyableWebsale),
      overview_image: overview,
      panorama_image: panorama,
      remove_overview: values.overview ? !values.overview[0] : false,
      remove_panorama: values.panorama ? !values.panorama[0] : false,
    };
    if (values.template && values.template.value) { payload.id_to_copy = values.template.value; }
    setLoading(true);
    if (fieldID) {
      try {
        await Api.stadium.updateField(fieldID, payload);
        toast.success(p('stadiumSetupUpdated'));
        setLoading(false);
      } catch (err) {
        toast.error(err);
      }
    } else {
      try {
        await Api.stadium.createField(payload);
        toast.success(p('stadiumSetupCreated'));
        history.replace('/stadiums?tab=1');
      } catch (err) {
        toast.error(err);
      }
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">{fieldID ? s('edit') : s('create') } {s('field')}</h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            { fieldID ?
              <div className="tabs tabs--justify tabs--bordered-top overflowY-hidden">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => setActiveTab('1')}
                      >
                        {s('settings')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => setActiveTab('2')}
                      >
                        {s('seats')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      { activeTab === '1' &&
                      <FieldForm
                        onSubmit={handleSubmit}
                        field={field}
                        fieldTypes={fieldTypes}
                        type="edit"
                      />
                    }
                    </TabPane>
                    <TabPane tabId="2">
                      { activeTab === '2' &&
                      <RowsAndSeats
                        field={field}
                        fieldID={fieldID}
                      />
                    }
                    </TabPane>
                  </TabContent>
                </div>
              </div>
              :
              <FieldForm
                onSubmit={handleSubmit}
                fieldTypes={fieldTypes}
              />
          }

          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default FieldFormPage;
