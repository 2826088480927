import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import renderField from '../../../shared/components/custom/Field';
import validate from './validate';
import { partial } from '../../../util/translation/translation';

const DateForm = (props) => {
  const p = partial('DateForm');
  const s = partial('shared');
  const { handleSubmit } = props;
  return (
    <form className="form width-100p" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col lg={4} md={6}>
            <div className="form__form-group">
              <span className="form">{p('dateFrom')} </span>
              <div className="form__form-group-field">
                <Field
                  name="dateFrom"
                  component={renderField}
                  type="date"
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="form__form-group">
              <h5 className="form-header">{p('dateTo')}</h5>
              <Field name="dateTo" component={renderField} type="date" />
            </div>
          </Col>
          <Col md={4}>
            <Button type="submit" color="primary">{s('search')}</Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

DateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'statsForm',
  validate,
})(DateForm);
