import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import CloseIcon from 'mdi-react/CloseIcon';
import Pagination from '../../../shared/components/pagination/Pagination';
import t, { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
// import config from '../../../config';

const p = partial('HistoryModal');
const HistoryModal = (props) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [name, setName] = useState('');

  function handleInputs(_e) {
    if (_e.target.name === 'date') {
      setDate(_e.target.value);
      setName('');
    } else {
      setName(_e.target.value);
      setDate('');
    }
  }

  function getOrderHistory(pager = null) {
    if (pager && pager.preventDefault) {
      pager.preventDefault();
    }
    if (pager === null) {
      props.getOrderHistory(date, name, 1);
    } else if (pager.pageSize !== props.orderHistory.per_page || props.orderHistory.current_page !== pager.currentPage) {
      props.getOrderHistory(date, name, pager.currentPage, pager.pageSize);
    }
  }

  return (
    <Modal isOpen={props.visible} toggle={props.toggle} className="kiosk-modal history-modal">
      <ModalBody>
        <div className="history-modal">
          <div className="kiosk-modal-header">
            <h3>{p('orderHistory')} - {props.kiosk.name}</h3>
            <button onClick={props.toggle}>
              <CloseIcon />
            </button>
          </div>
          <div className="kiosk-modal-body">
            <form onSubmit={getOrderHistory} className="history-modal-body-search">
              <label htmlFor="history-modal-date">
                <p>{t('shared.date')}</p>
                <input onChange={handleInputs} value={date} name="date" id="history-modal-date" type="date" />
              </label>
              <label htmlFor="history-modal-name">
                <p>{t('shared.or')}</p>
                <input onChange={handleInputs} value={name} name="name" id="history-modal-name" type="text" placeholder={p('searchNameOrID')} />
              </label>
              <button onClick={() => getOrderHistory()}>{p('getOrder')}</button>
            </form>
            <div className="history-modal-list">
              <RenderHistoryList
                orderHistory={props.orderHistory}
                handleRefundModalState={props.handleRefundModalState}
                handlePrint={props.handlePrint}
              />
            </div>
            <Pagination
              items={props.orderHistory.total}
              pageSize={props.orderHistory.per_page}
              onChangePage={getOrderHistory}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

HistoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  getOrderHistory: PropTypes.func.isRequired,
  orderHistory: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    total: PropTypes.number,
    per_page: PropTypes.string,
    current_page: PropTypes.number,
  }).isRequired,
  handleRefundModalState: PropTypes.func.isRequired,
  kiosk: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  handlePrint: PropTypes.func.isRequired,
};

const RenderHistoryList = (props) => {
  const config = useConfig();
  if (props.orderHistory.data.length === 0) {
    return (
      <p>{p('noOrderFound')}</p>
    );
  }
  return props.orderHistory.data.map((order) => {
    let status = '';
    if (order.status === 'ready') { status = 'ready'; }
    if (order.status === 'processing' || order.status === 'active') { status = 'processing'; }
    if (order.status === 'inactive') { status = 'inactive'; }
    return (
      <div className="list-item" key={`RenderHistoryList-${order.id}`}>
        <div className="list-container">
          <div className="list-header">
            <h4><strong>#{order.identifier}</strong></h4>
            <span className={status}>{p(order.status)}</span>
          </div>
          <div className="list-body">
            <div className="list-row">
              <div className="list-column">
                <span><strong>{t('shared.user')}: </strong>{order.user && `${order.user.firstname} ${order.user.lastname}`}</span>
                <span><strong>{t('shared.created')}: </strong>{moment(order.created_at).format('DD.MM.YYYY - HH:mm')}</span>
                { order.handler !== null &&
                  <span><strong>{p('processedBy')}: </strong>{order.handler.firstname} {order.handler.lastname}</span>
                }
                { order.refunder !== null &&
                  <span><strong>{p('refoundedBy')}: </strong>{order.refunder.firstname} {order.refunder.lastname}</span>
                }
                <span><strong> {t('shared.paid')}: </strong>{moment(order.bought_at).format('DD.MM.YYYY - HH:mm')} ({order.payment_method})</span>
              </div>
              <div className="list-column">
                <span><strong>{p('totalPrice')}: </strong>{config.currencyShort}. {order.price},- ({p('inclusiveShort')} {order.tax}% {t('shared.taxCaps')})</span>
                <ul>
                  <RenderHistoryListProducts products={order.products} />
                </ul>
              </div>
            </div>
            <div className="list-refund">
              { order.status !== 'refunded' &&
                <>
                  <button onClick={() => props.handlePrint(order)}>{p('printAgain')}</button>
                  <button onClick={() => props.handleRefundModalState(true, order.id)}>{p('refound')}</button>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    );
  });
};

RenderHistoryList.propTypes = {
  handlePrint: PropTypes.func.isRequired,
  handleRefundModalState: PropTypes.func.isRequired,
};

const RenderHistoryListProducts = (props) => {
  return props.products.map((product) => {
    return (
      <li key={`product-${product.id}`}>{product.name} {product.subtitle && `- ${product.subtitle}`}</li>
    );
  });
};

export default HistoryModal;
