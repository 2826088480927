import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.shortname) {
    errors.shortname = p('abbreviationFieldCantBeEmpty');
  }
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  return errors;
};

export default validate;
