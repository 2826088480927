import React, { useEffect, useState, useMemo } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Row, Col, Container } from 'reactstrap';
import TimetableIcon from 'mdi-react/TimetableIcon';
import moment from 'moment';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateTileForm';
import t, { partial } from '../../../util/translation/translation';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import renderSelectField from '../../../shared/components/form/Select';
import Api from '../../../util/api';
import useConfig from '../../../util/useConfig';
import { getReadableFormat, getFormatDimensions, isFilterable, hasAccess } from '../spotlightUtils';
import { getImageHash } from '../../../util/functions';

const UNLIMITED_DATE = '9999-12-31T00:00:00.000Z';

const p = partial('HighlightBannerForm');
const bp = partial('HighlightBannerPage');
const s = partial('shared');
const AVAILABLE_CONTENT = [
  {
    value: 'NEWS',
    label: s('news'),
  },
  {
    value: 'OFFERS',
    label: t('AppLayout.offersTile'),
  },
  {
    value: 'EVENTS',
    label: t('AppLayout.eventsTile'),
  },
  {
    value: 'EXTERNAL_LINK',
    label: s('externalLink'),
  },
  {
    value: 'YOUTUBE_VIDEO',
    label: t('NewsForm.YOUTUBE_VIDEO_title'),
  },
  {
    value: 'AUCTIONS',
    label: t('AppLayout.auctionsTile'),
  },
  {
    value: 'COMPANIES',
    label: s('companies'),
  },
  {
    value: 'PLAYERS',
    label: s('players'),
  },
  {
    value: 'NEWS_CATEGORY',
    label: s('newsCategory'),
  },
  {
    value: 'TEAMS',
    label: s('teams'),
  },
  {
    value: 'QUESTIONAIRES',
    label: t('questionaire.questionaires'),
  },
  // {
  //   value: 'MATCH_CENTER',
  //   label: t('AppLayout.matchcenterTile'),
  // },
];
const getContentType = (type) => {
  return AVAILABLE_CONTENT.find((item) => item.value === type);
};
const SpotlightTileForm = ({
  handleSubmit, initialize, entity, change,
  format, setSelectedEntityAccess, groupsLevelsTiers, descriptionAvailable,
  formValues,
}) => {
  const [availableContent, setAvailableContent] = useState({
    news: [],
    events: [],
    offers: [],
    auctions: [],
    companies: [],
    players: [],
    news_category: [],
    matchCenter: [],
    teams: [],
    questionaires: [],
  });
  const [contentTypes, setContentTypes] = useState([]);
  const [auctionItems, setAuctionItems] = useState([]);
  const [content, setContent] = useState(null);
  const { id: clubId, teams } = useConfig();
  const imageFormat = format || 'SQUARE';
  const CONTENT_LIST = useMemo(() => AVAILABLE_CONTENT.filter((item) => {
    if (!['PORTRAIT', 'LANDSCAPE'].includes(format)) {
      if (item.value === 'YOUTUBE_VIDEO') {
        return false;
      }
    }
    return true;
  }), [format]);
  const fetchWholeContent = async (setInnerState = true, auctionHouseId = null) => {
    try {
      const [{ data: eventsData }, { data: newsData }, { data: offersData },
        { data: auctionHouseData }, auctionHouseItems, resCompanies, resNewsCategories, teamPlayersResults, { data: questionaireData }] = await Promise.all(
          [
            Api.events.all(),
            Api.news.getNews({ page: 1, take: 100 }, { orderBy: 'publicationDate', direction: 'DESC' }),
            Api.offers.fetchAllOffers(clubId, 1, 50),
            Api.auctions.getAuctionHouses({ page: 1, take: 100 }, { orderBy: 'createdAt', direction: 'DESC' }),
            auctionHouseId ? Api.auctions.getAuctionItemsForHouse(auctionHouseId) : [],
            Api.companies.getCompaniesForClub(),
            Api.news.getNewsCategories(),
            Promise.all(teams.map((team) => Api.players.getPLayersFromNtb(team.id).then((res) => res.data.map((item) => ({ ...item, teamId: team.id }))))),
            Api.questionaire.getQuestionaires({ page: 1, take: 100 }, { orderBy: 'createdAt', direction: 'DESC' }),
          ]);
      const sortedEvents = eventsData.sort((a, b) => moment(b.startDate).diff(moment(a.startDate)));
      const sortedCompanies = resCompanies.filter((item) => item.company.name).sort((a, b) => a.company.name.trim().localeCompare(b.company.name.trim()));
      const mappedEvents = [{
        value: null,
        label: `${t('AppLayout.eventsTile')} ${s('list')}`,
      }, ...sortedEvents.map((item) => ({
        label: item.title,
        value: item.id,
      }))];
      const mappedOffers = [{
        value: null,
        label: `${t('AppLayout.offersTile')} ${s('list')}`,
      }, ...offersData.offers.map((item) => ({
        label: item.title,
        value: item.id,
      }))];
      const mappedNews = [{
        value: null,
        label: `${t('AppLayout.newsTile')} ${s('list')}`,
      }, ...newsData.map((item) => ({
        label: item.title,
        value: item.id,
      }))];
      const mappedAuctions = [{
        value: null,
        label: `${t('auctionObjectsShared.auctionHouse')} ${s('list')}`,
      }, ...auctionHouseData.map((item) => ({
        label: item.name,
        value: item.id,
      }))];
      const mappedCompanies = [{
        value: null,
        label: `${s('companies')} ${s('list')}`,
      }, ...sortedCompanies.map((item) => ({
        label: item.company.name,
        value: item.id,
      }))];
      const mappedNewsCategories = resNewsCategories.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      const mappedPlayers = teamPlayersResults.flatMap((teamPlayers) => teamPlayers.map((item) => ({
        label: item.fullname,
        value: `${item.teamId}@${item.id}`,
      })));
      const mappedTeams = teams.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      const mappedAuctionHouseItems = [
        {
          label: bp('auctionHousesPage'),
          value: null,
        },
        ...auctionHouseItems.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ];
      const mappedQuestionaires = questionaireData.map((item) => ({
        label: item.title,
        value: item.id,
      }));


      const combinedData = {
        news: mappedNews,
        events: mappedEvents,
        offers: mappedOffers,
        auctions: mappedAuctions,
        companies: mappedCompanies,
        news_category: mappedNewsCategories,
        players: mappedPlayers,
        teams: mappedTeams,
        questionaires: mappedQuestionaires,
      };
      if (setInnerState) {
        setAvailableContent(combinedData);
      }
      return { ...combinedData, auctionHouseItems: mappedAuctionHouseItems };
    } catch (e) {
      return {
        news: [],
        events: [],
        offers: [],
        auctions: [],
        auctionHouseItems: [],
        questionaires: [],
      };
    }
  };
  const mapOfferAccess = (accessArray, field) => {
    let key = null;
    switch (field) {
      case 'groups': key = 'name'; break;
      case 'levels': key = 'name'; break;
      case 'tiers': key = 'tierId'; break;
      default: break;
    }
    // eslint-disable-next-line no-nested-ternary
    return (accessArray.length === 0 || accessArray?.find((item) => item.name === 'All users')) ? null : (
      accessArray.find((item) => item.name === `All ${field}`) ? [] : accessArray.map((item) => item[key])
    );
  };

  const mapEventAccess = (accessArray, field, searchField) => {
    const mappedData = (accessArray === null || accessArray?.length === 0) ? accessArray : accessArray.map((accessName) => {
      const foundRecord = groupsLevelsTiers[field].find((item) => item[searchField] === accessName);
      return foundRecord.value;
    });
    return mappedData;
  };
  const fetchEntityAccess = async (entityId) => {
    switch (content) {
      case 'NEWS': return Api.news.getNewsAccess(entityId);
      case 'QUESTIONAIRES': return Api.questionaire.getQuestionaireAccess(entityId);
      case 'OFFERS': return Api.offers.fetchSingleOffer(entityId, clubId).then(({ data }) => {
        const groups = mapOfferAccess(data.groups, 'groups');
        const levels = mapOfferAccess(data.levels, 'levels');
        const tiers = mapOfferAccess(data.tiers, 'tiers');
        return { groups, levels, tiers, noAccessView: data.isVisible };
      });
      case 'EVENTS': return Api.events.show(entityId).then(({ data }) => {
        const groups = mapEventAccess(data.accessCategories, 'groups', 'value');
        const levels = mapEventAccess(data.accessLevels, 'levels', 'label');
        const tiers = mapEventAccess(data.accessTiers, 'tiers', 'value');
        return { groups, levels, tiers, noAccessView: data.feedVisibility };
      });
      case 'AUCTIONS': {
        const [houseId] = entityId.split('|');
        const [auctionHouseAccess, auctionItemsData] = await Promise.all([
          Api.auctions.getAuctionHouseAccess(houseId),
          Api.auctions.getAuctionItemsForHouse(houseId),
        ]);
        const mappedItems = [
          {
            label: 'Main page',
            value: null,
          },
          ...auctionItemsData.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        ];
        setAuctionItems(mappedItems);
        return auctionHouseAccess;
      }
      default: return null;
    }
  };
  const handleContentTypeChange = async (data) => {
    if (hasAccess(content)) {
      const entityId = data.value;
      if (!entityId) {
        setSelectedEntityAccess({ groups: null, levels: null, tiers: null, segments: [], noAccessView: true, selectedContent: { type: content, contentId: data.value } });
      } else {
        const result = await fetchEntityAccess(entityId);
        if (!result) {
          setSelectedEntityAccess({ groups: null, levels: null, tiers: null, segments: [], noAccessView: true, selectedContent: { type: content, contentId: data.value } });
          return;
        }
        const { groups, levels, tiers, noAccessView } = result;
        setSelectedEntityAccess({ groups, levels, tiers, segments: [], noAccessView, selectedContent: { type: content, contentId: data.value } });
      }
    } else {
      setSelectedEntityAccess({ groups: null, levels: null, tiers: null, segments: [], noAccessView: true, selectedContent: { type: content, contentId: data.value }, isEditable: true });
    }
  };
  const initializeEntity = async () => {
    let foundContent = entity.contentId || '';
    let foundSubContent = null;
    if (isFilterable(entity.contentType)) {
      let results = null;
      if (entity.contentType === 'AUCTIONS') {
        const [houseId, itemId] = foundContent.split('|');
        results = await fetchWholeContent(false, houseId);
        foundContent = (results[entity.contentType.toLowerCase()] || []).find((item) => item.value === houseId)?.label
          || (results[entity.contentType.toLowerCase()] || []).find((item) => item.value === null)?.label;
          const isAuctionHouseList = foundContent === `${t('auctionObjectsShared.auctionHouse')} ${s('list')}`;
        foundSubContent = isAuctionHouseList ? null : {
          label: results.auctionHouseItems.find((item) => item.value === itemId)?.label
            || (results[entity.contentType.toLowerCase()] || []).find((item) => item.value === null)?.label,
          value: itemId,
        };
      } else {
        results = await fetchWholeContent(false);
        foundContent = (results[entity.contentType.toLowerCase()] || []).find((item) => item.value === entity.contentId)?.label
          || (results[entity.contentType.toLowerCase()] || []).find((item) => item.value === null)?.label;
      }
    }
    initialize({
      title: entity.title,
      description: entity.description,
      publicationDate: moment(entity.publicationDate).format('YYYY-MM-DDTHH:mm'),
      expirationDate: entity.expirationDate !== UNLIMITED_DATE ? moment(entity.expirationDate).format('YYYY-MM-DDTHH:mm') : null,
      content: getContentType(entity.contentType),
      contentType: isFilterable(entity.contentType) ? {
        label: foundContent,
        value: entity.contentId,
      } : entity.contentId,
      subContentType: foundSubContent,
      headingImage: (entity.contentType === 'YOUTUBE_VIDEO' || format === 'TEXT_BANNER' || format === undefined) ? undefined : {
        name: 'tile image',
        preview: entity.imageUrl,
        croppedImage: entity.imageUrl,
        hash: getImageHash(entity.imageUrl),
      },
    });
    setContentTypes(availableContent[entity.contentType.toLowerCase()] || []);
    setContent(entity.contentType);
    await handleContentTypeChange({ value: entity?.contentId || null });
  };
  useEffect(() => {
    if (entity) {
      initializeEntity();
    }
  }, [entity, availableContent, format]);
  useEffect(() => {
    fetchWholeContent();
  }, [entity, teams]);

  const changeContentType = async (contentType) => {
    if (isFilterable(contentType)) {
      setContentTypes(availableContent[contentType.toLowerCase()]);
      if (['AUCTIONS', 'NEWS_CATEGORY', 'PLAYERS', 'TEAMS'].includes(contentType)) {
        change('contentType', null);
      } else {
        change('contentType', {
          label: `${t(`AppLayout.${contentType.toLowerCase()}Tile`)} ${s('list')}`,
          value: null,
        });
      }

      setSelectedEntityAccess({ groups: null, levels: null, tiers: null, noAccessView: true, selectedContent: { type: contentType, contentId: null } });
    } else {
      setSelectedEntityAccess({ groups: null, levels: null, tiers: null, noAccessView: true, selectedContent: { type: contentType, contentId: null }, isEditable: true });
    }
  };
  const handleContentChange = (data) => {
    setContent(data.value);
    change('contentType', null);
    change('subContentType', null);
    changeContentType(data.value);
  };
  const contentTypePlaceholder = (_cont) => {
    switch (_cont) {
      case 'EXTERNAL_LINK': return s('externalLink');
      case 'YOUTUBE_VIDEO': return t('NewsForm.youtubeVideoID');
      default: return '';
    }
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      {(content !== 'YOUTUBE_VIDEO' && format !== 'TEXT_BANNER') ?
        <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginBottom: 10, width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('image')}</div>
            </div>
          </div>
          <div style={{ padding: '10px' }}>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="form__form-group">
                    <Field
                      saveBase64Cropped
                      name="headingImage"
                      component={renderDropZoneField}
                      crop={`SPOTLIGHT_${imageFormat}_CROP`}
                      displayCrop
                    />
                    <span className="form-header"><strong>{getReadableFormat(imageFormat)}</strong> {getFormatDimensions(imageFormat, true)}</span>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div> : null}
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: 10, marginBottom: 10, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('details')}</div>
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <Container>
            <Row>
              <Col md={!descriptionAvailable ? 12 : 6}>
                <div className="form__form-group">
                  <span className="form-header">{s('title')}*</span>
                  <Field
                    name="title"
                    component={renderField}
                    placeholder={s('title')}
                  />
                </div>
              </Col>
              {descriptionAvailable ?
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form-header">{bp('description')}*</span>
                    <Field
                      name="description"
                      component={renderField}
                      placeholder={s('description')}
                    />
                  </div>
                </Col> : null}
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form">{s('publicationDate')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                    <Field
                      name="publicationDate"
                      component={renderField}
                      type="datetime-local"
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form">{s('expirationDate')}</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                    <Field
                      name="expirationDate"
                      component={renderField}
                      type="datetime-local"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={content === 'AUCTIONS' ? 4 : 6}>
                <div className="form__form-group">
                  <span className="form-header">{p('selectContent')}*</span>
                  <Field
                    name="content"
                    placeholder={p('selectContent')}
                    options={CONTENT_LIST}
                    component={renderSelectField}
                    onChange={handleContentChange}
                  />
                </div>
              </Col>
              <Col md={content === 'AUCTIONS' ? 4 : 6}>
                <div className="form__form-group">
                  <span className="form-header">{p('selectContentType')}*</span>
                  {
                    !isFilterable(content) ?
                      <Field
                        name="contentType"
                        component={renderField}
                        placeholder={contentTypePlaceholder(content)}
                      /> : <Field
                        name="contentType"
                        placeholder={p('selectContentType')}
                        options={contentTypes}
                        component={renderSelectField}
                        disabled={!content}
                        onChange={(e) => {
                          handleContentTypeChange(e);
                          change('subContentType', null);
                        }}
                      />
                  }
                </div>
              </Col>
              {content === 'AUCTIONS' ?
                <Col md={content === 'AUCTIONS' ? 4 : 6}>
                  <div className="form__form-group">
                    <span className="form-header">{p('selectContentType')}*</span>
                    <Field
                      disabled={!formValues?.contentType?.value}
                      name="subContentType"
                      placeholder={p('selectContentType')}
                      options={auctionItems}
                      component={renderSelectField}
                    />
                  </div>
                </Col> : null}
            </Row>
          </Container>
        </div>
      </div>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'tile_form',
  validate,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('tile_form')(state),
});
export default connect(mapStateToProps)(reduxF(SpotlightTileForm));
