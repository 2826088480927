export default {
  club_name: 'Dynamo TFG ESP',
  club_name_short: 'DynamoEsp',
  stadium_name: 'Park',
  stadium_address: 'Grønnegata 83, 2317 Hamar',
  staging_url: 'https://tfg-esp.api-dev.fangroup.io',
  prod_url: 'https://tfg-esp.api-dev.fangroup.io',
  api: '/api',
  facebook: 'thefangroup.no',
  basename: '/tfgesp',
  club_logo: '/assets/teams/tfg.png',
  app_name: 'tfg',
  websale_name: 'tfg',
  ntb_team_id: 127,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'tfgesp',
  monolithEnabled: false,
};
