export default {
  club_name: 'Sarpsborg 08',
  club_name_short: 'S08',
  stadium_name: 'Sarpsborg Stadion',
  staging_url: 'https://sarpsborg.api.fangroup.io',
  prod_url: 'https://sarpsborg.api.fangroup.io',
  api: '/api',
  facebook: 'sarpsborg08',
  basename: '/sarpsborg',
  club_logo: '/assets/teams/sarpsborg.png',
  app_name: 'sarpsborg',
  websale_name: 'sarpsborg',
  ntb_team_id: 10014,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'sarpsborg',
  monolithEnabled: false,
};
