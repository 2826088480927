import moment from 'moment';
import { partial } from '../../../util/translation/translation';

const validateAuctionObjectForm = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.name) {
    errors.name = `${p('productFieldEmpty')}`;
  }

  if (!values.startDate) {
    errors.startDate = `${p('biddingOpensFieldEmpty')}`;
  }

  if (!values.endDate) {
    errors.endDate = `${p('biddingClosesFieldEmpty')}`;
  }

  if (values.startDate && values.endDate && (moment(values.startDate).isAfter(values.endDate))) {
    errors.endDate = `${p('biddingCloseTimeError')}`;
  }

  if (!values.bidExtensionValue) {
    errors.bidExtensionValue = `${p('biddingIncreaseFieldEmpty')}`;
  }

  if (!values.bidExtensionTime) {
    errors.bidExtensionTime = `${p('biddingExtendedFieldEmpty')}`;
  }

  if (!values.headingImage) {
    errors.headingImage = p('picFieldCantBeEmpty');
  }

  return errors;
};

export default validateAuctionObjectForm;

