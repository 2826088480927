export default {
  club_name: 'Stavanger Oilers',
  club_name_short: 'oilers',
  stadium_name: '',
  staging_url: 'https://oilers.api.fangroup.io',
  prod_url: 'https://oilers.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/oilers',
  club_logo: '/assets/teams/oilers.png',
  app_name: 'oilers',
  websale_name: 'oilers',
  ntb_team_id: 10048,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'oilers',
  monolithEnabled: false,
};
