import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Badge } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HEADER_ICON from 'mdi-react/FormatTextIcon';
import RICH_TEXT_ICON from 'mdi-react/FormatTextboxIcon';
import IMAGE_ICON from 'mdi-react/ImageOutlineIcon';
import IMAGE_PHOTOGRAPHER_ICON from 'mdi-react/CameraIcon';
import DELIMITER_ICON from 'mdi-react/FormatPageBreakIcon';
import YOUTUBE_VIDEO_ICON from 'mdi-react/YoutubeIcon';
import SPORTALITY_VIDEO_ICON from 'mdi-react/VideoPlusIcon';
import I_FRAME_ICON from 'mdi-react/IframeIcon';
import DragIcon from 'mdi-react/DragIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import t, { partial } from '../../../util/translation/translation';
import TextEditor from '../../../shared/components/text-editor/TextEditor';
import TextEditor2 from '../../../shared/components/text-editor/TextEditor2';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import EmptyStateInfo from '../../../shared/components/custom/EmptyStateInfo';
import renderTexField from '../../../shared/components/custom/Field';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import AppPreviewModal from './AppPreviewModal';
import { AVAILABLE_COMPONENTS, stateFromComponents } from './newsUtils';

const s = partial('shared');
const p = partial('NewsForm');
const getIconComponent = (type) => {
  switch (type) {
    case 'RICH_TEXT': return RICH_TEXT_ICON;
    case 'RICH_TEXT2': return RICH_TEXT_ICON;
    case 'IMAGE': return IMAGE_ICON;
    case 'IMAGE_PHOTOGRAPHER': return IMAGE_PHOTOGRAPHER_ICON;
    case 'HEADER': return HEADER_ICON;
    case 'DELIMITER': return DELIMITER_ICON;
    case 'YOUTUBE_VIDEO': return YOUTUBE_VIDEO_ICON;
    case 'SPORTALITY_VIDEO': return SPORTALITY_VIDEO_ICON;
    case 'I_FRAME': return I_FRAME_ICON;
    default: return null;
  }
};
const NewsFieldWrapper = ({ children, type, onRemove, onUp, onDown, isFirst, isLast }) => {
  const IconComponent = getIconComponent(type);
  return (
    <div style={{ backgroundColor: 'rgb(249, 250, 251)', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: isFirst ? 0 : 15, marginBottom: isLast ? 15 : 0 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: type !== 'DELIMITER' ? 1 : 0, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DragIcon color="#b5b7bf" size={30} />
          <div style={{ backgroundColor: '#D7E9FE', borderRadius: 18, padding: 5, marginRight: 10, marginLeft: 5 }}>
            <IconComponent style={{ fill: '#121B2B' }} size={20} />
          </div>
          <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{p(`${type}_title`)}</div>
        </div>
        <div>
          <div>
            <button disabled={isFirst} type="button" onClick={onUp} style={{ opacity: !isFirst ? '1' : '0.3', background: 'rgb(249, 250, 251)', borderColor: '#b5b7bf', borderWidth: 1, borderStyle: 'solid', borderRadius: 5, marginRight: 10, paddingTop: 5, paddingBottom: 5 }}>
              <ArrowUpIcon style={{ fill: '#b5b7bf' }} size={20} />
            </button>
            <button disabled={isLast} type="button" onClick={onDown} style={{ opacity: !isLast ? '1' : '0.3', background: 'rgb(249, 250, 251)', borderColor: '#b5b7bf', borderWidth: 1, borderStyle: 'solid', borderRadius: 5, marginRight: 10, paddingTop: 5, paddingBottom: 5 }}>
              <ArrowDownIcon style={{ fill: '#b5b7bf' }} size={20} />
            </button>
            <button type="button" onClick={onRemove} style={{ background: 'rgb(249, 250, 251)', borderColor: '#b5b7bf', borderWidth: 1, borderStyle: 'solid', borderRadius: 5, paddingTop: 5, paddingBottom: 5 }}>
              <DeleteOutlineIcon style={{ fill: '#b5b7bf' }} size={20} />
            </button>
          </div>
        </div>
      </div>
      {type !== 'DELIMITER' &&
        <div style={{ padding: '10px 10px 10px 10px' }}>
          {children}
        </div>
      }
    </div>
  );
};
const ContentBuilderCard = ({ item, isDragging }) => {
  const IconComponent = getIconComponent(item.id);
  return (
    <div style={{
      backgroundColor: isDragging
        ? '#F4F4F6'
        : 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
      color: 'black',
      padding: 10,
      borderRadius: 8,
      boxShadow: '2px 3px 29px -8px rgba(209,209,214,1)',
    }}
    >
      <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <IconComponent style={{ widht: 10, borderRadius: 6, fill: '#121B2B', backgroundColor: '#D7E9FE' }} size={30} />
        <p style={{ fontSize: 12, fontWeight: 700, lineHeight: '12px', color: 'black' }}>{p(`${item.id}_title`)} {item.name === 'richText2' && <Badge>Beta</Badge>}</p>
      </div>
    </div>
  );
};
const NewsComponentsForm = ({
  onSubmitPublish,
  onSubmitSave,
  change,
  initialize,
  components,
  formValues,
  news,
  newsObjects,
}) => {
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [columns, setColumns] = useState({
    Col0: {
      name: 'Available',
      value: 'Available',
      items: AVAILABLE_COMPONENTS,
    },
    Col1: {
      value: 'Current',
      name: 'Current',
      items: [],
    },
  });
  const nodeRef = useRef();
  useEffect(() => {
    const { state, items } = stateFromComponents(components);
    initialize(state);
    setColumns((prev) => ({
      ...prev,
      Col1: {
        ...prev.Col1,
        items,
      },
    }));
  }, [components]);
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    let columnObject = {};
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      destItems.splice(destination.index, 0, sourceItems[source.index]);
      const withCleanedIds = destItems.map((item, index) => {
        if (index === destination.index) {
          return ({
            ...item,
            id: `${item.id.split('-')[0]}-${destItems.length}`,
          });
        } else {
          return item;
        }
        // only id for newest will change
      });
      if (source.droppableId === 'Col1') {
        sourceItems.splice(source.index, 1);
        columnObject = {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
        };
      } else {
        columnObject = {
          ...columns,
          [destination.droppableId]: {
            ...destColumn,
            items: withCleanedIds,
          },
        };
      }
      setColumns(columnObject);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      columnObject = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      setColumns(columnObject);
    }
  };
  const handleRemoveItem = (itemId) => () => {
    const foundIdx = columns.Col1.items.findIndex((item) => item.id === itemId);
    const _items = [...columns.Col1.items];
    if (foundIdx !== -1) {
      _items.splice(foundIdx, 1);
      setColumns((prev) => ({
        ...prev,
        Col1: {
          ...prev.Col1,
          items: _items,
        },
      }));
    }
  };
  const handleMoveItem = (itemId, goingUp) => () => {
    const moveIndex = goingUp ? -1 : 1;
    const foundIdx = columns.Col1.items.findIndex((item) => item.id === itemId);
    const _items = [...columns.Col1.items];
    const value = _items[foundIdx];
    _items.splice(foundIdx, 1); // remove the element at the old position
    _items.splice(foundIdx + moveIndex, 0, value); // insert it at the new position
    setColumns((prev) => ({
      ...prev,
      Col1: {
        ...prev.Col1,
        items: _items,
      },
    }));
  };
  const renderField = (fieldId, fieldIndex, arrayLength) => {
    const fieldBase = fieldId.split('-')[0];
    const additionalData = {
      isFirst: fieldIndex === 0,
      isLast: fieldIndex === arrayLength - 1,
      type: fieldBase,
      onRemove: handleRemoveItem(fieldId),
      onUp: handleMoveItem(fieldId, true),
      onDown: handleMoveItem(fieldId, false),
    };
    switch (fieldBase) {
      case 'IMAGE':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              saveBase64Cropped
              name={`${fieldId}_image`}
              component={renderDropZoneField}
              crop="NEWS_OPTIONS_CROP"
              displayCrop
            />
            <Field
              name={`${fieldId}_link`}
              component={renderTexField}
              placeholder={s('link')}
            />
            <Field
              name={`${fieldId}_author`}
              component={renderTexField}
              placeholder={p('IMAGE_PHOTOGRAPHER_title')}
            />
          </NewsFieldWrapper>
        );
      case 'RICH_TEXT':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              name={fieldId}
              component={(prop) => (
                <TextEditor
                  editorState={prop.input.value}
                  setEditorState={prop.input.onChange}
                />
              )}
            />
          </NewsFieldWrapper>
        );
      case 'RICH_TEXT2':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              name={fieldId}
              component={TextEditor2}
            />
          </NewsFieldWrapper>
        );
      case 'IMAGE_PHOTOGRAPHER':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              name={fieldId}
              component={renderTexField}
              placeholder={p('IMAGE_PHOTOGRAPHER_title')}
            />
          </NewsFieldWrapper>
        );
      case 'YOUTUBE_VIDEO':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              name={fieldId}
              component={renderTexField}
              placeholder={p('YOUTUBE_VIDEO_placeholder')}
            />
            <div style={{ paddingLeft: 2, marginTop: '-5px', color: '#98A0AE', display: 'flex' }}>{s('example')}: https://www.youtube.com/watch?v={'{'}<div className="bold">Youtube ID</div>{'}'}</div>
          </NewsFieldWrapper>
        );
        case 'SPORTALITY_VIDEO':
          return (
            <NewsFieldWrapper {...additionalData}>
              <Field
                name={fieldId}
                component={renderTexField}
                placeholder={p('SPORTALITY_VIDEO_title')}
              />
              <div style={{ paddingLeft: 2, marginTop: '-5px', color: '#98A0AE' }}>{s('example')}: 244304</div>
            </NewsFieldWrapper>
          );
      case 'HEADER':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              name={fieldId}
              component={renderTexField}
              placeholder={p('HEADER_title')}
            />
          </NewsFieldWrapper>
        );
      case 'DELIMITER':
        return (
          <NewsFieldWrapper {...additionalData}>
            <Field
              name={fieldId}
              value="test"
              component={() => null}
              placeholder={p('DELIMITER_title')}
            />
          </NewsFieldWrapper>
        );
        case 'I_FRAME':
          return (
            <NewsFieldWrapper {...additionalData}>
              <Field
                name={fieldId}
                component={renderTextAreaField}
                placeholder={p('I_FRAME_title')}
              />
            </NewsFieldWrapper>
          );
      default:
        return null;
    }
  };
  useEffect(() => {
    change('selectedColums', columns);
    if (columns && formValues) {
      newsObjects(columns, formValues);
    }
  }, [columns, components]);
  return (
    <>
      <AppPreviewModal
        visible={previewModalVisible}
        toggle={() => setPreviewModalVisible(false)}
        components={formValues}
        selectedItems={columns.Col1.items}
        news={news}
      />
      <form className="form" ref={nodeRef}>
        <Col>
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(result, columns, setColumns, 'NewsComponent')
            }
          >
            <Row md={12} lg={12}>
              <Col md={9} lg={9} className="mt-4">
                <p className="small" style={{ textAlign: 'center', marginBottom: -15 }}>{p('dontForgetToSave')}</p>
                <Droppable droppableId="Col1" key="Col1">
                  {(provided, snapshot) => {
                    return (
                      <div
                        className="comCol"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? '#d9edff'
                            : '#F9FAFB',
                          borderWidth: 2,
                          borderRadius: 4,
                          borderStyle: snapshot.isDraggingOver ? 'solid' : 'dashed',
                          borderColor: snapshot.isDraggingOver ? '#0469DC' : '#D7DAE0',
                          marginTop: 15,
                        }}
                      >
                        {columns.Col1.items.length === 0 &&
                          <div className="my-5">
                            <EmptyStateInfo icon="layout" title={s('emptyStateTitle')} text={p('emptyAppComponents')} />
                          </div>
                        }

                        {columns.Col1.items.map((item, idx) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}Col0`}
                            index={idx}
                          >
                            {(provided2) => {
                              return (
                                <div
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                >
                                  {renderField(item.id, idx, columns.Col1.items.length)}
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                      </div>
                    );
                  }}
                </Droppable>
                <p className="small" style={{ textAlign: 'center' }}>{p('dontForgetToSave')}</p>
              </Col>
              <Col lg={3} md={3}>
                <div style={{ position: 'sticky', top: '20px' }}>
                  <Button type="button" onClick={() => onSubmitSave(formValues)} color="success" size="sm" style={{ marginBottom: 10 }} block outline>
                    {s('save')}
                  </Button>
                  <Droppable droppableId="Col0" key="Col0">
                    {(provided) => {
                      return (
                        <div
                          className="comCol"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            padding: '15px 10px',
                            background: '#F9FAFB',
                            borderWidth: 1,
                            borderRadius: 4,
                          }}
                        >
                          {columns.Col0.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={`${item.id}Col0`}
                                index={index}
                              >
                                {(provided2, snapshot2) => {
                                  return (
                                    <div
                                      ref={provided2.innerRef}
                                      {...provided2.draggableProps}
                                      {...provided2.dragHandleProps}
                                    >
                                      <ContentBuilderCard item={item} isDragging={snapshot2.isDragging} last={index === columns.Col0.items.length - 1} />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </Col>
            </Row>
          </DragDropContext>
          <Row style={{ paddingTop: 10 }}>
            <Col>
              <Button type="button" onClick={() => onSubmitPublish(formValues)} color="primary">
                {t('NewsFormPage.publish')}
              </Button>
              <Button type="button" onClick={() => onSubmitSave(formValues)} outline color="success">
                {s('save')}
              </Button>
              <Link className="color-unset" to="/news">
                <Button type="button">{s('close')}</Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </form>
    </>
  );
};
const reduxF = reduxForm({
  form: 'news_components_form',
});

export default connect((state) => ({
  formValues: getFormValues('news_components_form')(state),
}))(reduxF(NewsComponentsForm));
