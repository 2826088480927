import React from 'react';
import { Card, CardBody } from 'reactstrap';

const ActiveUsers = () => {
    return (
      <Card>
        <CardBody style={{ borderRadius: '6px', position: 'relative', padding: '15px 20px' }}>
          <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Inter' }}>
            <h3 style={{ fontSize: '13px', color: '#4D576A', fontWeight: '700' }}>Users</h3>
            <div style={{ marginLeft: '5px', fontSize: '13px', color: '#4D576A', fontWeight: '700' }}>(2200)</div>
          </div>
          <div style={{ padding: '5px', borderTop: '1px solid rgb(244, 244, 246)', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontStyle: 'Inter', fontWeight: '400', fontSize: '14px' }}>Yesterday</div>
            <div style={{ color: 'rgb(4, 105, 220)', fontSize: '16px' }}>60</div>
          </div>
          <div style={{ padding: '5px', borderTop: '1px solid rgb(244, 244, 246)', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontStyle: 'Inter', fontWeight: '400', fontSize: '14px' }}>Last 7 days</div>
            <div style={{ color: 'rgb(4, 105, 220)', fontSize: '16px' }}>220</div>
          </div>
          <div style={{ padding: '5px', borderTop: '1px solid rgb(244, 244, 246)', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontStyle: 'Inter', fontWeight: '400', fontSize: '14px' }}>Last 30 days</div>
            <div style={{ color: 'rgb(4, 105, 220)', fontSize: '16px' }}>1345</div>
          </div>
          <div style={{ padding: '5px', borderTop: '1px solid rgb(244, 244, 246)', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ fontStyle: 'Inter', fontWeight: '400', fontSize: '14px' }}>Total</div>
            <div style={{ color: 'rgb(4, 105, 220)', fontSize: '16px' }}>1625</div>
          </div>
        </CardBody>
      </Card>
    );
};
export default ActiveUsers;
