import React, { PureComponent } from 'react';
import { ChromePicker } from 'react-color';
import { Popover } from 'reactstrap';
import PropTypes from 'prop-types';

class ChromeColorPickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    defaultColor: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        hex: PropTypes.string,
      }),
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: this.props.defaultColor && this.props.defaultColor,
      rgb: this.props.defaultColor && this.hexToRgb(this.props.defaultColor),
      active: false,
    };
  }

  componentDidMount() {
    const color = {
      hex: this.state.color,
      rgb: this.state.rgb,
    };
    this.props.onChange(color);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && prevProps.value && this.props.value.hex !== prevProps.value.hex) {
      this.handleChange({ hex: this.props.value.hex, rgb: this.props.value.rgb });
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker, active: !this.state.active });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex, rgb: color.rgb });
    this.props.onChange(color);
  };

  hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const newHex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  }

  render() {
    const { name } = this.props;

    return (
      <div className="color-picker">
        <button
          className={`color-picker__button${this.state.active ? ' active' : ''}`}
          onClick={this.handleClick}
          id={name}
        >
          <p className="color-picker__color">{this.state.color}</p>
          <div className="color-picker__color-view" style={{ backgroundColor: this.state.color }} />
        </button>
        <Popover
          isOpen={this.state.displayColorPicker}
          target={name}
          placement="bottom"
          className="color-picker__popover"
        >
          <ChromePicker
            color={this.state.rgb}
            onChange={this.handleChange}
            disableAlpha
          />
        </Popover>
      </div>
    );
  }
}

const renderChromeColorPickerField = props => (
  <div className="form__form-group-input-wrap">
    <ChromeColorPickerField
      {...props.input}
      defaultColor={props.defaultColor}
    />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderChromeColorPickerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  defaultColor: PropTypes.string,
};

renderChromeColorPickerField.defaultProps = {
  meta: null,
  defaultColor: '#ffffff',
};

export default renderChromeColorPickerField;
