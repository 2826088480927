export default {
  club_name: 'Leksands IF',
  club_name_short: 'LIF',
  stadium_name: 'Tegera Arena',
  staging_url: 'https://lif.api.fangroup.io',
  prod_url: 'https://lif.api.fangroup.io',
  api: '/api',
  facebook: 'leksandsif',
  basename: '/lif',
  club_logo: '/assets/teams/lif.png',
  app_name: 'lif',
  websale_name: 'lif',
  ntb_team_id: 614,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'lif',
  monolithEnabled: false,
};
