import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Alert, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import renderSelectField from '../../../shared/components/form/Select';
import validate from './validateAccess';
import t, { partial } from '../../../util/translation/translation';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import { isFilterable } from '../spotlightUtils';

const TileAccessForm = (props) => {
  const o = partial('OfferForm');
  const s = partial('shared');
  const e = partial('EventsForm');
  const n = partial('NewsForm');
  const p = partial('HighlightBannerForm');
  const { handleSubmit, news, initialize, groupsLevelsTiers, access, segments, accessFormValues = {}, change, selectedEntityAccess } = props;
  const { tiers, levels, groups } = groupsLevelsTiers;
  const targetAudience = useMemo(() => accessFormValues?.targetAudience, [accessFormValues]);
  const getTargetAudience = () => {
    if (access.levels || access.groups || access.tiers || access.segments) {
      return 'targetAudience';
    }
    return 'all';
  };
  const getSelectedFields = (accessObject, objectField, title, searchArray) => {
    if (Array.isArray(accessObject[objectField])) {
      if (accessObject[objectField].length === 0) {
        return [{ label: e(`all${title}`), value: [] }];
      } else {
        return accessObject[objectField].map((accessItem) => searchArray.find((item) => item.value === accessItem));
      }
    } else {
      return [{ label: s('ignore'), value: null }];
    }
  };
  useEffect(() => {
    initialize({
      targetAudience: getTargetAudience(),
      level: getSelectedFields(access, 'levels', 'Levels', levels),
      tier: getSelectedFields(access, 'tiers', 'Tiers', tiers),
      group: getSelectedFields(access, 'groups', 'Groups', groups),
      segments: access?.segments?.map((item) => ({ label: item.name, value: item.id })) || [{ label: s('ignore'), value: null }],
      noAccessView: typeof access.noAccessView === 'boolean' ? access.noAccessView : true,
    });
  }, [news, access]);
  const adjustDropdowns = (fieldName) => {
    if (accessFormValues[fieldName] && accessFormValues[fieldName].length > 1) {
      const [old, ...newOnes] = accessFormValues[fieldName];
      const newestOne = accessFormValues[fieldName][accessFormValues[fieldName].length - 1];
      if (old.value === null || Array.isArray(old.value)) {
        change(fieldName, newOnes);
      }
      if (newestOne.value === null || Array.isArray(newestOne.value)) {
        change(fieldName, [newestOne]);
      }
    }
  };
  useEffect(() => {
    adjustDropdowns('group');
    adjustDropdowns('level');
    adjustDropdowns('tier');
    adjustDropdowns('segments');
  }, [accessFormValues.level, accessFormValues.group, accessFormValues.tier, accessFormValues.segments]);
  useEffect(() => {
    if (selectedEntityAccess && isFilterable(selectedEntityAccess?.selectedContent?.type)) {
      change('level', getSelectedFields(selectedEntityAccess, 'levels', 'Levels', levels));
      change('tier', getSelectedFields(selectedEntityAccess, 'tiers', 'Tiers', tiers));
      change('group', getSelectedFields(selectedEntityAccess, 'groups', 'Groups', groups));
      change('noAccessView', selectedEntityAccess.noAccessView);
      if (accessFormValues.segments?.length > 0) {
        change('targetAudience', 'targetAudience');
      } else if (selectedEntityAccess.levels === null && selectedEntityAccess.groups === null && selectedEntityAccess.tiers === null) {
          change('targetAudience', 'all');
        } else {
          change('targetAudience', 'targetAudience');
        }
    }
  }, [selectedEntityAccess]);
  const handleAudienceChange = (newAudience) => {
    const ignore = [{ label: s('ignore'), value: null }];
    if (newAudience === 'all') {
      change('level', ignore);
      change('tier', ignore);
      change('group', ignore);
      change('segments', ignore);
    } else {
      change('level', [{ label: e('allLevels'), value: [] }]);
      change('tier', [{ label: e('allTiers'), value: [] }]);
      change('group', [{ label: e('allGroups'), value: [] }]);
      change('segments', ignore);
    }
  };
  const accessDisabled = (selectedEntityAccess && selectedEntityAccess.isEditable !== true) ? !(selectedEntityAccess?.selectedContent?.contentId === null || selectedEntityAccess?.selectedContent?.type === 'EXTERNAL_LINK' || selectedEntityAccess?.selectedContent?.type === 'YOUTUBE_VIDEO') : false;
  return (
    <form onSubmit={handleSubmit} className="form">
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: 15, marginBottom: 15, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('access')}</div>
          </div>
        </div>
        <div style={{ padding: '10px 10px 10px 10px' }}>
          <Col>
            <Row>
              <Col >
                <span className="form">{n('targetAudienceTitle')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <Field
                      name="targetAudience"
                      component={renderRadioButtonField}
                      radioValue="all"
                      label={n('targetAudience-all')}
                      defaultChecked
                      disabled={accessDisabled}
                      onChange={handleAudienceChange}
                    />
                    <Field
                      name="targetAudience"
                      label={n('targetAudience-custom')}
                      component={renderRadioButtonField}
                      radioValue="targetAudience"
                      disabled={accessDisabled}
                      onChange={handleAudienceChange}
                    />
                  </div>
                </div>
              </Col>
              <Col >
                <span className="form">
                  {n('noAccessView')}
                  &nbsp;
                  <TooltipWithIcon id="no-access-view-tooltip" text={t('NewsFormPage.noAccessViewInfo')} />
                </span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <Field
                      name="noAccessView"
                      component={renderRadioButtonField}
                      // eslint-disable-next-line react/jsx-boolean-value
                      radioValue={true}
                      label={s('all')}
                      disabled={accessDisabled}
                      defaultChecked
                    />
                    <Field
                      name="noAccessView"
                      label={o('targetAudience')}
                      component={renderRadioButtonField}
                      disabled={accessDisabled}
                      radioValue={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {targetAudience !== 'all' ?
              <Row>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('partnerlevelList')}</span>
                    <Field
                      name="level"
                      placeholder={o('selectLevels')}
                      options={levels}
                      component={renderSelectField}
                      multiple
                      disabled={accessDisabled}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('partnergroupList')}</span>
                    <Field
                      name="group"
                      placeholder={o('selectGroup')}
                      options={groups}
                      component={renderSelectField}
                      multiple
                      disabled={accessDisabled}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{s('userTiers')}</span>
                    <Field
                      name="tier"
                      placeholder={o('selectTier')}
                      options={tiers}
                      component={renderSelectField}
                      multiple
                      disabled={accessDisabled}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{t('HighlightBannerPage.segments')}</span>
                    <Field
                      name="segments"
                      placeholder={p('selectSegments')}
                      options={segments}
                      component={renderSelectField}
                      multiple
                      disabled={accessDisabled}
                    />
                  </div>
                </Col>
              </Row> :
              null}
          </Col>
        </div>
      </div>
      {accessDisabled &&
        <Alert color="primary">
          {p('alertTargetAudienceInfo')}
        </Alert>
      }
    </form>

  );
};

const reduxF = reduxForm({
  form: 'tile_access_form',
  validate,
});

const mapStateToProps = (state) => ({
  accessFormValues: getFormValues('tile_access_form')(state),
});

export default connect(mapStateToProps)(reduxF(TileAccessForm));
