export default {
  club_name: 'IF Elfsborg',
  club_name_short: 'elfsborg',
  stadium_name: 'Borås Arena',
  staging_url: 'https://elfsborg.api.fangroup.io',
  prod_url: 'https://elfsborg.api.fangroup.io',
  api: '/api',
  facebook: 'ifelfsborg',
  basename: '/elfsborg',
  club_logo: '/assets/teams/elfsborg.png',
  app_name: 'elfsborg',
  websale_name: 'elfsborg',
  ntb_team_id: 10033,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'elfsborg',
  monolithEnabled: false,
};
