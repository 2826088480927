import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${p('discountNameFieldEmpty')}'!'`;
  }
  if (!values.price) {
    errors.price = `${p('discountFieldEmpty')}'!'`;
  }

  if (values.price && values.price < 0) {
    errors.price = `${p('DiscountFieldNegativeNumberERROR')}'!'`;
  }

  if (!values.saleStart) {
    errors.saleStart = `${p('availableFromFieldCantBeEmpty')}`;
  }

  if (!values.saleEnd) {
    errors.saleEnd = `${p('availableToFieldCantBeEmpty')}`;
  }

  if (!values.rules || !values.rules.length) {
    errors.rules = { _error: `${p('createDiscountRule')}` };
  } else {
    const rulesArrayErrors = [];
    let totalDiscountedTickets = 0;
    values.rules.forEach((rule, index) => {
      const ruleErrors = {};
      totalDiscountedTickets += Number(rule.quantity);

      if (!rule || !rule.priceGroup) {
        ruleErrors.priceGroup = `${p('priceGroupFieldEmpty')}`;
        rulesArrayErrors[index] = ruleErrors;
      }

      if (!rule || !rule.quantity) {
        ruleErrors.quantity = `${p('numberOfFieldEmpty')}`;
        rulesArrayErrors[index] = ruleErrors;
      }

      if (!rule || !rule.modifier) {
        ruleErrors.modifier = `${p('logicFieldEmpty')}`;
        rulesArrayErrors[index] = ruleErrors;
      }

      if ((rule && rule.quantity) && rule.quantity < 0) {
        ruleErrors.quantity = `${p('numberOfFieldNegativeNumberERROR')}'!'`;
        rulesArrayErrors[index] = ruleErrors;
      }
      if (totalDiscountedTickets > 6) {
        ruleErrors.quantity = `${p('toBigNumberOfTickets')}'6!'`;
        rulesArrayErrors[index] = ruleErrors;
      }
    });
    if (rulesArrayErrors.length) {
      errors.rules = rulesArrayErrors;
    }
  }

  return errors;
};

export default validate;
