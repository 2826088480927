import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import t, { partial } from '../../../util/translation/translation';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import Api from '../../../util/api';

const s = partial('shared');
const CommentModal = ({
  selectedParticipantForComment,
  getPaticipants,
  handleCloseModal,
  isWaitlist = false,
  initialize }) => {
  useEffect(() => {
    initialize({
      additionalComment: selectedParticipantForComment.additionalNotes,
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const additionalComment = event.target[0].value;
    try {
      if (isWaitlist) {
        await Api.events.addWaitingParticipantNotes(
          { additionalNotes: additionalComment }, selectedParticipantForComment.id,
        );
      } else {
        await Api.events.addAdditionalNotes(
          { additionalComment }, selectedParticipantForComment.id,
        );
      }
      toast.success(t('EventsPage.notesAddedSuccessfuly'));
      handleCloseModal();
      getPaticipants();
    } catch (err) {
      toast.error(t('EventsPage.failedToAddNotes'));
    }
  };


  return (

    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header bold">{t('EventsPage.addAdditionalNotes')} {selectedParticipantForComment?.firstname}</span>
        <Field
          name="additionalComment"
          component={renderTextAreaField}
          type="text"
          placeholder={t('EventsPage.addNotes')}
        />
      </div>
      <Row>
        <Col>
          <Button color="primary" type="submit">
            {selectedParticipantForComment?.additionalNotes ? `${s('save')}` : `${s('add')}`}
          </Button>
        </Col>
      </Row>
    </form>
  );
};


const reduxF = reduxForm({
  form: 'CommentModal',
});
export default reduxF(CommentModal);
