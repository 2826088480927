import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';

const p = partial('ReportsPage');
const s = partial('shared');

const MatchVippsReport = (props) => {
  const [priceGroups, setPriceGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInitialData = async () => {
    setLoading(true);
    try {
      const resPriceGroups = await Api.reports.getPriceGroups();
      const filteredPriceGroups = resPriceGroups.data
        .filter(priceGroup => priceGroup.buyable || priceGroup.title === s('ticket'))
        .map(priceGroup => priceGroup.title);
      setPriceGroups(filteredPriceGroups);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('fetcingMatchTicketsFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const listHeaders = () =>
    priceGroups.map(priceGroup => (
      <th key={`listHeaders-${priceGroup}`}>{priceGroup}</th>
    ));

  const listPriceGroups = matchTicket =>
    priceGroups.map(priceGroup => (
      <td key={`listPriceGroups-${matchTicket.match_id}-${priceGroup}`}>
        { props.formatSoldTicketsData(priceGroup, matchTicket) }
      </td>
    ));

  const listVippsMatchSales = () =>
    props.matchTickets.map(matchTicket => (
      <tr key={`listVippsMatchSales-${matchTicket.match_id}`}>
        <td>{ props.renderMatchName(matchTicket.match_id) }</td>
        {listPriceGroups(matchTicket)}
        <td>{ props.formatSoldTicketsData('Total', matchTicket) }</td>
      </tr>
    ));

  return (
    <Table responsive bordered>
      <Loading loading={loading} />
      <thead>
        <tr>
          <th>{s('opponent')}</th>
          {listHeaders()}
          <th>{s('total')}</th>
        </tr>
      </thead>
      <tbody>
        {listVippsMatchSales()}
      </tbody>
    </Table>
  );
};

MatchVippsReport.propTypes = {
  matchTickets: PropTypes.arrayOf(PropTypes.shape({
    match_id: PropTypes.number.isRequired,
  })).isRequired,
  formatSoldTicketsData: PropTypes.func.isRequired,
  renderMatchName: PropTypes.func.isRequired,
};

export default MatchVippsReport;
