import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import Loading from '../../shared/Spinner';
import { DoughnutChart, EmptyGraphData, Widget, WidgetHeader } from './shared';
import helpers from '../helpers';

const SeasonTickets = () => {
  const [pickedSeason, setPickedSeason] = useState(null);
  const dT = partial('DashboardPage');
  const [chartData, setChartData] = useState({});
  const { data: allSeasons } = useQuery({ queryKey: ['get-seasons'], queryFn: () => Api.seasonTickets.getSeasons() });
  const { data: sales, isLoading } = useQuery({ queryKey: ['price-group-sales', allSeasons?.data, pickedSeason],
  enabled: pickedSeason !== null,
  queryFn: () => {
      return Api.dashboard.getPriceGroupSales(pickedSeason.id);
    } });

  useEffect(() => {
    if (allSeasons?.data) {
      setPickedSeason(allSeasons.data[0]);
    }
  }, [allSeasons?.data]);

  useEffect(() => {
    if (sales) {
      const { labels, dataset, colors } = helpers.getSortedChartData(sales.data, 'title', 'amount');
      setChartData({
        labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: colors,
          },
        ],
      });
    }
  }, [sales]);

  const renderMatchesList = () => {
    return allSeasons?.data.map(season => (
      <button key={season.id} onClick={() => setPickedSeason(season)}>
        {season.name}
      </button>
    ));
  };

  const renderChart = (
      chartData.datasets && chartData.datasets[0].data.length > 0 ? <DoughnutChart chartData={chartData} /> : <EmptyGraphData message={dT('noData')} />
    );

  return (
    <Widget>
      <WidgetHeader title={dT('seasonTickets')} subhead={dT('seasonTicketsSubheading')}>
        <div className="widget-dropdown">
          <button className="widget-dropdown-btn">{ pickedSeason?.name}&nbsp;&nbsp;<span className="dropdown-chevron">&#9662;</span></button>
          <div className="widget-dropdown-content">
            { renderMatchesList() }
          </div>
        </div>
      </WidgetHeader>
      {isLoading ? <Loading /> : renderChart}
    </Widget>
  );
};

export default SeasonTickets;
