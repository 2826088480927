import Api from '../../../util/api';
import t from '../../../util/translation/translation';
import config from '../../../config';

export const getMessageType = (message) => {
    const messageChannels = message.payload.channels.map(({ channel }) => channel);
    switch (true) {
      case messageChannels.includes('push'): return 'push';
      case messageChannels.includes('sms'): return 'sms';
      case messageChannels.includes('email'): return 'email';
      default: return null;
    }
  };
export const getOriginLink = async (message) => {
    const availableGeneralScreens = [
      {
        label: t('MessagesForm.events'),
        value: '/more/eventStack/events',
      },
      {
        label: t('MessagesForm.lotteries'),
        value: '/more/lotteryStack/lotteriesScreen',
      },
      {
        label: t('MessagesForm.auctions'),
        value: '/more/auctions/Auctions',
      },
      {
        label: t('MessagesForm.news'),
        value: '/home/news/false',
      },
      {
        label: t('MessagesForm.homeScreen'),
        value: '/home/false',
      },
      {
        label: t('MessagesForm.matches'),
        value: '/tickets/ticketScreen/buy',
      },
    ];
    switch (message.type) {
      case 'NEWS': return {
        link: `news/edit/${message.payload.id.replace('-push-sent', '')}`,
        title: t('shared.news'),
      };
      case 'OFFER': return {
        link: `offers/edit/${message.payload.id.replace('-push-sent', '')}`,
        title: t('shared.offer'),
      };
      case 'EVENT': return {
        link: `events/edit/${message.payload.eventInfo.eventId}`,
        title: t('shared.event'),
      };
      case 'AUCTIONS':
      {
        const containsLabel = message.payload.channels.some((item) => item.pushLabel);
        if (!containsLabel) {
          return {
            link: null,
            title: t('AppLayout.auctionsTile'),
          };
        }
        const houseOrItem = message.payload.eventInfo.eventId;
        const isAuctionHouse = message.payload.channels.find((item) => item.pushLabel.includes('auction-house'));
        let generalUrl = `auctions/edit/${houseOrItem}?tab=1`;
        let title = t('auctionObjectsShared.auctionHouse');
        if (!isAuctionHouse) {
          const foundItem = await Api.auctions.getAuctionItem(houseOrItem);
          generalUrl = `auctions/edit/${foundItem.auctionHouseId}/edit/${houseOrItem}`;
           title = t('auctionObjectsShared.auctionObject');
        }
        return {
        link: generalUrl,
        title,
      }; }
      case 'GENERAL': {
        const isMatchCenterPush = message.payload.channels.find((channel) => channel?.pushLabel?.includes('matchCenterMatch'));
        if (isMatchCenterPush) {
          return {
            link: `matches/upcoming/edit/${message.payload.eventInfo.eventId}`,
            title: t('AppLayout.matchcenterTile'),
          };
        }
        return {
          link: null,
          title: availableGeneralScreens.find((item) => item.value === message.payload.placeholders.url)?.label || '',
        };
      }
      case null: {
        if (message?.payload?.placeholders?.en?.body?.includes('Someone has sent you match tickets.')) {
          return { link: null, title: t('MessagesForm.sendtTickets') };
        }
        return { link: null, title: null };
      }
      default: return { link: null, title: null };
    }
  };
  export const getCurrentLanguage = () => {
    const currency = config?.currencyCaps;
    if (currency === 'SEK') {
      return 'sv';
    }
    if (currency === 'NOK') {
      return 'nb';
    }
    return 'en';
  };
export const getTitleAndBody = (message) => {
    const messageType = getMessageType(message);
    const language = getCurrentLanguage();
    switch (messageType) {
      case 'push': return {
        messageTitle: message.payload.placeholders[language]?.title || message.payload.placeholders.TITLE || message.payload.placeholders.title,
        messageBody: message.payload.placeholders[language]?.body || message.payload.placeholders.BODY || message.payload.placeholders.body,
      };
      case 'email': return {
        messageTitle: message.payload.placeholders.SUBJECT,
        messageBody: message.payload.placeholders.BODY,
      };
      case 'sms': return {
        messageTitle: t('shared.empty'),
        messageBody: message.payload.placeholders.BODY,
      };
      default: return { messageTitle: null, messageBody: null };
    }
  };
  export const mapTitleAndBodyToPlaceholders = (message, title, body) => {
    const messageType = getMessageType(message);
    switch (messageType) {
      case 'push': {
        const titleKey = message.payload.placeholders.TITLE ? 'TITLE' : 'title';
        const bodyKey = message.payload.placeholders.BODY ? 'BODY' : 'body';
        return {
          [titleKey]: title,
          [bodyKey]: body,
        };
      }
      case 'email': return {
        SUBJECT: title,
        BODY: body,
      };
      case 'sms': return {
        BODY: body,
      };
      default: return { };
    }
  };
