import React, { useState } from 'react';
import { Card, CardBody, Container, Col, TabContent } from 'reactstrap';
import { useRecoilState } from 'recoil';
import MatchTicketsReport from './components/MatchTicketsReport';
import MatchAttendanceReport from './components/MatchAttendanceReport';
import SeasonTicketsReport from './components/SeasonTicketsReport';
import MatchSoldTicketsTabs from './components/MatchSoldTicketsTabs';
import InvoiceBasis from './components/InvoiceBasis';
import InvoicesTab from './components/InvoicesTab';
import DiscountReport from './components/DiscountReport';
import NavTab from '../../shared/components/custom/NavTab';
import LazyTabPane from '../../shared/components/custom/LazyTabPane';
import { partial } from '../../util/translation/translation';
import seasonsState from '../../recoil/seasons/selector';

const ReportsPage = () => {
  const [seasons] = useRecoilState(seasonsState);
  const [activeTab, setActiveTab] = useState('1');
  const p = partial('ReportsPage');
  const s = partial('shared');

  return (
    <Container>
      <Col md={12}>
        <NavTab
          activeTab={activeTab}
          setTab={setActiveTab}
          tabs={[
            p('singleTicketSale'),
            s('vipps'),
            s('kiosk'),
            p('freeTickets'),
            p('visitReport'),
            p('seasonCardSale'),
            s('invoiceBasis'),
            s('invoice'),
            p('quantityDiscount'),
          ]}
        />
        <Card>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <LazyTabPane activeTab={activeTab} tabId="1">
                <MatchTicketsReport />
              </LazyTabPane>
              {/* tabIds  2 to 4 */}
              <MatchSoldTicketsTabs activeTab={activeTab} />
              <LazyTabPane activeTab={activeTab} tabId="5">
                <MatchAttendanceReport />
              </LazyTabPane>
              <LazyTabPane activeTab={activeTab} tabId="6">
                <SeasonTicketsReport />
              </LazyTabPane>
              <LazyTabPane activeTab={activeTab} tabId="7">
                <InvoiceBasis />
              </LazyTabPane>
              <LazyTabPane activeTab={activeTab} tabId="8">
                <InvoicesTab />
              </LazyTabPane>
              <LazyTabPane activeTab={activeTab} tabId="9">
                <DiscountReport seasons={seasons} />
              </LazyTabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default ReportsPage;
