export default {
  club_name: 'Score 2.0',
  club_name_short: 'SCORE',
  stadium_name: 'Score Arena',
  staging_url: 'https://score.api.fangroup.io',
  prod_url: 'https://score.api.fangroup.io',
  api: '/api',
  facebook: 'neveroffsideab',
  basename: '/score',
  club_logo: '/assets/teams/score.png',
  app_name: 'score',
  websale_name: 'score',
  ntb_team_id: 3725,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'score',
  monolithEnabled: false,
};
