import React, { useEffect, useMemo, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Button, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import moment from 'moment';
import AccountGroup from 'mdi-react/CashMultipleIcon';
import { Parser } from 'json2csv';
import fileDownload from 'js-file-download';
import CheckIcon from '../../../shared/components/custom/CheckIcon';
import { formatCurrencyNumber } from '../../../util/functions';
import { partial } from '../../../util/translation/translation';
import { formatDateTime } from '../../../util/date-and-time';
import useConfig from '../../../util/useConfig';
import Api from '../../../util/api';
import ValueDisplayCounter from './ValueDisplayCounter';
import Loading from '../../../shared/components/custom/Loading';

const AuctionObjectList = ({ auctionHouseId, handleModal, setLoading, deleteVisible }) => {
  const config = useConfig();
  const p = partial('auctionObjectsShared');
  const s = partial('shared');
  const [auctionItems, setAuctionItems] = useState([]);
  const totalBidValue = useMemo(() => auctionItems.reduce((tot, curr) => {
    return tot + curr.lastBid || 0;
  }, 0), [auctionItems]);
  const getAuctionHouseItems = async () => {
    setLoading(true);
    try {
      const resAuctionItems = await Api.auctions.getAuctionItemsForHouse(auctionHouseId);
      setAuctionItems(resAuctionItems);
      setLoading(false);
      const auctionItemIds = resAuctionItems.map((item) => item.id);
      const { data: pressData } = await Api.auctions.getAuctionsPressAmount({ auctionItemIds });
      const mapped = resAuctionItems.map((item) => ({ ...item, views: pressData.find((itemPress) => itemPress.itemId === item.id)?.presses || 0 }));
      setAuctionItems(mapped);
    } catch (err) {
      toast.error(JSON.stringify(err));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (auctionHouseId) {
      getAuctionHouseItems();
    }
  }, [auctionHouseId]);
  useEffect(() => {
    if (!deleteVisible && auctionHouseId) {
      getAuctionHouseItems();
    }
  }, [deleteVisible, auctionHouseId]);
  const renderAuctionObjectList = () => {
    return auctionItems.map((auctionItem, i) => (
      <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`auctionItem-${auctionItem.id}`}>
        <td><CheckIcon state={auctionItem.isActive && moment().isBetween(moment(auctionItem.startDate), moment(auctionItem.endDate))} /></td>
        <td><Link to={`/auctions/edit/${auctionHouseId}/edit/${auctionItem.id}`}>{auctionItem.name}</Link></td>
        <td>{`${formatDateTime(auctionItem.startDate)} - ${formatDateTime(auctionItem.endDate)}`}</td>
        <td>{auctionItem.views !== undefined ? auctionItem.views : <Loading loading size={40}/>}</td>
        <td>{auctionItem.totalBids}</td>
        <td />
        <td><b>{formatCurrencyNumber(auctionItem.lastBid)} {config.currencyCaps}</b></td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/auctions/edit/${auctionHouseId}/edit/${auctionItem.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem onClick={() => handleModal(auctionItem.id)} className="danger">{s('deleteCaps')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };
  const handleCsvExport = async () => {
    try {
      // for all items gets bids + users
      setLoading(true);

      const auctionItemsWithBids = await Promise.all(auctionItems.map((item) => Api.auctions.getAuctionItemBids(item.id, { page: 1, take: 1 }).then(({ data }) => ({ ...item, bids: data }))));
      const userIds = [...new Set(auctionItemsWithBids.flatMap((item) => item.bids.map((bid) => bid.userId)))];
      const { data: usersByIds } = await Api.users.getUsersByCloudIds(userIds);
      const userByIdMap = {};
      for (let i = 0; i < usersByIds.length; i++) {
        userByIdMap[usersByIds[i].userCloudId] = usersByIds[i];
      }
      const mappedItems = auctionItems.map((item) => ({
        name: item.name,
        description: item.description,
        startDate: moment(item.startDate).format('DD/MM/YYYY HH:mm'),
        endDate: moment(item.endDate).format('DD/MM/YYYY HH:mm'),
        highestBid: item.bids[0]?.amount || 'empty',
        phoneNumber: item.bids[0]?.userId ? userByIdMap[item.bids[0]?.userId]?.phoneNumber : 'empty',
        email: item.bids[0]?.userId ? userByIdMap[item.bids[0]?.userId]?.email : 'empty',
        firstName: item.bids[0]?.userId ? userByIdMap[item.bids[0]?.userId]?.firstname : 'empty',
        lastName: item.bids[0]?.userId ? userByIdMap[item.bids[0]?.userId]?.lastname : 'empty',
      }));

      const fields = [
        { label: 'Name', value: 'name' },
        { label: 'Description', value: 'description' },
        { label: 'Start', value: 'startDate' },
        { label: 'End', value: 'endDate' },
        { label: 'Bid', value: 'highestBid' },
        { label: 'Phone', value: 'phoneNumber' },
        { label: 'Email', value: 'email' },
        { label: 'First name', value: 'firstName' },
        { label: 'Last name', value: 'lastName' },

      ];
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(mappedItems);
      fileDownload(`\uFEFF${csv}`, 'export.csv');
    } catch (err) {
      toast.error(err || `${p('listExportingERROR')}'!'`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <p className="bold-text flex alignItems-center flex-HSpaceBetween">
        <UncontrolledTooltip placement="bottom" target="addedValue">
          {p('auctionValue')}
        </UncontrolledTooltip>
        <ValueDisplayCounter
          id="addedValue"
          icon={<AccountGroup style={{ width: 24, display: 'inline-block' }} />}
          text={`${formatCurrencyNumber(totalBidValue)} ${config.currencyCaps}`}
        />
        <div>
          <Button className="margin-0" outline color="secondary" size="sm" onClick={handleCsvExport}>{p('exportWinningBid')}</Button>
          <Link style={{ marginLeft: 10 }} to={`/auctions/edit/${auctionHouseId}/create`}><Button className="margin-0" color="primary" style={{ float: 'right' }} size="sm">{p('createAuctionObject')}</Button></Link>
        </div>
      </p>
      <div className="mt-4" style={{ display: 'flex', paddingBottom: 5, marginTop: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
        <Table responsive striped >
          <thead>
            <tr>
              <th className="bold" style={{ color: '#4D576A' }}>{s('active')}</th>
              <th className="bold" style={{ color: '#4D576A' }}>{s('product')}</th>
              <th className="bold" style={{ color: '#4D576A' }}>{s('date')}</th>
              <th className="bold" style={{ color: '#4D576A' }}>{s('views')}</th>
              <th className="bold" style={{ color: '#4D576A' }}>{p('totalBids')}</th>
              <th className="bold" style={{ color: '#4D576A' }}>{p('highestBid')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {renderAuctionObjectList()}
          </tbody>
        </Table>
      </div>
    </>
  );
};


export default AuctionObjectList;
