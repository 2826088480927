/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { any } from 'prop-types';
import { toast } from 'react-toastify';
import Form from './components/form';
import Api from '../../../../../../util/api';
import Loading from '../../../../../../shared/components/custom/Loading';
import { partial } from '../../../../../../util/translation/translation';

const p = partial('EditSeasonTicketPage');
const t = partial('TicketStates');
class EditSeasonTicket extends Component {
  static propTypes = {
    formValues: PropTypes.objectOf(any),
    match: PropTypes.shape({
      params: PropTypes.shape({
        seasonTicketID: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    formValues: undefined,
  };

  state = {
    ownerOptions: [],
    userOptions: [],
    ticketStates: [],
    seasonPrices: [],
    seasonTicket: {},
    loading: true,
  };

  componentDidMount() {
    this.initializeComponent();
  }

  onSubmit = () => {
    const payload = {
      new_company_id: this.props.formValues.owner.isCompany ? this.props.formValues.owner.value : undefined,
      owner_id: !this.props.formValues.owner.isCompany ? this.props.formValues.owner.value : undefined,
      season_price_id: Number(this.props.formValues.priceGroup.value),
      shareable: Number(this.props.formValues.shareable),
      tags: this.props.formValues.tags,
      ticket_state_id: Number(this.props.formValues.ticketState.value),
      total: Number(this.props.formValues.total),
      user_id: this.props.formValues.user.value,
      vip: Number(this.props.formValues.vip),
      comment: this.props.formValues.comment,
      releasable: this.props.formValues.releasable,
    };
    this.setState({ loading: true }, () => {
      Api.seasonTickets
        .editSeasonTicket(payload, this.props.match.params.seasonTicketID)
        .then(() => {
          this.setState({ loading: false });
          toast.success(p('seasonTicketWasUpdated'));
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(err || p('errorUpdatingSeasonTicket'));
        });
    });
  };

  // eslint-disable-next-line react/sort-comp
  debounce = (cb) => {
    let id = '';
    return (value) => {
      clearTimeout(id);
      id = setTimeout(() => {
        cb(value);
      }, 400);
    };
  };

  getOwners = this.debounce(async (text) => {
    try {
      const ownerOptions = [];
      const [resCompanies, { users: resUsers }] = await Promise.all([
        Api.companies.searchCompanies(text.toLowerCase()),
        Api.users.getTeamUsers(1, 100, { phoneNumber: 'ASC' }, text),
      ]);
      resCompanies.data.forEach((company) =>
        ownerOptions.push({
          label: company.name,
          value: company.id,
          isCompany: company.id,
        }),
      );
      resUsers.forEach((user) =>
        ownerOptions.push({
          label: `${user.firstname} ${user.lastname} ${user.username}`,
          value: user.userCloudId,
        }),
      );
      this.setState({ ownerOptions });
    } catch (err) {
      toast.error(err || p('fetchingSponsorsFailed'));
    }
  });
  getUsers =this.debounce(async (text) => {
    try {
      const { users } = await Api.users.getTeamUsers(1, 100, { phoneNumber: 'ASC' }, text);
      const userOptions = users.map((user) => ({
        label: `${user.firstname} ${user.lastname} ${user.username}`,
        value: user.userCloudId,
      }));
      this.setState({ userOptions });
    } catch (err) {
      toast.error(err || p('fetchingUsersFailed'));
    }
  });

  handleTicketStates = (ticketStates) => {
    const acceptedStates = ['ACTIVE', 'RESERVED'];
    const filterTicketStates =
      (_acceptedStates) =>
      ({ name }) =>
        _acceptedStates.includes(name);
    return ticketStates.filter(filterTicketStates(acceptedStates)).map((ticketState) => {
      return {
        label: t(ticketState.name.toLowerCase()),
        value: ticketState.id.toString(),
      };
    });
  };

  handleSeasonPrices = (seasonPrices, season) => {
    const filteredSeasonPrices = seasonPrices.filter((seasonPrice) => seasonPrice.season_id === season.id);
    return filteredSeasonPrices.map((price) => {
      return {
        label: price.title,
        value: price.id.toString(),
      };
    });
  };

  initializeComponent = async () => {
    const url = new URL(window.location.href);
    const seasonId = url.searchParams.get('season');
    const getSeason = () => (seasonId ? Api.seasons.getSeasonByID(seasonId) : Api.seasonTickets.getCurrentSeason());
    try {
      const [resTicketStates, resSeasonPrices, resTicket, resSeason] = await Promise.all([
        Api.seasonTickets.getTicketStates(),
        Api.seasonTickets.getSeasonPrices(),
        Api.seasonTickets.getSeasonTicket(this.props.match.params.seasonTicketID),
        getSeason(),
      ]);
      const ticketStates = this.handleTicketStates(resTicketStates.data);
      const seasonPrices = this.handleSeasonPrices(resSeasonPrices.data, resSeason.data);
      const seasonTicket = resTicket.data;
      this.setState({
        ticketStates,
        seasonPrices,
        seasonTicket,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      toast.error(err || p('errorFetchingInfo'));
    }
  };

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <Col md={12}>
          <h3 className="page-title">{p('editSeasonTicket')}</h3>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <Form
                ownerOptions={this.state.ownerOptions}
                userOptions={this.state.userOptions}
                getOwner={this.getOwners}
                getUser={this.getUsers}
                ticketStates={this.state.ticketStates}
                seasonPrices={this.state.seasonPrices}
                seasonTicket={this.state.seasonTicket}
                onSubmit={this.onSubmit}
              />
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}
export default connect((state) => ({
  formValues: getFormValues('edit_season_ticket')(state),
}))(EditSeasonTicket);
