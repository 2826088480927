import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateGroupForm = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('titleFieldEmpty');
  }
  return errors;
};

export default validateGroupForm;
