import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import NewsCategoryList from './NewsCategoryList';
import NewsProvidersList from './NewsProvidersList';
import ContentAPIList from './ContentAPIList';
import t, { partial } from '../../../util/translation/translation';

const s = partial('shared');
const p = partial('NewsPage');

const SettingsPage = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingNewsCategories, setLoadingNewsCategories] = useState(false);
  const [loadingNewsProviders, setLoadingNewsProviders] = useState(false);

  const [modal, setModal] = useState({
    modalVisible: false, deleteNewsID: null, type: null,
  });

  const [apiAccessList, setApiAccessList] = useState([]);
  const [newsCategoryList, setNewsCategoryList] = useState([]);
  const [newsProvidersList, setNewsProvidersList] = useState([]);

  const handleModal = (state, id, type) => {
    setModal({
      modalVisible: state, deleteNewsID: id, type,
    });
  };

  const getApiAccess = async () => {
    setLoadingApi(true);
    try {
      const accessList = await Api.news.getApiAccessList();
      setApiAccessList(accessList);
      setLoadingApi(false);
    } catch (err) {
      setLoadingApi(false);
    }
  };
  const getNewsCategories = async () => {
    setLoadingNewsCategories(true);
    try {
      const categoryList = await Api.news.getNewsCategories();
      setNewsCategoryList(categoryList);
      setLoadingNewsCategories(false);
    } catch (err) {
      setLoadingNewsCategories(false);
    }
  };
  const getNewsProviders = async () => {
    setLoadingNewsProviders(true);
    try {
      const newsProviders = await Api.news.getNewsProviders();
      setNewsProvidersList(newsProviders);
      setLoadingNewsProviders(false);
    } catch (err) {
      setLoadingNewsProviders(false);
    }
  };

  useEffect(() => {
    getApiAccess();
  }, []);
  useEffect(() => {
    getNewsCategories();
  }, []);
  useEffect(() => {
    getNewsProviders();
  }, []);

  const getModalType = () => {
    switch (modal.type) {
      case 'news': return s('news').toLowerCase();
      case 'api_access': return 'api';
      case 'news_category': return s('newsCategory');
      default: return '';
    }
  };
  const handleCategoryOrder = async (newCategories) => {
    setLoadingNewsCategories(true);
    try {
      const formatedCategories = newCategories.map((item, idx) => ({ id: item.id, order: idx }));
      await Api.news.updateCategoryOrder({ categories: formatedCategories });
      toast.success(t('CompanyListPage.categoryOrderUpdated'));
      getNewsCategories();
    } catch (err) {
      setLoadingNewsCategories(false);
      toast.error(t('CompanyListPage.orderUpdatingERROR'));
    }
  };
  const deleteEntity = async () => {
    try {
      switch (modal.type) {
        case 'api_access': {
          setLoadingApi(true);
          await Api.news.deleteApiAccess(modal.deleteNewsID);
          await getApiAccess();
          break;
        }
        case 'news_category': {
          setLoadingNewsCategories(true);
          await Api.news.removeCategory(modal.deleteNewsID);
          await getNewsCategories();
          break;
        }
        default: break;
      }
      setModal({
        modalVisible: false, deleteNewsID: null, type: null,
      });
    } catch (err) {
      toast.error(p('deletingNewsFailed'));
    }
  };

  return (
    <>
      <DeleteModal
        type={getModalType()}
        visible={modal.modalVisible}
        handleModal={handleModal}
        modalAction={deleteEntity}
      />
      <Loading loading={loadingApi || loadingNewsCategories || loadingNewsProviders} />
      <NewsCategoryList
        categoryList={newsCategoryList}
        handleModal={handleModal}
        handleOrder={handleCategoryOrder}
      />
      <NewsProvidersList
        providers={newsProvidersList}
      />
      <ContentAPIList
        apiAccess={apiAccessList}
        handleModal={handleModal}
      />
    </>
  );
};



export default SettingsPage;
