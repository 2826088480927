import {
  SET_ME,
  SET_IS_AUTHENTICIATED,
  SET_HAS_CHECKED_PERMISSIONS,
} from '../actions/adminActions';

const initialState = {
  me: null,
  isAuthenticated: 'loading', // loading, false and true
  hasCheckedPermissions: {
    path: '/',
    foundPath: true,
    hasPermission: true,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ME:
      return {
        ...state,
        me: action.me,
      };
    case SET_IS_AUTHENTICIATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case SET_HAS_CHECKED_PERMISSIONS:
      return {
        ...state,
        hasCheckedPermissions: ({
          path: action.path,
          foundPath: action.foundPath,
          hasPermission: action.hasPermission,
        }),
      };
    default:
      return state;
  }
}
