import { partial } from '../../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');

  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  if (!values.displayName) {
    errors.displayName = p('displayNameFieldCantBeEmpty');
  }

  if (!values.price) {
    errors.price = p('priceFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
