import React, { useEffect } from 'react';
import { Button, Container } from 'reactstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { partial } from '../../../util/translation/translation';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderSelectField from '../../../shared/components/form/Select';

const EditAnswersModal = ({
  userQuestions,
  handleSubmit,
  initialize,
}) => {
  const s = partial('shared');

  const renderQuestion = (question) => {
    switch (question.question_type) {
      case 'text_field':
        return (
          <div className="form__form-group">
            <span className="form-header">{question.question}</span>
            <Field
              name={question.id}
              component={renderField}
              value={question?.answer}
              type="text"
            />
          </div>
        );

      case 'checkbox':
        return (
          <div className="form__form-group">
            <span className="form-header">{question.question}</span>
            {question?.choices?.map((choice) => {
              return (
                <div className="flex">
                  <Field
                    name={`${question.id} ${choice.choice}`}
                    component={renderCheckBoxField}
                    disableDefault
                    type="checkbox"
                    className="colored-click"
                  />
                  <span
                    className="form__form-group-label"
                    style={{ marginLeft: 5, marginRight: 'auto' }}
                  >
                    {choice.choice}
                  </span>
                </div>
              );
            })}
          </div>
        );

      case 'radio_button':
        return (
          <div className="form__form-group">
            <span className="form-header">{question.question}</span>
            <div className="form__form-group-field">
              {question?.choices?.map((choice) => {
                return (
                  <Field
                    name={question.id}
                    label={choice.choice}
                    component={renderRadioButtonField}
                    radioValue={choice.choice}
                  />
                );
              })}
            </div>
          </div>
        );

      case 'dropdown':
        return (
          <div className="form__form-group">
            <span className="form-header">{question.question}</span>
            <Field
              name={question.id}
              component={renderSelectField}
              options={question.choices.map((choice) => ({
                label: choice.choice,
                value: choice.choice,
              }))}
              type="option"
            />
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (userQuestions) {
      (async () => {
        const initialValues = {};

        userQuestions.forEach((question) => {
          if (question?.question_type === 'checkbox') {
            question?.answer?.forEach((existingAnswer) => {
              initialValues[`${question.id} ${existingAnswer}`] = !!existingAnswer;
            });
          } else {
            initialValues[question.id] = question.answer;
          }
        });

        initialize(initialValues);
      })();
    }
  }, [userQuestions]);

  return (
    <Container>
      <form className="form" onSubmit={handleSubmit}>
        {userQuestions?.length > 0 && userQuestions.map((question) => renderQuestion(question))}
        <Button color="primary" type="submit">
          {s('save')}
        </Button>
      </form>
    </Container>
  );
};

const reduxF = reduxForm({
  form: 'EditAnswersModal',
  // validate: validatePersonParticipants,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('EditAnswersModal')(state),
});

export default connect(mapStateToProps)(reduxF(EditAnswersModal));
