import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validatePrizeForm = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('titleFieldEmpty');
  }
  if (!values.headingImage) {
    errors.headingImage = p('picFieldCantBeEmpty');
  }

  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }

  return errors;
};
export default validatePrizeForm;
