import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import useConfig from '../../util/useConfig';
import EasyTabs from '../../shared/components/custom/EasyTabs';
import ProfileForm from './components/ProfileForm';
import TeamProfileList from './components/TeamProfileList';
import { partial } from '../../util/translation/translation';

const p = partial('ClubProfilePage');

const ClubProfilePage = () => {
  const config = useConfig();
  const [profile, setProfile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const fetchProfile = async (clubId) => {
    setLoading(true);
    try {
      const { data } = await api.clubadmin.profile.fetchProfile(clubId);
      setProfile(data);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await api.clubadmin.profile.post({
        ...values,
        clubId: config.id,
        imageUrl: values.imageUrl.length > 0 ? values.imageUrl[0].value : undefined,
      });
      setProfile(data);
      toast.success(p('profileUpdated'));
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    if (config) {
      fetchProfile(config.id);
    }
  }, [config]);

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">
          {p('editProfile')}
        </h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <EasyTabs tabnames={[p('clubProfile'), p('teamProfile')]}>
              <ProfileForm profile={profile} onSubmit={handleSubmit} />
              <TeamProfileList />
            </EasyTabs>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default ClubProfilePage;
