export default {
  club_name: 'BIK Karlskoga',
  club_name_short: 'BIK',
  stadium_name: 'Nobelhallen',
  staging_url: 'https://bik.api.fangroup.io',
  prod_url: 'https://bik.api.fangroup.io',
  api: '/api',
  facebook: 'BIKKarlskoga',
  basename: '/bik',
  club_logo: '/assets/teams/bik.png',
  app_name: 'bik',
  websale_name: 'bik',
  ntb_team_id: 3721,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'bik',
  monolithEnabled: false,
};
