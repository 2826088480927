import React, { useState, useEffect } from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { toast } from 'react-toastify';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';
import { formatDate } from '../../../util/date-and-time';

const p = partial('MatchAttendanceReport');
const e = partial('ReportsPage');
const s = partial('shared');

const MatchAttendanceReport = () => {
  const [matches, setMatches] = useState([]);
  const [matchAttendances, setMatchAttendances] = useState({ data: [], keys: [] });
  const [selectedMatch, setSelectedMatch] = useState({ data: [], keys: [] });
  const [loading, setLoading] = useState(false);

  const getInitialData = async () => {
    setLoading(true);
    try {
      const [resMatches] = await Promise.all([
        Api.reports.getMatches(),
      ]);
      setMatches(resMatches.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || e('fetcingMatchTicketsFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getMatchAttendance = async () => {
    setLoading(true);
    try {
      const resMatchAttendances = await Api.reports.getMatchAttendanceReport();
      resMatchAttendances.data.data = resMatchAttendances.data.data.map((attendance) => {
        const matchedMatch = matches.find(match => match.id === attendance.match_id);
        if (matchedMatch) {
          return { ...matchedMatch, ...attendance };
        }
        return {};
      });
      setMatchAttendances(resMatchAttendances.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('fetchingMatchAttendanceFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (matches.length !== 0) {
      getMatchAttendance();
    }
  }, [matches]);

  const formatAttendanceTotal = (column) => {
    let totalTickets = 0;
    matchAttendances.data.forEach((curr) => {
      totalTickets += curr[column] || 0;
    });

    return totalTickets;
  };

  const formatAttendanceData = (column, data) => {
    return (data[column]) ? data[column] : 0;
  };

  const renderMatchTitle = (match) => {
    try {
      return `${match.away_team.shortname} (${formatDate(match.match_starts_at)})`;
    } catch (err) {
      return '';
    }
  };

  const listMatchAttendanceHeader = () => {
    return matchAttendances.keys.map(matchAttendance => <th key={`$listMatchAttendanceHeader-${matchAttendance}`}>{matchAttendance === s('ticket') ? s('kiosk') : matchAttendance }</th>);
  };

  const listMatchAttendanceBody = () => {
    const selectedMatchAttendance = matchAttendances.data.find(match => match.match_id === selectedMatch.id);
    if (!selectedMatchAttendance) {
      return matchAttendances.data.map(attendance => (
        <tr key={`listMatchAttendanceBody-${attendance.match_id}`}>
          <td>{renderMatchTitle(attendance)}</td>
          {matchAttendances.keys.map(priceGroup => (
            <td key={`listMatchAttendanceBodyInner-${attendance.match_id}-${priceGroup}`}>{formatAttendanceData(priceGroup, attendance)}</td>
          ))}
          <td>{formatAttendanceData(s('seasonCardTickets'), attendance)}</td>
          <td>{formatAttendanceData('Total', attendance)}</td>
        </tr>
      ));
    }
    return (
      <tr>
        <td>{renderMatchTitle(selectedMatch)}</td>
        { matchAttendances.keys.map(priceGroup => (
          <td key={`listMatchAttendanceBody-${selectedMatchAttendance.match_id}-${priceGroup}`}>{ formatAttendanceData(priceGroup, selectedMatchAttendance) }</td>
        ))}
        <td>{formatAttendanceData(s('seasonCardTickets'), selectedMatch)}</td>
        <td>{formatAttendanceData(s('total'), selectedMatch)}</td>
      </tr>
    );
  };

  const listMatches = () => {
    return matchAttendances.data.map(attendance => (
      <DropdownItem onClick={() => setSelectedMatch(attendance)} key={`listMatches-${attendance.match_id}`}>{renderMatchTitle(attendance)}</DropdownItem>
    ));
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="card__title margin-0">
        <h5 className="bold-text">{p('visitReportCaps')}</h5>
        <UncontrolledDropdown className="marginRight-15 marginBottom-30">
          <DropdownToggle className="icon icon--right" outline>{p('filterList')} {selectedMatch && renderMatchTitle(selectedMatch)} <ChevronDownIcon /></DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <DropdownItem onClick={() => setSelectedMatch({ data: [], keys: [] })}>{s('all')}</DropdownItem>
            { listMatches() }
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{s('opponent')}</th>
            { listMatchAttendanceHeader() }
            <th>{s('seasonCardTickets')}</th>
            <th>{s('total')}</th>
          </tr>
        </thead>
        <tbody>
          { listMatchAttendanceBody() }
          <tr>
            <td><b>{s('total')}</b></td>
            { matchAttendances.keys.map(priceGroup => (
              <td key={`reportsPageListMatchAttencande-${priceGroup}`}>
                <b>{formatAttendanceTotal(priceGroup)}</b>
              </td>
            ))}
            <td>
              <b>{formatAttendanceTotal('Sesongbilletter') }</b>
            </td>
            <td>
              <b>{formatAttendanceTotal('Total')}</b>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default MatchAttendanceReport;
