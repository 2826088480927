import React from 'react';
import { Button, Card, CardBody, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import t from '../../util/translation/translation';

const NotFoundPage = () => {
  return (
    <Container className="notFoundPage">
      <Card>
        <CardBody>
          <h1>404</h1>
          <h3>Page not found</h3>
          <Link to="/"><Button color="primary">{t('NotFoundPage.goBackToDashboard')}</Button></Link>
        </CardBody>
      </Card>
    </Container>
  );
};

export default NotFoundPage;
