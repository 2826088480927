import { Route, useHistory, withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../util/api';
import { getPermissions } from '../../util/functions';
import { setMe } from '../../redux/actions/adminActions';
import t from '../../util/translation/translation';
import AuthService from './AuthService';
import NotFoundPage from './NotFoundPage';

const PrivateRoute = ({
  isAuthenticated,
  dispatch,
  component: Component,
  location,
  permissions,
  hasCheckedPermissions,
  ...rest
}) => {
  const history = useHistory();
  const getUser = async () => {
    const resMe = await Api.login.me();
    const __permissions = await getPermissions();
    dispatch(setMe({ ...resMe.data, permissions: __permissions }));
  };

  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  useEffect(() => {
    if (location.pathname === hasCheckedPermissions.path && hasCheckedPermissions.hasPermission === false) {
      toast.error(t('Router.notAuthorized'));
      history.push('/');
    }
  }, [hasCheckedPermissions]);

  useEffect(() => {
    if (permissions === 'loading' ||
      (location.pathname === hasCheckedPermissions.path && hasCheckedPermissions.hasPermission)) { return; }
    AuthService.permissionCheck(location, permissions, dispatch);
  }, [location.pathname, permissions]);

  if (hasCheckedPermissions === '404') {
    return <NotFoundPage />;
  }

  return (
    <Route
      {...rest}
      render={props => <Component {...props} />}
    />
  );
};

PrivateRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  component: PropTypes.elementType.isRequired,
  isAuthenticated: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  hasCheckedPermissions: PropTypes.shape({
    path: PropTypes.string,
    foundPath: PropTypes.bool,
    hasPermission: PropTypes.bool,
  }).isRequired,
};

PrivateRoute.defaultProps = {
  permissions: 'loading',
};

const mapStateToProps = state => ({
  isAuthenticated: state.admin.isAuthenticated,
  hasCheckedPermissions: state.admin.hasCheckedPermissions,
  permissions: state.admin.me?.permissions,
});


export default withRouter(connect(mapStateToProps)(PrivateRoute));
