import React, { useState, useEffect } from 'react';
import {
  Container,
  Table, ButtonToolbar, ButtonGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import IconButton from '../../../shared/components/custom/IconButton';
import ShareModal from '../../../shared/components/custom/ShareModal';
import EditCustomEventCategory from './EditCustomEventCategory';

const CustomEventCategoryPage = ({
    companyID,
    setLoading,
}) => {
  const p = partial('EventsForm');
  const s = partial('shared');
  const [eventCategories, setEventCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const fetchCustomCategories = async () => {
    setLoading(true);
    try {
      const { data: categoryConfigrations } = await Api.eventCategories.getCompanyConfiguration(companyID);
      setLoading(false);
      setEventCategories(categoryConfigrations);
    } catch (err) {
      setLoading(false);
      toast.error(t('CompanyFormPage.categoryConfigurationFetchFailed'));
    }
  };
  useEffect(() => {
    if (companyID) {
        fetchCustomCategories();
    }
  }, [companyID]);

  const handleEditPressed = (category) => {
    setSelectedCategory(category);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
    setModalVisible(false);
  };

  const handleSubmit = async ({ maxParticipants }) => {
    const maxAttendes = +maxParticipants;
    if (maxAttendes > selectedCategory?.category?.maxAttendees) {
      toast.error(`${t('EventCategories.maxPartcipnatsExceeded')}`);
    } else {
      setLoading(true);
      try {
          await Api.eventCategories.updateCompanyConfiguration(selectedCategory.id,
            { maxAttendes },
          );

        toast.success(`${t('EventCategories.eventCategoryUpdated')}`);
        handleCloseModal();
        fetchCustomCategories();
      } catch (err) {
        setLoading(false);
        toast.error(`${t('EventCategories.couldnotUpdateCategory')}`);
      }
    }
  };

  const renderCategoryConfigurations = () => {
    return eventCategories
      .map((categoryConfig, index) => (
        <tr key={`renderCategoryConfigurations-${categoryConfig.id}`}>
          <td>{index + 1}.</td>
          <td>{categoryConfig.category.title}</td>

          <td>{categoryConfig.maxAttendes}</td>
          <td>
            <ButtonToolbar className="float-right">
              <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                <IconButton
                  onClick={() => handleEditPressed(categoryConfig, index)}
                  type={IconButton.types.EDIT}
                  id="edit"
                />
              </ButtonGroup>
            </ButtonToolbar>
          </td>
        </tr>
      ));
  };

  return (
    <Container>
      <ShareModal
        visible={modalVisible}
        title={`${t('EventCategories.editEventCategory')}`}
        handleModal={handleCloseModal}
      >
        <EditCustomEventCategory
          onSubmit={handleSubmit}
          selectedCategory={selectedCategory}
        />
      </ShareModal>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>#</th>
            <th>{s('name')}</th>
            <th>{p('maxNumberOfParticipant')}</th>
          </tr>
        </thead>
        <tbody>
          {renderCategoryConfigurations()}
        </tbody>
      </Table>
    </Container>
  );
};

CustomEventCategoryPage.propTypes = {
    companyID: PropTypes.string,
};
CustomEventCategoryPage.defaultProps = {
    companyID: null,
  };

export default CustomEventCategoryPage;


