import React, { useEffect, useMemo, useState } from 'react';
import {

  Button,
} from 'reactstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import t, { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
import Api from '../../../util/api';
import renderSearchSelectField from '../../../shared/components/custom/SearchSelect';
import validatePersonParticipants from './validatePersonParticipants';
import renderSelectField from '../../../shared/components/form/Select';


const PersonCardForm = ({ formValues, change, handleSubmit, participant, initialize, event, setMaxGuestAmount, maxGuestAmount }) => {
  const config = useConfig();
  const p = partial('EventParticipantsPage');
  const s = partial('shared');
  const [foundUsers, setFoundUsers] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  const userOptions = useMemo(() => foundUsers.map(
    (user) => (
      {
        label: `${user.firstname} ${user.lastname}`,
        value: user.userCloudId,
      }),
  ), [foundUsers]);

  const fetchUsers = async (searchStr) => {
    if (searchStr) {
      const { users } = await Api.users.getTeamUsers(1, 10, { phoneNumber: 'asc' }, searchStr, config);
      setFoundUsers(users);
      change('firstName', null);
      change('lastName', null);
      change('guestAmount', null);
      change('selectedUser', null);
    }
  };
  const handlePickuser = async ({ value }) => {
    const foundUser = foundUsers.find((item) => item.userCloudId === value);
    const { userCloudId, firstname, lastname, phoneNumber } = foundUser;
    const { data: resEmployeeCompanies } = await Api.employees.getUserCompaniesForTeam(userCloudId);
    const mappedCompanies = resEmployeeCompanies.map(({ name, id }) => ({ label: name, value: { id } }));
    setCompanyOptions(mappedCompanies);
    setFoundUsers([]);
    change('selectedUser', { userId: userCloudId, firstName: firstname, lastName: lastname, phone: phoneNumber, hasCompany: mappedCompanies.length > 0 });
    change('userPhone', phoneNumber);
    change('firstName', firstname);
    change('lastName', lastname);
    change('selectedCompany', null);
    change('guestAmount', null);
  };

  const handleCompanyChange = async (selectedOption) => {
    const { data: categoryConfigrations } = await Api.eventCategories.getCompanyConfiguration(selectedOption?.value?.id);
    const { participants, category } = event;
    const selectedCompanyParticipants = participants.filter(
      (par) => par.companyId === selectedOption?.value?.id,
    );
    const amountOfSeats = category
        ? categoryConfigrations.find(
            (customeCategory) =>
              customeCategory?.category?.id === category?.id,
          )?.maxAttendes
        : event.maxAccountAttendees;

    let spotsLeft =
        amountOfSeats -
        selectedCompanyParticipants.length -
        selectedCompanyParticipants.reduce(
          (acc, _participant) => acc + _participant.friendAmount,
          0,
        ) - 1;
        spotsLeft = (spotsLeft < 0 ? 0 : spotsLeft);

        setMaxGuestAmount(spotsLeft);
  };
  useEffect(() => {
    if (participant) {
      initialize({
        userPhone: participant.phoneNumber,
        firstName: participant.firstname,
        lastName: participant.lastname,
        guestAmount: participant.friendAmount,
      });
    }
  }, [participant]);


  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group mx-2">
        <span className="form-header">{s('user')}</span>
        <div className="marginTop-5" />
        <Field
          name="owner"
          component={renderSearchSelectField}
          placeholder={t('UsersTab.searchForUser')}
          options={userOptions.filter(item => !!item.value)}
          search={(search) => fetchUsers(search)}
          onChange={handlePickuser}
        />
      </div>
      {companyOptions.length ?
        <div className="form__form-group mx-2">
          <span className="form-header">{p('company')}</span>
          <Field
            name="selectedCompany"
            placeholder={p('chooseCompany')}
            options={companyOptions}
            component={renderSelectField}
            onChange={handleCompanyChange}
          />
        </div> : null}
      <div className="form__form-group mx-2">
        <span className="form-header">{s('firstname')}</span>
        <Field
          name="firstName"
          placeholder={s('firstname')}
          disabled={!!formValues?.selectedUser || !!participant}
          component={renderField}
        />
      </div>
      <div className="form__form-group mx-2">
        <span className="form-header">{s('lastname')}</span>
        <Field
          name="lastName"
          placeholder={s('lastname')}
          disabled={!!formValues?.selectedUser || !!participant}
          component={renderField}
        />
      </div>
      <div className="form__form-group mx-2">
        <span className="form-header">{p('numberOfGuests')} ({p('availableSpots')}: {maxGuestAmount})</span>
        <Field
          name="guestAmount"
          placeholder={p('chooseGuests')}
          disabled={!formValues?.firstName && !formValues?.lastName}
          component={renderField}
          type="number"
        />
      </div>
      <Button color="primary" type="submit">
        {p('inviteParticipants')}
      </Button>
    </form>
  );
};


const reduxF = reduxForm({
  form: 'PersonCardForm',
  validate: validatePersonParticipants,
});

const mapStateToProps = state => ({
  formValues: getFormValues('PersonCardForm')(state),
});

export default connect(mapStateToProps)(reduxF(PersonCardForm));



