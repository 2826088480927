import React, { useState, useEffect } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProductForm from './components/ProductForm';
import Loading from '../../../../shared/components/custom/Loading';
import { convertImageToBlob } from '../../../../util/image-functions';
import Api from '../../../../util/api';
import CategoryForm from '../category/components/CategoryForm';
import { partial } from '../../../../util/translation/translation';

const s = partial('shared');
const p = partial('product&CategoryShare');

const ProductFormPage = ({ dispatch }) => {
  const history = useHistory();
  const { kioskID } = useParams();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});

  const hasImageThenConvert = async (image) => {
    const hasImage = Array.isArray(image) && image.length === 1;
    if (!hasImage) {
      return null;
    }
    if (image[0].size === undefined) {
      return image[0];
    }
    try {
      return {
        ...image[0],
        preview: await convertImageToBlob(image[0]),
      };
    } catch (err) {
      toast.error(err || `${s('imageUploadingERROR')}`);
      throw err;
    }
  };
  const handleSubmit = async (values) => {
    const productImage = await hasImageThenConvert(values.image);

    const payload = {
      name: values.name,
      subtitle: values.subtitle,
      price: values.price,
      category_id: values.category && values.category.value,
      description: values.description,
      comment: values.comment,
      can_comment: values.canComment,
      image: productImage,
    };
    setLoading(true);
    try {
      if (kioskID) {
        await Api.cnc.updateProductByID(product.id, payload);
        toast.success(`${p('productUpdated')}`);
      } else {
        await Api.cnc.createProduct(payload);
        toast.success(`${p('productCreated')}`);
      }
      setLoading(false);
      history.replace('/cnc?tab=2');
    } catch (err) {
      toast.error(err || `${p('productUpdatingERROR')}'!'`);
      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const [resProductCategories, resProduct] = await Promise.all([
        Api.cnc.getProductCategories(),
        kioskID && Api.cnc.getProductByID(kioskID),
      ]);
      const categoryOptions = resProductCategories.data.map(category => ({
        value: category.id.toString(),
        label: category.name,
        ...category,
      }));
      setCategories(categoryOptions);
      setLoading(false);
      if (kioskID) {
        setProduct(resProduct.data);
      }
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const handleCategorySubmit = async (values) => {
    if (categories.find(category => category.name === values.name) === undefined) {
      setLoading(true);
      try {
        await Api.cnc.createCategory({ name: values.name });
        dispatch(reset('create-new-category-form'));
        toast.success(`${p('categoryCreated')}`);
        getData();
      } catch (err) {
        getData();
        toast.error(err || `${p('categoryCreatingSomethingWentWrongERROR')}`);
      }
    } else {
      toast.error(`${p('productCategoryAlreadyExistsERROR')}`);
    }
  };

  useEffect(() => {
    getData();
  }, [kioskID]);

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">{kioskID ? `${s('edit')}` : `${s('create')}` } {s('productSmall')}</h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{s('product')}</h5>
            </div>
            <ProductForm product={product} categories={categories} onSubmit={handleSubmit} />
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{p('createProductCategory')}</h5>
            </div>
            <CategoryForm onSubmit={handleCategorySubmit} />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

ProductFormPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ProductFormPage);
