import React, { useEffect, useRef, useState } from 'react';
import {

  Container, TabContent, TabPane,
} from 'reactstrap';
import { toast } from 'react-toastify';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Api from '../../util/api';
import NewsList from './components/NewsList';
import { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import SettingsPage from './components/SettingsPage';

const p = partial('NewsPage');
const s = partial('shared');

const NewsPage = () => {
  const [pager, setPager] = useState({
    take: 10,
    page: 1,
    total: 0,
  });
  const [loadingNews, setLoadingNews] = useState(false);

  const [tab, setTab] = useState('1');
  const [order, setOrder] = useState({
    orderBy: 'publicationDate',
    desc: true,
  });
  const [totalNews, setTotalNews] = useState(0);
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState({
    modalVisible: false, deleteNewsID: null, type: null,
  });
  const [news, setNews] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const getNews = async () => {
    setLoadingNews(true);
    try {
      const { data, count } = await Api.news.getNews(pager, { orderBy: order.orderBy, direction: order.desc ? 'DESC' : 'ASC' }, search);
      if (isMounted.current) {
        setTotalNews(count);
      }
      setNews(data);
      setLoadingNews(false);
      const newsIds = data.map(elem => elem.id);
      const { data: pressData } = await Api.news.getNewsViews({ newsIds });
      const pressDataMap = {};
      for (let i = 0; i < pressData.length; i++) {
        const press = pressData[i];
        pressDataMap[press.itemId] = press;
      }
      const mergedNews = data.map((newsItem) => {
        return (
          {
            ...newsItem,
            presses: pressDataMap[newsItem.id].presses || 0,
          }
        );
      });
      setNews(mergedNews);
    } catch (err) {
      setLoadingNews(false);
      toast.error(p('fetchingNewsFailed'));
    }
  };
  useEffect(() => {
    getNews();
    return () => {
      isMounted.current = false;
    };
  }, [order, pager]);
  const onChangePageNews = (newPager) => {
    if (pager.take !== newPager.pageSize || pager.page !== newPager.currentPage) {
      setPager((prev) => ({
        ...prev,
        take: newPager.pageSize,
        page: newPager.currentPage,
      }));
    }
  };
  const handleModal = (state, id, type) => {
    setModal({
      modalVisible: state, deleteNewsID: id, type,
    });
  };

  const handleSort = (newOrder) => {
    setOrder((prev) => ({
      orderBy: newOrder,
      desc: newOrder === prev.orderBy ? !prev.desc : true,
    }));
  };

  const handleSearch = (key) => {
    if (key === 'Enter') {
      getNews();
    }
  };
  const deleteEntity = async () => {
    try {
      switch (modal.type) {
        case 'news': {
          setLoadingNews(true);
          await Api.news.deleteNews(modal.deleteNewsID);
          await getNews();
          break;
        }
        default: break;
      }
      setModal({
        modalVisible: false, deleteNewsID: null, type: null,
      });
    } catch (err) {
      setLoadingNews(false);
      toast.error(p('deletingNewsFailed'));
    }
  };
  const getModalType = () => {
    switch (modal.type) {
      case 'news': return s('news').toLowerCase();
      default: return '';
    }
  };

  return (
    <Container>
      <NavTab
        activeTab={tab}
        tabs={[
          s('news'),
        s('settings')]}
        setTab={setTab}
      />
      <DeleteModal
        type={getModalType()}
        visible={modal.modalVisible}
        handleModal={handleModal}
        modalAction={deleteEntity}
      />
      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <NewsList
            news={news}
            onChangePage={onChangePageNews}
            loading={loadingNews}
            getNews={getNews}
            handleSearch={handleSearch}
            handleInput={setSearch}
            handleModal={handleModal}
            handleSort={handleSort}
            setLoadingNews={setLoadingNews}
            order={{ total: totalNews, ...order }}
          />
        </TabPane>
        <TabPane tabId="2">
          <SettingsPage />
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default NewsPage;
