import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Badge, Container, Table, Button, Row, Col, TabContent, TabPane, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import skeletonTableLoader from '../../shared/components/custom/SkeletonLoaders';
import useConfig from '../../util/useConfig';
import Pagination from '../../shared/components/pagination/Pagination';
import t, { partial } from '../../util/translation/translation';
import MatchCenterHelpers from './helpers';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';
import NavTab from '../../shared/components/custom/NavTab';
import PrizesCard from './PrizesCard';

const UpcomingMatches = () => {
    const history = useHistory();
    const config = useConfig();
    const s = partial('shared');
    const mc = partial('matchCenter');
    const df = partial('DateFormat');
    const lm = partial('ListMatches');
    const [tab, setTab] = useState('1');
    const [handleModal, visible, deleteId] = useModal();
    const { addDash, getCompany, getSponsor } = MatchCenterHelpers;
    const [matches, setMatches] = useState([]);
    const [concludedMatches, setConcludedMatches] = useState([]);
    const [paginationDetails, setPaginationDetails] = useState({ first: 1, last: 1, previous: null, next: null, total: 0 });
    const [paginationDetailsConcluded, setPaginationDetailsConcluded] = useState({ first: 1, last: 1, previous: null, next: null, total: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [concludedMatchesCurrentPage, setConcludedMatchesCurrentPage] = useState(1);
    const [concludedMatchespageSize, setConcludedMatchesPageSize] = useState(5);
    const { data: upcomingMatches, isLoading, refetch: refecthUpcoming } = useQuery({ queryKey: ['upcoming-matches-banner', currentPage, pageSize], queryFn: () => Api.matchCenter.getUpcomingMatches(currentPage, pageSize, config.teams.map(team => team.id), false) });
    const { data: concludedMatchesData, isLoading: isLoadingConcluded, refetch: refecthConcluded } =
    useQuery({ queryKey: ['concluded-matches-banner', concludedMatchesCurrentPage, concludedMatchespageSize], queryFn: () => Api.matchCenter.getUpcomingMatches(concludedMatchesCurrentPage, concludedMatchespageSize, config.teams.map(team => team.id), true) });
    const [loading, setLoading] = useState(false);

    const { data: companies } = useQuery({ queryKey: ['club-companies'], queryFn: Api.companies.getCompaniesForClub });

    useEffect(() => {
      if (!upcomingMatches) return;
      const results = upcomingMatches.data;
      setMatches(results.matches);
      setPaginationDetails({ first: results.first, last: results.last, previous: results.previous, next: results.next, total: results.total });
    }, [upcomingMatches, isLoading]);

    useEffect(() => {
      if (!concludedMatchesData) return;
      const results = concludedMatchesData.data;
      setConcludedMatches(results.matches);
      setPaginationDetailsConcluded({ first: results.first, last: results.last, previous: results.previous, next: results.next, total: results.total });
    }, [concludedMatchesData, isLoadingConcluded]);

    const handlePagination = (paginationObject, isConcluded) => {
      if (isConcluded) {
        setConcludedMatchesCurrentPage(paginationObject.currentPage);
        if (concludedMatchespageSize !== paginationObject.pageSize) {
          setConcludedMatchesPageSize(paginationObject.pageSize);
        }
      } else {
        setCurrentPage(paginationObject.currentPage);
        if (pageSize !== paginationObject.pageSize) {
          setPageSize(paginationObject.pageSize);
        }
      }
    };

    const renderTeam = (match, type) => {
      if (match.isCustom) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ height: '30px' }} src={match.extraData[`${type}Logo`]} alt={match.extraData[type]} />
            <span style={{ marginLeft: 5 }}>{match.extraData[type]}</span>
          </div>
        );
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {match[type].logoUrl &&
            <img style={{ height: '30px' }} src={match[type].logoUrl} alt={match[type].name} />
          }
          <span style={{ marginLeft: 5 }}>{match[type].name}</span>
        </div>
      );
    };

    const renderSpinner = (skeletonTableLoader(3, 9, 100));

    const renderTableRows = (renderMatches) => {
        return renderMatches.map(match =>
          <tr key={match.id}>
            <UncontrolledTooltip placement="bottom" target={`editBtn-${match.id}`}>{s('edit')}</UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target={`deleteBtn-${match.id}`}>{s('delete')}</UncontrolledTooltip>
            <td>{moment.utc(match.matchStart).local(true).format(df('getBaseDateTime'))}</td>
            <td>{match?.league?.name || match?.extraData?.matchType}</td>
            <td>{renderTeam(match, 'homeTeam')}</td>
            <td>{renderTeam(match, 'awayTeam')}</td>
            <td>{addDash(getSponsor(match).name.label)}</td>
            <td>{addDash(getCompany(match, companies).label)}</td>
            <td>{match?.settings?.settings?.ticket?.link && <CheckCircleOutlineIcon/>}</td>
            <td><Badge color={match?.settings?.visibleInBanner ? 'success' : 'danger'}>{match?.settings?.visibleInBanner ? lm('Visible') : lm('NotVisible')}</Badge></td>
            <td><Badge style={{ textTransform: 'uppercase' }} color={match?.isCustom ? 'success' : 'info'}>{match?.isCustom ? mc('custom') : 'SYSTEM'}</Badge></td>
            <td>
              <ButtonGroup
                size="sm"
              >
                <Button onClick={() => { history.push(`/matches/upcoming/edit/${match.id}`); }} id={`editBtn-${match.id}`} outline>
                  <EditIcon />
                </Button>
                <Button onClick={() => { handleModal(match.id); }} id={`deleteBtn-${match.id}`} outline>
                  <DeleteIcon />
                </Button>
              </ButtonGroup>
            </td>
          </tr>,
        );
    };

    const noRecords = (
      <tr>
        <td colSpan={7} style={{ textAlign: 'center' }}>{mc('noRecords')}</td>
      </tr>
    );

    const tableLayout = (renderMatches, pagination, title, _loading, isConcluded) => {
      return (
        <>
          <Card>
            <Col>
              <Row>
                <h3 className="page-title mb-0">{title}</h3>
                {!isConcluded && (
                  <Link to={{ pathname: '/matches/upcoming/custom/create' }} style={{ marginLeft: 'auto' }}>
                    <Button color="primary" size="sm">
                      {mc('createMatchBanner')}
                    </Button>
                  </Link>
                )}
              </Row>
            </Col>
            <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th scope="row">{mc('startDate')}</th>
                    <th scope="row">{mc('league')}</th>
                    <th scope="row">{mc('homeTeam')}</th>
                    <th scope="row">{mc('awayTeam')}</th>
                    <th scope="row">{mc('matchSponsor')}</th>
                    <th scope="row">{mc('pRSponsor')}</th>
                    <th scope="row">{mc('ticketLink')}</th>
                    <th scope="row">{mc('bannerVisibility')}</th>
                    <th scope="row">{mc('origin')}</th>
                    <th scope="row" />
                  </tr>
                </thead>
                <tbody>
                  {_loading && renderSpinner}
                  { renderMatches.length > 0 && renderTableRows(renderMatches)}
                  { !_loading && renderMatches.length === 0 && noRecords}
                </tbody>
              </Table>
            </div>
            <Pagination
              items={pagination.total || 0}
              rowsPerPage={[10, 20, 30]}
              onChangePage={(paginationObject) => handlePagination(paginationObject, isConcluded)}
            />
          </Card>
        </>
      );
    };

    const deleteMatch = async () => {
      setLoading(true);
      try {
        await Api.matchCenter.deleteMatch(deleteId);
        toast.success(mc('matchDeleted'));
      } catch (err) {
        toast.error(err);
      }
      handleModal();
      setLoading(false);
      refecthConcluded();
      refecthUpcoming();
    };

    return (
      <Container>
        <NavTab
          activeTab={tab}
          tabs={[s('matches'), t('prizes.prizes')]}
          setTab={(activeTab) => setTab(activeTab)}
        />
        <Loading loading={loading} />
        <DeleteModal
          visible={visible}
          handleModal={handleModal}
          type={lm('theMatch')}
          modalAction={deleteMatch}
        />
        <TabContent activeTab={tab}>
          <TabPane tabId="1">
            {tableLayout([...matches], paginationDetails, lm('comingMatches'), isLoading, false)}
            {tableLayout([...concludedMatches], paginationDetailsConcluded, lm('earlierMatches'), isLoadingConcluded, true)}
          </TabPane>
          <TabPane tabId="2">
            <PrizesCard />
          </TabPane>
        </TabContent>
      </Container>
    );
};
export default UpcomingMatches;
