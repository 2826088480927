import React, { useState, useEffect } from 'react';
import { Table, Col, Row } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { formatCurrencyNumber } from '../../../util/functions';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import Pagination from '../../../shared/components/pagination/Pagination';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import useModal from '../../../shared/hooks/useModal';
import { partial } from '../../../util/translation/translation';
import { formatDate, formatDateTime } from '../../../util/date-and-time';
import useConfig from '../../../util/useConfig';

const AuctionHouseBids = ({ auctionHouseId }) => {
  const config = useConfig();
  const [loadingBids, setLoadingBids] = useState(false);
  const [selectedAuctionItem, setSelectedAuctionItem] = useState(null);
  const [bids, setBids] = useState([]);
  const [auctionItems, setAuctionItems] = useState([]);
  const [handleModal, visible, deleteBidId] = useModal();
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });

  const p = partial('auctionObjectsShared');
  const s = partial('shared');

  const getAuctionHouseItems = async (displayLoading = true) => {
    if (displayLoading) {
      setLoadingBids(true);
    }
    try {
      const resAuctionItems = await Api.auctions.getAuctionItemsForHouse(auctionHouseId);
      setAuctionItems(resAuctionItems);
    } catch (err) {
      toast.error(JSON.stringify(err));
    } finally {
      if (displayLoading) {
        setLoadingBids(false);
      }
    }
  };
  useEffect(() => {
    if (auctionHouseId) {
      getAuctionHouseItems();
    }
  }, [auctionHouseId]);

  const remainingTime = (date) => {
    const minutes = date.diff(moment(), 'minutes');
    if (minutes < 0) {
      return formatDate(date);
    }
    if (minutes < 60) {
      return `${minutes}m`;
    }
    const hours = date.diff(moment(), 'hours');
    if (hours < 24) {
      return `${hours}t ${Math.floor(minutes - (hours * 60))}m`;
    }
    const days = date.diff(moment(), 'days');
    return `${days}d ${Math.floor(hours - (days * 24))}t ${Math.floor(minutes - (hours * 60))}m`;
  };

  const getBids = async (currentPage = 1, pageSize = 10) => {
    setLoadingBids(true);
    try {
      const resBids = await Api.auctions.getAuctionItemBids(selectedAuctionItem.id, { page: currentPage, take: pageSize });
      const userIds = [...new Set(resBids.data.map((item) => item.userId))];
      const { data: usersByIds } = await Api.users.getUsersByCloudIds(userIds);
      const userByIdMap = {};
      for (let i = 0; i < usersByIds.length; i++) {
        userByIdMap[usersByIds[i].userCloudId] = usersByIds[i];
      }
      setPagination(old => ({ ...old, total: resBids.count }));

      const sortedBids = resBids.data.sort((a, b) => b.amount - a.amount).map((item) => ({ ...item, user: userByIdMap[item.userId] }));
      setBids(sortedBids);
      setLoadingBids(false);
    } catch (err) {
      toast.error(err);
      setLoadingBids(false);
    }
  };

  const removeBid = async () => {
    setLoadingBids(true);
    try {
      await Api.auctions.deleteAuctionItemBid(deleteBidId);
    } catch (err) {
      toast.error(err);
    } finally {
      handleModal();
      getAuctionHouseItems(false);
      getBids();
    }
  };

  const renderAuctionItems = () => {
    return auctionItems.map((auctionItem, i) => (
      <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`auctionItem-${auctionItem.id}`}>
        <td>{auctionItem.name}</td>
        <td>{remainingTime(moment(auctionItem.endDate))}</td>
        <td><b>{formatCurrencyNumber(auctionItem.lastBid)} {config.currencyCaps}</b></td>
        <td>
          <button onClick={() => setSelectedAuctionItem(auctionItem)} className="outline-badge primary">
            {p('seeBids')}
          </button>
        </td>
      </tr>
    )); // TODO we should have proper currency handling here i18n
  };

  const onChangePage = (pager) => {
    if (pager.pageSize !== pagination.perPage || pager.currentPage !== pagination.currentPage) {
      setPagination(old => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      getBids(pager.currentPage, pager.pageSize);
    }
  };

  useEffect(() => {
    if (selectedAuctionItem) {
      getBids(1, bids?.per_page ?? 10);
    }
  }, [selectedAuctionItem]);

  const renderBids = () => {
    return bids.map((bid, i) => (
      <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`bids-${bid.id}`}>
        <td>{formatDateTime(bid.createdAt)}</td>
        <td>
          <div className="flex-Vcenter">
            <Link to={`/users/edit/${bid.user?.userCloudId}`}>{`${bid.user?.firstname} ${bid.user?.lastname} (${bid.user?.phoneNumber})`}</Link>
          </div>
        </td>
        <td><b>{formatCurrencyNumber(bid.amount)} {config.currencyCaps}</b></td>
        <td><button onClick={() => handleModal(bid.id)} className="outline-badge danger">{s('deleteCaps')}</button></td>
      </tr>
    ));
  };

  return (
    <Row>
      <DeleteModal
        handleModal={handleModal}
        visible={visible}
        type={p('theBidSmall')}
        modalAction={removeBid}
      />
      <Loading loading={loadingBids} />
      <Col xl={5} lg={6} md={12} className="marginBottom-20">
        <p className="bold">{p('auctionObjects')}</p>
        <div style={{ display: 'flex', paddingBottom: 5, marginTop: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped >
            <thead>
              <tr>
                <th className="bold" style={{ color: '#4D576A' }}>{p('object')}</th>
                <th className="bold" style={{ color: '#4D576A' }}>{s('date')}</th>
                <th className="bold" style={{ color: '#4D576A' }}>{p('highestBid')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderAuctionItems()}
            </tbody>
          </Table>
        </div>
      </Col>
      <Col xl={7} lg={6} md={12}>
        <p className="bold">{selectedAuctionItem ? `${selectedAuctionItem.name}` : p('chooseAuctionObject')}</p>
        <div style={{ display: 'flex', paddingBottom: 5, marginTop: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped >
            <thead>
              <tr>
                <th className="bold" style={{ color: '#4D576A' }}>{s('date')}</th>
                <th className="bold" style={{ color: '#4D576A' }}>{s('name')}</th>
                <th className="bold" style={{ color: '#4D576A' }}>{s('amount')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderBids()}
            </tbody>
          </Table>
        </div>
        <Pagination
          items={pagination.total || 0}
          pageSize={bids.per_page}
          onChangePage={onChangePage}
        />
      </Col>
    </Row>
  );
};

export default AuctionHouseBids;
