import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateDiscountCode = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }
  if (!values.saleStart) {
    errors.saleStart = p('availableFromFieldCantBeEmpty');
  }
  if (!values.saleEnd) {
    errors.saleEnd = p('availableToFieldCantBeEmpty');
  }
  if (!values.code) {
    errors.code = p('discountCodeFieldEmpty');
  }
  if (!values.price) {
    errors.price = p('discountedAmountFieldEmpty');
  }
  return errors;
};

export default validateDiscountCode;
