import t, { partial } from '../../util/translation/translation';

const validateAssign = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (values.media === 'push') {
    if (!values.heading) {
      errors.heading = `${p('titleFieldCantBeEmpty')}!`;
    }
    if (!values.message) {
      errors.message = `${p('messageFieldCantBeEmpty')}!`;
    }
    if (!values.type) {
      errors.type = p('pushClickFieldCantBeEmpty');
    }
  }

  if (!values.title && values.media === 'push') {
    errors.title = `${p('titleFieldCantBeEmpty')}!`;
  }

  if (!values.group) {
    errors.group = p('userGroupFieldCantBeEmpty');
  }
  if (!values.message) {
   errors.message = `${p('messageFieldCantBeEmpty')}!`;
 }

 if (values?.timeToSend === 'SCHEDULED' && (!values?.scheduleTime || new Date(values?.scheduleTime) < Date.now())) {
  errors.scheduleTime = `${t('ScheduledMessagesPage.selectValidDate')}`;
}

  return errors;
};

export default validateAssign;
