import React, { useState } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';

const getDefaultCountry = () => {
  const config = useConfig();
  switch (config.currencyCaps) {
    case 'NOK':
      return 'NO';
    case 'SEK':
      return 'SE';
    case 'GBP':
      return 'GB';
    default:
      return 'NO';
  }
};
const renderCountryPhone = (props) => {
  const { input, meta } = props;
  const defaultCountry = getDefaultCountry();
  const [country, setCountry] = useState(defaultCountry);
  const s = partial('shared');
  return (
    <div className="form__form-group-input-wrap">
      <PhoneInput
        style={{ width: '100%' }}
        defaultCountry={defaultCountry}
        placeholder={s('phonenumber')}
        onCountryChange={setCountry}
        onChange={(value) =>
        input.onChange({ phone: value, code: getCountryCallingCode(country) })
      }
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>

  );
};
export default renderCountryPhone;
