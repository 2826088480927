import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateOneTimeCode = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = p('oneTimeFieldCantBeEmpty');
  }

  return errors;
};

export default validateOneTimeCode;
