import React, { useState, useEffect } from 'react';
import { Col, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import DateFrom from './DateForm';
import { partial } from '../../../util/translation/translation';

const InvoiceBasis = () => {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [billableData, setBillableData] = useState([]);
  const [billableSeasonData, setBillabSeasonleData] = useState([]);
  const [billableKioskData, setBillabKioskleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const p = partial('InvoiceBasis');
  const s = partial('shared');

  const getBillable = async () => {
    setLoading(true);
    try {
      const invoiceRes = await Api.invoices.getBillableByDates(dateFrom, dateTo);
      setBillableData(invoiceRes.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('fetchingSingleTicketsFailed'));
      setLoading(false);
    }
  };

  const getSeasonBillable = async () => {
    setLoading(true);
    try {
      const invoiceRes = await Api.invoices.getSeasonBillableByDates(dateFrom, dateTo);
      setBillabSeasonleData(invoiceRes.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || p('fetchingSeasonCardTicketsFailed'));
    }
  };

  const getKioskBillable = async () => {
    setLoading(true);
    try {
      const invoiceRes = await Api.invoices.getKioskBillableByDates(dateFrom, dateTo);
      setBillabKioskleData(invoiceRes.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || p('fetchingKioskTicketsFailed'));
    }
  };

  const validateDates = (date1, date2) => {
    const utcDate1 = Date.parse(date1);
    const utcDate2 = Date.parse(date2);
    if (utcDate2 < utcDate1) {
      toast.error(p('endDateCantBeEarlierThanStartDate'));
      return false;
    } return true;
  };


  useEffect(() => {
    const dateCheck = validateDates(dateFrom, dateTo);
    if (dateFrom !== '' && dateTo !== '' && dateCheck === true) {
      getBillable();
      getSeasonBillable();
      getKioskBillable();
    }
  }, [dateFrom, dateTo]);

  const findMatchName = (array, id) => {
    let stringName = '';
    array.tickets.forEach((group) => {
      if (group.id === id) {
        stringName = `${group.hometeam} - ${group.awayteam}`;
      }
    });
    return stringName;
  };

  const findTotalAmountTicketsPerType = (array, perTicket = 1) => {
    if (typeof array.tickets !== 'undefined') {
      let total = 0;
      array.tickets.forEach((ticket) => {
        total += ticket.priceGroupCount;
      });
      return total * perTicket;
    } return null;
  };

  const renderAmountTicketsPerType = (title, array, perTicket, scrollId) => {
    return (
      <tr>
        <td><a href={`#${scrollId}`}>{title}</a></td>
        <td>{findTotalAmountTicketsPerType(array)}</td>
        <td>{findTotalAmountTicketsPerType(array, perTicket)}</td>
      </tr>
    );
  };

  const totalamountTicketsPerAll = (billableArray, seasonArray, kioskArray, perTicket = 1) => {
    if (typeof billableArray.tickets !== 'undefined' && typeof seasonArray.tickets !== 'undefined' && typeof kioskArray.tickets !== 'undefined') {
      let total = 0;
      try {
        total += findTotalAmountTicketsPerType(billableArray, perTicket);
        total += findTotalAmountTicketsPerType(seasonArray, perTicket);
        total += findTotalAmountTicketsPerType(kioskArray, perTicket);
      } catch (err) {
        toast.error(err || p('fetchingTicketsFailed'));
      }
      return total;
    } return null;
  };
  const renderSummary = () => {
    if (billableData !== [] && billableSeasonData !== [] && billableKioskData !== []) {
      return (
        <Table responsive bordered>
          <thead>
            <tr>
              <th>{s('product')}</th>
              <th>{s('quantity')}</th>
              <th>{p('FeeOf3')}</th>
            </tr>
          </thead>
          <tbody>
            {renderAmountTicketsPerType(s('singleTickets'), billableData, 3, 'singleTickets')}
            {renderAmountTicketsPerType(s('seasonCardTickets'), billableSeasonData, 3, 'seasonTickets')}
            {renderAmountTicketsPerType(p('kioskTickets'), billableKioskData, 3, 'kioskTickets')}
            <tr>
              <th>{s('totalCaps')}</th>
              <th>{totalamountTicketsPerAll(billableData, billableKioskData, billableSeasonData)}</th>
              <th>{totalamountTicketsPerAll(billableData, billableKioskData, billableSeasonData, 3)}</th>
            </tr>
          </tbody>
        </Table>);
    } return null;
  };
  const renderAmountPerTicketType = (matchIds, ticketTitles, array, prefix) => {
    return matchIds.map((matchId) => {
      let totalTickets = 0;
      const filteredGroup = array.tickets.filter(match => matchId === match.id);
      const groupTitles = [];
      filteredGroup.forEach((group) => {
        groupTitles.push(group.title);
      });
      ticketTitles.sort();
      ticketTitles.forEach((title) => {
        if (groupTitles.indexOf(title) === -1) {
          const obj = { id: matchId, title, priceGroupCount: 0 };
          filteredGroup.push(obj);
        }
        const compare = (a, b) => {
          const bandA = a.title;
          const bandB = b.title;
          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        };
        filteredGroup.sort(compare);
      });
      return (
        <tr key={`renderAmountPerTicketType-${matchId}`}>
          <td>{findMatchName(array, matchId)}</td>
          {filteredGroup.map((match) => {
            totalTickets += match.priceGroupCount;

            return <td key={`amount-${match.title}-${match.id}-${prefix}`}>{match.priceGroupCount}</td>;
          })}
          <th>{totalTickets}</th>
        </tr>
      );
    });
  };
  const renderTable = (array, idPrefix) => {
    const matchIds = [];
    const ticketTitles = [];

    array.tickets.forEach((group) => {
      if (matchIds.indexOf(group.id) === -1) {
        matchIds.push(group.id);
      }
    });
    array.tickets.forEach((match) => {
      if (ticketTitles.indexOf(match.title) === -1) {
        ticketTitles.push(match.title);
      }
    });
    ticketTitles.sort();

    const renderTotals = (perTicket = 1) => {
      const sortedTickets = ticketTitles.map((title) => {
        const totals = array.tickets.filter((group) => { return group.title === title; });
        return { title, totals };
      });
      return sortedTickets.map((group) => {
        let total = 0;
        group.totals.forEach((match) => {
          total += match.priceGroupCount;
        });
        return (<th key={`${Math.random()}-${group.title}-${idPrefix}-${perTicket}`}>{total * perTicket}</th>);
      });
    };
    ticketTitles.sort();
    return (
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{s('match')}</th>
            {ticketTitles.map((title) => {
              return (
                <th key={`title-${title}-${idPrefix}`}>{title}</th>
              );
            })}
            <th>{s('total')}</th>
          </tr>
        </thead>
        <tbody>
          {renderAmountPerTicketType(matchIds, ticketTitles, array, idPrefix)}
          <tr>
            <th>{s('totalCaps')}</th>
            {renderTotals()}
            <th>{findTotalAmountTicketsPerType(array)}</th>
          </tr>
          <tr>
            <th>{p('InvoiceBasis')}</th>
            {renderTotals(3)}
            <th>{findTotalAmountTicketsPerType(array, 3)}</th>
          </tr>
        </tbody>
      </Table>
    );
  };

  const handleSubmit = async (values) => {
    setDateFrom(values.dateFrom);
    setDateTo(values.dateTo);
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="card__title margin-0">
        <h5 className="bold-text marginBottom-30">{s('invoiceBasisCaps')}</h5>
      </div>
      <DateFrom onSubmit={handleSubmit} />
      { billableData.tickets && billableSeasonData.tickets && billableKioskData.tickets &&
        <div className="padding-bottom-15">
          <Col lg={6} md={9} sm={12}>
            <h3>{s('summary')}</h3>
            {renderSummary()}
          </Col>
        </div>
      }
      { billableData.tickets &&
        <div>
          <div className="padding-bottom-15">
            <Col lg={12} md={12} sm={12}>
              <h3 id="singleTickets">{s('singleTickets')}</h3>
              {renderTable(billableData, 'billable')}
            </Col>
          </div>
        </div>
      }
      { billableSeasonData.tickets &&
        <div>
          <div className="padding-bottom-15">
            <Col lg={12} md={12} sm={12}>
              <h3 id="seasonTickets">{s('seasonCardTickets')}</h3>
              {renderTable(billableSeasonData, 'season')}
            </Col>
          </div>
        </div>
      }
      { billableKioskData.tickets &&
        <div>
          <div>
            <Col lg={12} md={12} sm={12}>
              <h3 id="kioskTickets">{p('kioskTickets')}</h3>
              {renderTable(billableKioskData, 'kiosk')}
            </Col>
          </div>
        </div>
      }
    </>
  );
};

export default InvoiceBasis;

