import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');

const validate = (values) => {
  const errors = {};
  if (!values.orgName) {
    errors.orgName = `${p('companyNameFieldEmpty')}`;
  }
  if (!values.orgNumber) {
    errors.orgNumber = `${p('organizationNumberFieldEmpty')}`;
  }
  if (!values.companyLevel) {
    errors.companyLevel = `${p('levelFieldCantBeEmpty')}`;
  }
  if (!values.category || values.category.length === 0) {
    errors.category = `${p('categoryFieldCantBeEmpty')}`;
  }
  console.log(values);

  return errors;
};

export default validate;
