import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import validateLogin from './validateLogin';
import renderField from '../../../shared/components/custom/Field';
import t, { partial } from '../../../util/translation/translation';

const p = partial('EmailLogInForm');
const EmailLogInForm = ({ handleSubmit, loading, goToPassword, goToForgot }) => {
  const SmsLogin = () => {
    return (
      <div
        className="form__form-group-field marginBottom-10 pointerButton"
        role="button"
        tabIndex={0}
        onClick={goToPassword}
      >
        <p>{p('smsLogin')}</p>
      </div>
    );
  };
  const ForgotButton = () => {
    return (
      <div
        className="form__form-group-field marginBottom-10 pointerButton"
        role="button"
        tabIndex={0}
        onClick={goToForgot}
      >
        <p>{t('LogInForm.forgotPassword')}</p>
      </div>
    );
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <div id="recaptcha-container"/>
        <span className="form__form-group-label">{t('LogInForm.emailLogin')}</span>
        <div className="form__form-group-field">
          <Field
            name="email"
            component={renderField}
            placeholder={t('EmailLogInForm.email')}
            autoComplete="email"
          />
        </div>
        <div className="form__form-group-field">
          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder={t('shared.password')}
            autoComplete="current-password"
          />
        </div>
      </div>
      <ForgotButton/>
      <SmsLogin />
      <Button color="primary" className="width-100p" disabled={loading}>
        {t('LogInForm.logIn')}
      </Button>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'log_in_form',
  validateLogin,
});

export default reduxF(EmailLogInForm);
