import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Navbar from './components/Navbar';
import TicketSelector from './components/TicketSelector';
import OrderOverview from './components/OrderOverview';
import Api from '../../util/api';
import PrintTicket from './components/PrintTicket';
import Loading from '../../shared/components/custom/Loading';
// import config from '../../config';
import { partial } from '../../util/translation/translation';
import Config from '../../util/Config';

const p = partial('box_officeShare');

let imagesLoaded = 0;

class BoxOffice extends Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    matches: [],
    selectedMatch: 'none',
    priceGroups: [],
    fields: [],
    selectedField: null,
    rows: [],
    selectedRow: null,
    seats: [],
    tickets: 0,
    buttonState: false,
    print: [],
    loading: false,
    enabledQR: true,
    purchaseHistory: [],
    mini: false,
  };

  componentDidMount = () => {
    this.getMatches();
  }

  getMatches = () => {
    this.setState({ loading: true }, async () => {
      try {
        const resMatches = await Api.box.getMatches();
        let { selectedMatch } = this.state;
        if (resMatches.data.length > 0) {
          const urlMatch = new URLSearchParams(window.location.search).get('match');
          const foundMatch = resMatches.data.find(match => match.id === Number(urlMatch));
          if (urlMatch && foundMatch) {
            selectedMatch = foundMatch.id;
          } else if (!urlMatch) {
            this.props.history.replace(`?match=${resMatches.data[0].id}`);
            selectedMatch = resMatches.data[0].id;
          }
        }
        if (selectedMatch !== 'none') {
          const [resPriceGroups, resFields, resFieldStatus] = await Promise.all([
            Api.box.getPriceGroups(selectedMatch),
            Api.box.getFields(selectedMatch),
            Api.box.getFieldStatus(selectedMatch),
          ]);
          resPriceGroups.data = resPriceGroups.data.map(pG => ({ ...pG, amount: 0 }));
          const fields = resFields.data.map((field) => {
            const fieldStatus = resFieldStatus.data.find(fieldStat => fieldStat.field_id === field.id);
            return {
              ...field,
              available: fieldStatus ? fieldStatus.available : 0,
            };
          });
          this.setState({
            loading: false,
            matches: resMatches.data,
            selectedMatch,
            priceGroups: resPriceGroups.data,
            fields,
          });
        } else {
          this.setState({ loading: false, matches: resMatches.data, selectedMatch });
        }
      } catch (err) {
        toast.error(err || `${p('matchFetchingERROR')}!`);
        this.setState({ loading: false });
      }
    });
  }

  getRows = (selectedMatch, fieldID) => {
    const tickets = this.updateTicketsAmount();
    this.setState({ loading: true }, async () => {
      try {
        const resRows = await Api.box.getRows(selectedMatch, fieldID);
        this.setState({ rows: resRows.data, loading: false, tickets });
      } catch (err) {
        toast.error(err || `${p('seatFetchingERROR')}!`);
        this.setState({ loading: false, tickets });
      }
    });
  }

  getSeats = (selectedMatch, rowID, autoSeats) => {
    let tickets = this.updateTicketsAmount();
    this.setState({ loading: true }, async () => {
      try {
        const resSeats = await Api.box.getSeats(selectedMatch, rowID);
        if (autoSeats) {
          resSeats.data = resSeats.data.map((seat) => {
            if (tickets > 0) {
              tickets -= 1;
              return ({
                ...seat,
                active: true,
              });
            }
            return seat;
          });
        }
        const buttonState = this.getButtonState(resSeats.data, tickets);
        this.setState({
          loading: false,
          seats: resSeats.data,
          tickets,
          buttonState,
        });
      } catch (err) {
        toast.error(err || `${p('seatFetchingERROR')}!`);
        this.setState({ loading: false, tickets });
      }
    });
  }

  getPurchaseHistory = (matchID, transactionID = '') => {
    this.setState({ loading: true, purchaseHistory: [] }, async () => {
      try {
        const resPurchaseHistory = await Api.box.getPrintHistory(matchID, transactionID);
        this.setState({ loading: false, purchaseHistory: resPurchaseHistory.data });
      } catch (err) {
        this.setState({ loading: false });
        toast.error(err || `${p('purchaseHistoryFetchingERROR')}!`);
      }
    });
  }

  getButtonState = (seats, tickets) => {
    if (tickets !== 0 && this.state.selectedField && this.state.selectedField.field_type.name === 'unmarked') {
      return true;
    }
    for (let i = 0; i < seats.length; i++) {
      if (seats[i].active && tickets === 0) {
        return true;
      }
    }
    return false;
  }

  getPriceGroupsAmount = () => {
    let total = 0;
    this.state.priceGroups.forEach((priceGroup) => {
      total += priceGroup.amount;
    });
    return total;
  };

  handleSelect = async (selected) => {
    this.props.history.replace(`?match=${selected.value}`);
    this.setState({
      selectedMatch: Number(selected.value),
      priceGroups: [],
      fields: [],
      selectedField: null,
      rows: [],
      selectedRow: null,
      seats: [],
      tickets: 0,
      loading: true,
    }, async () => {
      const [resPriceGroups, resFields, resFieldStatus] = await Promise.all([
        Api.box.getPriceGroups(this.state.selectedMatch),
        Api.box.getFields(this.state.selectedMatch),
        Api.box.getFieldStatus(this.state.selectedMatch),
      ]);
      resPriceGroups.data = resPriceGroups.data.map(pG => ({ ...pG, amount: 0 }));
      const fields = resFields.data.map((field) => {
        const fieldStatus = resFieldStatus.data.find(fieldStat => fieldStat.field_id === field.id);
        return {
          ...field,
          available: fieldStatus ? fieldStatus.available : 0,
        };
      });
      this.setState({ priceGroups: resPriceGroups.data, fields, loading: false });
    });
  }

  handlePriceGroups = (index, assignment) => {
    const priceGroups = [...this.state.priceGroups];
    if (priceGroups[index].amount + assignment >= 0) {
      priceGroups[index].amount += assignment;
      let tickets = this.updateTicketsAmount();
      if (this.state.selectedRow !== null && this.state.selectedRow.available_seats < tickets) {
        this.setState({
          priceGroups,
          tickets,
          selectedRow: null,
          seats: [],
          print: [],
        });
      } else {
        let seats = [...this.state.seats];
        if (tickets < 0 && seats.length !== 0) {
          let amountToRemove = tickets * -1;
          seats = seats.map((seat) => {
            const newSeat = { ...seat };
            if (newSeat.active && amountToRemove !== 0) {
              newSeat.active = false;
              amountToRemove -= 1;
            }
            return newSeat;
          });
        }
        this.setState({
          seats,
        }, () => {
          tickets = this.updateTicketsAmount();
          const buttonState = this.getButtonState(this.state.seats, tickets);
          this.setState({ priceGroups, tickets, buttonState });
        });
      }
    }
  }

  handleField = (index) => {
    this.setState({
      selectedField: this.state.fields[index],
      rows: [],
      selectedRow: null,
      seats: [],
      print: [],
    }, () => {
      this.getRows(this.state.selectedMatch, this.state.fields[index].id);
      this.setState({
        buttonState: this.getButtonState(this.state.seats, this.updateTicketsAmount()),
      });
    });
  }

  handleRow = (index, autoSeats) => {
    if (!this.state.selectedRow || this.state.selectedRow.id !== this.state.rows[index].id) {
      this.setState({
        selectedRow: this.state.rows[index],
        seats: [],
        buttonState: false,
        print: [],
      }, () => {
        this.getSeats(this.state.selectedMatch, this.state.rows[index].id, autoSeats);
      });
    }
  }

  handleSeats = (seatIndex, state, scatteredSeats) => {
    let tickets = this.updateTicketsAmount();
    let seats = [...this.state.seats];
    if (tickets > seats.length) {
      toast.error(`${p('moreTicketsThenSeatsERROR')}`);
      return;
    }

    if (scatteredSeats === false) {
      if (state === true) {
        seats = seats.map(seat => ({ ...seat, active: false }));
      } else if (state === false) {
        if (seats.slice(seatIndex).length < tickets) {
          for (let i = seats.length - 1; i > seats.length - 1 - tickets; i -= 1) { // Not enough seats downwards, start bottom go up
            seats[i].active = true;
          }
        } else {
          let hasFoundSeat = false;
          seats = seats.map((seat, index) => { // Selects all seats downwards
            if (tickets !== 0 && (index === seatIndex || hasFoundSeat)) {
              hasFoundSeat = true;
              tickets -= 1;
              return {
                ...seat,
                active: true,
              };
            }
            return seat;
          });
        }
      }
    } else if (scatteredSeats === true) {
      if (tickets > 0 && !seats[seatIndex].active) {
        seats[seatIndex].active = !seats[seatIndex].active; // Single select
      } else if (seats[seatIndex].active) {
        seats[seatIndex].active = !seats[seatIndex].active;
      }
    }

    this.setState({
      print: [],
      seats,
    }, () => {
      tickets = this.updateTicketsAmount();
      this.setState({
        buttonState: this.getButtonState(seats, tickets),
        tickets,
      });
    });
  }

  handlePurchaseHistorySelect = (config) => (printHistory) => {
    const tickets = printHistory.map(ticket => ({
      ...ticket,
      user_id: ticket.payment.user_id,
      match: ticket.seat_match.match,
      price: Number(ticket.payment.price).toFixed(2),
      reference: `${config.club_name_short}${new Date().getTime()}${ticket.id}`,
      printImage: ticket.seat_match.match?.company?.print_image?.url, // eslint-disable-line camelcase
    }));
    if (this.state.mini === true) {
      this.changePaperSize('label');
    } else {
      this.changePaperSize('A4');
    }
    this.setState({ print: tickets });
  };

  updateTicketsAmount = () => {
    const ticketsCount = this.state.priceGroups.reduce((acc, curr) => acc + curr.amount, 0);
    const activeSeatsCount = this.state.seats.reduce((acc, curr) => acc + !!curr.active, 0);
    return ticketsCount - activeSeatsCount;
  };

  imageCount = () => {
    const fangroupLogos = this.state.print.length;
    const pinLogos = this.state.print.length;
    let homeTeamLogos = 0;
    if (this.state.print?.[0]?.match?.home_team?.logo?.url) {
      homeTeamLogos = this.state.print.length;
    }
    let matchSponsorLogos = 0;
    if (this.state.print?.[0]?.match?.company?.print_image?.url) {
      matchSponsorLogos = this.state.print.length;
    }
    let imageCount = fangroupLogos + pinLogos + homeTeamLogos + matchSponsorLogos;
    if (this.state.print.length > 1 && this.state.enabledQR) {
      imageCount += imageCount / this.state.print.length;
    }
    return imageCount;
  }

  onLoadImage = () => {
    const imageCount = this.imageCount();
    if (imageCount === 0) {
      return;
    }
    imagesLoaded += 1;
    if (imagesLoaded >= imageCount) {
      window.print();
      this.setState({
        print: [],
      }, () => {
        imagesLoaded = 0;
      });
    }
  }

  changePaperSize = (variant) => {
    const cssPagedMedia = (function () {
      const style = document.createElement('style');
      document.head.appendChild(style);
      return function (rule) {
        style.innerHTML = rule;
      };
    }());

    cssPagedMedia.size = function (size) {
      cssPagedMedia(`@page {size: ${size}}`);
    };

    if (variant === 'label') {
      return cssPagedMedia.size('107mm 63.5mm');
    }
    return cssPagedMedia.size('A4');
  }

  reserveTicketsAndPrint = (config) => async () => {
    this.setState({ loading: true }, async () => {
      const match = this.state.matches.find(m => m.id === Number(this.state.selectedMatch));

      const filteredPriceGroups = this.state.priceGroups.filter(pG => pG.amount > 0);

      let seats = [];

      if (this.state.selectedField.field_type.name === 'unmarked') {
        const payload = {
          field_id: this.state.selectedField.id,
          number_of_tickets: this.updateTicketsAmount(),
        };
        try {
          const resUnmarkedSeats = await Api.box.getUnmarkedSeats(match.id, payload);
          seats = resUnmarkedSeats.data;
        } catch (err) {
          toast.error(err || `${p('seatFetchingERROR2')}`);
          return;
        }
      } else {
        seats = this.state.seats.filter(seat => !!seat.active);
      }

      const seatIDs = seats.map(seat => seat.seats_id);
      const payload = {
        match_id: match.id,
        price_groups: filteredPriceGroups,
        field_id: this.state.selectedField.id,
        seats: seatIDs,
      };
      const mapTickets = ticket => ({
        ...ticket,
        user_id: this.props.me.id,
        price: ((this.state.priceGroups.find(pG => pG.id === ticket.price_group.id).percentage * this.state.selectedField.field_type.price) / 100).toFixed(2),
        reference: `${config.club_name_short}${new Date().getTime()}${ticket.id}`,
        match,
        printImage: match?.company?.print_image?.url, // eslint-disable-line camelcase
      });
      try {
        let resTickets = { data: [] };
        if (this.state.selectedField.field_type.name === 'unmarked') {
          resTickets = await Api.box.buyUnmarkedTickets(payload);
        } else {
          resTickets = await Api.box.buyTickets(payload);
        }
        const mappedTickets = resTickets.data.map((mapTickets));

        if (this.state.mini === true) {
          this.changePaperSize('label');
        } else {
          this.changePaperSize('A4');
        }

        this.setState({ print: mappedTickets });
      } catch (err) {
        toast.error(err || `${p('cantBuyTicketsERROR')}`);
      } finally {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <Config>
        { (config) => (
          <>
            <Loading loading={this.state.loading} />
            <div className="box-office">
              <Navbar
                matches={this.state.matches}
                selectedMatchID={this.state.selectedMatch}
                handleSelect={this.handleSelect}
                getPurchaseHistory={this.getPurchaseHistory}
                purchaseHistory={this.state.purchaseHistory}
                handlePurchaseHistorySelect={this.handlePurchaseHistorySelect(config)}
              />
              <TicketSelector
                priceGroups={this.state.priceGroups}
                fields={this.state.fields}
                handlePriceGroups={this.handlePriceGroups}
                handleField={this.handleField}
                selectedField={this.state.selectedField}
                rows={this.state.rows}
                handleRow={this.handleRow}
                selectedRow={this.state.selectedRow}
                seats={this.state.seats}
                handleSeats={this.handleSeats}
                tickets={this.state.tickets}
                getPriceGroupsAmount={this.getPriceGroupsAmount}
              />
              <OrderOverview
                state={this.state.buttonState}
                fields={this.state.fields}
                priceGroups={this.state.priceGroups}
                selectedField={this.state.selectedField}
                selectedRow={this.state.selectedRow}
                seats={this.state.seats}
                reserveTicketsAndPrint={this.reserveTicketsAndPrint(config)}
                enabledQR={this.state.enabledQR}
                toggleQR={() => this.setState({ enabledQR: !this.state.enabledQR })}
                mini={this.state.mini}
                toggleMini={() => this.setState({ mini: !this.state.mini })}
              />
            </div>
            <PrintTicket
              tickets={this.state.print}
              selectedField={this.state.selectedField}
              enabledQR={this.state.enabledQR}
              fangroup={this.state.fangroup}
              pin={this.state.pin}
              homeTeamLogo={this.state.homeTeamLogo}
              matchSponsor={this.state.matchSponsor}
              onLoad={this.onLoadImage}
              mini={this.state.mini}
            />
          </>
        ) }
      </Config>
    );
  }
}

const mapStateToProps = state => ({
  me: state.admin.me,
});

export default connect(mapStateToProps)(BoxOffice);
