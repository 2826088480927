import React, { Component } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes, { any } from 'prop-types';
import Loading from '../../shared/components/custom/Loading';
import StadiumImportForm from './components/StadiumImportForm';
import TicketImportForm from './components/SeasonTicketImportForm';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';

const p = partial('settingsObjectsShared');

class Import extends Component {
  static propTypes = {
    stadiumValues: PropTypes.objectOf(PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.shape({
        file: PropTypes.objectOf(any),
      })),
    })),
    ticketsValues: PropTypes.objectOf(PropTypes.shape({
      file: PropTypes.objectOf(PropTypes.shape({
        file: PropTypes.objectOf(any),
      })),
    })),
  };

  static defaultProps = {
    stadiumValues: {
      stadium: {
        file: null,
      },
    },
    ticketsValues: {
      tickets: {
        file: null,
      },
    },
  }

  state = {
    loading: false,
    importDetails: {
      fields: 0,
      rows: 0,
      seats: 0,
      newFields: [],
      newRows: [],
      newSeats: [],
    },
  };

  stadiumSubmit = () => {
    if (this.props.stadiumValues.stadium.file) {
      const formData = new FormData();
      formData.append('file', this.props.stadiumValues.stadium.file);

      this.setState({ loading: true }, () => {
        Api.import.importStadiumSetup(formData)
          .then((res) => {
            this.setState({ importDetails: res.data, loading: false });
            toast.success(p('stadiumWasImported'));
          }).catch((err) => {
            this.setState({ loading: false });
            toast.error(err || p('errorWhenImportingStadium'));
          });
      });
    } else {
      toast.error(p('mustUploadFileBeforeImporting'));
    }
  }

  ticketSubmit = () => {
    if (this.props.ticketsValues.tickets.file) {
      const formData = new FormData();
      formData.append('file', this.props.ticketsValues.tickets.file);
      this.setState({ loading: true }, () => {
        Api.import.importSeasonTickets(formData)
          .then(() => {
            this.setState({ loading: false });
            toast.success(p('seasonTicketHasBeenImported'));
          }).catch(() => {
            this.setState({ loading: false });
            toast.error(p('errorWhenImportingSeasonTicket'));
          });
      });
    } else {
      toast.error(p('mustUploadFileBeforeImporting'));
    }
  }

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <Card>
          <CardBody>
            <StadiumImportForm
              onSubmit={this.stadiumSubmit}
              importDetails={this.state.importDetails}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <TicketImportForm
              onSubmit={this.ticketSubmit}
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default withRouter(connect(state => ({
  stadiumValues: getFormValues('stadium_import_form')(state),
  ticketsValues: getFormValues('season_ticket_import_form')(state),
}))(Import));
