import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import ControlImageForm from './components/ControlImageForm';
import { convertImageToBlob } from '../../util/image-functions';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';

const ControlImageFormPage = () => {
  const [loadingControlImage, setloadingControlImage] = useState(false);
  const [controlImage, setControlImage] = useState(null);
  const { controlImageID } = useParams();
  const history = useHistory();
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const getControlImage = async () => {
    setloadingControlImage(true);
    try {
      const resControlImage = await Api.controlImages.getControlImage(controlImageID);
      setControlImage(resControlImage.data);
      setloadingControlImage(false);
    } catch (err) {
      toast.error(p('errorWhenFetchingControllPicture'));
      setloadingControlImage(false);
    }
  };

  useEffect(() => {
    if (controlImageID) {
      getControlImage();
    }
  }, [controlImageID]);

  const handleSubmit = async (values) => {
    if (!values.image || !values.image.length === 1) {
      toast.error(p('mustUploadControllPicture'));
      return;
    }
    if (values.image[0].initialized === true) {
      toast.error(p('cantUploadSamePicture'));
      return;
    }
    let controlImageBlob = null;
    try {
      controlImageBlob = await convertImageToBlob(values.image[0]);
    } catch (err) {
      toast.error(err || p('somethingWhentWrongUploadingControllPicture'));
      return;
    }
    setloadingControlImage(true);
    const payload = {
      name: values.name,
      image: controlImageBlob,
      same_image: !!values.image[0].initialized,
    };
    try {
      if (controlImageID) {
        await Api.controlImages.updateControlImage(controlImageID, payload);
        toast.success(p('ControllPictureHasBeenUpdating'));
      } else {
        await Api.controlImages.createControlImage(payload);
        toast.success(p('ControllPictureHasBeenUpdating'));
      }
      history.replace('/settings?tab=5');
    } catch (err) {
      toast.error(err || p('somethingWhentWrongUploadingControllPicture'));
      setloadingControlImage(false);
    }
  };

  return (
    <Container>
      <Loading loading={loadingControlImage} />
      <Col>
        <h3 className="page-title">{ controlImageID ? s('edit') : s('create')} {p('controllImage')}</h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <ControlImageForm
              controlImage={controlImage}
              onSubmit={handleSubmit}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default ControlImageFormPage;
