import React, { useState, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';
import { Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../shared/components/custom/Loading';
import RenderField from '../../../shared/components/custom/Field';
import RenderCheckBoxField from '../../../shared/components/form/CheckBox';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
// import config from '../../../config';

const s = partial('shared');

const RenderPriceGroups = ({ fields, priceGroups }) => {
  return fields.map((key, index) => {
    const priceGroup = priceGroups[index];
    return (
      <Col lg={4} md={6} sm={12} key={key}>
        <div className="form__form-group">
          <div className="space-between">
            <span className="form__form-group-label">{priceGroup.title}</span>
            <Field
              name={`${key}.buyable`}
              component={RenderCheckBoxField}
              type="checkbox"
              defaultChecked={!!priceGroup.buyable}
              className="colored-click"
            />
          </div>
          <div className="form__form-group-field">
            <Field name={`${key}.percentage`} component={RenderField} type="number" />
            <div className="form__form-group-icon">
              <p>&nbsp;%&nbsp;</p>
            </div>
          </div>
        </div>
      </Col>
    );
  });
};

const RenderFieldTypes = ({ fields, fieldTypes }) => {
  const config = useConfig();
  return fields.map((key, index) => {
    const fieldType = fieldTypes[index];
    return (
      <Col lg={3} md={4} sm={6} key={key}>
        <div className="form__form-group">
          <span className={`form__form-group-label field-${fieldType.name}`}>{fieldType.display_name}</span>
          <div className="form__form-group-field">
            <Field name={`${key}.price`} component={RenderField} type="number" />
            <div className="form__form-group-icon">
              <p>&nbsp;{config.currencyShortSmall}&nbsp;</p>
            </div>
          </div>
        </div>
      </Col>
    );
  });
};

const Prices = (props) => {
  const [loading, setLoading] = useState(false);
  const p = partial('Prices');

  useEffect(() => {
    if (props.priceGroups?.length !== 0 || props.fieldTypes?.length !== 0) {
      const priceGroups = props.priceGroups?.map((priceGroup) => ({
        price_group_id: priceGroup.id,
        percentage: priceGroup.percentage,
        buyable: !!priceGroup.buyable,
      }));
      const fieldTypes = props.fieldTypes?.map((fieldType) => ({
        field_type_id: fieldType.id,
        price: fieldType.price,
      }));
      props.initialize({
        fieldTypes,
        priceGroups,
      });
    }
  }, [props.fieldTypes, props.priceGroups]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { priceGroups, fieldTypes } = props.formValues;
    setLoading(true);
    try {
      await Api.matches.putPriceGroups(props.matchID, { price_groups: priceGroups });
    } catch (err) {
      toast.error(err || `${p('updatingPricegroupsFailed')}`);
    }
    try {
      await Api.matches.putFieldTypes(props.matchID, { field_types: fieldTypes });
      toast.success(`${p('priceAndFieldtypesUpdated')}`);
    } catch (err) {
      toast.error(err || `${p('updatingPricefieldsFailed')}`);
    }
    setLoading(false);
  };
  return (
    <form className="form prices-flex" onSubmit={handleSubmit}>
      <Loading loading={loading} />
      <Col md={12}>
        <div className="card__title marginBottom-10">
          <h5 className="bold-text">{s('priceGroups')}</h5>
        </div>
        <Row>
          <FieldArray name="priceGroups" priceGroups={props.priceGroups} component={RenderPriceGroups} />
        </Row>
      </Col>
      <Col md={12}>
        <div className="card__title marginBottom-10">
          <h5 className="bold-text">{p('fieldPrices')}</h5>
        </div>
        <Row>
          <FieldArray name="fieldTypes" fieldTypes={props.fieldTypes} component={RenderFieldTypes} />
        </Row>
      </Col>
      <Button type="submit" color="primary">
        {p('savePrices')}
      </Button>
      <Link to="/matches">
        <Button type="button" color="secondary">
          {s('close')}
        </Button>
      </Link>
    </form>
  );
};

Prices.propTypes = {
  formValues: PropTypes.objectOf(any),
  matchID: PropTypes.string.isRequired,
  initialize: PropTypes.func.isRequired,
  priceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
      buyable: PropTypes.number.isRequired,
    }),
  ).isRequired,
  fieldTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      colour: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Prices.defaultProps = {
  formValues: {},
};

const reduxF = reduxForm({
  form: 'matchPrices',
});

export default connect((state) => ({
  formValues: getFormValues('matchPrices')(state),
}))(reduxF(Prices));
