import {
  SET_EDIT_COMPANY_DATA,
} from '../actions/companyActions';

const initialState = {

};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_COMPANY_DATA:
      return action.company;
    default:
      return state;
  }
}
