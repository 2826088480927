import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import t from '../../../util/translation/translation';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';

const TicksterEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateEvents = async (id) => {
    setLoading(true);
    try {
      const response = await Api.tickets.updateTicksterEvents(id);

      if (!response.data) return;

      const allEvents = [...events];
      const eventIndex = allEvents.findIndex(e => e.id === response.data?.id);

      allEvents[eventIndex] = response.data;

      setEvents(allEvents);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error || 'Error Updating events:\'!\'');
    }
  };

  const addEvents = async () => {
    setLoading(true);
    try {
      const response = await Api.tickets.postTicksterEvents();
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error || 'Error Updating events:\'!\'');
    }
  };

  const renderTicksterEvents = () => {
    return events
    .sort((a, b) => (new Date(b.date) - new Date(a.date)))
    .map((event) => (
      <tr key={`renderScheduledMessages-${event.id}`}>
        <td>{event.name}</td>
        <td>{event.startUtc}</td>
        <td>{event.endUtc}</td>
        <td>{event.venue?.name || '-- --'}</td>
        <td />
        <td><Button onClick={() => updateEvents(event.id)} size="sm" color="primary p-1 m-0">{t('shared.update')}</Button></td>
      </tr>
    ));
  };

  useEffect(() => {
    let isMounted = true;
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await Api.tickets.getTicksterEvents();
        if (isMounted) {
          setEvents(response.data);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false);
          toast.error(error || 'Error fetching events:\'!\'');
        }
      }
    };

    fetchEvents();

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <Container>
      <Loading loading={loading} />
      <div>
        <div className="card__title my-2">
          <h3 className="page-title">{t('shared.events')}</h3>
          <Button onClick={addEvents} size="sm" color="primary">{t('shared.import')}</Button>
        </div>
        <div style={{ display: 'flex', marginBottom: 10, paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped>
            <thead>
              <tr>
                <th>{t('shared.title')}</th>
                <th>{t('shared.startDate')}</th>
                <th>{t('shared.endDate')}</th>
                <th>venue</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {renderTicksterEvents()}
            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  );
};

export default TicksterEvents;
