import React, { useEffect, useState } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Button, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import appSponsorValidate from './appSponsorValidate';
import t, { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';
import Api from '../../../util/api';

const AppSponsor = (props) => {
  const {
    handleSubmit, appSponsorId, initialize, isLoading,
  } = props;
  const s = partial('shared');
  const [companies, setCompanies] = useState([]);
  const [sponsorUrl, setSponsorUrl] = useState('');
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const clubCompanies = await Api.companies.getClubCompanies();
        const mappedCompanies = clubCompanies.map((item) => ({ label: item.name, value: item })).sort((a, b) => a.label.localeCompare(b.label));
        const foundCompany = clubCompanies.find((item) => item.id === appSponsorId);
        initialize({
          selectedCompany: foundCompany && {
            label: foundCompany.name,
            value: foundCompany,
          },
        });
        setSponsorUrl(foundCompany?.images?.appSponsorImage);
        setCompanies(mappedCompanies);
        setLoadingCompanies(false);
      } catch (e) {
        setLoadingCompanies(false);
      }
    };
    fetchCompanies();
  }, [appSponsorId]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <div className="card__title margin-0">
            <p className="bold">App-sponsor</p>
          </div>
          <div className="form__form-group mx-2">
            <span className="form-header">{s('company')}</span>
            <Field
              name="selectedCompany"
              placeholder={t('EventParticipantsPage.chooseCompany')}
              options={companies}
              component={renderSelectField}
              onChange={({ value: { images } }) => {
                setSponsorUrl(images?.appSponsorImage);
              }}
            />
          </div>
          {
          !isLoading && !loadingCompanies &&
          <div className="dropzone--container dropzone dropzone--single">
            <div className="dropzone__img">
              <img src={sponsorUrl} alt="drop-img" />
            </div>
          </div>
          }
          <Col md={12}>
            <Button type="submit" color="primary">{s('save')}</Button>
            <Link className="color-unset" to="/companies?tab=1">
              <Button type="button">{s('close')}</Button>
            </Link>
          </Col>
        </CardBody>
      </Card>
    </form>
  );
};

AppSponsor.propTypes = {
  ...reduxFormProps,
  app_sponsor: PropTypes.shape({
    id: PropTypes.number,
  }),
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

AppSponsor.defaultProps = {
  app_sponsor: null,
};

const reduxF = reduxForm({
  form: 'AppSponsorForm',
  validate: appSponsorValidate,
});

export default reduxF(AppSponsor);
