import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import validate from './validateApi';
import { partial } from '../../../util/translation/translation';
import renderSelectField from '../../../shared/components/form/Select';

const NewsProviderForm = ({
  handleSubmit,
  categories,
  initialize,
  providerData,
}) => {
  const s = partial('shared');
  const categoryOptions = useMemo(() => [...categories, { name: s('notSelected') }].map((item) => ({ label: item.name, value: item.id })), [categories]);
  useEffect(() => {
    const currentCategory = providerData?.category?.id;
    initialize({
      category: categoryOptions.find((item) => item.value === currentCategory) || { label: s('notSelected') },
    });
  }, [providerData, categoryOptions]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Col>
        <div className="form__form-group">
          <span className="form-header">{s('newsCategory')}</span>
          <Field
            name="category"
            placeholder={s('newsCategory')}
            options={categoryOptions}
            component={renderSelectField}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {s('create')}
        </Button>
        <Link
          className="color-unset"
          to="/news?tab=2"
        >
          <Button type="button">
            {s('close')}
          </Button>
        </Link>
      </Col>
    </form>
  );
};



const reduxF = reduxForm({
  form: 'news_provider_form',
  validate,
});

export default withRouter(connect(state => ({
  formValues: getFormValues('news_provider_form')(state),
}))(reduxF(NewsProviderForm)));
