export default {
  club_name: 'Vålerenga Fotball',
  club_name_short: 'valerenga',
  stadium_name: '',
  staging_url: 'https://valerenga.api.fangroup.io',
  prod_url: 'https://valerenga.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/valerenga',
  club_logo: '/assets/teams/valerenga.png',
  app_name: 'valerenga',
  websale_name: 'valerenga',
  ntb_team_id: 10035,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'valerenga',
  monolithEnabled: false,
};
