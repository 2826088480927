const Utilities = {
  trim: (string, length = 50) => {
    if (string?.length > 50) {
      return `${string.substr(0, length)}...`;
    }
    return string;
  },
  previewQuestion: (hoveredQuestion, index, className, lengthLimit = 50) => {
    if (hoveredQuestion.question.length < lengthLimit) return;
    document.querySelector('.question-preview')?.remove();
    const parentElement = document.querySelectorAll(`.${className}`)[index];
    if (parentElement) {
      const div = document.createElement('div');
      div.classList.add('question-preview');
      div.textContent = hoveredQuestion.question;
      parentElement.appendChild(div);
    }
  },
  clearPreview: () => document.querySelector('.question-preview')?.remove(),
};

export default Utilities;
