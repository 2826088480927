import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Api from '../../util/api';
import Pagination from '../../shared/components/pagination/Pagination';

const TeamAdminListPage = () => {
  const [teams, setTeams] = useState({ data: [] });
  const fetchTeams = async (page) => {
    try {
      const { data } = await Api.clubadmin.teams.all(page);
      setTeams(data);
    } catch (e) {
      toast.error(e);
    }
  };

  const renderTeams = (_teams) => _teams.data.map((team) => (
    <tr key={`teams:${team.team_id}`}>
      <td><Link to={`/clubadmin/team/edit/${team.team_id}`}>{team.team_name}</Link></td>
      <td>{team.team_shortName}</td>
      <td>{team.team_externalProviderId}</td>
      <td>{team.team_logoUrl ? <img alt="team logo" src={team.team_logoUrl} /> : 'Missing logo'}</td>
    </tr>
  ));

  useEffect(() => {
    fetchTeams();
  }, []);
  return (
    <>
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>Short name</th>
            <th>ExternalId</th>
            <th>Logo</th>
          </tr>
        </thead>
        <tbody>
          {renderTeams(teams)}
        </tbody>
      </Table>
      <Pagination rowsPerPage={[100, 250, 500]} items={teams.total || 0} onChangePage={fetchTeams} />
    </>
  );
};

export default TeamAdminListPage;
