import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import Loading from '../../shared/Spinner';
import helpers from '../helpers';
import { Widget, WidgetHeader, DoughnutChart, EmptyGraphData } from './shared';

const TicketSales = () => {
  const dT = partial('DashboardPage');
  const p = partial('Reports');
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [chartData, setChartData] = useState({});
  const { data: allSeasons } = useQuery({ queryKey: ['get-seasons'], queryFn: () => Api.seasonTickets.getSeasons() });
  const getLatestSeason = () => {
    return allSeasons?.data?.sort((a, b) => b.id - a.id)[0].id;
  };
  const { data: stadiumStats } = useQuery({ queryKey: ['stadium-stats'], enabled: !!allSeasons, queryFn: () => Api.dashboard.getStadiumStats() });
  const { data: matchStats, isLoading } = useQuery({ queryKey: ['match-stats'], enabled: !!allSeasons, queryFn: () => Api.dashboard.getMatchStats(getLatestSeason()) });
  const toRender = useMemo(() => !!(matchStats?.data?.past?.length || matchStats?.data?.upcoming?.length), [matchStats]);
  const sortByAsc = (dateArray) => dateArray.sort((a, b) => new Date(a.match_starts_at) - new Date(b.match_starts_at));

  const getCurrentMatch = (match) => {
    const upcomingMatchCount = matchStats.data.upcoming.length;
    let currentMatch = upcomingMatchCount > 0 ? matchStats.data.upcoming[0] : sortByAsc(matchStats.data.past)[0];
    if (match) {
      currentMatch = match;
    }
    return currentMatch;
  };

  const getTotalTickets = (stadiumInfo) => stadiumInfo?.reduce((total, data) => total + Number(data.total_seats), 0);

  const getPriceGroupData = (match) => {
    const currentMatch = getCurrentMatch(match);
    const priceGroupData = [];
    if (currentMatch?.price_group_count) {
      const priceGroupCount = JSON.parse(currentMatch.price_group_count);
      priceGroupData.push(...Object.keys(priceGroupCount).map((key) => ({ title: key, amount: priceGroupCount[key] })));
    }
    priceGroupData.unshift({ title: p('seasonTickets'), amount: Number(currentMatch?.season_ticket_count) });
    return priceGroupData;
  };

  const getSoldTickets = (match) => getPriceGroupData(match).reduce((total, group) => total + group.amount, 0);

  const getAvailableTickets = (match) => {
    const stadiumInfo = stadiumStats.data;
    const currentMatch = getCurrentMatch(match);
    if (!currentMatch) {
      const amount = getTotalTickets(stadiumInfo);
      return [{ title: p('availableTickets'), amount }];
    } else if (!currentMatch?.field_ids) {
      const soldTickets = getSoldTickets(match);
      const amount = getTotalTickets(stadiumInfo);
      return [{ title: p('availableTickets'), amount: amount - soldTickets }];
    } else {
      currentMatch.field_ids.split(',').forEach(id => {
        const stadiumIndex = stadiumInfo.findIndex(info => Number(info.field_id) === Number(id));
        if (stadiumIndex !== -1) {
          stadiumInfo.splice(stadiumIndex, 1);
        }
      });
      const soldTickets = getSoldTickets(match);
      const amount = getTotalTickets(stadiumInfo);
      return [{ title: p('availableTickets'), amount: amount - soldTickets }];
    }
  };

  useEffect(() => {
    if (stadiumStats && matchStats && !selectedMatch) {
      const matchInfo = [...getAvailableTickets(), ...getPriceGroupData()];
      const { labels, dataset, colors } = helpers.getSortedChartData(matchInfo, 'title', 'amount');
      setChartData({
        labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: colors,
          },
        ],
      });
    }
  }, [stadiumStats, matchStats, selectedMatch]);

  useEffect(() => {
    if (selectedMatch) {
      const matchInfo = [...getAvailableTickets(selectedMatch), ...getPriceGroupData(selectedMatch)];
      const { labels, dataset, colors } = helpers.getSortedChartData(matchInfo, 'title', 'amount');
      setChartData({
        labels,
        datasets: [
          {
            data: dataset,
            backgroundColor: colors,
          },
        ],
      });
    }
  }, [selectedMatch]);

  const renderDropDown = () => {
    let home_team = '';
    let away_team = '';
    let match_starts_at = new Date();

    if (!selectedMatch) {
      const upcomingMatchCount = matchStats?.data.upcoming.length;
      home_team = upcomingMatchCount > 0 ? matchStats?.data.upcoming[0]?.home_team : sortByAsc(matchStats?.data.past)[0]?.home_team;
      away_team = upcomingMatchCount > 0 ? matchStats?.data.upcoming[0]?.away_team : sortByAsc(matchStats?.data.past)[0]?.away_team;
      match_starts_at = upcomingMatchCount > 0 ? matchStats?.data.upcoming[0]?.match_starts_at : sortByAsc(matchStats?.data.past)[0]?.match_starts_at;
    } else {
      home_team = selectedMatch.home_team;
      away_team = selectedMatch.away_team;
      match_starts_at = selectedMatch.match_starts_at;
    }
    return <button className="widget-dropdown-btn">{ `${home_team} - ${away_team} ${moment(match_starts_at).format('ddd D.M.YY')}`.substring(0, 30)}&nbsp;&nbsp;<span className="dropdown-chevron">&#9662;</span></button>;
  };

  const renderMatchesList = () => {
    return [...sortByAsc(matchStats?.data.past), ...matchStats?.data.upcoming].map(match => (
      <button key={match.match_id} onClick={() => setSelectedMatch(match)}>
        <div>{match.home_team} - {match.away_team}</div>
        <div>{moment(match.match_starts_at).format('ddd D.M.YY')}</div>
      </button>
    ));
  };

  const renderChart = (
    chartData.datasets && chartData.datasets[0].data.length > 0 ? <DoughnutChart
      chartData={chartData}
    /> : <EmptyGraphData message={dT('noData')} />
  );
  return (
    <Widget>
      <WidgetHeader title={dT('ticketSales')} subhead={dT('ticketSalesSubheading')}>
        <div className="widget-dropdown">
          {!isLoading && toRender ? renderDropDown() : null}
          <div className="widget-dropdown-content">
            {isLoading ? <Loading /> : renderMatchesList() }
          </div>
        </div>
      </WidgetHeader>
      {isLoading ? <Loading /> : renderChart}
    </Widget>
  );
};

export default TicketSales;
