import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateTileForm = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }
  if (!values.headingImage) {
    errors.headingImage = p('picFieldCantBeEmpty');
  }

  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }

  return errors;
};
export default validateTileForm;
