import React, { useEffect } from 'react';
import { Col, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from '../validate';
import renderField from '../../../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';
import renderSelectField from '../../../../../shared/components/form/Select';
import { partial } from '../../../../../util/translation/translation';

const s = partial('shared');
const p = partial('product&CategoryShare');
const ProductForm = (props) => {
  useEffect(() => {
    if (props.product.id) {
      const category = props.categories.find(cat => cat.value === props.product.category_id.toString());
      props.initialize({
        name: props.product.name,
        subtitle: props.product.subtitle,
        price: props.product.price,
        category,
        description: props.product.description,
        comment: props.product.comment,
        canComment: !!props.product.can_comment,
        image: props.product.image ? [{
          ...props.product.image,
          preview: props.product.image.url,
        }] : undefined,
      });
    }
  }, [props.product]);
  const { handleSubmit } = props;
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('name')}</span>
          <div className="marginTop-5" />
          <Field
            name="name"
            component={renderField}
            placeholder={s('theProductsName')}
          />
        </div>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('additionalInfo')}</span>
          <div className="marginTop-5" />
          <Field
            name="subtitle"
            component={renderField}
            placeholder={s('additionalInfo')}
          />
        </div>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('price')}</span>
          <div className="marginTop-5" />
          <Field
            name="price"
            component={renderField}
            placeholder={s('price')}
            type="number"
          />
        </div>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('category')}</span>
          <div className="marginTop-5" />
          <Field
            name="category"
            component={renderSelectField}
            options={props.categories}
            placeholder={s('selectCategory')}
          />
        </div>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('description')}</span>
          <div className="marginTop-5" />
          <Field
            name="description"
            component={renderField}
            placeholder={s('description')}
          />
        </div>
        <div className="form__form-group flex-10">
          <span className="form-header">{s('comment')}</span>
          <div className="marginTop-5" />
          <Field
            name="comment"
            component={renderField}
            placeholder={p('hiddenComment')}
          />
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="canComment"
              component={renderCheckBoxField}
              className="colored-click"
              placeholder={p('test')}
              label={p('canComment')}
            />
          </div>
        </div>
      </Col>
      <Col md={6} sm={12}>
        <div className="card__title marginTop-0">
          <h5 className="bold-text">{p('uploadProductImageCaps')}</h5>
          <h5 className="subhead">{s('imageOrVideo')}: 750px/390px</h5>
        </div>
        <Field
          name="image"
          component={renderDropZoneField}
        />
      </Col>
      <Col>
        <Button color="primary" type="submit">{props.type === 'create' ? `${s('create')}` : `${s('save')}`}</Button>
        <Link to="/cnc?tab=2"><Button type="button">{s('close')}</Button></Link>
      </Col>
    </form>
  );
};

ProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    price: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    comment: PropTypes.string,
    can_comment: PropTypes.number,
    category_id: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  initialize: PropTypes.func.isRequired,
  type: PropTypes.string,
};

ProductForm.defaultProps = {
  product: {},
  type: 'create',
};

const reduxF = reduxForm({
  form: 'create-new-product-form',
  validate,
});

export default reduxF(ProductForm);
