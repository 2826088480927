import { useEffect, useState } from 'react';

const useModal = (defaultVisible = false, defaultID = null) => {
  const [visible, setVisible] = useState(defaultVisible);
  const [deleteID, setDeleteID] = useState(defaultID);
  const [comment, setComment] = useState('');

  const handleModal = (id = false) => {
    setVisible(id !== false);
    setDeleteID(id !== false && id);
  };

  useEffect(() => {
    setComment('');
  }, [visible]);

  return [handleModal, visible, deleteID, comment, setComment];
};

export default useModal;
