export default {
  club_name: 'Vålerenga Hockey',
  club_name_short: 'VIF',
  stadium_name: 'Furuset Forum',
  staging_url: 'https://staging-valerenga-billett.innit.no',
  prod_url: 'https://valerenga-billett.innit.no',
  api: '/api',
  facebook: 'VIFHockey',
  basename: '/vif',
  club_logo: '/assets/teams/vif.png',
  app_name: 'vif',
  websale_name: 'vif',
  ntb_team_id: 10049,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'vif',
  monolithEnabled: false,
};
