import React from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../util/api';

const RefreshCachePage = () => {
  const refreshSpotlightCache = async () => {
    try {
      await Api.clubadmin.spotlight.refreshCache();
      toast.success('Refresh successful, wait 10s to populate');
    } catch (e) {
      toast.error('Error refreshing cache');
    }
  };
  const refreshAuctionsCache = async () => {
    try {
      await Api.clubadmin.auctions.refreshCache();
      toast.success('Refresh successful, wait 10s to populate');
    } catch (e) {
      toast.error('Error refreshing cache');
    }
  };
  return (
    <div className="flex justify-center">
      <Button color="info" onClick={refreshSpotlightCache}>Refresh spotlight cache</Button>
      <Button color="info" onClick={refreshAuctionsCache}>Refresh auctions cache</Button>
    </div>
  );
};

export default RefreshCachePage;
