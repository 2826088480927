import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

const ShareModal = (props) => {
  const { children, handleModal, title } = props;
  return (
    <Modal
      isOpen={props.visible}
      toggle={() => { handleModal(false); }}
      className="theme-light"
      centered
      size={props.size}
    >
      <ModalHeader toggle={() => { handleModal(false); }}>{title}</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
    </Modal>
  );
};

ShareModal.defaultProps = {
  size: 'lg',
  title: null,
};

ShareModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
};

export default ShareModal;
