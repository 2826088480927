import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors.participantFormErrors');
const validateParticipants = (values) => {
  const errors = {};

  if (!values.selectedCompany && values?.selectedUser?.hasCompany) {
    errors.selectedCompany = p('companyFieldCantBeEmpty');
  }

  if (!values.owner && !values.firstName) {
    errors.owner = p('userFieldCantBeEmpty');
  }

  if (!values.firstName) {
    errors.firstName = p('firstNameFieldCantBeEmpty');
  }

  if (!values.lastName) {
    errors.lastName = p('lastNameFieldCantBeEmpty');
  }
  return errors;
};

export default validateParticipants;
