import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { Pie, HorizontalBar } from 'react-chartjs-2';

import QuestionsStatsModel from './QuestionsStatsModel';
import QuestionType from './QuestionnaireBuilder/QuestionType';
import Api from '../../../util/api';
import Loading from '../../shared/Spinner';

import { partial } from '../../../util/translation/translation';

const QuestionsStatsCard = ({ eventId }) => {
  const [totalResponses, setTotalResponses] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupedAnswers, setGroupedAnswers] = useState({});
  const [questionNumber, setQuestionNumber] = useState(0);
  const [questionKey, setQuestionKey] = useState('');
  const [questionValues, setQuestionValues] = useState([]);
  const qS = partial('question');

  const textFieldQuestionLimit = 5;

  const groupAnswers = async () => {
    const answers = await Api.questions.fetchAnswers(eventId);
    if (answers && answers.data.length > 0) {
      setIsLoading(true);
      setTotalResponses(answers.data.length);
      const answerArr = [];
      answers.data.forEach((answerObj) => {
        answerObj?.answer?.forEach((answer) => {
          if (answer?.guest !== true) {
            answerArr.push(answer);
          }
        });
      });
      // Group the data by question
      const groupedData = answerArr.reduce((result, item) => {
        // Check if there's already an array for this question, if not, create one
        if (!result[item.question]) {
          // eslint-disable-next-line no-param-reassign
          result[item.question] = [];
        }

        // Push the current item to the array with the corresponding key
        result[item.question].push(item);

        return result;
      }, {});

      setGroupedAnswers(groupedData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const clearAnswers = () => {
    setGroupedAnswers({});
    setTotalResponses(0);
  };

  useEffect(() => {
    groupAnswers();
    return () => {
      clearAnswers();
    };
  }, []);

  const handleCloseModal = () => {
    setQuestionValues([]);
    setQuestionKey('');
    setShowModal(false);
  };

  const renderDropDownQuestions = (key) => {
    const values = groupedAnswers[key]; // all user answers for key question
    const labels = []; // labels used in chart
    const answerArray = [];
    const count = {}; // calcualted count of each answer

    values.forEach((value) => {
      answerArray.push(value.answer); // push all user answers to one array
      value?.choices?.forEach((i) => {
        const found = labels.find((element) => element === i.choice);
        if (!found) {
          labels.push(i.choice); // push all choice to one array
        }
      });
    });

    // remove empty values and count an answers in answerArray
    answerArray
      .filter((item) => !!item)
      .forEach((a) => {
        count[a] = (count[a] || 0) + 1;
      });

    const data = []; // data used in chart (values in label order)
    labels.forEach((label) =>
      data.push(
        count[label] !== '' && count[label] !== undefined ? count[label] : 0,
      ),
    );

    const chartOptions = {
      responsive: true,
      legend: {
        labels: {
          boxWidth: 16,
        },
        position: 'right',
      },
      maintainAspectRatio: false,
    };

    // customLabels to display lable with value
    const customLabels = labels.map(
      (label, index) => `${label}: ${data[index]}`,
    );

    return (
      <div className="chart-container">
        <div className="chart-style">
          <Pie
            data={{
              datasets: [
                {
                  data,
                  backgroundColor: [
                    '#EF6C6C',
                    '#8D66F5',
                    '#2388FB',
                    '#47D78A',
                    '#F0B26A',
                  ],
                  borderWidth: 0,
                },
              ],
              labels: customLabels,
            }}
            options={chartOptions}
          />
        </div>
      </div>
    );
  };

  const renderTextFieldQuestions = (key) => {
    const values = groupedAnswers[key];
    const questionIndex = Object.keys(groupedAnswers).indexOf(key);
    return (
      <div className="container">
        <Row>
          <div className="recent-answers">
            <p>{qS('recentAnswers')}</p>
          </div>
        </Row>
        {values.map(
          (item, index) =>
            item.answer !== '' &&
            index < textFieldQuestionLimit && (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index}>
                <div className="answer-box">
                  <p>{item.answer}</p>
                </div>
              </Row>
            ),
        )}

        {values && values.length > textFieldQuestionLimit && (
          <Row className="justify-content-center">
            <div style={{ width: 200, marginTop: 8 }}>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  setQuestionNumber(questionIndex + 1);
                  setQuestionValues(values);
                  setQuestionKey(key);
                  setShowModal(true);
                }}
              >
                {qS('viewAllResponses')}
              </Button>
            </div>
          </Row>
        )}
      </div>
    );
  };

  const renderCheckboxQuestions = (key) => {
    const values = groupedAnswers[key]; // all user answers for key question
    const labels = []; // labels used in chart
    const answerArray = [];
    values.forEach((value) => {
      value?.answer?.forEach((answer) => answerArray.push(answer));
      value?.choices?.forEach((i) => {
        const found = labels.find((element) => element === i.choice);
        if (!found) {
          labels.push(i.choice); // push all choice to one array
        }
      });
    });

    const count = {}; // calcualted count of each answer

    // remove empty values and count an answers in answerArray
    answerArray
      .filter((item) => !!item)
      .forEach((a) => {
        count[a] = (count[a] || 0) + 1;
      });

    const data = []; // data used in chart (values in label order)
    labels.forEach((label) =>
      data.push(
        count[label] !== '' && count[label] !== undefined ? count[label] : 0,
      ),
    );

    const chartOptions = {
      responsive: true,
      legend: {
        labels: {
          boxWidth: 16,
        },
        position: 'right',
      },
      maintainAspectRatio: false,
    };

    return (
      <div className="chart-container">
        <div className="chart-style">
          <HorizontalBar
            options={chartOptions}
            data={{
              labels,
              datasets: [
                {
                  data,
                  maxBarThickness: 16,
                  backgroundColor: '#2388FB',
                  label: '',
                },
              ],
            }}
          />
        </div>
      </div>
    );
  };

  // Quetions number variable
  let itemID = 0;
  return (
    <div>
      <Row className="row align-center">
        <Col className="col-10">
          <p className="total-responses">
            {qS('totalResponses')} : <b>{totalResponses}</b>
          </p>
        </Col>
        <Col className="col-2">
          {/* <Button
            onClick={() => {}}
            outline
            style={{ color: '#0469DC', fontWeight: 600 }}
            className="flex align-center margin-0"
          >
            <ExportVariantIcon
              style={{
                height: '20px',
                width: 'auto',
                fill: '#0469DC',
                position: 'relative',
                top: '-2px',
              }}
            />
            {qS('exportCSV')}
          </Button> */}
        </Col>
      </Row>
      {isLoading ? (
        <Loading />
      ) : (
        Object.entries(groupedAnswers).map(([key, value]) => {
          itemID += 1;

          const answerLength = value.filter((item) => item.answer !== '');

          return (
            <div
              key={`question-${key}`}
              className="summary-question-card card-margin"
            >
              <div className="content-area">
                <Row>
                  <Col className="question-header">
                    <div className="question-id">
                      <p>{itemID}</p>
                    </div>
                    <div className="question-description">
                      <h5>{key}</h5>
                      <p>
                        {answerLength.length} {qS('answers')} {value.length}{' '}
                        {qS('users')}
                      </p>
                    </div>
                  </Col>
                  <Col className="col-3 d-flex justify-content-end">
                    <QuestionType type={value[0].question_type} />
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="content-area">
                <div className="question-content">
                  {value[0].question_type === 'text_field' &&
                    renderTextFieldQuestions(key)}

                  {value[0].question_type === 'dropdown' &&
                    renderDropDownQuestions(key)}

                  {value[0].question_type === 'radio_button' &&
                    renderDropDownQuestions(key)}

                  {value[0].question_type === 'checkbox' &&
                    renderCheckboxQuestions(key)}
                </div>
              </div>
            </div>
          );
        })
      )}
      <QuestionsStatsModel
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        questionNumber={questionNumber}
        questionKey={questionKey}
        questionValues={questionValues}
      />
    </div>
  );
};

export default QuestionsStatsCard;
