import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RenderDropZoneField from '../../../shared/components/form/DropZone';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';

const ControlImagesForm = ({ handleSubmit, initialize, controlImage }) => {
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  useEffect(() => {
    if (controlImage) {
      initialize({
        name: controlImage.name,
        image: [{
          preview: controlImage.control_image_data,
          initialized: true,
        }],
      });
    }
  }, [controlImage]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Col md={12}>
        <div className="form__form-group">
          <span className="form-header">{p('pictureName')}</span>
          <Field
            name="name"
            component={renderField}
            placeholder={p('pictureName')}
            disabled={!!controlImage}
          />
        </div>
      </Col>
      <Col lg={6} md={12}>
        <div className="form__form-group">
          <span className="form-header">{p('pictureGIF')}</span>
          <Field
            name="image"
            component={RenderDropZoneField}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button color="primary">{s('save')}</Button>
        <Link to="/settings?tab=4">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

ControlImagesForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  controlImage: PropTypes.shape({
    name: PropTypes.string,
    control_image_data: PropTypes.string,
  }),
};

ControlImagesForm.defaultProps = {
  controlImage: null,
};

const reduxF = reduxForm({
  form: 'control_images_form',
  // validate,
});

export default withRouter(reduxF(ControlImagesForm));
