import React, { useState } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import CategoryForm from './components/CategoryForm';
import Loading from '../../../../shared/components/custom/Loading';
import Api from '../../../../util/api';
import { partial } from '../../../../util/translation/translation';

const p = partial('product&CategoryShare');
const CategoryCreatePage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (form) => {
    const payload = {
      name: form.name,
    };

    setLoading(true);
    try {
      await Api.cnc.createCategory(payload);
      toast.success(`${p('categoryCreated')}`);
      setLoading(false);
      history.replace('/cnc?tab=2');
    } catch (err) {
      toast.error(err || `${p('categoryCreatingERROR')}`);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col md={12}>
        <h3 className="page-title">{p('newCategory')}</h3>
      </Col>
      <Col md={12}>
        <Card>
          <CardBody>
            <CategoryForm onSubmit={handleSubmit} />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default CategoryCreatePage;
