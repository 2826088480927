import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');

const validateSegment = (values) => {
  const errors = {};
  if (!values?.name) {
    errors.name = p('nameFieldEmpty');
  }
  return errors;
};

export default validateSegment;
