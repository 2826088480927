export default {
  club_name: 'Cardiff Devils',
  club_name_short: 'CAR',
  stadium_name: 'Ice Arena Wales',
  staging_url: 'https://cardiff.api.fangroup.io',
  prod_url: 'https://cardiff.api.fangroup.io',
  api: '/api',
  facebook: 'CardiffDevils',
  basename: '/cardiff',
  club_logo: '/assets/teams/cardiff.png',
  app_name: 'cardiff',
  websale_name: 'cardiff',
  ntb_team_id: 3683,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'GBP',
  currencyShort: '£',
  currencyShortSmall: '£',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'cardiff',
  monolithEnabled: false,
};
