import { atom } from 'recoil';
import { getDefault } from '../../util/translation/translation';

const getDef = () => {
  const def = getDefault();
  localStorage.setItem('language', def);
  return def;
};

const getLanguage = () => {
  const lang = localStorage.getItem('language') || getDef();
  return lang === 'undefined' ? getDef() : lang; // retarded safari
};

const userLanguage = atom({
  key: 'userLanguage',
  default: getLanguage(),
});

export default userLanguage;
