export default {
  club_name: 'Timrå IK',
  club_name_short: 'timra',
  stadium_name: '',
  staging_url: 'https://timra.api.fangroup.io',
  prod_url: 'https://timra.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/timra',
  club_logo: '/assets/teams/timra.png',
  app_name: 'timra',
  websale_name: 'timra',
  ntb_team_id: 10055,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'timra',
  monolithEnabled: false,
};
