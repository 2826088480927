import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuestionItem from './QuestionItem';
import useConfig from '../../../util/useConfig';
import { optionsAvailable } from './utils';
import { partial } from '../../../util/translation/translation';


const QuestionBuilder = ({
  pickedOptions, setPickedOptions, type,
}) => {
    const [availableTeams, setAvailableTeams] = useState([]);
    const config = useConfig();
  const p = partial('questionaire');
    useEffect(() => {
        if (config && config.teams?.length > 0 && type?.value) {
            const mappedTeams = config.teams.map((team) => ({ label: team.name, value: team.id }));
            setAvailableTeams(mappedTeams);
        }
    }, [config, type]);
    const removeIndicator = () => {
        document.querySelectorAll('.picked-question-card').forEach((element) => {
            element?.parentNode.classList.remove('prepend-indicator', 'append-indicator');
        });
    };

    const handleOnDrag = ({ source, destination }) => {
        if (!destination) return;
        removeIndicator();
        const sourceQuestion = pickedOptions[source.index];
        const updatedPickedQuestions = [...pickedOptions];
        updatedPickedQuestions.splice(source.index, 1);
        updatedPickedQuestions.splice(destination.index, 0, sourceQuestion);
        setPickedOptions(updatedPickedQuestions);
    };

    const handleMoveQuestion = (question, direction) => {
        const index = pickedOptions.findIndex((q) => q.id === question.id);
        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= pickedOptions.length) return;

        const updatedQuestions = [...pickedOptions];
        [updatedQuestions[index], updatedQuestions[newIndex]] = [updatedQuestions[newIndex], updatedQuestions[index]];

        setPickedOptions(updatedQuestions);
    };
    const handleAddOption = () => {
        setPickedOptions((prev) => {
            return [...prev, {
                id: prev.length,
                type: type?.value,
                value: '',
            }];
        });
    };
    const removeQuestion = (index) => {
        const optionsCopy = [...pickedOptions];
        optionsCopy.splice(index, 1);
        setPickedOptions(optionsCopy);
    };
    const editOption = (index, value) => {
        const optionsCopy = [...pickedOptions];
        optionsCopy[index].value = value;
        setPickedOptions(optionsCopy);
    };
    return (
      <DragDropContext onDragEnd={handleOnDrag}>
        <Col>
          <Droppable droppableId="pick">
            {(provided) => (
              <div>
                <div className="question-answers-list flex-column" ref={provided.innerRef} {...provided.droppableProps}>
                  {pickedOptions.map((q, index) => (
                    <Draggable key={q.id} draggableId={`pick-${q.id}`} index={index}>
                      {(draggableProvided) => (
                        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                          <QuestionItem
                            question={q}
                            onDelete={() => removeQuestion(index)}
                            onMoveUp={() => handleMoveQuestion(q, -1)}
                            onMoveDown={() => handleMoveQuestion(q, 1)}
                            handleInput={(value) => editOption(index, value)}
                            type={type?.value}
                            availableTeams={availableTeams}
                          />
                        </div>
                                      )}
                    </Draggable>
                              ))}
                  {provided.placeholder}
                </div>
                {optionsAvailable(type?.value) &&
                  <div
                    style={{ color: 'blue' }}
                    onClick={handleAddOption}
                    role="button"
                    tabIndex={0}
                  >{p('addOption')}
                  </div>}
              </div>
            )}
          </Droppable>
        </Col>
      </DragDropContext>
    );
};

export default QuestionBuilder;



