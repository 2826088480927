import React, { useState } from 'react';
import { Container, TabContent, TabPane } from 'reactstrap';
import Loading from '../../shared/components/custom/Loading';

import { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import QuestionaireListPage from './QuestionaireListPage';
import QuestionListPage from './QuestionListPage';

const QuestionairePage = () => {
  const [questionairesLoading, setQuestionairesLoading] = useState(true);
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const p = partial('questionaire');
  return (
    <Container>
      <Loading loading={questionairesLoading || questionsLoading} />
      <NavTab
        activeTab={tab}
        tabs={[
          p('questionaires'),
          p('questions'),
        ]}
        setTab={setTab}
      />
      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <QuestionaireListPage setLoading={setQuestionairesLoading}/>
        </TabPane>
        <TabPane tabId="2">
          <QuestionListPage setLoading={setQuestionsLoading}/>
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default QuestionairePage;
