import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';

const transformSeasonSales = (sales) => {
  const season = [];
  const elements = {};
  const priceGroupsElementsArray = [];
  sales.forEach((seasonSale) => {
    season.push({
      name: seasonSale.name,
      turnover: seasonSale.turnover,
    });
    elements[seasonSale.name] = seasonSale.prices;
    priceGroupsElementsArray.push(seasonSale.prices);
  });
  const priceGroupsElementsFlat = priceGroupsElementsArray.flat();
  const priceGroups = [...new Set(priceGroupsElementsFlat.map(elem => elem.title))];

  return [season, elements, priceGroups];
};

const SeasonTicketsReport = () => {
  const [loadingSeasonTicketSales, setLoadingSeasonTicketsales] = useState(true);
  const [seasonTicketSalesHeaders, setSeasonTicketSalesHeaders] = useState([]);
  const [seasonTicketsReport, setSeasonTicketsReport] = useState({ pricegroups: [], seasonNames: [], elements: {} });
  const s = partial('shared');
  const p = partial('SeasonTicketsReport');

  const getSeasonTicketSales = async () => {
    try {
      const { data: seasonSales } = await Api.dashboard.getSeasonTicketSales();
      const [seasonNames, elements, priceGroups] = transformSeasonSales(seasonSales);
      setSeasonTicketSalesHeaders(priceGroups);
      setSeasonTicketsReport({ seasonNames, elements, priceGroups });
      setLoadingSeasonTicketsales(false);
    } catch (err) {
      toast.error(err || p('fetcingSeasonCardSaleFailed'));
      setLoadingSeasonTicketsales(false);
    }
  };

  useEffect(() => {
    getSeasonTicketSales();
  }, []);

  const getTotalCountFromPriceGroupAndSeason = (pricegroup, season) => {
    if (seasonTicketsReport?.elements?.[season]) {
      return seasonTicketsReport.elements[season].reduce((total, curr) => total + curr.amount, 0);
    }
    return 0;
  };

  const getCountFromPriceGroupAndSeason = (pricegroup, season) => {
    let returnAmount = 0;
    const foundObject = seasonTicketsReport.elements[season].find(it => it.title === pricegroup);
    if (foundObject) {
      returnAmount = foundObject.amount;
    }
    return returnAmount;
  };

  const listHeaderPriceGroups = () => {
    return seasonTicketSalesHeaders.map(seasonTicket => (
      <th key={`listHeaderPriceGroups-${seasonTicket}`}>{seasonTicket}</th>
    ));
  };

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  const listPriceGroups = () => {
    return seasonTicketsReport.seasonNames.map((season) => {
      return (
        <tr key={`season-${season.name}`}>
          <td>{season.name}</td>
          { seasonTicketsReport.priceGroups.map(seasonPriceGroup => (
            <React.Fragment key={`priceGroups-${season}-${seasonPriceGroup}`}>
              <td>
                { getCountFromPriceGroupAndSeason(seasonPriceGroup, season.name) }
              </td>
            </React.Fragment>
          ))}
          <td>{ getTotalCountFromPriceGroupAndSeason(null, season.name) }</td>
          <td>{numberWithSpaces(season?.turnover ?? 0)}
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <Loading loading={loadingSeasonTicketSales} />
      <div className="card__title margin-0">
        <p className="bold-text marginBottom-30">{p('salesReportSeasonCardSaleCaps')}</p>
      </div>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{s('season')}</th>
            { listHeaderPriceGroups() }
            <th>{s('total')}</th>
            <th>{s('turnover')}</th>
          </tr>
        </thead>
        <tbody>
          { listPriceGroups() }
        </tbody>
      </Table>
    </>
  );
};

export default SeasonTicketsReport;
