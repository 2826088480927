import React, { useState, useMemo } from 'react';
import {
  Container,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getFormValues } from 'redux-form';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import t from '../../util/translation/translation';
import EditScheduledMessageForm from './components/EditScheduledMessageForm';
import { getMessageType, getTitleAndBody, mapTitleAndBodyToPlaceholders } from '../../shared/components/messageList/utils';


const MessagesFormPage = () => {
  const history = useHistory();
  const { state: messageState } = useLocation();
  const [loading, setLoading] = useState(false);
  const messageValues = useMemo(() => {
      const res = getTitleAndBody(messageState);
      return {
        ...res,
        scheduledDate: messageState.date,
      };
    });
    const messageType = getMessageType(messageState);
  const handleEditMessage = async (values) => {
    setLoading(true);
    try {
        const payload = {
            ...messageState.payload,
            scheduledDate: values.scheduledDate,
            placeholders: {
                ...messageState.payload.placeholders,
                ...mapTitleAndBodyToPlaceholders(messageState, values.title, values.body),
            },
        };
     await Api.scheduleMessages.updateMessage(payload, messageState.id);
      setLoading(false);
      toast.success(t('ScheduledMessagesPage.scheduleMessageUpdated'));
      history.push('/messages?tab=2');
    } catch (err) {
      toast.error(err || 'error');
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <Card>
          <CardBody>
            <EditScheduledMessageForm
              onSubmit={handleEditMessage}
              messageValues={messageValues}
              type={messageType}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default withRouter(connect(state => ({
  smsForm: getFormValues('send_messages_form')(state),
  me: state.admin.me,
}))(MessagesFormPage));
