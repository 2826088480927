import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Badge, UncontrolledDropdown,
  DropdownMenu, DropdownToggle, DropdownItem,
  UncontrolledTooltip, Table, Button, Row,
} from 'reactstrap';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import AlertIcon from 'mdi-react/AlertIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TooltipText from 'mdi-react/TooltipTextIcon';
import CheckboxMarkedCircle from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircle from 'mdi-react/CloseCircleIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import UserIcon from 'mdi-react/UserIcon';
import Select from 'react-select';
import Checkbox from '../../../../../shared/components/custom/Checkbox';
import Pagination from '../../../../../shared/components/pagination/Pagination';
import { partial } from '../../../../../util/translation/translation';



const SeasonTicketsList = ({
  seasonTickets, selectedField, selectedRow, handleField, handleRow,
  searchText, handleInput, handleSearch, fields, rows, onChangePage,
  handleReleaseTicketsModal, handleShareTicketsModal, handleToggle,
  seasons, selectedSeason, handleSeason, handlePrint, setEditUsersTickets,
  editUsersTickets, sort, updateSort, populateTickets, filter, setFilter,
  ticketStates, seasonPrices, options, companies,
}) => {
  const [masterToggled, setMasterToggled] = useState(false);
  const [shareableTickets, setShareableTickets] = useState(false);
  const [ticketsAreShared, setTicketsAreShared] = useState(false);
  const p = partial('SeasonTicketsList');
  const d = partial('TicketStates');
  const s = partial('shared');
  const t = partial('MatchTicketsList');


  useEffect(() => {
    const toggled = seasonTickets.data.filter(seasonTicket => seasonTicket.toggled);
    setMasterToggled(seasonTickets.data.every(seasonTicket => seasonTicket.toggled));
    setShareableTickets(toggled.length > 0 && toggled.filter(seasonTicket => seasonTicket.user_id).length === 0);
    setTicketsAreShared(toggled.filter(seasonTicket => !!seasonTicket.user_id).length > 0);
    setEditUsersTickets(toggled.every(ticket => ticket.user_id != null) && toggled.length > 0);
  }, [seasonTickets]);

  const badgeColor = (ticketState) => {
    switch (ticketState) {
      case 'ACTIVE': return 'success';
      case 'REDEEMED': return 'warning';
      case 'INACTIVE': return 'info';
      case 'EXPIRED': return 'dark';
      case 'SENDING': return 'primary';
      case 'RESERVED': return 'danger';
      case 'RESERVED/RELEASABLE': return 'danger';
      case 'RELEASED': return 'dark';
      default: return 'dark';
    }
  };

  const translatePayment = (seasonTicket) => {
    if (seasonTicket.assigned_by_id && !seasonTicket.payment_id) {
      return `${p('assignedBy')} ${seasonTicket.assigned_by_firstname} ${seasonTicket.assigned_by_lastname}`;
    }
    switch (seasonTicket.payment_method) {
      case 'Billable': return `${s('invoice')} (${seasonTicket.company_name})`;
      case 'Vipps': return `${seasonTicket.came_from} - Vipps`;
      case 'Kiosk': return p('kiosk');
      case 'DagsVip': return s('dayVIP');
      case 'Websale': return 'Web - Vipps';
      case 'Saldo': return `${seasonTicket.came_from} - Saldo`;
      case 'Stripe': return `${seasonTicket.came_from} - Stripe`;
      case null: return '-';
      default: return p('unknownPaymentMethod');
    }
  };

  const handleDeleteTickets = () => {
    const toggledTickets = seasonTickets.data.filter(seasonTicket => seasonTicket.toggled);
    handleReleaseTicketsModal(true, toggledTickets);
  };

  const handleMassAssign = () => {
    const toggledTicketIDs = seasonTickets.data.filter(seat => seat.toggled);
    handleShareTicketsModal(true, toggledTicketIDs);
  };

  const renderSeasonDropdown = () => {
    return seasons.map(season => (
      <DropdownItem key={`season-${season.id}`} onClick={() => handleSeason(season)}>{s('season')} {season.name}</DropdownItem>
    ));
  };

  const renderFieldDropdown = () => {
    return fields.map(field => (
      <DropdownItem onClick={() => handleField(field)} key={`fieldDropdown-${field.id}`}>{s('field')} {field.name}</DropdownItem>
    ));
  };

  const renderRowDropdown = () => {
    return rows.map(row => (
      <DropdownItem onClick={() => handleRow(row)} key={`rowDropdown-${row.id}`}>
        {`${s('row')} ${typeof row.number === 'number' ? row.number : s('all')}`}
      </DropdownItem>
    ));
  };

  const renderCheckIcon = state => (state ?
    <CheckboxMarkedCircle size={12} className="icon-min-20" color="#399351" />
    :
    <CloseCircle size={12} className="icon-min-20" color="#FF4861" />
  );

  const renderTags = (tags) => {
    if (!tags) {
      return '-';
    }
    const tagSplit = tags.split(',');
    return tagSplit.map(tag => <Badge key={`tag-${tagSplit.id}`} style={{ marginLeft: '10px' }} color="square-tags">{tag}</Badge>);
  };

  const renderClassNameRows = (toggled, seatDisabledGlobally) => {
    if (toggled) {
      return 'listSelected transitionDuration-03 overflow-visible-hard';
    }
    if (seatDisabledGlobally) {
      return 'listDisabled transitionDuration-03 overflow-visible-hard';
    }
    return 'transitionDuration-03 overflow-visible-hard';
  };

  const getSortIcon = (_sort, type) => {
    if (_sort.type !== type) {
      return null;
    } else if (_sort.order === 'asc') {
      return <ArrowDownIcon size={16} />;
    }
    return <ArrowUpIcon size={16} />;
  };

  const renderFilterDropdown = (key, items) => {
    return items.map(item => (
      <DropdownItem
        onClick={() => setFilter((prev) => ({
          ...prev,
          [key]: item,
        }))}
        key={`${key}-${item.value}`}
      >{item.label}
      </DropdownItem>
    ));
  };

  return (
    <Card>
      <CardBody style={{ maxHeight: 'fit-content', overflow: 'visible' }}>
        <div className="card__title marginTop-0">
          <Row>
            <div style={{ display: 'flex' }}>
              <UncontrolledDropdown className="mb-3">
                <DropdownToggle className="icon icon--right" outline>
                  <p>{s('season')} {selectedSeason?.name}</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  {renderSeasonDropdown()}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="mb-3">
                <DropdownToggle className="icon icon--right" outline>
                  <p>{s('field')} {selectedField?.name ?? s('all')} <ChevronDownIcon /></p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem onClick={() => handleField(null)}>
                    {s('field')} {s('all')}
                  </DropdownItem>
                  {renderFieldDropdown()}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="mb-3">
                <DropdownToggle className="icon icon--right" outline>
                  <p>
                    {s('row')} {selectedRow?.number ?? s('all')}
                    <ChevronDownIcon />
                  </p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu tickets">
                  <DropdownItem onClick={() => handleRow(null)}>
                    {s('row')} {s('all')}
                  </DropdownItem>
                  {renderRowDropdown()}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="mb-3">
                <DropdownToggle className="icon icon--right" outline>
                  <p>{t('ticketStatus')} {filter?.ticketState?.label ?? s('all')}<ChevronDownIcon /></p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  {renderFilterDropdown('ticketState', [{ label: s('all'), value: null }, ...ticketStates])}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="mb-3">
                <DropdownToggle className="icon icon--right" outline>
                  <p>{s('priceGroup')} {filter?.seasonPrice?.label ?? s('all')}<ChevronDownIcon /></p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  {renderFilterDropdown('seasonPrice', [{ label: s('all'), value: null }, ...seasonPrices])}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown className="marginRight-15 mb-3">
                <Select
                  className="select-filter"
                  placeholder={s('filter')}
                  options={options}
                  value={filter.options}
                  onChange={(value) => setFilter(prev => ({ ...prev, options: value }))}
                  multi
                />
              </UncontrolledDropdown>
            </div>
            <div style={{ display: 'flex' }}>
              <>
                <Link className="marginRight-15 mb-3" to={`/seasons/edit/${selectedSeason?.id}`} >
                  <Button color="primary" style={{ borderRadius: 5 }}>
                    {p('seasonPrice')}
                  </Button>
                </Link>
                {editUsersTickets &&
                <>
                  <button className=" marginRight-15 mb-3 btn btn-primary" onClick={handleMassAssign}> {p('editMultipleTickets')} </button>
                  <button className="marginRight-15 mb-3 btn btn-primary" onClick={populateTickets}>{p('populateTickets')}</button>
                </>
                }
                {shareableTickets &&
                <button className="marginRight-15 mb-3 btn btn-primary" onClick={handleMassAssign}> {s('assign')} </button>
                }
                {ticketsAreShared &&
                <button className="marginRight-15 mb-3 btn btn-danger" onClick={handleDeleteTickets}> {p('releaseSeat')} </button>
                }
              </>
              <div className="form noWrap">
                <div className="form__form-group products-list__search flex">
                  <input
                    value={searchText}
                    placeholder={`${s('search')}...`}
                    name="search"
                    onChange={handleInput}
                    onKeyPress={handleSearch}
                  />
                </div>
              </div>
            </div>
          </Row>
        </div>
        <div className="margin-20" />
        <Table responsive bordered>
          <thead>
            <tr>
              <th><Checkbox index={-1} handleToggle={() => handleToggle(-1)} toggled={masterToggled} /></th>
              <th onClick={() => updateSort('seats')}>{s('field')}/{s('row')}/{s('seat')}{getSortIcon(sort, 'seats')}</th>
              <th>{p('seatStatus')}</th>
              <th>{s('vacant')}</th>
              <th onClick={() => updateSort('owners')}>{s('owner')}{getSortIcon(sort, 'owners')}</th>
              <th onClick={() => updateSort('ticketStates')}>{p('ticketStatus')}{getSortIcon(sort, 'ticketStates')}</th>
              <th onClick={() => updateSort('paymentMethods')}>{s('paymentMethod')}{getSortIcon(sort, 'paymentMethods')}</th>
              <th onClick={() => updateSort('users')}>{s('user')}{getSortIcon(sort, 'users')}</th>
              <th onClick={() => updateSort('priceGroups')}>{s('priceGroup')}{getSortIcon(sort, 'priceGroups')}</th>
              <th onClick={() => updateSort('tags')}>{s('tags')}{getSortIcon(sort, 'tags')}</th>
              <th onClick={() => updateSort('comment')}>{s('note')}{getSortIcon(sort, 'comment')}</th>
              <th onClick={() => updateSort('total')}>{s('total')}{getSortIcon(sort, 'total')}</th>
              <th onClick={() => updateSort('shareable')}>{p('canBeShared')}{getSortIcon(sort, 'shareable')}</th>
              <th onClick={() => updateSort('vip')}>{p('hasVip')}{getSortIcon(sort, 'vip')}</th>
              <th onClick={() => updateSort('releasable')}>{p('hasPickupDeadline')}{getSortIcon(sort, 'releasable')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { seasonTickets.data.map((seasonTicket, index) => {
              const fieldName = `${seasonTicket.field_name} \xa0 ${seasonTicket.row_number} \xa0 ${seasonTicket.seat_id}`;
              const seatDisabledGlobally = seasonTicket.seat_state_id === 2;
              return (
                <tr key={`SeasonTicket-${seasonTicket.id}`} className={renderClassNameRows(seasonTicket.toggled, seatDisabledGlobally)}>
                  <td><Checkbox index={index} handleToggle={() => handleToggle(index, seasonTicket.toggled)} toggled={seasonTicket.toggled || false} /></td>
                  <td>{fieldName}</td>
                  <td>
                    {seatDisabledGlobally && (
                      <>
                        <UncontrolledTooltip
                          placement="top"
                          target="UncontrolledTooltipExample"
                        >
                          {p('seatDisabledGlobally')}
                        </UncontrolledTooltip>
                        <AlertIcon id="UncontrolledTooltipExample" />
                      </>
                    )}
                    {p(seasonTicket.seat_state_name)}
                  </td>
                  <td>{seasonTicket.assignment_collsion ? <AlertIcon color="red" size={20} /> : <CheckboxMarkedCircle size={12} className="icon-min-20" color="#399351" />}</td>
                  <td>
                    { seasonTicket.owner_id || seasonTicket.new_company_id || seasonTicket.company_id ?
                      <div className="flex" style={{ alignItems: 'center' }}>
                        <div>
                          { seasonTicket.new_company_id || seasonTicket.company_id ?
                            <DomainIcon size={12} className="icon-min-20" color="#555555" />
                            :
                            <UserIcon size={12} className="icon-min-20" color="#555555" />
                        }
                        </div>
                        {seasonTicket.new_company_id ?
                          companies.find(company => company.id === seasonTicket.new_company_id)?.name
                          :
                          `${seasonTicket.owner_firstname || seasonTicket.company_name} ${seasonTicket.owner_lastname ?? ''}`
                      }
                      </div>
                      :
                      '-'
                  }
                  </td>
                  <td>
                    {!!seasonTicket.ticket_state_name &&
                    <Badge color={badgeColor(seasonTicket.ticket_state_name)}>
                      {d(seasonTicket.ticket_state_name.toLowerCase())}
                    </Badge>
                  }
                  </td>
                  <td>{translatePayment(seasonTicket)}</td>
                  <td>
                    { seasonTicket.user_id ?
                      <div className="flex" style={{ alignItems: 'center' }}>
                        <div>
                          <UserIcon size={12} className="icon-min-20" color="#555555" />
                        </div>
                        {seasonTicket.user_firstname} {seasonTicket.user_lastname}
                      </div>
                      :
                      '-'
                  }
                  </td>
                  <td>{seasonTicket.season_price_title ? seasonTicket.season_price_title : '-'}</td>
                  <td>{renderTags(seasonTicket.tags)}</td>
                  <td>
                    { seasonTicket.comment !== null &&
                    <>
                      <TooltipText className="outline-0" id={`tooltip-${seasonTicket.id}`} color="#555555" />
                      <UncontrolledTooltip placement="top" target={`tooltip-${seasonTicket.id}`}>
                        {seasonTicket.comment}
                      </UncontrolledTooltip>
                    </>
                  }
                  </td>
                  <td>{seasonTicket.total !== null && renderCheckIcon(seasonTicket.total)}</td>
                  <td>{seasonTicket.shareable !== null && renderCheckIcon(seasonTicket.shareable)}</td>
                  <td>{seasonTicket.vip !== null && renderCheckIcon(seasonTicket.vip)}</td>
                  <td className="center-text">{seasonTicket.releasable !== null && renderCheckIcon(seasonTicket.releasable)}</td>
                  <td>
                    { seasonTicket.season_ticket_id
                      ? (
                        <UncontrolledDropdown className="dashboard__table-more">
                          <DropdownToggle>
                            <DotsHorizontalIcon />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown__menu">
                            <Link to={`/tickets/season/edit/${seasonTicket.season_ticket_id}${window.location.search}&available=${seasonTicket.assignment_collsion}`}>
                              <DropdownItem>{s('edit')}</DropdownItem>
                            </Link>
                            <DropdownItem onClick={() => handlePrint(seasonTicket.season_ticket_id)}>{s('print')}</DropdownItem>
                            <DropdownItem onClick={() => handleReleaseTicketsModal(true, [seasonTicket])} className="danger">{s('delete')}</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ) : (
                        <div className="flex-centerVertHort">
                          <Link
                            to={`/tickets/season/assign/${seasonTicket.id}${window.location.search}&available=${!seasonTicket.assignment_collsion}`}
                            className="outline-badge success"
                          >
                            {s('assign')}
                          </Link>
                        </div>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Pagination
          items={seasonTickets.total || 0}
          rowsPerPage={[100, 250, 500]}
          onChangePage={onChangePage}
        />
      </CardBody>
    </Card>
  );
};

SeasonTicketsList.propTypes = {
  seasonTickets: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  searchText: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedField: PropTypes.shape({}),
  selectedRow: PropTypes.oneOfType([
    PropTypes.shape({}),
  ]),
  handleField: PropTypes.func.isRequired,
  handleRow: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReleaseTicketsModal: PropTypes.func.isRequired,
  handleShareTicketsModal: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  setEditUsersTickets: PropTypes.func.isRequired,
  editUsersTickets: PropTypes.bool.isRequired,
  selectedSeason: PropTypes.shape({
    name: PropTypes.string,
  }),
  seasons: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  handleSeason: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    order: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  updateSort: PropTypes.func.isRequired,
  populateTickets: PropTypes.func.isRequired,
};

SeasonTicketsList.defaultProps = {
  selectedSeason: null,
  selectedField: null,
  selectedRow: null,
};

export default SeasonTicketsList;
