import { partial } from '../../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  if (!values.fieldType || !values.fieldType) {
    errors.fieldType = p('fieldTypeEmpty');
  }
  return errors;
};

export default validate;
