/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import UpcomigMatchForm from './UpcomigMatchForm';
import useConfig from '../../util/useConfig';
import Api from '../../util/api';
import t, { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import FanRating from './FanRating';
import MatchContactForm from './components/MatchContactForm';
import Loading from '../../shared/components/custom/Loading';

const UpcomigMatchFormPage = () => {
  const mc = partial('matchCenter');
  const mf = partial('matchCenter.form');
  const s = partial('shared');
  const p = partial('EventsFormPage');
  const [tab, setTab] = useState('');

  const [loading, setLoading] = useState(false);
  const [prizesLoading, setPrizesLoading] = useState(false);
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [scheduleMessagesForMatch, setScheduleMessagesForMatch] = useState([]);
  const [numberOfPendingMessagesPerMatch, setNumberOfPendingMessagesPerMatch] =
    useState([]);
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] =
    useState(0);
  const [availablePrizes, setAvailablePrizes] =
    useState([]);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [clearContactPage, setClearContactPage] = useState(false);
  const [startDate, setStartDate] = useState();
  const { matchID } = useParams();
  const history = useHistory();
  const config = useConfig();

  const { data: match } = useQuery({
    queryKey: ['matchinfo', 'match', matchID],
    queryFn: () => Api.matchCenter.getUpcomingMatch(matchID),
    enabled: !!matchID,
    select: data => data?.data,
  });

  const getScheduleMessagesByServiceId = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      let messageByMatchId = [];

      if (messageStatus === t('ScheduledMessagesPage.filterByStatus')) {
        messageByMatchId =
          await Api.scheduleMessages.getScheduleMessagesByServiceId(
            matchID,
            page,
            pageSize,
          );
      } else {
        messageByMatchId =
          await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(
            matchID,
            page,
            pageSize,
            messageStatus,
          );
      }

      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerMatch(messageByMatchId?.data?.total);
      }

      setScheduleMessagesForMatch(messageByMatchId.data.data);
      setTotalAmountOfScheduledMessages(messageByMatchId.data.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${t('ScheduledMessagesPage.GetByOfferIdError')}`);
    }
  };
  const fetchPrizes = async () => {
    setPrizesLoading(true);
    try {
      const prizeResults = await Api.mvpPrize.getAllPrizes();
      setAvailablePrizes(prizeResults.map((item) => ({
        label: item.title,
        value: item.id,
      })));
      setPrizesLoading(false);
    } catch (err) {
      setPrizesLoading(false);
      toast.error(err || `${t('ScheduledMessagesPage.GetByOfferIdError')}`);
    }
  };


  useEffect(() => {
    if (matchID) {
      getScheduleMessagesByServiceId();
    }
  }, [matchID, messageStatus]);
  useEffect(() => {
    fetchPrizes();
  }, [matchID]);


  const getTeamId = () => {
    if (!match || match.isCustom) {
      return '';
    }
    const homeTeam = match.homeTeam.id;
    const awayTeam = match.awayTeam.id;
    const teamId =
      config.teams.find((team) => team.id === homeTeam || team.id === awayTeam)
        ?.id ?? '';
    return teamId;
  };

  const isCustomMatch = match?.isCustom || matchID === undefined;
  const handleSubmit = async (values) => {
    let ticket = {};
    let links = [];
    const playerRatingCompany = {
      companyId: values?.company?.value,
      teamId: getTeamId(),
      type: values.scope,
    };
    if (values.ticket_name) {
      ticket = {
        name: values.ticket_name,
        link: values.ticket_link,
        externalId: values.externalId,
      };
    }
    if (values?.links?.length > 0 && Object.values(values.links[0]).length > 0) {
      links = values.links.map((link) => ({
        name: link.link_name,
        link: link.link_link,
        type: 'external',
      }));
    }
    const settingPayload = {
      visibleInBanner: values.visibleInBanner,
      sponsorId: values?.sponsor_name?.value,
      sponsorName: values?.sponsor_name?.label,
      sponsorImage: values.sponsor_image,
      companyLogo: values.sponsor_logo,
      gamecenter: values.gamecenter,
      mvp: values.mvp,
      playpredict: values.playpredict,
      lineup: values.lineup,
      tickets: values.showTickets,
      matchSponsor: values.showMatchSponsor,
      playerRatingCompany,
      ticket,
      links,
      prizes: values?.prizes?.map((item) => item.value) || [],
    };
    console.log(settingPayload, values.sponsor_logo);
    try {
      if (isCustomMatch) {
        const payloadCustom = {
          homeTeam: values.homeTeam,
          homeTeamLogo: values?.homeTeamLogo?.croppedBase64 || values?.homeTeamLogo?.preview,
          awayTeam: values.awayTeam,
          awayTeamLogo: values?.awayTeamLogo?.croppedBase64 || values?.awayTeamLogo?.preview,
          matchStartsAt: moment(values.matchStartsAt).format('YYYY-MM-DDTHH:mm:ss'),
          matchStatus: values.matchStatus.value,
          matchType: values.matchType,
          matchTypeLogo: values?.matchTypeLogo?.croppedBase64 || values?.matchTypeLogo?.preview,
          homeTeamScore: values.homeTeamScore,
          awayTeamScore: values.awayTeamScore,
          settings: settingPayload,
          administrativeTeamId: config.teams?.[0]?.id,
        };
        setLoading(true);
        if (matchID) {
          await Api.matchCenter.updateCustomMatch(matchID, payloadCustom);
        } else {
          await Api.matchCenter.createCustomMatch(payloadCustom);
        }
      } else {
        await Api.matchCenter.updateUpcomingMatch(config.id, match.id, settingPayload);
      }
      setLoading(false);
      toast.success(mf('matchHasBeenUpdated'));
      history.goBack();
    } catch (error) {
      setLoading(false);
      toast.error(mf('somethingWentWrong'));
    }
  };


  const checkCount = async (values) => {
    const payload = {
      type: values.type,
      to: values.to,
      send_count: true,
    };
    setLoading(true);
    try {
      const resCount = await Api.events.sendMessage(matchID, payload);
      toast.success(
        `${p('messageWillBeSentTo')} ${resCount.data === 1
          ? `${resCount.data} ${t('shared.person')}`
          : `${resCount.data} ${t('shared.persons')}`
        }`,
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const handleSendMessage = async (payload) => {
    if (scheduleMessageID) {
      await Api.scheduleMessages.updateMessage(payload, scheduleMessageID);
    } else if (payload.scheduledDate) {
      await Api.scheduleMessages.sendMessage(payload);
    } else {
      await Api.userDevices.sendMessage(payload);
    }
  };

  const sendMessage = async (values) => {
    const payload = {
      channels: [
        {
          channel: 'push',
          templateId: 'push',
          pushLabel: values.screen?.value || null,
        },
      ],
      dryRun: false,
      recipients: [],
      userGroups: ['all groups'],
      targetAudience: {
        type: 'all',
        groups: ['all groups'],
      },
      placeholders:
      {
        body: values.content,
        title: values.title,
        url: values.screen.value,
      },
      eventInfo: {
        eventId: matchID,
      },
      senderInfo:
      {
        sender: `${config.id}`,
        type: 'Club',
      },
      scheduledDate: values.timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null,
      type: 'GENERAL',
    };

    try {
      await handleSendMessage(payload);
      if (values.timeToSend === 'SCHEDULED') {
        toast.success(t('ScheduledMessagesPage.messageScheduled'));
      } else {
        toast.success(s('sent'));
      }
    } catch (err) {
      toast.error(err);
    }
    setClearContactPage(true);
    setScheduleMessageID(null);
    getScheduleMessagesByServiceId();
  };

  return (
    <Container>
      <Loading loading={loading || prizesLoading} />
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: 0 }}>
              <NavTab
                tabs={[
                  { tab: s('match') },
                  {
                    tab: (
                      <>
                        {s('communication')}
                        {numberOfPendingMessagesPerMatch > 0 && <Badge style={{ marginLeft: 5 }} color="danger">{numberOfPendingMessagesPerMatch}</Badge>}
                      </>
                    ),
                    disabled: (!matchID || isCustomMatch),
                  },
                  { tab: mc('fanRating'), disabled: (!matchID || isCustomMatch) },
                ]}
                activeTab={tab}
                setTab={(activeTab) => setTab(activeTab)}
                size="sm"
                style={{ marginBottom: 0 }}
              />
              <TabContent activeTab={tab}>
                <TabPane style={{ padding: 10 }} tabId="1">
                  <UpcomigMatchForm match={match} isCustomMatch={isCustomMatch} onSubmit={handleSubmit} availablePrizes={availablePrizes} />
                </TabPane>
                <TabPane style={{ padding: 10 }} tabId="2">
                  <MatchContactForm
                    match={match}
                    onSubmit={sendMessage}
                    checkCount={checkCount}
                    matchID={matchID}
                    scheduleMessageID={scheduleMessageID}
                    setScheduleMessageID={setScheduleMessageID}
                    setScheduleMessagesForMatch={setScheduleMessagesForMatch}
                    scheduleMessagesForMatch={scheduleMessagesForMatch}
                    getScheduleMessagesByServiceId={
                      getScheduleMessagesByServiceId
                    }
                    totalAmountOfScheduledMessages={
                      totalAmountOfScheduledMessages
                    }
                    clearContactPage={clearContactPage}
                    setClearContactPage={setClearContactPage}
                    messageStatus={messageStatus}
                    setMessageStatus={setMessageStatus}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setLoading={setLoading}
                  />
                </TabPane>
                <TabPane style={{ padding: 10 }} tabId="3">
                  <FanRating match={match} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default UpcomigMatchFormPage;
