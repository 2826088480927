import React, { useState } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FieldTypeForm from './components/FieldTypeForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';

const CreateFieldTypePage = () => {
  const p = partial('CreateFieldTypePage');
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const newHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  };

  const handleSubmit = async (values) => {
    const payload = {
      name: values.name,
      display_name: values.displayName,
      price: values.price,
      colour: values.color.hex,
      is_unmarked: values.unmarked,
    };
    setLoading(true);
    try {
      await Api.settings.createFieldType(payload);
      toast.success(p('fieldTypeCreated'));
      setLoading(false);
      history.replace('/stadiums?tab=2');
    } catch (err) {
      toast.error(err || p('errorWhenCreatingFieldType'));
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">
          {p('newFieldType')}
        </h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <FieldTypeForm
              onSubmit={handleSubmit}
              hexToRgb={hex => hexToRgb(hex)}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default withRouter(CreateFieldTypePage);
