import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert, ButtonToolbar, Card, Col,
  DropdownItem, DropdownMenu, DropdownToggle, Row,
  Table, UncontrolledDropdown,
} from 'reactstrap';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Loading from '../../shared/components/custom/Loading';
import useModal from '../../shared/hooks/useModal';
import Api from '../../util/api';
import CheckIcon from '../../shared/components/custom/CheckIcon';
import t, { partial } from '../../util/translation/translation';
import Pagination from '../../shared/components/pagination/Pagination';
import { formatDate } from '../../util/date-and-time';
import { getReadableFormat, getReadableSize } from './spotlightUtils';



const SpotlightGroupListPage = () => {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    perPage: 10,
  });
  const [order, setOrder] = useState({
    orderBy: 'createdAt',
    desc: true,
  });
  const p = partial('HighlightBannerPage');
  const s = partial('shared');
  const getSpotlightGroups = async (currentPage = 1, pageSize = 10, orderObject = {
    orderBy: 'createdAt',
    desc: true,
  }) => {
    setLoading(true);
    try {
      const resSpotlight = await Api.spotlight.getGroups({ page: currentPage, take: pageSize }, { orderBy: orderObject.orderBy, direction: orderObject.desc ? 'DESC' : 'ASC' }, searchText);
      setGroups(resSpotlight.data);
      setLoading(false);
      setPagination(old => ({ ...old, total: resSpotlight.count }));
    } catch (err) {
      toast.error(err || p('errorFetchingSlider'));
      setLoading(false);
    }
  };
  const onChangePage = (pager) => {
    if (pager.pageSize !== pagination.perPage || pager.currentPage !== pagination.currentPage) {
      setPagination(old => ({
        ...old,
        currentPage: pager.currentPage,
        perPage: pager.pageSize,
      }));
      getSpotlightGroups(pager.currentPage, pager.pageSize, order);
    }
  };
  const [handleModal, visible, deleteId] = useModal();
  useEffect(() => {
    getSpotlightGroups();
  }, []);

  const renderHighligts = () => {
    return groups.map((group, i) => (
      <tr
        style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }}
        key={`group-${group.id}`}
      >
        <td>
          <div className="flex-Vcenter">
            <Link to={`spotlight/group/edit/${group.id}?tab=2`}>{group.title}</Link>
          </div>
        </td>
        <td>{getReadableFormat(group.format)}</td>
        <td>{getReadableSize(group.size)}</td>
        <td>
          <CheckIcon state={group.descriptionAvailable} />
        </td>
        <td style={{ textTransform: 'capitalize' }}>{formatDate(group.createdAt)}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/spotlight/group/edit/${group.id}?tab=2`}>
                <DropdownItem>{t('shared.edit')}</DropdownItem>
              </Link>
              <DropdownItem
                onClick={() => handleModal(group.id)}
                className="danger"
              >
                {t('shared.delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  const deleteHighlightEntity = async () => {
    setLoading(true);
    try {
      await Api.spotlight.deleteGroup(deleteId);
      handleModal();
      toast.success(p('sliderDeleted'));
      getSpotlightGroups(pagination.currentPage, pagination.perPage, order);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };
  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ?
      <ArrowDownIcon size={16} />
      :
      <ArrowUpIcon size={16} />;
  };
  const handleSort = (incommingOrder) => {
    const newOrder = {
      orderBy: incommingOrder,
      desc: incommingOrder === order.orderBy ? !order.desc : true,
    };
    setOrder(newOrder);
    setPagination({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    getSpotlightGroups(1, 10, newOrder);
  };
  const handleSearch = (key) => {
    if (key === 'Enter') {
      getSpotlightGroups(1, 10, order);
    }
    return null;
  };
  return (
    <Card>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={s('spotlight')}
        modalAction={deleteHighlightEntity}
      />
      <Row>
        <Col md="3">
          <h3 className="page-title">{p('spotlight')}</h3>
        </Col>
        <Col md="9">
          <ButtonToolbar className="margin-top-0 float-right">
            <div className="form">
              <div className="form__form-group products-list__search">
                <input
                  placeholder={s('search')}
                  value={searchText}
                  onKeyPress={event => handleSearch(event.key)}
                  onChange={event => setSearchText(event.target.value)}
                  name="search"
                />
                <MagnifyIcon />
              </div>
            </div>
            <Link className="btn btn-primary products-list__btn-add" to="/spotlight/group/create">{p('createSlide')}</Link>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert color="primary">
            {p('alertApplayoutInfo')}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Loading loading={loading} />
          <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
            <Table responsive striped >
              <thead>
                <tr style={{ color: '#4D576A' }}>
                  <th className="sortable" onClick={() => handleSort('title')}>
                    <div className="flex alignItems-center">{s('title')} {renderSort('title')}</div>
                  </th>
                  <th className="bold" style={{ color: '#4D576A' }}>{p('format')}</th>
                  <th className="bold" style={{ color: '#4D576A' }}>{p('size')}</th>
                  <th className="bold" style={{ color: '#4D576A' }}>{p('description')}</th>
                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort('createdAt')}>
                    <div className="flex alignItems-center">{s('publicationDate')} {renderSort('createdAt')}</div>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {renderHighligts()}
              </tbody>
            </Table>
          </div>
          <Pagination
            items={pagination.total}
            onChangePage={onChangePage}
            initialPage={pagination.currentPage}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SpotlightGroupListPage;
