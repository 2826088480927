import React from 'react';
import { Container } from 'reactstrap';
import SentMessagesTable from './SentMessagesTable';
import ScheduledMessagesTable from './ScheduledMessagesTable';

const MessagesListPage = () => {
  return (
    <Container className="form d-flex">
      <ScheduledMessagesTable />
      <SentMessagesTable />
    </Container>
  );
};


export default MessagesListPage;
