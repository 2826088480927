import { selector } from 'recoil';
import { setDefaultLocale } from 'react-datepicker';

import userLanguage from './atom';

const userLanguageState = selector({
  key: 'userLanguageState',
  get: ({ get }) => get(userLanguage),
  set: ({ set }, newValue) => {
    localStorage.setItem('language', newValue);
    setDefaultLocale(newValue);
    set(userLanguage, newValue);
  },
});
export default userLanguageState;
