import React, { useState, useEffect } from 'react';
import {
  DropdownItem, Badge, UncontrolledDropdown,
  DropdownMenu, DropdownToggle, Table, Button, UncontrolledPopover, PopoverHeader, PopoverBody,
} from 'reactstrap';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import PropTypes from 'prop-types';
import Moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/sv';
import userLanguageState from '../../../recoil/userLanguage/selector';
import SortDirection from '../../../shared/components/custom/SortDirection';
import { partial } from '../../../util/translation/translation';
import { formatShortDate } from '../../../util/date-and-time';
import { TitleWithImage } from '../../dashboard/components/shared';

const RenderMatches = (props) => {
  const [matches, setMatches] = useState([]);
  const [sort, setSort] = useState({ order: 'match_starts_at', reverse: 0 });
  const [userLanguage] = useRecoilState(userLanguageState);
  const p = partial('RenderMatches');
  const s = partial('shared');
  Moment.locale(userLanguage);

  useEffect(() => {
    setMatches(props.matches);
    setSort({ order: 'match_starts_at', reverse: 0 });
  }, [props.matches]);

  const sortOpponents = () => {
    let tmpMatches = [...matches];
    tmpMatches = matches.sort((a, b) => {
      if (a.away_team.name > b.away_team.name) {
        return sort.reverse === 0 ? -1 : 1;
      } else if (a.away_team.name < b.away_team.name) {
        return sort.reverse === 0 ? 1 : -1;
      }
      return 0;
    });
    setMatches(tmpMatches);
    if (sort.order === 'away_team') {
      setSort({ order: 'away_team', reverse: sort.reverse === 0 ? 1 : 0 });
    } else {
      setSort({ order: 'away_team', reverse: 0 });
    }
  };

  const sortMatchDate = () => {
    let tmpMatches = [...matches];
    tmpMatches = matches.sort((a, b) => {
      if (sort.reverse === 0) {
        return new Date(a.match_starts_at) - new Date(b.match_starts_at);
      }
      return new Date(b.match_starts_at) - new Date(a.match_starts_at);
    });
    setMatches(tmpMatches);
    if (sort.order === 'match_starts_at') {
      setSort({ order: 'match_starts_at', reverse: sort.reverse === 0 ? 1 : 0 });
    } else {
      setSort({ order: 'match_starts_at', reverse: 0 });
    }
  };

  const sortMatchType = () => {
    let tmpMatches = [...matches];
    tmpMatches = tmpMatches.sort((a, b) => {
      if (a.match_type.name > b.match_type.name) {
        return sort.reverse === 0 ? -1 : 1;
      } else if (a.match_type.name < b.match_type.name) {
        return sort.reverse === 0 ? 1 : -1;
      }
      return 0;
    });
    setMatches(tmpMatches);
    if (sort.order === 'match_type') {
      setSort({ order: 'match_type', reverse: sort.reverse === 0 ? 1 : 0 });
    } else {
      setSort({ order: 'match_type', reverse: 0 });
    }
  };

  const sortSaleDate = () => {
    let tmpMatches = [...matches];
    tmpMatches = tmpMatches.sort((a, b) => {
      if (sort.reverse === 0) {
        return new Date(a.sale_start) - new Date(b.sale_start);
      }
      return new Date(b.sale_start) - new Date(a.sale_start);
    });
    setMatches(tmpMatches);
    if (sort.order === 'sale_start') {
      setSort({ order: 'sale_start', reverse: sort.reverse === 0 ? 1 : 0 });
    } else {
      setSort({ order: 'sale_start', reverse: 0 });
    }
  };

  const sortAvailable = () => {
    let tmpMatches = [...matches];
    tmpMatches = tmpMatches.sort((a, b) => {
      return sort.reverse === 0 ? (a.totalSeats - a.soldTickets) - (b.totalSeats - b.soldTickets) : (b.totalSeats - b.soldTickets) - (a.totalSeats - a.soldTickets);
    });
    setMatches(tmpMatches);
    if (sort.order === 'available') {
      setSort({ order: 'available', reverse: sort.reverse === 0 ? 1 : 0 });
    } else {
      setSort({ order: 'available', reverse: 0 });
    }
  };

  const sortReservastions = () => {
    let tmpMatches = [...matches];
    tmpMatches = tmpMatches.sort((a, b) => {
      return sort.reverse === 0 ? a.reservedTickets - b.reservedTickets : b.reservedTickets - a.reservedTickets;
    });
    setMatches(tmpMatches);
    if (sort.order === 'reservations') {
      setSort({ order: 'reservations', reverse: sort.reverse === 0 ? 1 : 0 });
    } else {
      setSort({ order: 'reservations', reverse: 0 });
    }
  };

  const sortSold = () => {
    let tmpMatches = [...matches];
    tmpMatches = tmpMatches.sort((a, b) => {
      return sort.reverse ? a.soldTickets - b.soldTickets : b.soldTickets - a.soldTickets;
    });
    setMatches(tmpMatches);
    setSort({ order: 'sold_tickets', reverse: sort.reverse === 0 ? 1 : 0 });
  };

  const listMatches = () => {
    return matches.map((match, index) => {
      return (
        <tr className="center-list" key={`matches-${match.id}`}>
          <td><TitleWithImage path={`/matches/edit/${match.id}`} image={match.away_team.logo.url} title={match.away_team.name} /></td>
          <td>{formatShortDate(match.match_starts_at)}</td>
          <td><strong>{match.match_type.name}</strong></td>
          <td>{match.sale_start ? formatShortDate(match.sale_start) : '-'}</td>
          {/* <td>{match.universalLinkEnters ? match.universalLinkEnters : 0}</td> */}
          <td>
            <Button color="link" className="no-style margin-0 vertical-pad" id={`totalSeats${match.id}`} type="button">{match.totalSeats}</Button>
            <UncontrolledPopover trigger="focus" placement="top" target={`totalSeats${match.id}`}>
              <PopoverHeader>
                {p('numbers.capacity.title')}
              </PopoverHeader>
              <PopoverBody>
                <div className="margin-5">{p('numbers.capacity.description')}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
          <td>
            <Button color="link" className="no-style margin-0 vertical-pad" id={`totalSold${match.id}`} type="button">{`${match.soldTickets} (${match.paid})`}</Button>
            <UncontrolledPopover trigger="focus" placement="top" target={`totalSold${match.id}`}>
              <PopoverHeader>
                {p('numbers.tickets.title')}
              </PopoverHeader>
              <PopoverBody>
                <div className="margin-5">
                  {p('numbers.tickets.description1')}<br />
                  {`${p('numbers.tickets.description2')}: ${match.soldTickets}`}<br />
                  {`${p('numbers.tickets.description3')}: ${match.paid}`}<br />
                  {`${p('numbers.tickets.description4')}: ${match.season_tickets}, ${p('numbers.tickets.description5', { number: match.assigned_season_ticket })}`}<br />
                  {`${p('numbers.tickets.description6')}: ${match.assigned}`}<br />
                  {`${p('numbers.tickets.description7')}: ${match.excluded}`}

                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
          <td><Button color="link" className="no-style margin-0 vertical-pad" id={`reserved${match.id}`} type="button">{match.reservedTickets}</Button>
            <UncontrolledPopover trigger="focus" placement="top" target={`reserved${match.id}`}>
              <PopoverHeader>
                {p('numbers.reserved.title')}
              </PopoverHeader>
              <PopoverBody>
                <div className="margin-5">{p('numbers.reserved.description')}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
          <td><Button color="link" className="no-style margin-0 vertical-pad" id={`assigned${match.id}`} type="button">{match.assigned + match.assigned_season_ticket}</Button>
            <UncontrolledPopover trigger="focus" placement="top" target={`assigned${match.id}`}>
              <PopoverHeader>
                {p('numbers.assigned.title')}
              </PopoverHeader>
              <PopoverBody>
                <div className="margin-5">{p('numbers.assigned.description1')}</div>
                <div className="margin-5">{`${p('numbers.assigned.description2')}: ${match.assigned}`}</div>
                <div className="margin-5">{`${p('numbers.assigned.description3')}: ${match.assigned_season_ticket}`}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
          <td><Button color="link" className="no-style margin-0" id={`available${match.id}`} type="button">{match.totalSeats - match.usedSeats}</Button>
            <UncontrolledPopover trigger="focus" placement="top" target={`available${match.id}`}>
              <PopoverHeader>
                {p('numbers.available.title')}
              </PopoverHeader>
              <PopoverBody>
                <div className="margin-5">{p('numbers.available.description1')}</div>
                <div className="margin-5">{`${p('numbers.available.description2')}: ${match.totalSeats} -`}</div>
                <div className="margin-5">{`${p('numbers.available.description3')}: ${match.usedSeats}`}</div>
                <div className="margin-5">{`${p('numbers.available.description4')}   ${match.totalSeats - match.usedSeats}`}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
          <td>
            <Badge
              color={match.can_buy_through_app ? 'success sqaure-badge' : 'danger sqaure-badge'}
            >
              {match.can_buy_through_app ? s('activeCaps') : s('inActiveCaps')}
            </Badge>
          </td>
          <td>
            <Badge
              color={match.use_websale ? 'success sqaure-badge' : 'danger sqaure-badge'}
            >
              {match.use_websale ? s('activeCaps') : s('inActiveCaps')}
            </Badge>
          </td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/matches/edit/${match.id}`}>
                  <DropdownItem id={index}>{s('edit')}</DropdownItem>
                </Link>
                <DropdownItem onClick={() => props.handleModal(true, match.id)} className="danger">{s('delete')}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th className="clickable" onClick={sortOpponents}>
            {s('opponent')}
            <SortDirection active={sort.order === 'away_team'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortMatchDate}>
            {s('date')}
            <SortDirection active={sort.order === 'match_starts_at'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortMatchType}>
            {s('matchType')}
            <SortDirection active={sort.order === 'match_type'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortSaleDate}>
            {s('saleStart')}
            <SortDirection active={sort.order === 'sale_start'} direction={sort.reverse} />
          </th>
          {/* <th>
            {s('universalLinkOpen')}
          </th> */}
          <th className="clickable" onClick={sortSold}>
            {s('capacity')}
            <SortDirection active={sort.order === 'sold_tickets'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortSold}>
            {`${s('tickets')} (${s('paid')})`}
            <SortDirection active={sort.order === 'sold_tickets'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortReservastions}>
            {s('reservations')}
            <SortDirection active={sort.order === 'reservations'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortReservastions}>
            {s('assigned')}
            <SortDirection active={sort.order === 'reservations'} direction={sort.reverse} />
          </th>
          <th className="clickable" onClick={sortAvailable}>
            {s('available')}
            <SortDirection active={sort.order === 'available'} direction={sort.reverse} />
          </th>
          <th>In-App</th>
          <th>Websale</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {listMatches()}
      </tbody>
    </Table>
  );
};

RenderMatches.propTypes = {
  matches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default RenderMatches;
