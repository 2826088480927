import { getAuth } from 'firebase/auth';
import { setIsAuthenticated, setMe, setHasCheckedPermissions } from '../../redux/actions/adminActions';
import routePermissionCheck from './routePermissionCheck';
import Api, { getClubName } from '../../util/api';
import { mappedPermissions } from '../../util/functions';

function mapRolesToPermisions(roleIds) {
  return roleIds.reduce((o, e) => ([...o, ...mappedPermissions.find(p => String(p.key) === String(e)).permissions]), []);
}

async function afterFirebaseLogin(dispatch) {
  const firebaseAuth = getAuth();
  const clubName = getClubName();
  const usr = await firebaseAuth.currentUser.getIdTokenResult();
  const team = usr.claims.teams[clubName];
  const __permissions = mapRolesToPermisions(team.roles);
  return new Promise(async (resolve, reject) => {
    try {
      const resMe = await Api.login.me();
      dispatch(setMe({ ...resMe.data, permissions: __permissions }));
      dispatch(setIsAuthenticated(true));
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}
function login(payload, dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      const clubName = getClubName();
      const resMe = await Api.login.me();
      const firebaseAuth = getAuth();
      const usr = await firebaseAuth.currentUser.getIdTokenResult();
      const team = usr.claims.teams[clubName];
      const __permissions = mapRolesToPermisions(team.roles);
      dispatch(setMe({ ...resMe.data, permissions: __permissions }));
      dispatch(setIsAuthenticated(true));
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

async function signout(dispatch) {
  await getAuth().signOut();
  dispatch(setIsAuthenticated(false));
  dispatch(setMe(null));
}

function permissionCheck(location, permissions, dispatch) {
  const path = location.pathname;
  const currentRoute = path === '/'
    ? routePermissionCheck.find(route => route.path === path)
    : routePermissionCheck.find(route => route.path !== '/' && path.startsWith(route.path));

  if (!currentRoute) {
    dispatch(setHasCheckedPermissions(path, false));
    return true;
  }

  const hasPerms = currentRoute.permissions.reduce((hasPerm, orPermissions) => hasPerm || orPermissions.every((andPermission) => permissions.includes(andPermission)), false);

  dispatch(setHasCheckedPermissions(path, true, hasPerms));
  return hasPerms;
}


export default {
  login, signout, permissionCheck, afterFirebaseLogin, mapRolesToPermisions,
};
