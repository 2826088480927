import React from 'react';
import StarIcon from 'mdi-react/StarIcon';

const styles = {
  badgeOuterContainer: {
    backgroundColor: '#313131',
    position: 'absolute',
    top: 0,
    right: 0,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  badgeInnerContainer: {
    padding: '5px 5px 5px 5px',
    display: 'flex',
    alignItems: 'center',
  },
  badgeIconStyle: {
    color: '#FFF',
    paddingHorizontal: 5,
  },
  badgeCircleContainer: {
    backgroundColor: '#313131',
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 50,
    overflow: 'hidden',
  },
  badgeCircleInnerContainer: {
    alignSelf: 'center',
    display: 'flex',
    padding: 6,
    width: 20,
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },

  exclusiveLabelText: {
    color: '#ffffff',
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 'bold',
    paddingLeft: 5,
  },
};
const ExclusiveLabel = ({ visible, format, text }) => {
    return (
        visible ? (
          <div
            style={
            format === 'CIRCLE'
              ? styles.badgeCircleContainer
              : styles.badgeOuterContainer
          }
          >
            <div
              style={
              format === 'CIRCLE'
                ? styles.badgeCircleInnerContainer
                : styles.badgeInnerContainer
            }
            >
              <StarIcon style={styles.badgeIconStyle} size={10} />
              {text ? <div style={styles.exclusiveLabelText}>{text}</div> : null}
            </div>
          </div>) : null
    );
  };

  export default ExclusiveLabel;

