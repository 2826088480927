export default {
  club_name: 'Linköping Innebandy',
  club_name_short: 'LIBK',
  stadium_name: 'Linköpings Sporthall',
  staging_url: 'https://linkoping.api.fangroup.io',
  prod_url: 'https://linkoping.api.fangroup.io',
  api: '/api',
  facebook: 'linkopingibk',
  basename: '/linkoping',
  club_logo: '/assets/teams/linkoping.png',
  app_name: 'linkoping',
  websale_name: 'linkoping',
  ntb_team_id: 10016,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'linkoping',
  monolithEnabled: false,
};
