import React, { useEffect, useMemo, useState } from 'react';
import { Col, Button, Card, CardBody, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import t, { partial } from '../../util/translation/translation';
import Api from '../../util/api';

const CommunicationApiStatsPage = () => {
    const { accessId } = useParams();
    const [apiAccess, setApiAccess] = useState(null);
  const chartData = useMemo(() => {
    if (!apiAccess) {
        return {};
    }
    const sortedUsages = apiAccess.usages.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
    const groupedByDay = sortedUsages.reduce((acc, usage) => {
        const day = moment(usage.createdAt).format('YYYY-MM-DD');

        if (!acc[day]) {
          acc[day] = { count: 0, sentAmount: 0 };
        }

        acc[day].sentAmount += usage.sentAmount;
        acc[day].count += 1;
        return acc;
      }, {});
      const sentValues = Object.values(groupedByDay).map((item) => item.sentAmount);
      const requestCountValues = Object.values(groupedByDay).map((item) => item.count);
      const labels = Object.keys(groupedByDay);
    return {
      labels: labels.map(label => moment(label).format('DD.MM.YYYY')),
      datasets: [
        {
          label: t('MessagesForm.receiversCount'),
          data: sentValues,
          backgroundColor: '#4ce1b666',
          borderColor: '#4ce1b6',
        },
        {
            label: t('MessagesForm.requestCount'),
            data: requestCountValues,
            backgroundColor: '#03ecfc66',
            borderColor: '#03ecfc',
          },
      ],
    };
  }, [apiAccess]);
  const totalReceivers = useMemo(() => {
    if (!apiAccess) {
        return 0;
    }
    return apiAccess.usages.reduce((tot, curr) => tot + curr.sentAmount, 0);
  }, [apiAccess]);
  const s = partial('shared');
  const DoubleRowName = ({ row1, row2 }) => {
    return (
      <>
        <CardText className="bold-text"> {row1}</CardText>
        <CardText style={{ marginTop: '0px' }}>{row2}</CardText>
      </>
    );
  };
  const getAccessData = async () => {
    try {
        const { data: result } = await Api.userDevices.getApiAccessById(accessId);
        setApiAccess(result);
    } catch (e) {
        toast.error(s(`${'somethingWentWrong'}`));
    }
  };
  useEffect(() => {
    getAccessData();
  }, [accessId]);
  return (
    <Card>
      <CardBody>
        <div className="flex">
          <Col lg={3} md={12}>
            <div className="card__title">
              <h4 className="bold-text">API</h4>
            </div>
            <DoubleRowName row1={t('shared.key')} row2={apiAccess?.apiKey}/>
            <DoubleRowName row1={t('shared.companyName')} row2={apiAccess?.entityName}/>
            <DoubleRowName row1={`${t('shared.total')} ${t('MessagesForm.requestCount')}`.toUpperCase()} row2={apiAccess?.usages?.length || 0} />
            <DoubleRowName row1={`${t('shared.total')} ${t('MessagesForm.receiversCount')}`.toUpperCase()} row2={totalReceivers} />
          </Col>
          <div className="margin-10" />
          <Col lg={9} md={12}>
            <div className="card__title">
              <h4 className="bold-text">{}</h4>
            </div>
            <Line data={chartData} />
          </Col>
        </div>
        <div style={{ marginTop: 10 }}>
          <Link to="/messages?tab=3"><Button>{s('close')}</Button></Link>
        </div>
      </CardBody>
    </Card>
  );
};


export default CommunicationApiStatsPage;
