import React, { useState, useEffect } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CategoryForm from './components/CategoryForm';
import Loading from '../../../../shared/components/custom/Loading';
import Api from '../../../../util/api';
import { partial } from '../../../../util/translation/translation';

const p = partial('product&CategoryShare');
const CategoryEditPage = (props) => {
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (form) => {
    const payload = {
      name: form.name,
    };

    setLoading(true);
    try {
      await Api.cnc.updateCategory(category.id, payload);
      toast.success(`${p('categoryUpdated')}`);
      setLoading(false);
    } catch (err) {
      toast.error(err || `${p('categoryCreatingERROR')}`);
      setLoading(false);
    }
  };

  const getProduct = async () => {
    setLoading(true);
    try {
      const resCategory = await Api.cnc.getCategoryByID(props.match.params.categoryID);
      setCategory(resCategory.data);
      setLoading(false);
    } catch (err) {
      toast.error(err || `${p('categoryFetchingERROR')}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <Container>
      <Loading loading={loading} />
      <Col md={12}>
        <h3 className="page-title">{p('editCategory')}</h3>
      </Col>
      <Col md={12}>
        <Card>
          <CardBody>
            <CategoryForm type="edit" category={category} onSubmit={handleSubmit} />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

CategoryEditPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryID: PropTypes.string,
    }),
  }).isRequired,
};

export default CategoryEditPage;
