import React, { useEffect, useState } from 'react';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import {
  Card, Table, ButtonToolbar, Button,
  UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { splitEvery } from 'ramda';
import { Parser } from 'json2csv';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import Pagination from '../../../shared/components/pagination/Pagination';
import t, { partial } from '../../../util/translation/translation';
import { capitalizeFirstLetter } from '../../../util/functions';
import useModal from '../../../shared/hooks/useModal';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import useConfig from '../../../util/useConfig';
import { formatDate } from '../../../util/date-and-time';

const p = partial('UsersTab');
const s = partial('shared');
const l = partial('LoginPage');


const UsersTab = () => {
  const config = useConfig();
  const [handleModal, visible, deleteId, comment, setComment] = useModal();
  const [usersPaginated, setUsersPaginated] = useState({ total: 0, users: [] });
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 0,
  });
  const [sort, setSort] = useState({
    field: 'phoneNumber',
    direction: 'asc',
  });

  const getUsers = async () => {
    setLoading(true);
    const { total, users } = await Api.users.getTeamUsers(pagination.page, pagination.pageSize, { [sort.field]: sort.direction }, searchText, config);
    const { data: usersTiers } = await Api.tiers.userTiersByCloudIDs(users.map(user => user.userCloudId), config);
    setLoading(false);
    setUsersPaginated({
      total,
      users: users.map(user => ({
        ...user,
        tiers: usersTiers.find(tier => tier.userID === user.userCloudId),
      })),
    });
  };

  const handleEmailTranslations = (language, firstname, password, club_name) => {
    let emailBody;
    switch (language) {
      case 'sv':
        emailBody = `Hej ${firstname}

        Jag har uppdaterat lösenordet till: ${password}
        När du loggat in kan du gå till mer menyn nere till höger välja "min sida" sedan "lösenord" för att ändra lösenordet.
        ${club_name}
        `;
        break;
      case 'nb':
        emailBody = `Hei ${firstname}

        Vi har nå opprettet et nytt password for deg. Det nye passordet er: ${password}.
        Når du har logget inn i appen, gå til "mer-menyen" nede i høyre hjørne, og velg deretter "Min side", deretter "Passord". Der kan du lage ditt eget passord.

        Ha en fortsatt fin dag!
        ${club_name}
        `;
        break;
      default:
        emailBody = `Hi ${firstname}

        We have created a new password for you. Your new password is: ${password}
        When you have logged into the app, go to the "more menu" in the bottom right corner and then select "my page", then "password", where you can create your own password.

        Have a nice day!
        ${club_name}
        `;
    }

    return emailBody;
  };

  const sendRestPassword = async (user) => {
    setLoading(true);
    const password = Math.random().toString(36).slice(-8);
    try {
    await Api.users.updateUserGraphQL(user.id, { password });

    const language = await Api.userDevices.getDeviceLanguage(user.userCloudId);
    const BODY = handleEmailTranslations(language.data[0].preferredLanguage, user.firstname, password, config.club_name);
    const messagePayload = {
      recipients: [{ userId: user.userCloudId }],
      channels: [{ channel: 'email', templateId: 'event-admin-communication' }],
      placeholders: { BODY,
      SUBJECT: 'Account Passowrd Changed' },
      senderInfo: { sender: config.id, type: 'Club' },
      type: 'SYSTEM',
      };

      await Api.userDevices.sendMessage(messagePayload);
      toast.success(l('successfullyChangedPassword'));
      setLoading(false);
    } catch (err) {
      toast.error('failed to update password');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pagination.page !== 0) {
      getUsers();
    }
  }, [pagination.page, pagination.pageSize, sort.field, sort.direction]);

  const onChangePage = (page) => {
    setPagination({
      page: page.currentPage,
      pageSize: page.pageSize,
    });
  };

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
  };

  const search = (e) => {
    if (e.key === 'Enter') {
      getUsers();
    }
  };

  const handleSort = (field) => {
    const getReversedSortDirection = (direction) => {
      return direction === 'asc' ? 'desc' : 'asc';
    };
    setSort((prev) => ({
      field,
      direction: prev.field === field ? getReversedSortDirection(prev.direction) : 'asc',
    }));
  };

  const renderSort = (cellOrder) => {
    if (sort.field !== cellOrder) {
      return undefined;
    }
    return sort.direction === 'asc' ?
      <ArrowUpIcon size={16} />
      :
      <ArrowDownIcon size={16} />;
  };

  const deleteUser = async () => {
    setLoading(true);
    try {
      await Api.users.deleteTeamUser(config.cloudIdName, deleteId, comment);
    } catch (err) {
      toast.error(p('deleteUserFailed'));
    }
    handleModal();
    getUsers();
  };
  const handleExport = async () => {
    setLoading(true);
    try {
      const { users: fetchedUsers } = await Api.users.getTeamUsers(1, 100000, { phoneNumber: 'asc' }, '', config);
      const chunkedUsers = splitEvery(200, fetchedUsers);
      const { data: usersTiers } = await Api.tiers.userTiersByCloudIDs(chunkedUsers.map(user => user.userCloudId), config);
      const mappedUsers = fetchedUsers.map(user => {
        const foundTier = usersTiers.find(tier => tier.userID === user.userCloudId);
        const allRoles = `${user.teams?.[0]?.roles?.map(role => role.name).slice(0, 3).join(', ') || '-'}${user.teams?.[0]?.roles?.length > 3 ? '...' : ''}`;
        return ({
          ...user,
          dateOfBirth: (!user.dateOfBirth || user.dateOfBirth === '0000-00-00') ? '-' : user.dateOfBirth,
          tier: foundTier?.allTierNames?.length > 0 ? foundTier?.allTierNames?.slice(0, 3)?.join(', ') : '-',
          roles: allRoles,
          createdAt: user?.createdAt ? formatDate(user?.createdAt) : '-',
          lastLoggedInAt: user?.lastLoggedInAt ? formatDate(user?.lastLoggedInAt) : '-',
        });
        });
      const fields = [
        { label: t('AddEmployee.firstName'), value: 'firstname' },
        { label: t('AddEmployee.lastName'), value: 'lastname' },
        { label: s('phone'), value: 'username' },
        { label: s('email'), value: 'email' },
        { label: capitalizeFirstLetter(t('UserTiers.tier')), value: 'tier' },
        { label: s('created'), value: 'createdAt' },
        { label: s('lastLoggedInAt'), value: 'lastLoggedInAt' },
        { label: s('roles'), value: 'roles' },
        { label: s('dateOfBirth'), value: 'dateOfBirth' },
        { label: 'ID', value: 'userCloudId' },
      ];
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(mappedUsers);
      fileDownload(`\uFEFF${csv}`, `${p('exportUsers')}.csv`);
    } catch (e) {
      toast.error(s('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };
  const handleExportTerms = async () => {
    setLoading(true);
    try {
      const { data: results } = await Api.terms.getUserTermApprovals();
      const mappedResults = results.map((item) => ({
        ...item,
        SMS: item.SMS ? s('yes') : s('no'),
        EMAIL: item.EMAIL ? s('yes') : s('no'),
        PUSH: item.PUSH ? s('yes') : s('no'),
        GENERAL: item.GENERAL ? s('yes') : s('no'),
      }));
      const fields = [
        { label: 'ID', value: 'userCloudId' },
        { label: t('AddEmployee.firstName'), value: 'firstname' },
        { label: t('AddEmployee.lastName'), value: 'lastname' },
        { label: s('phone'), value: 'phoneNumber' },
        { label: s('email'), value: 'email' },
        { label: t('settingsTerms.termName-SMS'), value: 'SMS' },
        { label: t('settingsTerms.termName-EMAIL'), value: 'EMAIL' },
        { label: t('settingsTerms.termName-PUSH'), value: 'PUSH' },
        { label: t('settingsTerms.termName-GENERAL'), value: 'GENERAL' },
      ];
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(mappedResults);
      fileDownload(`\uFEFF${csv}`, `${p('exportTermApprovals')}.csv`);
    } catch (e) {
      console.log(e);
      toast.error(s('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={p('userDeleteType')}
        modalAction={deleteUser}
        canComment
        comment={comment}
        onCommentChange={setComment}
      />
      <Loading loading={loading} />
      <div className="flex">
        <div className="flex-1" />
        <ButtonToolbar className="margin-0">
          <div className="form">
            <div className="form__form-group products-list__search">
              <input
                placeholder={p('searchForUser')}
                value={searchText}
                onChange={handleSearchInput}
                onKeyPress={search}
                name="search"
              />
              <MagnifyIcon />
            </div>
          </div>
          <Button size="sm" onClick={handleExportTerms}>{p('exportTermApprovals')}</Button>
          <Button size="sm" onClick={handleExport}>{p('exportUsers')}</Button>
          <Link to="/users/create">
            <Button size="sm" color="primary" >{p('createUser')}</Button>
          </Link>
        </ButtonToolbar>
      </div>
      <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
        <Table responsive striped>
          <thead>
            <tr>
              <th className="sortable" onClick={() => handleSort('firstname')}>{s('firstname')} {renderSort('firstname')}</th>
              <th className="sortable" onClick={() => handleSort('lastname')}>{s('lastname')} {renderSort('lastname')}</th>
              <th className="sortable" onClick={() => handleSort('phoneNumber')}>{s('username')} {renderSort('phoneNumber')}</th>
              <th className="sortable" onClick={() => handleSort('email')}>Email {renderSort('email')}</th>
              <th>{capitalizeFirstLetter(t('UserTiers.tier'))}</th>
              <th className="sortable" onClick={() => handleSort('createdAt')}>{s('created')} {renderSort('createdAt')}</th>
              <th className="sortable" onClick={() => handleSort('lastLoggedInAt')}>{s('lastLoggedInAt')} {renderSort('lastLoggedInAt')}</th>
              <th>{s('roles')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {usersPaginated.users.map(user => (
              <tr key={`user-${user.userCloudId}`}>
                <td>{user?.firstname ?? '-'}</td>
                <td>{user?.lastname ?? '-'}</td>
                <td><Link to={`/users/edit/${user.userCloudId}`}>{user?.flag} {user?.phoneNumber ?? '-'}</Link></td>
                <td>{user?.email ?? '-'}</td>
                <td>{user?.tiers?.allTierNames?.length > 0 ?
                  <Link to={`/users/edit/${user.userCloudId}?tab=3`}>{user?.tiers?.allTierNames?.slice(0, 3)?.join(', ')}</Link> :
                  <Link component={Button} outline color="primary" to={`/users/edit/${user.userCloudId}?tab=3`} size="sm">{s('add')}</Link>}
                </td>
                <td>{user?.createdAt ? formatDate(user?.createdAt) : '-'}</td>
                <td>{user?.lastLoggedInAt ? formatDate(user?.lastLoggedInAt) : '-'}</td>
                <td>{user.teams?.[0]?.roles?.map(role => role.name).slice(0, 3).join(', ')}{user.teams?.[0]?.roles?.length > 3 && '...'}</td>
                <td>
                  <UncontrolledDropdown className="dashboard__table-more" >
                    <DropdownToggle>
                      <DotsHorizontalIcon />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu" style={{ minWidth: '150px' }}>
                      <DropdownItem
                        onClick={() => handleModal(user.userCloudId)}
                        className="danger"
                      >
                        {t('shared.delete')}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => sendRestPassword(user)}
                      >
                        Reset password
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        rowsPerPage={[100, 250, 500]}
        items={usersPaginated.total}
        onChangePage={onChangePage}
      />
    </Card>
  );
};

export default UsersTab;
