export const TargetAudience = {
  partner: 'partner',
  private: 'private',
  both: 'both',
  all: 'all',
};
export const PUSH_LABELS = [
  {
      label: 'Default',
      value: 'default',
  },
  {
      label: 'Marketing',
      value: 'marketing',
  },
  {
      label: 'News',
      value: 'news',
  },
  {
      label: 'Auctions',
      value: 'auctions',
  },
  {
      label: 'External store',
      value: 'externalStore',
  },
  {
      label: 'Lottery',
      value: 'lottery',
  },
  {
      label: 'Events',
      value: 'events',
  },
  {
      label: 'Match tickets',
      value: 'matchTickets',
  },
  {
      label: 'Packages',
      value: 'packages',
  },
];
