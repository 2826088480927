import { partial } from '../../../../util/translation/translation';

const p = partial('validateErrors');

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${p('nameFieldEmpty')}'!'`;
  }

  return errors;
};

export default validate;
