import { partial } from '../../../util/translation/translation';

const validateSeasonPrice = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.title) {
    errors.title = p('titleFieldCantBeEmpty');
  }

  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }
  if (!values.price) {
    errors.price = p('priceFieldCantBeEmpty');
  }
  if (!values.priceGroup) {
    errors.priceGroup = p('mustChoosePriceGroup');
  }

  return errors;
};

export default validateSeasonPrice;
