import React from 'react';
import { getLandscapeImageFromSize, getLandscapeStyleFromSize, getLandscapeContentFromSize, isSpotlightExclusive, getFontSize } from './SpotlightUtils';
import ExclusiveLabel from './ExclusiveLabel';
import { partial } from '../../../../util/translation/translation';

const styles = {
  landscapeImageContainer: (size) => ({
    width: getLandscapeImageFromSize(size).width,
    height: getLandscapeImageFromSize(size).height,
    objectFit: 'cover',
  }),
  landscapeContainer: (size) => ({
    width: getLandscapeStyleFromSize(size).width,
    backgroundColor: '#FFFFFF',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: size === 'FULL_SCREEN' ? 0 : 8,
    boxShadow: size === 'FULL_SCREEN' ? null : 'rgb(0 0 0 / 30%) 5px 5px 5px -6px',
  }),
  landscapeContentContainer: (size) => ({
    marginHorizontal: getLandscapeContentFromSize(size).margin,
    paddingTop: getLandscapeContentFromSize(size).padding,
  }),
  largeTitle: {
    paddingBottom: 10,
  },
  smallTitle: {
    paddingBottom: 0,
  },
};
const PortraitItem = ({ tile, size, access, descriptionAvailable }) => {
  const p = partial('SpotlightForm');
  const { headingImage, description } = tile;
  const isExclusive = isSpotlightExclusive(access);
  const isYoutube = tile?.content?.value === 'YOUTUBE_VIDEO';
  const videoId = `https://www.youtube.com/embed/${tile?.contentType}?controls=0&iv_load_policy=3&rel=0`;
  return (
    <div style={styles.landscapeContainer(size)}>
      <div>
        {isYoutube ?
          <iframe
            title="Youtube frame"
            style={styles.landscapeImageContainer(size)}
            src={videoId}
          /> :
          <img
            resizeMode="cover"
            src={headingImage?.preview}
            style={styles.landscapeImageContainer(size)}
            alt=""
          />}
        {size !== 'FULL_SCREEN' && descriptionAvailable ?
          <div style={{ height: getLandscapeStyleFromSize(size).descHeight, paddingTop: '5px' }}>
            <p style={{ fontWeight: '500', fontSize: getFontSize(size), color: 'black', overflow: 'hidden', padding: '0 10px 10px 10px', textOverflow: 'ellipsis', width: '100%', lineHeight: '1.5em', height: '3em' }}>{description}</p>
          </div> : null}
        <ExclusiveLabel
          visible={isExclusive}
          text={size === 'LARGE' ? p('onlyForPartners') : null}
        />
      </div>
    </div>
  );
};

export default PortraitItem;

