import React from 'react';
import {
    Container,
    Row,
    Col,
  Button,
  Alert,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';
import validateGuestParticipants from './validateGuestParticipants';


const GuestCardForm = ({ handleSubmit }) => {
  const p = partial('EventParticipantsPage');
  const s = partial('shared');
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Alert color="warning">{p('guetsMessageInformation')}</Alert>
      <Container>
        <Row>
          <Col lg="6">
            <div className="form__form-group mx-2">
              <span className="form-header">{s('firstname')}</span>
              <Field
                name="firstName"
                placeholder={s('firstname')}
                component={renderField}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form__form-group mx-2">
              <span className="form-header">{s('lastname')}</span>
              <Field
                name="lastName"
                placeholder={s('lastname')}
                component={renderField}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form__form-group mx-2">
              <span className="form-header">{s('phone')}</span>
              <Field
                name="phoneNumber"
                placeholder={s('phone')}
                component={renderField}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form__form-group mx-2">
              <span className="form-header">{s('email')}</span>
              <Field
                name="email"
                placeholder={s('email')}
                component={renderField}
              />
            </div>
          </Col>
          <Col lg="6">
            <div className="form__form-group mx-2">
              <span className="form-header">{s('comments')}</span>
              <Field
                name="comments"
                placeholder={s('comments')}
                component={renderField}
              />
            </div>
          </Col>
          <Col>
            <div className="form__form-group mx-2">
              <span className="form-header">{p('numberOfGuests')}</span>
              <Field
                name="guestAmount"
                placeholder={p('chooseGuests')}
                component={renderField}
                type="number"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Button color="primary" type="submit">
        {p('inviteParticipants')}
      </Button>
    </form>
  );
};


const reduxF = reduxForm({
  form: 'GuestCardForm',
  validate: validateGuestParticipants,
});

export default connect()(reduxF(GuestCardForm));



