import React, { useMemo, useState } from 'react';
import {
  Container, ButtonToolbar,
  UncontrolledDropdown, DropdownMenu, DropdownToggle,
  Table, ButtonGroup, Button, DropdownItem, Row, UncontrolledTooltip, Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import UserAddIcon from 'mdi-react/UserAddIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import AccountMultipleCheckIcon from 'mdi-react/AccountMultipleCheckIcon';
import { reduxForm } from 'redux-form';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import CommentTextOutlineIcon from 'mdi-react/CommentTextOutlineIcon';
import CommentOutlineIcon from 'mdi-react/CommentOutlineIcon';
import AccountGroup from 'mdi-react/AccountGroupIcon';
import moment from 'moment';
import { toast } from 'react-toastify';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import t, { partial } from '../../../util/translation/translation';
import Checkbox from '../../../shared/components/custom/Checkbox';
import useModal from '../../../shared/hooks/useModal';
import ShareModal from '../../../shared/components/custom/ShareModal';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import InviteParticipantsModal from './InviteParticipantsModel';
import Api from '../../../util/api';
import IconButton from '../../../shared/components/custom/IconButton';
import EditAnswersModal from './EditAnswersModal';
import CommentModal from './CommentModal';
import ParticipationDisplayCounter from './EventParticipation/ParticipationDisplayCounter';
import SortDirection from '../../../shared/components/custom/SortDirection';

const EventsPariticipantForm = ({
  companies,
  setCompanies,
  handleInviteParticipant,
  inviteModelVisible,
  setInviteModelVisible,
  changeGuestAmount,
  participants,
  setLoading,
  getPaticipants,
  currentParticipant,
  setCurrentParticipant,
  setParticipants,
  markParticipantAttendance,
  event,
  exportParticpants,
  questionsNanswers,
  questions,
  userAnswers,
  saveAnswers,
  setMaxGuestAmount,
  maxGuestAmount,
  deletedParticipants,
  setDeletedParticipants,
}) => {
  const p = partial('EventParticipantsPage');
  const qst = partial('question');
  const s = partial('shared');
  const [handleModal, visible, deleteId, comment, setComment] = useModal();
  const [showEditModal, setShowEditModal] = useState(false);
  const [userQuestions, setUserQuestions] = useState([]);
  const [questionAndAnswerIds, setQuestionAndAnswerIds] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [visibleCompany, setVisibleCompany] = useState(null);
  const [participantSearch, setParticipantSearch] = useState('');
  const [participantType, setParticipantType] = useState('person');
  const [selectedParticipantForComment, setSelectedParticipantForComment] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [sort, setSort] = useState({ column: 'name', order: 0 });
  const totalParticipantCount = useMemo(() => participants.reduce((tot, curr) => {
    return tot + curr.friendAmount + 1;
  }, 0), [participants]);
  const attendedCount = useMemo(() => participants.reduce((tot, curr) => {
    return tot + curr.attendedAmount;
  }, 0), [participants]);
  const companyParticipants = useMemo(() => participants.filter((item) => item.type === 'COMPANY'), [participants]);
  const guestPartcipants = useMemo(() => participants.filter((item) => item.type === 'GUEST'), [participants]);
  const userPartcipants = useMemo(() => participants.filter((item) => item.type === 'USER'), [participants]);
  const markedAttendantIds = useMemo(() => participants.filter((item) => item.toggled).map((item) => item.id), [participants]);
  const markedCompanyAttendantIds = useMemo(() => companies.filter(({ toggled }) => toggled).map(({ id }) => participants.filter(({ companyId }) => companyId === id)).flat().map((item) => item.id), [companies, participants]);
  const selectedParticipantIds = participantType === 'person' ? markedAttendantIds : markedCompanyAttendantIds;
  const removeParticipant = async (payload, participantID) => {
    setLoading(true);
    try {
      await Api.events.removeParticipants(payload, participantID);
      const { userId } = participants.find((participant) => participant.id === participantID);
      await Api.questions.deleteAllAnswersInEvent(event.id, [userId]);
      getPaticipants();
      setLoading(false);
      handleModal();
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('participantDeletingERROR')}'!'`);
    }
  };
  const deleteMultipleParticipants = async (ids) => {
    setLoading(true);
    try {
      await Promise.all(ids.map((id) => Api.events.removeParticipants({}, id)));
      const matchedUsers = participants.filter(participant => ids.includes(participant.id)).map(participant => participant.userId);
      await Api.questions.deleteAllAnswersInEvent(event.id, [...matchedUsers]);
      getPaticipants();
      setLoading(false);
      handleModal();
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('participantDeletingERROR')}'!'`);
    }
  };
  const toggleParticipant = (participantId) => {
    const participantIndex = participants.findIndex(({ id }) => id === participantId);
    const _participants = [...participants];
    _participants[participantIndex].toggled = !_participants[participantIndex].toggled;
    setParticipants(_participants);
  };

  const sortTable = (array) => {
    return array.slice().sort((a, b) => {
        let fieldA;
        let fieldB;
        if (sort.column === 'name') {
          fieldA = `${a.firstname} ${a.lastname}`;
          fieldB = `${b.firstname} ${b.lastname}`;
        } else if (sort.column === 'company') {
          fieldA = `${a.company?.name}`;
          fieldB = `${b.company?.name}`;
        } else if (sort.column === 'phone') {
          fieldA = `${a.phoneNumber.replace(/^\+/, '')}`;
          fieldB = `${b.phoneNumber.replace(/^\+/, '')}`;
        }

        if (sort.order === 1) {
            return fieldA.localeCompare(fieldB);
        } else {
            return fieldB.localeCompare(fieldA);
        }
    });
  };

  const hanldeHeaderClick = (header) => {
    let direction;
    if (header === sort.column) {
      if (sort.order === 1) {
        direction = 0;
      } else {
        direction = 1;
      }
    } else {
      direction = 0;
    }
    setSort({ column: header, order: direction });
  };

  const displayLongStringsInToolTip = (message, id) => {
    if (message.length > 35) {
      const tooltipId = `tooltip-${id}`;

      return (
        <>
          <UncontrolledTooltip placement="bottom" target={tooltipId}>
            {message}
          </UncontrolledTooltip>
          {message.substr(0, 35)}<DotsHorizontalIcon id={tooltipId} />
        </>
      );
    } else {
      return <>{message}</>;
    }
  };

  const handleEditPressed = (participant) => {
    setModalVisible(true);
    setSelectedParticipantForComment(participant);
  };

  const handleCloseModal = () => {
    setSelectedParticipantForComment(null);
    setModalVisible(false);
  };


  const renderDeletedParticipants = () => {
    const participantsArray = [...deletedParticipants];
    const keys = ['firstname', 'lastname', 'phoneNumber'];
    return sortTable(participantsArray)
      .filter(participant => keys
        .some((key) => participant[key].toLowerCase().includes(participantSearch.toLowerCase())))
      .map((participant) => (
        <tr key={`renderParticipants-${participant.id}`} style={{ background: (participant.friendAmount + 1 === participant.attendedAmount) ? '#F0F9F3' : 'transparent' }}>
          <td> </td>
          <td>
            {`${participant.firstname} ${participant.lastname}`}
            <br/>
            {participant.type === 'GUEST' &&
              <Badge color="info" style={{ fontSize: '75%', padding: '0.25em 0.4em' }}>{s('guest')}</Badge>
            }
          </td>
          <td>{participant.phoneNumber}</td>
          <td>{participant?.company || ''}</td>
          <td>{moment(participant?.deletedAt).format('YYYY-MM-DD / HH:mm') || ''}</td>
        </tr>
      ));
  };

  const renderParticipants = () => {
    const participantsArray = [...userPartcipants, ...guestPartcipants, ...companyParticipants];
    const keys = ['firstname', 'lastname', 'phoneNumber'];
    return sortTable(participantsArray)
      .filter(participant => keys
        .some((key) => participant[key].toLowerCase().includes(participantSearch.toLowerCase())))
      .map((participant) => (
        <tr key={`renderParticipants-${participant.id}`} style={{ background: (participant.friendAmount + 1 === participant.attendedAmount) ? '#F0F9F3' : 'transparent' }}>
          <td><Checkbox index={1} toggled={participant.toggled} single handleToggle={() => toggleParticipant(participant.id)}/>
          </td>
          <td>
            {`${participant.firstname} ${participant.lastname}`}
            <br/>
            {participant.type === 'GUEST' &&
              <Badge color="info" style={{ fontSize: '75%', padding: '0.25em 0.4em' }}>{s('guest')}</Badge>
            }
          </td>
          <td>{participant.phoneNumber}</td>
          <td>{participant?.company?.name || ''}</td>
          <td>
            <ButtonGroup className="btn-group--icons">
              <Button className="p-1 m-0" color="secondary" onClick={() => changeGuestAmount(participant.friendAmount - 1, participant)}>
                <MinusIcon/>
              </Button>
              <p className="p-0 m-1">{participant.friendAmount}</p>
              <Button className="p-1 m-0" color="secondary" onClick={() => changeGuestAmount(participant.friendAmount + 1, participant)}>
                <PlusIcon />
              </Button>
            </ButtonGroup>
          </td>
          <td>
            {!participant.additionalNotes ? <CommentOutlineIcon onClick={() => handleEditPressed(participant)}/> : <CommentTextOutlineIcon color="#3EA3FC" onClick={() => handleEditPressed(participant)}/>}
          </td>
          <td>
            <ButtonGroup className="btn-group--icons">
              <Button className="p-1 m-0" color="secondary" onClick={() => markParticipantAttendance(participant.id, participant.attendedAmount - 1)}>
                <MinusIcon/>
              </Button>
              <p className="p-0 m-1">{participant.attendedAmount}</p>
              <Button className="p-1 m-0" color="secondary" onClick={() => markParticipantAttendance(participant.id, participant.attendedAmount + 1)}>
                <PlusIcon />
              </Button>
            </ButtonGroup>
          </td>
          <td>
            <ButtonGroup className="btn-group--icons px-1">
              <Button className="p-2 m-0" color="secondary" onClick={() => markParticipantAttendance(participant.id, participant.friendAmount + 1)}>
                {s('all')}
              </Button>
            </ButtonGroup>
          </td>

          <td >
            <ButtonToolbar >
              <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                <IconButton onClick={() => handleModal(participant.id)} type={IconButton.types.DELETE} id={`delete-${participant.id}`}/>
              </ButtonGroup>
            </ButtonToolbar>
          </td>

        </tr>
      ));
  };

  const getAnswer = (eventQuestion, userQuestion) => {
    const answer = userQuestion?.answer.find((userAnswer) => userAnswer.id === eventQuestion.id);
    if (answer) {
      return answer.answer;
    }
    return eventQuestion.question_type === 'checkbox' ? [] : '';
  };

  const handleEditAnswers = (userId, guestCount) => {
    const questionnaire = userAnswers.find((a) => a.userId === userId);
    setShowEditModal(true);

    (async () => {
      const res = await Api.questions.fetchDomainSpecificQuestions(event.id);

      const eventQuestions = res?.data?.question.map((question) => ({
        ...question,
        answer: getAnswer(question, questionnaire),
      }));

      let guestQuestions = [];
      if (questionnaire) {
        guestQuestions = questionnaire?.answer.filter((question) => question.guest) || [];
      } else {
        let id = 1;
        for (let i = 1; i <= guestCount; i++) {
          guestQuestions.push(
            {
              id,
              question: `Name of guest ${i}`,
              question_type: 'text_field',
              guest: true,
              answer: '',
            },
            {
              id: id + 1,
              question: `Details of guest ${i}`,
              question_type: 'text_field',
              guest: true,
              answer: '',
            },
          );
          id += 2;
        }
      }

      setUserQuestions([
        ...eventQuestions,
        ...guestQuestions,
      ]);
      setQuestionAndAnswerIds({
        questionId: res?.data?.id,
        answerId: questionnaire?.id,
      });
      setSelectedUserId(userId);
    })();
  };

  const renderQuestions = () => {
    const participantsArray = [...userPartcipants, ...guestPartcipants, ...companyParticipants];
    const keys = ['firstname', 'lastname', 'phoneNumber'];
    return participantsArray
      .filter(participant => keys
        .some((key) => participant[key].toLowerCase().includes(participantSearch.toLowerCase())))
      .map((participant) => (
        <tr key={`renderParticipants-${participant.id}`}>
          <td
            style={{
            position: 'sticky',
            left: 0,
            background: '#FCFCFC',
            minWidth: '100px',
            zIndex: 100,
            }}
          >{`${participant.firstname} ${participant.lastname}`}
          </td>
          <td>{participant.phoneNumber}</td>
          <td style={{ width: '300px' }}>{participant?.company?.name || ''}</td>
          {(questionsNanswers.map(obj => obj[participant.userId])).map(answer => {
            return (
                answer && Object.values(answer).map(key => {
                  return (<td className="text-center" style={{ minWidth: '100px' }} key={participant.userId + key}> {key} </td>);
                })
            );
          },
          )}
          <td
            style={{
              position: 'sticky',
              right: 0,
              background: '#FCFCFC',
              minWidth: '100px',
              zIndex: 100,
            }}
          >
            <td >
              <ButtonToolbar className="float-right">
                <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                  <IconButton
                    onClick={() => handleEditAnswers(
                      participant.userId,
                      participant.friendAmount,
                    )}
                    type={IconButton.types.EDIT}
                    id={`edit-${participant.id}`}
                    disabled={participant.participantType === 'GUEST'}
                  />
                </ButtonGroup>
              </ButtonToolbar>
            </td>
          </td>
        </tr>
      ));
  };
  const toggleCompany = (companyId) => {
    const companyIndex = companies.findIndex(({ id }) => id === companyId);
    const _companies = [...companies];
    _companies[companyIndex].toggled = !_companies[companyIndex].toggled;
    setCompanies(_companies);
  };
  const renderCompanyParticipants = () => {
    const filteredCompanyIds = [...new Set(companyParticipants.map((item) => item.companyId))];
    const participantCompaniesArray = filteredCompanyIds.map((companyId) => {
      const participantsForCompany = companyParticipants.filter((employee) => employee.companyId === companyId);
      const foundCompany = companies.find((comp) => comp.id === companyId);
      const totalParticipantAmount = participantsForCompany.reduce((tot, curr) => tot + curr.friendAmount + 1, 0);
      return ({
        ...foundCompany,
        participants: participantsForCompany,
        totalParticipantAmount,
        guestAmount: totalParticipantAmount - participantsForCompany.length,
      });
    });
    return participantCompaniesArray
      .filter(company => participantSearch.toLowerCase() === '' ? company : company.name.toLowerCase().includes(participantSearch.toLowerCase()))
      .map((company) => (
        <React.Fragment key={`renderCompany-${company.id}`}>
          <tr
            key={`renderCompany-${company.id}`}
          >
            <td >
              <Checkbox
                handleToggle={() => toggleCompany(company.id)}
                toggled={company.toggled}
                single
                index={1}
              />
            </td>
            <td className="m-0 p-0">
              <button
                onClick={() => setVisibleCompany(visibleCompany === company.id ? null : company.id)}
                style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', color: '#646777', padding: '0px 0px' }}
              >{visibleCompany === company.id ? <ChevronDownIcon /> : <ChevronRightIcon />}
              </button>

            </td>
            <td className="p-0 m-0">
              {company?.imagesUrls?.sqNormal && company?.imagesUrls?.sqNormal !== 'NULL' ? (
                <img className="company__profile__avatar mr-2" src={company?.imagesUrls?.sqNormal} alt="logo" />
              ) : (
                <span className="company__profile__avatar_placeholder mr-2">{company?.name[0]?.toUpperCase()}</span>
              )}
              {company?.name}
            </td>
            <td className="text-center">{company.totalParticipantAmount}</td>
            <td className="text-center">
              {company.guestAmount}
            </td>
            <td className="text-center">
              {company.maxPartcipants}
            </td>
            <td />
            <td />
            <td/>
          </tr>
          {visibleCompany === company.id && company.participants.map((participant) => (
            <tr
              key={`renderParticipant-${participant.id}-${company.id}`}
            >
              <td/>
              <td />
              <td className="px-4">
                {participant?.imagesUrls?.sqNormal && participant?.imagesUrls?.sqNormal !== 'NULL' ? (
                  <img className="company__profile__avatar mr-4" src={company?.imagesUrls?.sqNormal} alt="logo" />
                ) : (
                  <span className="company__profile__avatar_placeholder mr-2">{company?.name[0]?.toUpperCase()}</span>
                )}
                {participant?.firstname}
                {participant?.lastname}
              </td>
              <td />
              <td className="text-center">
                <ButtonGroup className="btn-group--icons">
                  <Button className="p-1 m-0" color="secondary" onClick={() => changeGuestAmount(participant.friendAmount - 1, participant)}>
                    <MinusIcon/>
                  </Button>
                  <p className="p-0 m-1">{participant.friendAmount}</p>
                  <Button className="p-1 m-0" color="secondary" onClick={() => changeGuestAmount(participant.friendAmount + 1, participant)}>
                    <PlusIcon />
                  </Button>
                </ButtonGroup>
              </td>
              <td />
              <td>
                {!participant.additionalNotes ? <CommentOutlineIcon onClick={() => handleEditPressed(participant)}/> : <CommentTextOutlineIcon color="#3EA3FC" onClick={() => handleEditPressed(participant)}/>}
              </td>
              <td className="d-flex justify-content-center align-items-center py-3 ">
                <ButtonGroup className="btn-group--icons">
                  <Button className="p-1 m-0" color="secondary" onClick={() => markParticipantAttendance(participant.id, participant.attendedAmount - 1)}>
                    <MinusIcon/>
                  </Button>
                  <p className="p-0 m-1">{participant.attendedAmount}</p>
                  <Button className="p-1 m-0" color="secondary" onClick={() => markParticipantAttendance(participant.id, participant.attendedAmount + 1)}>
                    <PlusIcon />
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="btn-group--icons px-1">
                  <Button className="p-2 m-0" color="secondary" onClick={() => markParticipantAttendance(participant.id, participant.friendAmount + 1)}>
                    {s('all')}
                  </Button>
                </ButtonGroup>
              </td>
              <td>
                <ButtonToolbar className="float-right">
                  <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                    <IconButton onClick={() => handleModal(participant.id)} type={IconButton.types.DELETE} id={`delete-company-${participant.id}`}/>
                  </ButtonGroup>
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </React.Fragment>

      ));
  };
  const participantMasterToggle = (_, toggled) => {
    const _participants = participants.map((item) => ({ ...item, toggled }));
    const _deletedParticipants = deletedParticipants.map((item) => ({ ...item, toggled }));
    setParticipants(_participants);
    setDeletedParticipants(_deletedParticipants);
  };
  const companyMasterToggle = (_, toggled) => {
    const eligableCompanies = [...new Set(participants.map((item) => item.companyId))].filter((item) => item);
    const _companies = companies.map((item) => ({ ...item, toggled: eligableCompanies.includes(item.id) ? toggled : item.toggled }));
    setCompanies(_companies);
  };

  const DeletedParticipantsCard = () => {
    return (
      <Container style={{ padding: 0 }}>
        <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped >
            <thead>
              <tr>
                <th/>
                <th className="sortable" onClick={() => hanldeHeaderClick('name')}>
                  {s('name')}
                  <SortDirection active={sort.column === 'name'} direction={sort.order} />
                </th>
                <th className="sortable" onClick={() => hanldeHeaderClick('phone')}>{s('phone')}
                  <SortDirection active={sort.column === 'phone'} direction={sort.order} />
                </th>
                <th className="sortable" onClick={() => hanldeHeaderClick('company')}>{s('company')}
                  <SortDirection active={sort.column === 'company'} direction={sort.order} />
                </th>
                <th>Deleted At</th>
              </tr>
            </thead>
            <tbody>
              {renderDeletedParticipants()}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  };

  const ParticipantsCard = () => {
    const masterToggled = participants.every(({ toggled }) => toggled);
    return (
      <Container style={{ padding: 0 }}>
        <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped >
            <thead>
              <tr>
                <th>
                  <Checkbox
                    handleToggle={participantMasterToggle}
                    toggled={masterToggled}
                    single
                    index={1}
                  />
                </th>
                <th className="sortable" onClick={() => hanldeHeaderClick('name')}>
                  {s('name')}
                  <SortDirection active={sort.column === 'name'} direction={sort.order} />
                </th>
                <th className="sortable" onClick={() => hanldeHeaderClick('phone')}>{s('phone')}
                  <SortDirection active={sort.column === 'phone'} direction={sort.order} />
                </th>
                <th className="sortable" onClick={() => hanldeHeaderClick('company')}>{s('company')}
                  <SortDirection active={sort.column === 'company'} direction={sort.order} />
                </th>
                <th>{s('guests')}</th>
                <th>{s('comments')}</th>
                <th>{p('attended')}</th>
                <th>{s('signUp')}</th>
                <th>{s('delete')}</th>
              </tr>
            </thead>
            <tbody>
              {renderParticipants()}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  };

  const QuestionsCard = () => {
    return (
      <Container style={{ padding: 0 }}>
        <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table
            responsive
            striped
            style={{
            minWidth: questions?.length > 0 ? '100vw' : '100%',
          }}
          >
            <thead>
              <tr>
                <th style={{
                  position: 'sticky',
                  left: 0,
                  minWidth: '100px',
                  background: '#FCFCFC',
                  zIndex: 100,
                }}
                >{s('name')}
                </th>
                <th>{s('phone')}</th>
                <th style={{ width: '300px' }}>{s('company')}</th>
                {questions?.length > 0 && questions?.map((q) => {
                  return (
                    <th className="text-center" style={{ minWidth: '100px' }} key={q.id}>
                      {displayLongStringsInToolTip(q.question, q.id)}
                    </th>
                  );
                })}
                <th style={{
                  position: 'sticky',
                  right: 0,
                  minWidth: '100px',
                  background: '#FCFCFC',
                  zIndex: 100,
                  paddingLeft: 20,
                }}
                >
                  {s('edit')}
                </th>
              </tr>
            </thead>
            <tbody>
              {renderQuestions()}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  };

  const CompanyParticipantsCard = () => {
    const eligableCompanies = [...new Set(participants.map((item) => item.companyId))].filter((item) => item);
    const foundCompanies = companies.filter((comp) => eligableCompanies.includes(comp.id));
    const masterToggled = foundCompanies.every(({ toggled }) => toggled);
    return (
      <Container style={{ padding: 0 }}>
        <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    handleToggle={companyMasterToggle}
                    toggled={masterToggled}
                    single
                    index={1}
                  />
                </th>
                <th />
                <th>{p('company')}</th>
                <th className="text-center">{s('particpants')}</th>
                <th className="text-center">{s('guests')}</th>
                <th className="text-center">{p('maxParticipants')}</th>
                <th className="text-center">{s('comments')}</th>
                <th className="text-center">{p('attended')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderCompanyParticipants()}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  };

  const showDropdownLabel = (type) => {
    if (type === 'person') {
      return s('users');
    }

    if (type === 'questions') {
      return s('questions');
    }

    if (type === 'deleted') {
      return p('deletedParticipant');
    }

    return p('company');
  };

  const handleSubmit = async (values) => {
    try {
      const updatedAnswers = userQuestions.map((answer) => {
        const getValue = () => {
          if (answer.question_type === 'dropdown') {
            return values[answer.id].value || values[answer.id];
          } else if (answer.question_type === 'checkbox') {
            const selectedIds = Object.keys(values).filter((id) => {
              return id.substring(0, id.indexOf(' ')) === answer.id && values[id];
            });

            return selectedIds.map((id) => id.substring(id.indexOf(' ') + 1));
          }
          return values[answer.id];
        };

        const updatedAnswer = { ...answer };
        updatedAnswer.answer = getValue();
        return updatedAnswer;
      });

      const payload = {
        questionId: questionAndAnswerIds.questionId,
        answerId: questionAndAnswerIds.answerId,
        answer: updatedAnswers,
      };

      if (questionAndAnswerIds.answerId) {
        payload.answerId = questionAndAnswerIds.answerId;
      } else {
        payload.userId = selectedUserId;
      }

      await saveAnswers(payload);

      setShowEditModal(false);
      setUserQuestions([]);
      setQuestionAndAnswerIds(null);
      setSelectedUserId(null);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    }
  };

  return (
    <Container>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={s('participants')}
        modalAction={() => removeParticipant(comment, deleteId)}
        canComment
        comment={comment}
        onCommentChange={setComment}
      />
      <ShareModal
        visible={modalVisible}
        title={t('EventsPage.addNotes')}
        handleModal={handleCloseModal}
      >
        <CommentModal
          selectedParticipantForComment={selectedParticipantForComment}
          handleCloseModal={handleCloseModal}
          getPaticipants={getPaticipants}
        />
      </ShareModal>
      <ShareModal
        visible={inviteModelVisible}
        handleModal={() => { setInviteModelVisible(false); setCurrentParticipant(null); }}
      >
        <InviteParticipantsModal
          currentParticipant={currentParticipant}
          participantType={participantType}
          companies={companies}
          handleSubmit={handleInviteParticipant}
          event={event}
          maxGuestAmount={maxGuestAmount}
          setMaxGuestAmount={setMaxGuestAmount}
        />
      </ShareModal>
      <ShareModal
        title={qst('editAnswers')}
        visible={showEditModal}
        handleModal={() => {
          setShowEditModal(false);
          setUserQuestions([]);
          setQuestionAndAnswerIds(null);
          setSelectedUserId(null);
        }}
      >
        <EditAnswersModal
          userQuestions={userQuestions}
          onSubmit={handleSubmit}
        />
      </ShareModal>
      <Row className="flex justify-content-between">
        <div className="flex">
          <UncontrolledTooltip placement="bottom" target="countParticipant">
            {s('guests')}
          </UncontrolledTooltip>
          <UncontrolledTooltip placement="bottom" target="countAttended">
            {p('attended')}
          </UncontrolledTooltip>
          <ParticipationDisplayCounter
            id="countParticipant"
            color="primary"
            text={`${totalParticipantCount}/${event?.maxAttendees || 0}`}
            icon={<AccountGroup style={{ width: 24, display: 'inline-block' }} />}
          />
          <ParticipationDisplayCounter
            id="countAttended"
            color="success"
            text={`${attendedCount}/${totalParticipantCount}`}
            icon={<AccountMultipleCheckIcon style={{ width: 24, display: 'inline-block' }} />}
          />
        </div>
        <div className="flex">
          <Button
            className="p-2 float-right ml-2 mr-0 mb-0"
            color="secondary"
            size="sm"
            outline
            onClick={exportParticpants}
            disabled={participants?.length === 0}
          >
            <DownloadIcon />{p('exportParticipantsCSV')}
          </Button>
        </div>
      </Row>
      <Row className="mt-3 flex-wrap-reverse justify-content-between">
        <div className="flex">
          <UncontrolledTooltip placement="bottom" target="delete">
            {p('deleteParticipants')}
          </UncontrolledTooltip>
          <Button
            id="delete"
            color="danger"
            size="sm"
            style={{ padding: '10px' }}
            disabled={!selectedParticipantIds.length}
            onClick={() => {
            deleteMultipleParticipants(selectedParticipantIds);
          }}
          >
            <DeleteIcon style={{ width: 18, height: 18 }} />
          </Button>
          <UncontrolledDropdown className="mr-0">
            <DropdownToggle className="icon icon--right" outline>
              <p>{showDropdownLabel(participantType)} <ChevronDownIcon /></p>
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu py-0">
              <DropdownItem value="person" onClick={(e) => setParticipantType(e.target.value)}>{s('users')}</DropdownItem>
              <DropdownItem value="company" onClick={(e) => setParticipantType(e.target.value)}>{p('company')}</DropdownItem>
              <DropdownItem value="questions" style={{ opacity: questions?.length === 0 ? 0.5 : 1 }} disabled={questions?.length === 0} onClick={(e) => setParticipantType(e.target.value)}>{s('questions')}</DropdownItem>
              <DropdownItem value="deleted" onClick={(e) => setParticipantType(e.target.value)}>{p('deletedParticipant')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="form noWrap">
            <div className="form__form-group ">
              <input
                className="searchInput"
                value={participantSearch}
                placeholder={`${s('search')}...`}
                name="search participants"
                onChange={(e) => setParticipantSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <UncontrolledTooltip placement="bottom" target="addParticipant">
            {p('inviteParticipants')}
          </UncontrolledTooltip>
          <Button
            id="addParticipant"
            color="primary"
            size="sm"
            style={{ padding: '10px' }}
            onClick={() => {
            setInviteModelVisible(true);
          }}
          >
            <UserAddIcon style={{ width: 18, height: 18 }} /> {p('inviteParticipants')}
          </Button>
        </div>
      </Row>
      <Row>
        {participantType === 'person' && <ParticipantsCard />}
        {participantType === 'company' && <CompanyParticipantsCard />}
        {participantType === 'questions' && <QuestionsCard />}
        {participantType === 'deleted' && <DeletedParticipantsCard />}
      </Row>
    </Container>
  );
};

EventsPariticipantForm.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const reduxF = reduxForm({
  form: 'EventsPariticipantForm',
  enableReinitialize: true,
});

export default reduxF(EventsPariticipantForm);
