import React, { useState } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavTab from '../../shared/components/custom/NavTab';
import FieldTypesList from './components/FieldTypesList';
import SeatMap from './components/SeatMap';
import { partial } from '../../util/translation/translation';

const StadiumPage = ({ me }) => {
  const p = partial('StadiumPage');
  const [activeTab, setActiveTab] = useState('1');

  const renderTabs = () => {
    const permissions = me?.permissions ?? [];
    const hasStadiumRole = permissions.includes('access-stadium');
    const hasSettingsRole = permissions.includes('access-settings');
    const tabs = [];
    if (hasStadiumRole) tabs.push(p('editSeatMap'));
    if (hasSettingsRole) tabs.push(p('fieldTypes'));
    return tabs;
  };

  const renderTabComponents = () => {
    const permissions = me?.permissions ?? [];
    const hasStadiumRole = permissions.includes('access-stadium');
    const hasSettingsRole = permissions.includes('access-settings');
    if (!hasStadiumRole && !hasSettingsRole) {
      return <></>;
    } else if (hasStadiumRole && !hasSettingsRole) {
      return <SeatMap />;
    } else if (!hasStadiumRole && hasSettingsRole) {
      return <FieldTypesList />;
    }
    return (
      <>
        { activeTab === '1' &&
          <SeatMap />
        }
        { activeTab === '2' &&
          <FieldTypesList />
        }
      </>
    );
  };

  return (
    <Container>
      <NavTab
        activeTab={activeTab}
        setTab={setActiveTab}
        tabs={renderTabs()}
      />
      {renderTabComponents()}
    </Container>
  );
};

StadiumPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  me: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
};

StadiumPage.defaultProps = {
  me: null,
};

const mapStateToProps = state => ({
  me: state.admin.me,
});

export default connect(mapStateToProps)(StadiumPage);
