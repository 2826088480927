import { initializeApp } from 'firebase/app';
import firebaseDevConfig from './firebaseConfig-dev.json';
import firebaseProdConfig from './firebaseConfig-prod.json';

const splitPath = window.location.pathname.slice(1, window.location.pathname.length).split('/');
const club = splitPath[0];
const DEV_CLUBS = ['tfg', 'tfgesp'];
const firebaseConfig = DEV_CLUBS.includes(club) ? firebaseDevConfig : firebaseProdConfig;
export { firebaseConfig };
export default initializeApp(firebaseConfig);
