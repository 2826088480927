export default {
  club_name: 'Ringerike Panthers',
  club_name_short: 'RIK',
  stadium_name: 'Schjongshallen',
  staging_url: 'https://rik.api.fangroup.io',
  prod_url: 'https://rik.api.fangroup.io',
  api: '/api',
  facebook: 'ringerikepanthers',
  basename: '/rik',
  club_logo: '/assets/teams/rik.png',
  app_name: 'rik',
  websale_name: 'rik',
  ntb_team_id: 141,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'rik',
  monolithEnabled: false,
};
