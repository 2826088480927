import React from 'react';
import { Field, propTypes as reduxProps, getFormValues, reduxForm } from 'redux-form';
import { Col, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';

import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const p = partial('RuleFields');
const RuleFields = ({
  priceGroups, modifiers, seasonPriceGroups, array: { push }, fields, meta: { error, submitFailed }, formValues,
}) => (
  <>
    <Row>
      <Col md={12}>
        <Button color="success" onClick={() => push('rules', { id: fields.length })} >
          {p('addRule')}
          <PlusCircleOutlineIcon className="icon-24 marginLeft-8 " />
        </Button>
        {submitFailed && error && <span className="form__form-group-error inline-flex">{error}</span>}
      </Col>
    </Row>
    {fields && fields.length > 0 && (
      <Row>
        <Col lg={3} md={4}>
          <span className="form-header">{s('priceGroup')}</span>
          <div className="marginTop-5" />
        </Col>
        <Col lg={1} md={1}>
          <div className="marginTop-5" />
        </Col>
        <Col lg={3} md={4}>
          <span className="form-header">{s('priceGroup')}</span>
          <div className="marginTop-5" />
        </Col>
      </Row>
    )}
    {fields.map((rule, index) => (
      <Row key={rule}>
        <Col lg={3} md={4}>
          <div className="form__form-group">
            <Field
              name={`${rule}.priceGroup`}
              component={renderSelectField}
              options={formValues.seasonTicketSale === '0' ? priceGroups : seasonPriceGroups}
              placeholder={p('selectPricegroup')}
            />
          </div>
        </Col>
        <Col lg={3} md={4}>
          <div className="form__form-group">
            <Field
              name={`${rule}.modifier`}
              component={renderSelectField}
              options={modifiers}
              placeholder={p('selectLogic')}
            />
          </div>
        </Col>
        <Col lg={3} md={4}>
          <div className="form__form-group">
            <Field
              name={`${rule}.quantity`}
              component={renderField}
              type="number"
              placeholder={p('selectQuantity')}
            />
          </div>
        </Col>
        <Col lg={3} md={4} className="padding-top-3">
          <button className="outline-badge danger" onClick={() => fields.remove(index)}> {s('remove')} </button>
        </Col>
      </Row>
    ))}
  </>
);

const reduxF = reduxForm({
  form: 'createDiscountForm',
});

RuleFields.propTypes = {
  ...reduxProps,
  formValues: PropTypes.shape({
    seasonTicketSale: PropTypes.string,
  }),
};

RuleFields.defaultProps = {
  formValues: {},
};

export default connect(state => ({
  formValues: getFormValues('createDiscountForm')(state),
  discounts: state.discounts,
}))(reduxF(RuleFields));

