import React from 'react';
import { partial } from '../../../../util/translation/translation';

const qT = partial('question');

export default function SearchField(props) {
  return (
    <form action="true">
      <input
        type="search"
        name="search-question-library"
        placeholder={qT('searchQuestions')}
        className="search-field"
        value={props.value}
        onChange={props.onChange}
      />
    </form>
  );
}
