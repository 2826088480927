export default {
  club_name: 'Sparta Warriors',
  club_name_short: 'Sparta',
  stadium_name: 'Sparta Amfi',
  staging_url: 'https://sparta.api.fangroup.io',
  prod_url: 'https://sparta.api.fangroup.io',
  api: '/api',
  facebook: 'Sparta.Warriors.Hockey',
  basename: '/sparta',
  club_logo: '/assets/teams/sparta.png',
  app_name: 'sparta',
  websale_name: 'spa',
  ntb_team_id: 130,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: false,
  cloudIdName: 'sparta',
  monolithEnabled: false,
};
