import React, { useState } from 'react';
import {
  Row, Container, TabContent, TabPane,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import NavTab from '../../../shared/components/custom/NavTab';
import { partial } from '../../../util/translation/translation';
import PersonCardForm from './PersonCardForm';
import CompanyCardForm from './CompanyCardForm';
import GuestCardForm from './GuestCardForm';

const InviteParticipantsModal = ({
  companies,
  handleSubmit,
  currentParticipant,
  participantType,
  event,
  setMaxGuestAmount,
  maxGuestAmount,
}) => {
  const p = partial('EventParticipantsPage');
  const s = partial('shared');
  const participantTypeForEditing = currentParticipant?.companyId ? 'COMPANY' : 'USER';
  const [activeTab, setActiveTab] = useState(currentParticipant?.companyId || participantType !== 'person' ? '1' : '2');
  return (
    <div className="form">
      <Row className="mx-2 d-flex justify-content-between border-bottom-5">
        <div >
          <h3 className="page-title">{p('inviteParticipants')}</h3>
        </div>
      </Row>
      <Container>
        <NavTab
          tabs={[
            { tab: s('company'), disabled: currentParticipant && participantTypeForEditing !== 'COMPANY' },
            { tab: s('user'), disabled: currentParticipant && participantTypeForEditing === 'COMPANY' },
            { tab: s('guest'), disabled: false },
          ]}
          activeTab={activeTab}
          setTab={setActiveTab}
          inline
          replaceHistory={false}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CompanyCardForm
              companies={companies}
              onSubmit={handleSubmit}
              participant={participantTypeForEditing === 'COMPANY' ? currentParticipant : null}
            />
          </TabPane>
          <TabPane tabId="2">
            <PersonCardForm
              onSubmit={handleSubmit}
              participant={participantTypeForEditing !== 'COMPANY' ? currentParticipant : null}
              event={event}
              maxGuestAmount={maxGuestAmount}
              setMaxGuestAmount={setMaxGuestAmount}
            />
          </TabPane>
          <TabPane tabId="3">
            <GuestCardForm
              onSubmit={handleSubmit}
              event={event}
              participant={participantTypeForEditing === 'COMPANY' ? currentParticipant : null}
              maxGuestAmount={maxGuestAmount}
            />
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
};

const reduxF = reduxForm({
  form: 'InviteParticipantsCard',
  enableReinitialize: true,
});


export default reduxF(InviteParticipantsModal);


