import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Table, CardText, CardTitle, Col, Row, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { Parser } from 'json2csv';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import Pagination from '../../shared/components/pagination/Pagination';
import Loading from '../../shared/components/custom/Loading';


const FanRating = ({ match }) => {
  const config = useConfig();
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [totalRatings, setTotalRatings] = useState(undefined);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [topPlayers, setTopPlayers] = useState(null);

  const t = partial('Result');
  const s = partial('shared');

  const setDataToObject = (rating, userID) => {
    return (rating.data.reduce((acc, curr) => {
      const index = userID.findIndex((user) => {
        return curr.fanrating_userId === user.userCloudId;
      });
      return index === -1 ? acc : [...acc, {
        id: userID[index].userCloudId,
        name: `${userID[index].firstname} ${userID[index].lastname}`,
        phoneNumber: `${userID[index].phoneNumber}`,
        email: userID[index].email,
        votedFor: curr.player_fullname,
        uniqueID: curr.fanrating_id,

    }];
    }, []));
  };

  const getFanRating = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const currentTeam = config.teams.find((team) => team.id === match?.homeTeam.id || team.id === match?.awayTeam.id);
      const rating = await Api.clubadmin.match.getFanRating(match.id, currentTeam.id, page, pageSize);

      const getAllRating = await Api.clubadmin.match.getTopFanRating(match.id, currentTeam.id);

      if (getAllRating.data.length > 0) {
        const sortAllRating = getAllRating.data.sort((a, b) => b.rating - a.rating);
        const [threeStar, twoStar, oneStar] = sortAllRating;
        setTopPlayers([threeStar, twoStar, oneStar]);
      }

      const userRating = await Api.clubadmin.match.getUserRating(match.id, currentTeam.id);

      const result = (userRating.data).map((user) => user.userId);
      const { data: userID } = await Api.users.getUsersByCloudIds(result);
      setData(rating.data);
      setUserData(userID);

      const tableData = setDataToObject(rating.data, userID);
      setTotalRatings(rating?.data.total);
      setRatings(tableData);

      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (match) {
      getFanRating();
    }
  }, [match]);

  const renderFanRating = () => {
    return ratings.map((rating) => (
      <tr key={`renderFanRating-${rating?.uniqueID}`}>
        <td>{`${rating?.name}`}</td>
        <td>{rating?.phoneNumber.startsWith('+') ? rating?.phoneNumber : `+${rating?.phoneNumber}`}</td>
        <td>{rating?.email}</td>
        <td>{rating?.votedFor}</td>
      </tr>
    ));
  };

  const onChangePageSystem = (pager) => {
    if (match) {
      getFanRating(pager.currentPage, pager.pageSize);
    }
  };

  const exportFanRating = async () => {
    setLoading(true);
    try {
      const page = 1;
      const pageSize = 10;
      const currentTeam = config.teams.find((team) => team.id === match?.homeTeam.id || match?.awayTeam.id);

      const allRating = await Api.clubadmin.match.getAllRating(match.id, currentTeam.id, page, pageSize, true);

      const tableData = setDataToObject(allRating, userData);

      if (tableData.length <= 0) {
        toast.error(t('noVotesToDownload'));
        setLoading(false);
        return;
      }
      const fields = [
        { label: 'Name', value: 'name' },
        { label: 'Phone', value: 'phoneNumber' },
        { label: 'email', value: 'email' },
        { label: 'Voted For', value: 'votedFor' },
      ];
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(tableData);
      const fileName = `${match?.homeTeam.shortName} - ${match?.awayTeam.shortName}`;
      fileDownload(`\uFEFF${csv}`, `${fileName}_FanRating.csv`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  return (

    <Container>
      <Loading loading={loading} />
      {totalRatings ?
        <Row>
          <Col>
            <Card> <CardTitle tag="h4"> {t('votes')}: {totalRatings} </CardTitle></Card>
          </Col>
        </Row> :
        <></>
      }
      <Row>
        {topPlayers ? topPlayers.map((rating, index) => (
          <Col key={rating?.playerId}>
            <Card>
              <CardBody>
                <CardTitle tag="h4" className="text-center">{`${t('ranked')} nr ${index + 1}`}</CardTitle>
                <div className="flex-Vcenter">
                  <Col md={6}>
                    <img
                      style={{ width: '90%' }}
                      src={rating?.player_imageUrl}
                      alt=""
                    />
                  </Col>
                  <Col md={6}>
                    <CardText>{`${t('name')}: ${rating?.player_firstname} ${rating?.player_lastname}`}</CardText>
                    <CardText>{`${t('jersey')}: ${rating?.player_jerseyNumber}`}</CardText>
                    <CardText>{`${t('votes')}: ${rating?.votes}`}</CardText>
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Col>
      )) : <></>}
      </Row>
      <Button onClick={exportFanRating} color="secondary" size="sm">
        {t('exportFanRating')}
      </Button>
      <Card>
        <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <Table responsive striped>
            <thead>
              <tr>
                <th >
                  {s('name')}
                </th>
                <th >
                  {s('phonenumber')}
                </th>
                <th >
                  {s('email')}
                </th>
                <th >
                  {t('votedFor')}
                </th>
              </tr>
            </thead>
            <tbody>{match && renderFanRating()}</tbody>
          </Table>
        </div>
        <Pagination
          items={data.total ?? 0}
          pageSize={10}
          onChangePage={onChangePageSystem}
        />
      </Card>
    </Container>
  );
};


export default FanRating;
