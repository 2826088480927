import React from 'react';
import { Table, Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Checkbox from '../../../shared/components/custom/Checkbox';
import { partial } from '../../../util/translation/translation';

const PriceGroupFieldsExclusions = ({ fields, handleAvailable, handleSubmit }) => {
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const renderFields = () => {
    return fields.map((field, index) => (
      <tr key={`renderFields-${field.id}`} className={field.available ? 'listSelected transitionDuration-03' : 'transitionDuration-03'}>
        <td>
          <Checkbox
            handleToggle={() => handleAvailable(index)}
            toggled={field.available}
            color="#ffffff"
            selectedColor="#ffffff"
            index={index}
          />
        </td>
        <td>{field.name}</td>
      </tr>
    ));
  };
  return (
    <form onSubmit={handleSubmit}>
      <p>{p('chosenFieldsIsPossibleToBuyWithThisPriceGroup')}</p>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>
              <Checkbox
                handleToggle={() => handleAvailable(-1)}
                toggled={fields.every(field => field.available === true)}
                color="#ffffff"
                selectedColor="#ffffff"
                index={-1}
              />
            </th>
            <th>{s('fieldName')}</th>
          </tr>
        </thead>
        <tbody>
          {renderFields()}
        </tbody>
      </Table>
      <Col md={12} style={{ marginTop: 20 }}>
        <Button type="submit" color="primary">{s('save')}</Button>
        <Link className="color-unset" to="/settings?tab=2"><Button type="button">{s('close')}</Button></Link>
      </Col>
    </form>
  );
};

PriceGroupFieldsExclusions.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  handleAvailable: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'price_group_form',
});
export default reduxF(PriceGroupFieldsExclusions);
