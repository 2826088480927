import React, { useEffect, useState } from 'react';
import { Button, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';

const GoalSponsor = () => {
  const ct = partial('Category');
  const s = partial('shared');
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
  const [settings, setSettings] = useState([]);

  const fetchSettings = async (comps) => {
	const { data: resSettings } = await Api.club.getSettingsByKey('MatchinfoSponsorId');
	setSettings(resSettings);
	if (resSettings.length > 0) {
		const foundSponsor = comps.find((company) => company.value === resSettings.find((setting) => setting.key === 'MatchinfoSponsorId')?.value);
		setSelectedCompanyId(foundSponsor);
	}
  };
  useEffect(() => {
    const fetchCompanies = async () => {
        const clubCompanies = await Api.companies.getClubCompanies();
        const mappedCompanies = clubCompanies.map((company) => ({ label: company.name, value: company.id }));
		fetchSettings(mappedCompanies);
        setCompanies(mappedCompanies);
    };
    fetchCompanies();
  }, []);
 const handleSubmitGoalSponsor = async (selectedCompany) => {
    try {
      await Promise.all(settings.map((setting) => Api.club.deleteSetting(setting.id)));
      if (selectedCompany) {
      const payload = {
        key: 'MatchinfoSponsorId',
        value: selectedCompany.value,
      };
      await Api.club.createSetting(payload);
	  await fetchSettings(companies);
      toast.success(`${s('created')}`);
    } else {
		await fetchSettings(companies);
		toast.success(`${s('removed')}`);
	}
    } catch (err) {
        toast.error(err || `${s('somethingWentWrong')}`);
    }
  };
  return (
    <Card>
      <CardBody>
        <div className="card__title margin-5">
          <p className="bold">{ct('goalService')}</p>
        </div>
        <div className="form__form-group mx-2">
          <span className="form-header">{s('company')}</span>
          <Select
            placeholder={t('EventParticipantsPage.chooseCompany')}
            options={companies}
            value={selectedCompanyId}
            onChange={setSelectedCompanyId}
          />
        </div>
        <Col md={12}>
          <Button onClick={() => handleSubmitGoalSponsor(selectedCompanyId)} color="primary">{s('save')}</Button>
          <Link className="color-unset" to="/companies?tab=1">
            <Button type="button">{s('close')}</Button>
          </Link>
        </Col>
      </CardBody>
    </Card>
  );
};


export default GoalSponsor;
