import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.startDate) {
    errors.startDate = p('startDateFieldCantBeEmpty');
  }

  if (!values.endDate) {
    errors.endDate = p('endDateFieldCantBeEmpty');
  }

  if (!values.saleStart) {
    errors.saleStart = p('saleStartFieldCantBeEmpty');
  }

  if (!values.saleEnd) {
    errors.saleEnd = p('saleEndFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
