import React, { useEffect } from 'react';
import { Col, Row, Button, Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import TimetableIcon from 'mdi-react/TimetableIcon';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import moment from 'moment';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import validateAuctionItem from './validateAuctionItem';
import renderSwitchField from '../../../shared/components/custom/Switch';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
import { getImageHash } from '../../../util/functions';

const AuctionObjectForm = ({
  auctionHouseId, auctionItem, type, handleSubmit, initialize,
}) => {
  const config = useConfig();
  const p = partial('auctionObjectsShared');
  const s = partial('shared');

  useEffect(() => {
    if (auctionItem) {
      initialize({
        name: auctionItem.name,
        description: auctionItem.description,
        isActive: !!auctionItem.isActive,
        startDate: moment(auctionItem.startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(auctionItem.endDate).format('YYYY-MM-DDTHH:mm'),


        startValue: auctionItem.startValue,
        bidExtensionValue: auctionItem.bidExtensionValue,
        bidExtensionTime: auctionItem.bidExtensionTime,
        headingImage: {
          name: 'auction item image',
          preview: auctionItem.imageUrl,
          croppedImage: auctionItem.imageUrl,
          hash: getImageHash(auctionItem.imageUrl),
        },
        dynamicLink: auctionItem.dynamicLink,
      });
    }
  }, [auctionItem]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginBottom: 10, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('image')}</div>
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="form__form-group">
                  <Field
                    saveBase64Cropped
                    name="headingImage"
                    component={renderDropZoneField}
                    displayCrop
                    crop="BANNER_CROP"
                  />
                  <span className="form-header"><strong>{s('note')}:</strong> {s('recommendedImageSize')} 16:9 (1035 x 570 px)</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: 10, marginBottom: 10, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('details')}</div>
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <Container>
            <Row>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form-header">{s('name')}*</span>
                  <Field
                    name="name"
                    component={renderField}
                    placeholder={s('name')}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form-header">{s('description')}</span>
                  <Field
                    name="description"
                    component={renderField}
                    placeholder={s('description')}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{p('startingBid')}*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="startValue"
                      component={renderField}
                      type="number"
                      placeholder={p('startingBid')}
                    />
                    <div className="form__form-group-icon">&nbsp;{config.currencyShortSmall}&nbsp;</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form__form-group flex-Vcenter">
                  <span className="form-header" />
                  <Field
                    name="isActive"
                    component={renderSwitchField}
                    label={[`${s('active')}`, `${s('inActive')}`]}
                    defaultChecked
                    className="colored-click"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form">{p('biddingOpens')}*</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                    <Field
                      name="startDate"
                      component={renderField}
                      type="datetime-local"
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form">{p('biddingCloses')}*</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                    <Field
                      name="endDate"
                      component={renderField}
                      type="datetime-local"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{p('minBidIncrease')}*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="bidExtensionValue"
                      component={renderField}
                      type="number"
                      placeholder={`${p('minIncreasePerBid')} ${config.currencyShortSmall}`}
                    />
                    <div className="form__form-group-icon">&nbsp;{config.currencyShortSmall}&nbsp;</div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{p('bidExtensionInMinutes')}*</span>
                  <div className="form__form-group-field">
                    <Field
                      name="bidExtensionTime"
                      component={renderField}
                      type="number"
                      placeholder={p('minutesExtendedPerBid')}
                    />
                  </div>
                </div>
              </Col>
              {auctionItem ?
                <Col>
                  <div className="form__form-group">
                    <span className="form-header marginRight-8">{s('universalLink')}</span>
                    <div className="form__form-group-field">
                      <Field name="dynamicLink" component="input" disabled />
                      <button type="button" className="form__form-group-icon" onClick={() => navigator.clipboard.writeText(auctionItem.dynamicLink)}>
                        <LinkVariantIcon />
                      </button>
                    </div>
                  </div>
                </Col> : null}
            </Row>
          </Container>
        </div>
      </div>
      <Col>
        <Button color="primary">{type === 'create' ? `${s('create')}` : `${s('save')}`}</Button>
        <Link to={`/auctions/edit/${auctionHouseId}?tab=2`}><Button>{s('close')}</Button></Link>
      </Col>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'auction_item_form',
  validate: validateAuctionItem,
});

export default reduxF(AuctionObjectForm);
