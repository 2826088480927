export const SET_ME = 'SET_ME';
export const SET_IS_AUTHENTICIATED = 'SET_IS_AUTHENTICIATED';
export const SET_HAS_CHECKED_PERMISSIONS = 'SET_HAS_CHECKED_PERMISSIONS';

export function setMe(me) {
  return {
    type: SET_ME,
    me,
  };
}

export function setIsAuthenticated(isAuthenticated) {
  return {
    type: SET_IS_AUTHENTICIATED,
    isAuthenticated,
  };
}

export function setHasCheckedPermissions(path, foundPath, hasPermission = true) {
  return {
    type: SET_HAS_CHECKED_PERMISSIONS,
    path,
    foundPath,
    hasPermission,
  };
}
