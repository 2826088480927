import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateLogin = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  if (!values.password) {
    errors.password = p('passwordFieldCantBeEmpty');
  }

  return errors;
};

export default validateLogin;
