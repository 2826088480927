import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateExternalStore = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }
  if (!values.externalUrl) {
    errors.externalUrl = p('externalResourceFieldCantBeEmpty');
  }
  if (!values.saleStart) {
    errors.saleStart = p('availableFromFieldCantBeEmpty');
  }

  if (!values.buttonText) {
    errors.buttonText = p('buttonTextCantBeEmpty');
  }

  return errors;
};

export default validateExternalStore;
