const backgroundColors = ['#0469DC', '#399351', '#ED9E45', '#703FF3', '#EB4747', '#2CC975', '#5FA8FC', '#A98CF8', '#F4C590', '#6C7689'];

const getSortedChartData = (collection, labelField, dataField) => {
    const labels = [];
    const dataset = [];
    const colors = [];
    const sortedCollection = collection.filter(data => Number(data[dataField]) > 0).sort((a, b) => b[dataField] - a[dataField]);
    for (let i = 0; i < sortedCollection.length; i++) {
        if (sortedCollection[i][dataField] !== 0) {
            if (i < 9) {
                labels.push(sortedCollection[i][labelField]);
                dataset.push(Number(sortedCollection[i][dataField]));
                colors.push(backgroundColors[i]);
            } else {
                labels.push('Others');
                dataset.push(sortedCollection.slice(i).reduce((total, entity) => total + Number(entity[dataField]), 0));
                colors.push(backgroundColors[i]);
                break;
            }
        } else {
            break;
        }
    }
    return { labels, dataset, colors };
};

const helpers = {
    backgroundColors,
    getSortedChartData,
};

export default helpers;
