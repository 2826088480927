import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  sidebarReducer, themeReducer, newsReducer,
  matchReducer, userReducer, companyReducer,
  seasonReducer, teamReducer, priceGroupReducer,
  matchTypeReducer, fieldTypeReducer, kioskReducer,
  adminReducer, balanceReducer, enabledFeaturesReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  news: newsReducer,
  match: matchReducer,
  user: userReducer,
  company: companyReducer,
  kiosk: kioskReducer,
  season: seasonReducer,
  team: teamReducer,
  priceGroup: priceGroupReducer,
  matchType: matchTypeReducer,
  fieldType: fieldTypeReducer,
  admin: adminReducer,
  balance: balanceReducer,
  enabledFeatures: enabledFeaturesReducer,
});
/* eslint-disable no-underscore-dangle */
const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
