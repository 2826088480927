import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Row, CardBody, Card, Button } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { getFormValues, isValid, touch } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import t, { partial } from '../../util/translation/translation';
import SpotlightTileForm from './components/SpotlightTileForm';
import TileAccessForm from './components/TileAccessForm';
import useConfig from '../../util/useConfig';
import { isFilterable } from './spotlightUtils';
import SpotlightAppPreview from './components/SpotlightAppPreview';
import { calcImagSize } from '../../util/image-functions';

const SpotlightTileFormPage = ({
  tileAccessFormValues,
  tileFormValues,
  tileAccessFormValid,
  tileFormValid,
  dispatch,
}) => {
  const history = useHistory();
  const config = useConfig();
  const [entity, setEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [loadingAccess, setLoadingAccess] = useState(false);
  const [loadingGroupData, setLoadingGroupData] = useState(false);
  const [selectedEntityAccess, setSelectedEntityAccess] = useState(null);
  const [tileAccess, setAccess] = useState({});
  const [segments, setSegments] = useState([]);
  const [groupsLevelsTiers, setGroupsLevelsTiers] = useState({
    tiers: [],
    groups: [],
    levels: [],
  });
  const { groupID, tileID: entityID } = useParams();
  const p = partial('HighlightBannerFormPage');
  const s = partial('shared');
  const e = partial('EventsForm');
  const getData = async () => {
    setLoading(true);
    try {
      const tileData = await Api.spotlight.getTileById(entityID);
      setEntity(tileData);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const getTileAccess = async () => {
    setLoadingAccess(true);
    try {
      const [resAccess, resLevels, resGroups, { data: resTiers }, resSegments] = await Promise.all([
        Api.spotlight.getTileAccess(entityID),
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
        Api.spotlight.getSegments(),
      ]);
      const mappedTiers = [
        { label: e('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: e('allGroups'), value: [] },
        { label: s('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: e('allLevels'), value: [] },
        { label: s('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedSegments = resSegments.map((item) => ({ label: item.name, value: item.id }));
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setSegments(mappedSegments);
      setLoadingAccess(false);
      setAccess(resAccess);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };
  const getGroupsTiersLevels = async () => {
    setLoadingAccess(true);
    try {
      const [resLevels, resGroups, { data: resTiers }, resSegments] = await Promise.all([
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.tiers.allTiers(config),
        Api.spotlight.getSegments(),
      ]);
      const mappedTiers = [
        { label: e('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      const mappedGroups = [
        { label: e('allGroups'), value: [] },
        { label: s('ignore'), value: null },
        ...resGroups.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedLevels = [
        { label: e('allLevels'), value: [] },
        { label: s('ignore'), value: null },
        ...resLevels.map((item) => ({ label: item.value, value: item.value })),
      ];
      const mappedSegments = [
        { label: s('ignore'), value: null },
        ...resSegments.map((item) => ({ label: item.name, value: item.id })),
      ];
      setSegments(mappedSegments);
      setGroupsLevelsTiers({
        tiers: mappedTiers,
        groups: mappedGroups,
        levels: mappedLevels,
      });
      setLoadingAccess(false);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };
  const getGroupData = async () => {
    setLoadingGroupData(true);
    try {
      const groupDataResponse = await Api.spotlight.getGroupById(groupID);
      setGroupData(groupDataResponse);
      setLoadingGroupData(false);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingGroupData(false);
    }
  };
  useEffect(() => {
    if (entityID) {
      getData();
      getTileAccess();
    }
    getGroupData();
    getGroupsTiersLevels();
  }, [entityID]);
  const getSubmitContentId = (values) => {
    if (values.content.value === 'AUCTIONS') {
      const auctionHouseId = values.contentType.value;
      const subContentType = values?.subContentType?.value;
      return auctionHouseId ? `${auctionHouseId}${subContentType ? `|${subContentType}` : ''}` : null;
    }
    if (isFilterable(values.content.value)) {
      return values.contentType.value;
    } else {
      return values.contentType;
    }
  };
  const onTileSubmit = async (values) => {
    const payload = {
      title: values.title,
      description: values.description,
      publicationDate: moment(values.publicationDate),
      expirationDate: values.expirationDate ? moment(values.expirationDate) : undefined,
      contentType: values.content.value,
      contentId: getSubmitContentId(values),
      groupId: groupID,
      image: (values.content.value === 'YOUTUBE_VIDEO' || groupData?.format === 'TEXT_BANNER') ? undefined : (values.headingImage.croppedBase64 || values.headingImage.preview),
    };

    try {
      calcImagSize(values.headingImage?.croppedBase64, 3000);
    } catch (err) {
      toast.error(s('imageTooLarge', { size: '3MB' }));
      return;
    }

    setLoading(true);
    let finalTileId = null;
    try {
      if (entityID) {
        await Api.spotlight.updateTile(entityID, payload);
      } else {
        const createTileResponse = await Api.spotlight.createTile(payload);
        finalTileId = createTileResponse.id;
      }
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    }
    setLoading(false);
    // eslint-disable-next-line consistent-return
    return { tileId: entityID || finalTileId, isNew: !!finalTileId };
  };
  const getAccessValues = (searchArray) => {
    if (searchArray.length === 0) {
      return null;
    }
    const foundItem = searchArray.find(item => Array.isArray(item.value) || item.value === null);
    if (foundItem) {
      return foundItem.value;
    }
    return searchArray.map((item) => item.value);
  };
  const onAccessSubmit = async (tileId, values) => {
    const payload = {
      tiers: getAccessValues(values?.tier || []),
      groups: getAccessValues(values?.group || []),
      levels: getAccessValues(values?.level || []),
      noAccessView: values?.noAccessView || false,
      segmentIds: getAccessValues(values?.segments || []) || [],
      visible: true,
    };
    setLoadingAccess(true);
    try {
      await Api.spotlight.updateTileAccess(tileId, payload);
      history.goBack();
      toast.success(p('tileUpdated'));
    } catch (err) {
      toast.error(p('updatingAccessFailed'));
    }
    setLoadingAccess(false);
  };


  const pollTileAccess = async (tileId, callBack, iteration = 0) => {
    if (iteration > 10) {
      toast.error(p('updatingAccessFailed'));
      history.goBack();
      return;
    }
    try {
      await Api.spotlight.getTileAccess(tileId);
      callBack();
    } catch (err) {
      setTimeout(() => pollTileAccess(tileId, callBack, iteration + 1), 1000);
    }
  };

  const handleTileSubmit = async () => {
    if (!tileFormValid) {
      dispatch(touch('tile_form', 'title', 'headingImage', 'publicationDate', 'expirationDate', 'description', 'content', 'contentType', 'subContentType'));
      return;
    }
    if (!tileAccessFormValid) {
      dispatch(touch('tile_access_form', 'level', 'group', 'tier'));
      return;
    }
    const tileData = await onTileSubmit(tileFormValues);
    if (!tileData) {
      return;
    }
    if (tileData.isNew) {
      setLoadingAccess(true);
      pollTileAccess(tileData.tileId, () => onAccessSubmit(tileData.tileId, tileAccessFormValues));
    } else {
      await onAccessSubmit(tileData.tileId, tileAccessFormValues);
    }
  };
  return (
    <Container>
      <Loading loading={loading || loadingAccess || loadingGroupData} />
      <Row md={12}>
        <Col md={8}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3 className="page-title">{entityID ? t('shared.edit') : t('shared.create')} {s('tile')}</h3>
                </Col>
              </Row>
              <SpotlightTileForm
                setSelectedEntityAccess={setSelectedEntityAccess}
                entity={entity}
                format={groupData?.format}
                descriptionAvailable={groupData?.descriptionAvailable}
                groupsLevelsTiers={groupsLevelsTiers}
              />
              <TileAccessForm
                selectedEntityAccess={selectedEntityAccess}
                tile={entity}
                access={tileAccess}
                segments={segments}
                groupsLevelsTiers={groupsLevelsTiers}
              />
              <Col md={12}>
                <Button color="primary" onClick={handleTileSubmit}>
                  {entityID ? s('save') : s('create')}
                </Button>
                <Button type="button" onClick={() => history.goBack()}>
                  {s('close')}
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card >
            <CardBody>
              <Row>
                <Col>
                  <h3 className="page-title">{t('NewsForm.appPreview')}</h3>
                </Col>
              </Row>
              <SpotlightAppPreview
                tile={tileFormValues}
                group={groupData}
                access={tileAccessFormValues}
                descriptionAvailable={groupData?.descriptionAvailable}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tileAccessFormValues: getFormValues('tile_access_form')(state),
  tileFormValues: getFormValues('tile_form')(state),
  tileAccessFormValid: isValid('tile_access_form')(state),
  tileFormValid: isValid('tile_form')(state),
});
export default connect(mapStateToProps)(SpotlightTileFormPage);
