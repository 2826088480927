import React, { useState } from 'react';
import { Card, CardBody, Container, TabContent, TabPane } from 'reactstrap';
import NavTab from '../../shared/components/custom/NavTab';
import ClubAdminListPage from './ClubAdminListPage';
import TeamAdminListPage from './TeamAdminListPage';
import SeasonAdminListPage from './SeasonAdminListPage';
import LeagueAdminListPage from './LeagueAdminListPage';
import SyncMatchControl from './SyncMatchControlPage';
import ImportCompaniesPage from './ImportCompaniesPage';
import RefreshCachePage from './RefreshCachePage';

const ClubAdminTabs = () => {
  const [tab, setTab] = useState('1');
  return (
    <Container>
      <Card>
        <CardBody>
          <NavTab
            tabs={[
              { tab: 'Clubs' },
              { tab: 'Teams' },
              { tab: 'Seasons' },
              { tab: 'Leagues' },
              { tab: 'Sync Match Control' },
              { tab: 'Import companies' },
              { tab: 'Refresh cache' },
            ]}
            setTab={setTab}
            activeTab={tab}
          />
          <TabContent activeTab={tab}>
            <TabPane tabId="1">
              <ClubAdminListPage />
            </TabPane>
            <TabPane tabId="2">
              <TeamAdminListPage />
            </TabPane>
            <TabPane tabId="3">
              <SeasonAdminListPage />
            </TabPane>
            <TabPane tabId="4">
              <LeagueAdminListPage />
            </TabPane>
            <TabPane tabId="5">
              <SyncMatchControl />
            </TabPane>
            <TabPane tabId="6">
              <ImportCompaniesPage />
            </TabPane>
            <TabPane tabId="7">
              <RefreshCachePage />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  );
};
export default ClubAdminTabs;
