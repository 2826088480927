export default {
  club_name: 'FC Rosengård',
  club_name_short: 'FCR',
  stadium_name: 'Malmö Idrottsplats',
  staging_url: 'https://rosengard.api.fangroup.io',
  prod_url: 'https://rosengard.api.fangroup.io',
  api: '/api',
  facebook: 'fcrosengard.se/',
  basename: '/rosengard',
  club_logo: '/assets/teams/rosengard.png',
  app_name: 'rosengard',
  websale_name: 'rosengard',
  ntb_team_id: 10031,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'rosengard',
  monolithEnabled: false,
};
