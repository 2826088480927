import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Col, TabContent, TabPane } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import moment from 'moment';
import AuctionItemForm from './components/AuctionItemForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import t, { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import TabHeader from '../../shared/components/scheduledMessages/TabHeader';
import useConfig from '../../util/useConfig';
import OfferTypes from '../offers/types';
import AuctionContactForm from './components/AuctionContactForm';

const AuctionItemFormPage = () => {
  const { auctionHouseId, auctionItemId } = useParams();
  const history = useHistory();

  const p = partial('auctionObjectsShared');
  const s = partial('shared');
  const ev = partial('EventsForm');
  const o = partial('OffersFormPage');

  const [loading, setloading] = useState(false);
  const [auctionItem, setAuctionItem] = useState(null);
  const [tab, setTab] = useState('0');
  const [numberOfPendingMessagesPerEvent, setNumberOfPendingMessagesPerEvent] = useState(0);
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [scheduleMessagesForEvent, setScheduleMessagesForEvent] = useState([]);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] = useState(0);
  const [startDate, setStartDate] = useState();
  const [groupsLevelsTiers, setGroupsLevelsTiers] = useState({
    tiers: [],
    groups: [],
    levels: [],
  });
  const [loadingAccess, setLoadingAccess] = useState(false);
  const config = useConfig();

  const getAuctionItem = async () => {
    setloading(true);
    try {
      const resAuctionObject = await Api.auctions.getAuctionItem(auctionItemId);
      setAuctionItem(resAuctionObject);
      setloading(false);
    } catch (err) {
      toast.error(err || `${p('auctionObjectRetrievingERROR')}`);
      setloading(false);
    }
  };
  const getTiers = async () => {
    setLoadingAccess(true);
    try {
      const { data: resTiers } = await Api.tiers.allTiers(config);
      const mappedTiers = [
        { label: ev('allTiers'), value: [] },
        { label: s('ignore'), value: null },
        ...resTiers.map((item) => ({ label: item.name, value: item.id })),
      ];
      setGroupsLevelsTiers({
        tiers: mappedTiers,
      });
      setLoadingAccess(false);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoadingAccess(false);
    }
  };
  const getScheduleMessagesByServiceId = (async (page = 1, pageSize = 10) => {
    try {
      let messageByEventId = [];

      if (messageStatus === t('ScheduledMessagesPage.filterByStatus')) {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceId(auctionItemId, page, pageSize);
      } else {
        messageByEventId = await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(auctionItemId, page, pageSize, messageStatus);
      }
      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerEvent(messageByEventId?.data?.total || 0);
      }
      setScheduleMessagesForEvent(messageByEventId.data.data);
      setTotalAmountOfScheduledMessages(messageByEventId.data.total);
    } catch (error) {
      toast.error(error);
    }
  });
  // eslint-disable-next-line consistent-return
  const handleContact = async (values) => {
    const { title, message, type, timeToSend } = values;
    const scheduledDate = timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null;
    const payload = { title, message, type, tiers: [], levels: [], groups: [] };
    try {
      if (timeToSend === 'SCHEDULED' && scheduleMessageID) {
        await Api.auctions.updateScheduledMessage('auctionItem', auctionItemId, scheduleMessageID, { ...payload, scheduledDate });
        toast.success(`${t('ScheduledMessagesPage.scheduleMessageUpdated')}`);
      } else if (timeToSend === 'INSTANT') {
        await Api.auctions.contactAuction('auctionItem', auctionItemId, payload);
      } else {
        await Api.auctions.contactAuction('auctionItem', auctionItemId, { ...payload, scheduledDate });
        toast.success(`${t('ScheduledMessagesPage.messageScheduled')}`);
      }
      if (timeToSend === 'INSTANT') {
        if (type === OfferTypes.CommunicationTypes.push) {
          toast.success(o(`${'pushNotificationSent'}`));
        } else if (type === OfferTypes.CommunicationTypes.email) {
          toast.success(o(`${'emailSent'}`));
        } else {
          toast.success(o(`${'smsSent'}`));
        }
      }
    } catch (_e) {
      toast.error(s(`${'somethingWentWrong'}`));
    }
    getScheduleMessagesByServiceId();
    setScheduleMessageID(null);
  };
  useEffect(() => {
    if (auctionItemId) {
      getAuctionItem();
      getTiers();
    }
  }, [auctionItemId]);

  const onSubmit = async (values) => {
    const payload = {
      name: values.name,
      description: values.description,
      isActive: values.isActive,
      startDate: moment(values.startDate),
      endDate: moment(values.endDate),
      startValue: Number(values.startValue),
      bidExtensionValue: Number(values.bidExtensionValue),
      bidExtensionTime: Number(values.bidExtensionTime),
      image: values.headingImage.croppedBase64 || values.headingImage.preview,
    };
    setloading(true);
    try {
      if (auctionItemId) {
        await Api.auctions.updateAuctionItem(auctionItemId, payload);
        setloading(false);
        toast.success(`${p('auctionObjectUpdated')}`);
        history.push(`/auctions/edit/${auctionHouseId}?tab=2`);
      } else {
        await Api.auctions.createAuctionItem(auctionHouseId, payload);
        toast.success(`${s('auctionObjectCreated')}`);
        history.push(`/auctions/edit/${auctionHouseId}?tab=2`);
      }
    } catch (err) {
      toast.error(err || `${s('somethingWentWrong')}`);
      setloading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading || loadingAccess} />
      <Row>
        <Col>
          <h3 className="page-title">{auctionItemId ? `${s('edit')}` : `${s('create')}`} {p('auctionObject')}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody className="p-0">
              <NavTab
                tabs={[
                  { tab: s('details') },
                  {
                    tab: <TabHeader
                      count={numberOfPendingMessagesPerEvent}
                      heading={s('communication')}
                      iconSize="22"
                      limit={9}
                    />,
                    disabled: !auctionItemId,
                  },
                ]}
                setTab={setTab}
                activeTab={tab}
                size="sm"
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1" className="m-3">
                  <AuctionItemForm auctionItem={auctionItem} auctionHouseId={auctionHouseId} type={auctionItemId ? 'edit' : 'create'} onSubmit={onSubmit} />
                </TabPane>
                <TabPane tabId="2">
                  <AuctionContactForm
                    onSubmit={handleContact}
                    auctionItem={auctionItem}
                    scheduleMessageID={scheduleMessageID}
                    setScheduleMessageID={setScheduleMessageID}
                    scheduleMessagesForEvent={scheduleMessagesForEvent}
                    getScheduleMessagesByServiceId={getScheduleMessagesByServiceId}
                    setScheduleMessagesForEvent={setScheduleMessagesForEvent}
                    totalAmountOfScheduledMessages={totalAmountOfScheduledMessages}
                    messageStatus={messageStatus}
                    setMessageStatus={setMessageStatus}
                    tiers={groupsLevelsTiers.tiers}
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </Container>
  );
};

export default AuctionItemFormPage;
