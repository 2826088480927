import React, { useEffect } from 'react';
import { Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const p = partial('TeamProfileForm');
const TeamProfileForm = ({ handleSubmit, initialize, team }) => {
  useEffect(() => {
    if (team) {
      initialize(team);
    }
  }, [team]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="name">{s('name')}</Label>
          </Col>
          <Col sm={2}>
            <Field
              name="name"
              component={renderField}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="shortName">{p('shortName')}</Label>
          </Col>
          <Col sm={2}>
            <Field
              name="shortName"
              component={renderField}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="matchCenterUrl">{p('matchCenterUrl')}</Label>
          </Col>
          <Col sm={8}>
            <Field
              name="matchCenterUrl"
              component={renderField}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="standingsUrl">{p('standingsUrl')}</Label>
          </Col>
          <Col sm={8}>
            <Field
              name="standingsUrl"
              component={renderField}
              type="text"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={2}>
            <Label htmlFor="playerStatsUrl">{p('playerStatsUrl')}</Label>
          </Col>
          <Col sm={8}>
            <Field
              name="playerStatsUrl"
              component={renderField}
              type="text"
            />
          </Col>
        </FormGroup>
        <Row>
          <Button color="info" type="submit">{s('edit')}</Button>
          <Link to="/settings"><Button color="primary">{s('close')}</Button></Link>
        </Row>
      </Container>
    </form>
  );
};

const rf = reduxForm({
  form: 'teamProfileForm',
});

export default rf(TeamProfileForm);
