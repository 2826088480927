import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Field, reduxForm } from 'redux-form';
import { Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderSelectField from '../../../shared/components/form/Select';
import { partial } from '../../../util/translation/translation';

const SeasonReservationForm = ({ handleSubmit }) => {
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const p = partial('seasonObjectsShared');
  const s = partial('shared');

  const getSeasons = async () => {
    setLoading(true);
    let resSeasons;
    try {
      resSeasons = await Api.seasons.getSeasons();
    } catch (err) {
      toast.error(err || p('wrongWhenFetchingSeasons'));
      return;
    }
    setSeasons(resSeasons.data);
    setLoading(false);
  };

  useEffect(() => {
    getSeasons();
  }, []);

  const seasonsOptions = seasons.map(season => ({
    value: season.id,
    label: season.name,
  }));

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Loading loading={loading} />
      <Col md={12} style={{ margin: '0 0 10px' }}>
        <h4>{p('seasonReservation')}</h4>
      </Col>
      <Col lg={6} md={12} sm={12}>
        <div className="form__form-group">
          <span className="form-header">{p('overrideActiveSeason')}</span>
          <div className="marginTop-5" />
          <Field
            name="overrideActiveSeasonId"
            component={renderSelectField}
            placeholder={p('chooseSeason')}
            label={s('season')}
            className="colored-click"
            disabled={!seasons.length}
            options={seasonsOptions}
          />
        </div>
        <div className="form__form-group">
          <Field
            name="skipCompanies"
            component={renderCheckBoxField}
            label={p('skipCompanies')}
            className="colored-click"
          />
        </div>
      </Col>

      <Col md={12}>
        <Button type="submit" color="primary">
          {s('save')}
        </Button>
      </Col>
    </form>
  );
};

SeasonReservationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({ form: 'season-reservation-form' });

export default reduxF(SeasonReservationForm);
