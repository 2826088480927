import React from 'react';
import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';
import { useRecoilState } from 'recoil';
import showDocsState from '../../../recoil/showDocs/selector';

const Help = () => {
  const [showDocs, setShowDocs] = useRecoilState(showDocsState);

  const toggle = () => setShowDocs(!showDocs);
  return (
    <button className="Help-btn" onClick={toggle}>
      <HelpOutlineIcon size="32" />
    </button>
  );
};
export default Help;
