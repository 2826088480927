import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Container } from 'reactstrap';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import validateAuctionHouseForm from './validateAuctionHouseForm';
import renderSwitchField from '../../../shared/components/custom/Switch';
import { partial } from '../../../util/translation/translation';
import { getImageHash } from '../../../util/functions';

const AuctionHouseForm = ({
  auctionHouse, handleSubmit, initialize,
}) => {
  const s = partial('shared');
  useEffect(() => {
    if (auctionHouse) {
      initialize({
        name: auctionHouse.name,
        description: auctionHouse.description,
        vat: auctionHouse.vat,
        isActive: !!auctionHouse.isActive,
        headingImage: {
          name: 'auction image',
          preview: auctionHouse.imageUrl,
          croppedImage: auctionHouse.imageUrl,
          hash: getImageHash(auctionHouse.imageUrl),
        },
        dynamicLink: auctionHouse.dynamicLink,
      });
    }
  }, [auctionHouse]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginBottom: 10, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('image')}</div>
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="form__form-group">
                  <Field
                    saveBase64Cropped
                    name="headingImage"
                    component={renderDropZoneField}
                    displayCrop
                    crop="BANNER_CROP"
                  />
                  <span className="form-header"><strong>{s('note')}:</strong> {s('recommendedImageSize')} 16:9 (1035 x 570 px)</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: 10, marginBottom: 10, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('details')}</div>
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <Container>
            <Row>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form-header">{s('name')}*</span>
                  <Field
                    name="name"
                    component={renderField}
                    placeholder={s('name')}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form-header">{s('description')}*</span>
                  <Field
                    name="description"
                    component={renderField}
                    placeholder={s('description')}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group flex-Vcenter">
                  <span className="form-header" />
                  <Field
                    name="isActive"
                    component={renderSwitchField}
                    label={[`${s('active')}`, `${s('inActive')}`]}
                    defaultChecked
                    className="colored-click"
                  />
                </div>
              </Col>
              <Col className="hidden">
                <div className="form__form-group">
                  <span className="form-header">{s('taxCaps')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="vat"
                      component={renderField}
                      type="number"
                      placeholder={s('taxCaps')}
                    />
                    <div className="form__form-group-icon">&nbsp;%&nbsp;</div>
                  </div>
                </div>
              </Col>
            </Row>
            {auctionHouse ?
              <Row>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header marginRight-8">{s('universalLink')}</span>
                    <div className="form__form-group-field">
                      <Field name="dynamicLink" component="input" disabled />
                      <button type="button" className="form__form-group-icon" onClick={() => navigator.clipboard.writeText(auctionHouse.dynamicLink)}>
                        <LinkVariantIcon />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row> : null}
          </Container>
        </div>
      </div>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'auction_house_form',
  validate: validateAuctionHouseForm,
});

export default reduxF(AuctionHouseForm);
