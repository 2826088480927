
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import t, { partial } from '../../../util/translation/translation';

let hoverTimeout;
const p = partial('Kiosk');

const Kiosk = (props) => {
  const [selectedOrder, setSelectedOrder] = useState({});
  const [hoverItem, setHoverItem] = useState('');

  const handleDragOver = (_e, _type) => {
    _e.preventDefault();
    clearTimeout(hoverTimeout);
    setHoverItem(_type);
    hoverTimeout = setTimeout(() => {
      setHoverItem('');
    }, 100);
  };

  const handleDrop = (moveTo) => {
    setHoverItem('');
    props.moveOrder(selectedOrder, moveTo);
    setSelectedOrder({});
  };

  return (
    <div className="kiosk-type-container">
      <div className="kiosk-type-item">
        <div className="kiosk-type-item-header" style={{ borderBottom: '10px solid #e23d51' }}>
          <h4>{p('unprocessedOrder')}</h4>
        </div>
        <div className="kiosk-type-item-body" name="active" onDragOver={event => handleDragOver(event, 'active')} onDrop={() => handleDrop('active')}>
          { hoverItem === 'active' &&
            <div className="kiosk-box active">
              <div className="kiosk-box-body hover" />
            </div>
          }
          <RenderKioskItems
            setSelectedOrder={setSelectedOrder}
            orders={props.kioskOrders.active}
            variant="active"
            moveOrder={props.moveOrder}
          />
        </div>
      </div>
      <div className="kiosk-type-item middle">
        <div className="kiosk-type-item-header" style={{ borderBottom: '10px solid #F1D000' }}>
          <h4>{p('processing')}</h4>
        </div>
        <div className="kiosk-type-item-filter">
          <label htmlFor="only-show-mine">
            <input id="only-show-mine" value={props.onlyShowOwn} onChange={() => props.setOnlyShowOwn(!props.onlyShowOwn)} type="checkbox" />
            <p>{p('showMine')}</p>
          </label>
        </div>
        <div className="kiosk-type-item-body" name="processing" onDragOver={event => handleDragOver(event, 'processing')} onDrop={() => handleDrop('processing')}>
          { hoverItem === 'processing' &&
            <div className="kiosk-box processing">
              <div className="kiosk-box-body hover" />
            </div>
          }
          <RenderKioskItems
            onlyShowOwn={props.onlyShowOwn}
            setSelectedOrder={setSelectedOrder}
            orders={props.kioskOrders.processing}
            variant="processing"
            moveOrder={props.moveOrder}
            me={props.me}
          />
        </div>
      </div>
      <div className="kiosk-type-item">
        <div className="kiosk-type-item-header" style={{ borderBottom: '10px solid #6dd954' }}>
          <h4>{p('readyPickUp')}</h4>
        </div>
        <div className="kiosk-type-item-body" name="ready" onDragOver={event => handleDragOver(event, 'ready')} onDrop={() => handleDrop('ready')}>
          { hoverItem === 'ready' &&
            <div className="kiosk-box ready">
              <div className="kiosk-box-body hover" />
            </div>
          }
          <RenderKioskItems
            setSelectedOrder={setSelectedOrder}
            orders={props.kioskOrders.ready}
            variant="ready"
            moveOrder={props.moveOrder}
            sendPush={props.sendPush}
          />
        </div>
      </div>
    </div>
  );
};

Kiosk.propTypes = {
  kioskOrders: PropTypes.shape({
    ready: PropTypes.arrayOf(PropTypes.shape({})),
    processing: PropTypes.arrayOf(PropTypes.shape({})),
    active: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  moveOrder: PropTypes.func.isRequired,
  onlyShowOwn: PropTypes.bool.isRequired,
  setOnlyShowOwn: PropTypes.func.isRequired,
  sendPush: PropTypes.func.isRequired,
  me: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

const RenderKioskItems = (props) => {
  if (props.variant === 'active') {
    return props.orders.map((order) => {
      return (
        <div
          key={`RenderKioskItem-${order.id}`}
          className="kiosk-box active"
          draggable
          onDragStart={() => props.setSelectedOrder(order)}
        >
          <div className="kiosk-box-body">
            <div>
              <RenderProducts products={order.products} />
            </div>
            <div className="kiosk-box-body-tag">
              <h4>#{order.identifier}</h4>
              <p>{order.timestamp}</p>
              <p className="margin-0">{order.bought_at !== '0000-00-00 00:00:00' ? t('shared.paid') : p('noPaymentRegistred')}</p>
              <p className="margin-0">{`${order.user?.firstname} ${order.user?.lastname} (${order.user?.username})`}</p>
            </div>
          </div>
          <div>
            <button
              onClick={() => props.moveOrder(order, 'processing', false)}
              className="kiosk-box-buton-single"
            >
              {p('pick')}
            </button>
          </div>
        </div>
      );
    });
  } else if (props.variant === 'processing') {
    let { orders } = props;
    if (props.onlyShowOwn) {
      const user = props.me;
      orders = props.orders.filter((order) => {
        if (order.handler_id !== user.id) {
          return false;
        }
        return true;
      });
    }
    return orders.map((order) => {
      return (
        <div
          key={`RenderKioskItem-${order.id}`}
          className="kiosk-box processing"
          draggable
          onDragStart={() => props.setSelectedOrder(order)}
        >
          <div className="kiosk-box-body">
            <div>
              <RenderProducts products={order.products} />
            </div>
            <div className="kiosk-box-body-tag">
              <h4>#{order.identifier}</h4>
              <p>{order.timestamp}</p>
              <p className="margin-0">{order.bought_at !== '0000-00-00 00:00:00' ? t('shared.paid') : p('noPaymentRegistred')}</p>
              <p className="margin-0">{`${order.user?.firstname} ${order.user?.lastname} (${order.user?.username})`}</p>
            </div>
          </div>
          <div className="flex">
            <button
              className="kiosk-box-button processing"
              onClick={() => props.moveOrder(order, 'active')}
            >
              {p('moveBack')}
            </button>
            <button
              className="kiosk-box-button processing"
              onClick={() => props.moveOrder(order, 'ready')}
            >
              {p('ready')}
            </button>
          </div>
        </div>
      );
    });
  } else if (props.variant === 'ready') {
    return props.orders.map((order, index) => {
      return (
        <div
          key={`RenderKioskItem-${order.id}`}
          className="kiosk-box ready"
          draggable
          onDragStart={() => props.setSelectedOrder(order)}
        >
          <div className="kiosk-box-body">
            <div>
              <RenderProducts products={order.products} />
            </div>
            <div className="kiosk-box-body-tag">
              {
                order.min_since_push && order.min_since_push > 10 &&
                  <button onClick={() => props.sendPush(index)} className="no-style">
                    <p className="push">{p('sendNewPush')}</p>
                  </button>
              }
              <h4>#{order.identifier}</h4>
              <p>{order.timestamp}</p>
              <p className="margin-0">{order.bought_at !== '0000-00-00 00:00:00' ? t('shared.paid') : p('noPaymentRegistred')}</p>
              <p className="margin-0">{`${order.user?.firstname} ${order.user?.lastname} (${order.user?.username})`}</p>
            </div>
          </div>
          <div className="flex">
            <button className="kiosk-box-button ready" onClick={() => props.moveOrder(order, 'processing')}>{p('moveBack')}</button>
            <button className="kiosk-box-button ready" onClick={() => props.moveOrder(order, 'completed')}>{p('pickedUp')}</button>
            <button className="kiosk-box-button ready" onClick={() => props.moveOrder(order, 'completed', true)}>{p('print')}</button>
          </div>
        </div>
      );
    });
  }
  return <p>{t('shared.somethingWentWrong')}</p>;
};

RenderKioskItems.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  variant: PropTypes.string.isRequired,
  setSelectedOrder: PropTypes.func.isRequired,
  onlyShowOwn: PropTypes.bool,
  moveOrder: PropTypes.func.isRequired,
  sendPush: PropTypes.func,
  me: PropTypes.shape({
    id: PropTypes.number,
  }),
};

RenderKioskItems.defaultProps = {
  onlyShowOwn: false,
  sendPush: undefined,
  me: undefined,
};

const RenderProducts = (props) => {
  return props.products.map((product) => {
    return (
      <p key={`RenderProducts-${product.id}`}>{product.pivot.amount}x {product.name} {product.subtitle && `- ${product.subtitle}`}</p>
    );
  });
};

const mapStateToProps = state => ({
  me: state.admin.me,
});

export default connect(mapStateToProps)(Kiosk);
