import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }
  // if (!values.price1 && (values.canDelete || values.canDelete === undefined)) {
  //   errors.price1 = p('priceFieldEmpty');
  // }
  // if (!values.price12 && (values.canDelete || values.canDelete === undefined)) {
  //   errors.price12 = p('priceFieldEmpty');
  // }
  if (!values.image) {
    errors.image = p('picFieldCantBeEmpty');
  }
  return errors;
};

export default validate;
