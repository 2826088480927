const height = 300;
export const getFullHeight = () => {
  const topBarHeight = 100;
  const bottomBarHeight = 90;
  const titleMargin = 2 * 10 + 20;
  return height - topBarHeight - bottomBarHeight - titleMargin;
};
export const getFontSize = (size, format) => {
  switch (format) {
    case 'LANDSCAPE':
      switch (size) {
        case 'SMALL':
          return 12;
        case 'MEDIUM':
          return 16;
        case 'LARGE':
          return 20;
        default:
          return null;
      }
    case 'SQUARE':
      switch (size) {
        case 'SMALL':
          return 10;
        case 'MEDIUM':
          return 12;
        case 'LARGE':
          return 16;
        default:
          return null;
      }
    case 'PORTRAIT':
      switch (size) {
        case 'SMALL':
          return 10;
        case 'MEDIUM':
          return 12;
        case 'LARGE':
          return 12;
        default:
          return null;
      }
    default:
      return null;
  }
};

export const getCircleStyleFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { width: 150, height: 150 };
    case 'MEDIUM':
      return { width: 200, height: 200 };
    case 'LARGE':
      return { width: 250, height: 250 };
    default:
      return null;
  }
};
export const getPortraitStyleFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { width: (150 * 9) / 16, height: 150, descHeight: 60 };
    case 'MEDIUM':
      return { width: (200 * 9) / 16, height: 200, descHeight: 60 };
    case 'LARGE':
      return { width: (250 * 9) / 16, height: 250, descHeight: 60 };
    case 'FULL_SCREEN':
      return { width: (300 * 9) / 16 };
    default:
      return null;
  }
};

export const getLandscapeStyleFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { width: 125, height: 120, descHeight: 50 };
    case 'MEDIUM':
      return { width: 205, height: 205, descHeight: 80 };
    case 'LARGE':
      return { width: 335, height: 265, descHeight: 80 };
    case 'FULL_SCREEN':
      return { width: 400, height: 363 };
    default:
      return null;
  }
};

export const getLandscapeImageFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { width: 125, height: 70 };
    case 'MEDIUM':
      return { width: 205, height: 115 };
    case 'LARGE':
      return { width: 335, height: 190 };
      case 'FULL_SCREEN':
        return { width: 400, height: 225 };
    default:
      return null;
  }
};

export const getLandscapeContentFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { margin: 4, padding: 8 };
    case 'MEDIUM':
      return { margin: 4, padding: 10 };
    case 'LARGE':
      return { margin: 4, padding: 10 };
    case 'FULL_SCREEN':
      return { margin: 4, padding: 10 };
    default:
      return null;
  }
};

export const getSquareStyleFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { width: 70, height: 100, descHeight: 0 };
    case 'MEDIUM':
      return { width: 115, height: 180, descHeight: 60 };
    case 'LARGE':
      return { width: 190, height: 265, descHeight: 60 };
    case 'FULL_SCREEN':
      return { width: 270, height: 376, descHeight: 60 };
    default:
      return null;
  }
};

export const getSquareImageFromSize = (size) => {
  switch (size) {
    case 'SMALL':
      return { width: 70, height: 70 };
    case 'MEDIUM':
      return { width: 115, height: 115 };
    case 'LARGE':
      return { width: 190, height: 190 };
    case 'FULL_SCREEN':
      return { width: 270, height: 270 };
    default:
      return null;
  }
};

export const isSpotlightExclusive = (access) => {
      if (
        access.group === null &&
        access.level === null &&
        access.tier === null
      ) {
        return false;
      }
      if (
        access.group?.length === 1 && access.group[0]?.value === null &&
        access.level?.length === 1 && access.level[0]?.value === null &&
        access.tier?.length === 1 && access.tier[0]?.value === null
      ) {
        return false;
      }
      return true;
};
