import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';

const ToolbarOptions = {
  options: ['inline', 'list', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const TextEditor = ({ editorState, setEditorState }) => {
  return (
    <div className="text-editor">
      <Editor
        handlePastedText={() => false}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        stripPastedStyles
        toolbar={ToolbarOptions}
      />
    </div>
  );
};

TextEditor.propTypes = {
  editorState: PropTypes.shape().isRequired,
  setEditorState: PropTypes.func.isRequired,
};

export default TextEditor;
