export default {
  club_name: 'FC Stockholm',
  club_name_short: 'fcstockholm',
  stadium_name: '',
  staging_url: 'https://fcstockholm.api.fangroup.io',
  prod_url: 'https://fcstockholm.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/fcstockholm',
  club_logo: '/assets/teams/fcstockholm.png',
  app_name: 'fcstockholm',
  websale_name: 'fcstockholm',
  ntb_team_id: 10044,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'fcstockholm',
  monolithEnabled: false,
};
