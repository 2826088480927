import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';

const MatchKioskReport = (props) => {
  const s = partial('shared');

  const listHeaders = () => {
    return props.matchTickets.keys.map(priceGroup => (
      <th key={`listHeaders-${priceGroup}`}>{priceGroup}</th>
    ));
  };

  const listPriceGroups = (matchTicket) => {
    return props.matchTickets.keys.map(priceGroup => (
      <td key={`listPriceGroups-${matchTicket.match_id}-${priceGroup}`}>{ props.formatSoldTicketsData(priceGroup, matchTicket) }</td>
    ));
  };

  const listKioskMatchSales = () => {
    return props.kioskArray.map(matchTicket => (
      <tr key={`listKioskMatchSales-${matchTicket.match_id}`}>
        <td>{ props.renderMatchName(matchTicket.match_id) }</td>
        {listPriceGroups(matchTicket)}
        <td>{ props.formatSoldTicketsData('Total', matchTicket) }</td>
      </tr>
    ));
  };

  return (
    <>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{s('opponent')}</th>
            {listHeaders()}
            <th>{s('total')}</th>
          </tr>
        </thead>
        <tbody>
          {listKioskMatchSales()}
        </tbody>
      </Table>
    </>
  );
};

MatchKioskReport.propTypes = {
  matchTickets: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      match_id: PropTypes.number.isRequired,
    })).isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  formatSoldTicketsData: PropTypes.func.isRequired,
  renderMatchName: PropTypes.func.isRequired,
  kioskArray: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MatchKioskReport;
