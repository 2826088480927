import React, { useState } /* , { useEffect } */ from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import Checkbox from '../../../shared/components/custom/Checkbox';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const Assortment = ({ handleToggle, products, categories }) => {
  const [categoryFilter, setCategoryFilter] = useState(null);

  const renderProducts = () => {
    return products.filter(product => (categoryFilter?.id ? product.category_id === categoryFilter?.id : true)).map((product, index) => {
      return (
        <tr key={`permission-${product.id}`} className={product.toggled ? 'listSelected transitionDuration-03' : 'transitionDuration-03'}>
          <td>
            <Checkbox
              handleToggle={() => handleToggle(product.id)}
              toggled={product.toggled}
              color="#ffffff"
              selectedColor="#ffffff"
              index={index}
            />
          </td>
          <td>{product.name}</td>
          <td>{product.category.name}</td>
        </tr>
      );
    });
  };
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle outline>
          <p>{s('show')} {categoryFilter?.name ?? s('all')}</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown__menu">
          <DropdownItem onClick={() => setCategoryFilter(null)}>{s('all')}</DropdownItem>
          {categories.map(category => (
            <DropdownItem onClick={() => setCategoryFilter(category)} key={`category-filter-${category.id}`}>{category.name}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <Table responsive bordered>
        <thead>
          <tr>
            <th />
            <th>{s('name')}</th>
            <th>{s('category')}</th>
          </tr>
        </thead>
        <tbody>
          {renderProducts()}
        </tbody>
      </Table>
    </>
  );
};

Assortment.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    toggled: PropTypes.bool,
    name: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string,
    }),
  })).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  })).isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default Assortment;
