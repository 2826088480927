import {
  SET_CREATE_OR_EDIT_PRICE_GROUP,
} from '../actions/priceGroupActions';

const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_OR_EDIT_PRICE_GROUP:
      return action.priceGroup;
    default:
      return state;
  }
}
