import React from 'react';
import PropTypes from 'prop-types';

const AdjustableField = ({
  input, placeholder, type, disabled, meta: { touched, error }, autoComplete, showError, onKeyPress,
}) => (
  <div className="form__form-group-input-wrap">
    <textarea {...input} placeholder={placeholder} type={type} disabled={disabled} autoComplete={autoComplete} onKeyPress={onKeyPress !== undefined ? onKeyPress : undefined} />
    {touched && error && showError && <span className="form__form-group-error">{error}</span>}
  </div>
);

AdjustableField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  showError: PropTypes.bool,
  onKeyPress: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
};

AdjustableField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  disabled: false,
  autoComplete: '',
  showError: true,
  onKeyPress: undefined,
};

export default AdjustableField;
