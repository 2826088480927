import React from 'react';
import { getFontSize, getPortraitStyleFromSize, isSpotlightExclusive } from './SpotlightUtils';
import ExclusiveLabel from './ExclusiveLabel';

const styles = {
  imageStyle: (size) => ({
    ...getPortraitStyleFromSize(size),
  }),
  containerStyle: (size) => ({
    width: getPortraitStyleFromSize(size).width,
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: size === 'FULL_SCREEN' ? 0 : 8,
    position: 'relative',
    boxShadow: size === 'FULL_SCREEN' ? null : 'rgb(0 0 0 / 30%) 5px 5px 5px -6px',
  }),
};
const PortraitItem = ({ tile, size, access, format, descriptionAvailable }) => {
  const { headingImage, description } = tile;
  const isExclusive = isSpotlightExclusive(access);
  const isYoutube = tile?.content?.value === 'YOUTUBE_VIDEO';
  const videoId = `https://www.youtube.com/embed/${tile?.contentType}?controls=0&iv_load_policy=3&rel=0`;
  return (
    <div style={styles.containerStyle(size)}>
      <div>
        <div>
          {isYoutube ?
            <iframe
              title="Youtube frame"
              style={styles.imageStyle(size)}
              src={videoId}
            /> :
            <img
              resizeMode="cover"
              src={headingImage?.preview}
              style={styles.imageStyle(size)}
              alt=""
            />}
          {size !== 'FULL_SCREEN' && descriptionAvailable ?
            <div style={{ height: getPortraitStyleFromSize(size).descHeight, paddingTop: '5px' }}>
              <p style={{ fontWeight: '500', fontSize: getFontSize(size), color: 'black', overflow: 'hidden', padding: '0 10px 10px 10px', textOverflow: 'ellipsis', width: '100%', lineHeight: '1.5em', height: '3em' }}>{description}</p>
            </div> : null}
        </div>
      </div>
      <ExclusiveLabel visible={isExclusive} format={format} />
    </div>
  );
};

export default PortraitItem;

