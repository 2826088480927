import React from 'react';
import Skeleton from 'react-skeleton-loader';

const skeletonTableNestLoader = (length, width = 150) => {
  const test = [];
  for (let i = 0; i < length; i++) {
      test.push(<td><Skeleton width={`${width}px`} count={1} /></td>);
  }
  const key = Math.floor(Math.random() * 1000).toString() + Math.floor(Math.random() * 1000);
  return (
    <tr key={key}>{test}</tr>
  );
};

const skeletonTableLoader = (length, depth, skeletonWidth) => {
    const test = [];
    for (let i = 0; i < length; i++) {
        test.push(skeletonTableNestLoader(depth, skeletonWidth));
    }

    return (
      <>{test}</>
    );
  };

export default skeletonTableLoader;
