import React, { useEffect, useState } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import { Col, Button, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from '../validators/termValidate';
import RenderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import AdjustableField from '../../../shared/components/custom/AdjustableField';
import { partial } from '../../../util/translation/translation';

const TermForm = ({ handleSubmit, initialize, term, allTermTypes, blockedTermTypes }) => {
  const [termOptions, setTermOptions] = useState([]);
  const p = partial('settingsTerms');
  const s = partial('shared');
  useEffect(() => {
    if (term) {
      initialize({
        type: {
          label: p(`termName-${term.type.toUpperCase()}`),
          value: term.type,
        },
        title: term.title,
        intro: term.intro,
        description: term.description,
      });
    }
  }, [term]);
  useEffect(() => {
    const availableTypes = allTermTypes.filter(({ type }) => !blockedTermTypes.includes(type));
    const translatedTypes = availableTypes.map(({ type }) => ({
      label: p(`termName-${type.toUpperCase()}`),
      value: type,
    }));
    setTermOptions(translatedTypes);
  }, [allTermTypes, blockedTermTypes]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={12}>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('termType')}</span>
              <Field
                name="type"
                component={renderSelectField}
                options={termOptions}
                placeholder={p('termType')}
                className="textTransform-capitalize"
              />
            </div>
          </Col>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('termTitle')}</span>
              <Field
                name="title"
                component={RenderField}
                placeholder={p('termTitle')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('termIntro')}</span>
              <Field
                name="intro"
                component={RenderField}
                placeholder={p('termIntro')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form__form-group">
              <span className="form-header">{p('termDescription')}</span>
              <Field
                name="description"
                component={AdjustableField}
                placeholder={p('termDescription')}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12}>
        <Button
          type="submit"
          color="primary"
        >
          { term === null ? s('create') : s('save')}
        </Button>
        <Link
          className="color-unset"
          to="/settings?tab=7"
        >
          <Button type="button">
            {s('close')}
          </Button>
        </Link>
      </Col>
    </form>
  );
};

TermForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  term: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    deletedAt: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    intro: PropTypes.string.isRequired,
    isGeneral: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    teamId: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
};

TermForm.defaultProps = {
  term: null,
};

const reduxF = reduxForm({
  form: 'term_form',
  validate,
});

export default reduxF(TermForm);
