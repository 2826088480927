import React from 'react';

const BottomSpace = {
  sm: '0px',
  md: '36px',
};

const WidgetHeader = ({ title, subhead, children, isBorder = true, bottomSpace = 'md' }) => {
    return (
      <div className="widget-header" style={{ marginBottom: BottomSpace[bottomSpace], borderBottom: isBorder ? '1px solid #F4F4F6' : 'none' }}>
        <div>
          <p>{title}</p>
          {subhead && <h5>{subhead}.</h5>}
        </div>
        { children }
      </div>
    );
};
export default WidgetHeader;
