import React, { useState, useEffect } from 'react';
import { Container, Button, Table, CardBody, Card, Col, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import TooltipTextIcon from 'mdi-react/TooltipTextIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import CheckIcon from '../../shared/components/custom/CheckIcon';
import TableMenu from '../../shared/components/custom/TableMenu';
import useModal from '../../shared/hooks/useModal';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import t, { partial } from '../../util/translation/translation';
import { formatDateTime } from '../../util/date-and-time';

const ExternalStorePage = () => {
  const [loading, setLoading] = useState(true);
  const [externalStores, setExternalStores] = useState([]);

  const [handleModal, visible, deleteID] = useModal();
  const p = partial('ExternalStorePage');

  const getExternalStores = async () => {
    setLoading(true);
    try {
      const resExternalStores = await Api.externalStores.all();
      setExternalStores(resExternalStores.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    getExternalStores();
  }, []);

  const isActive = (store) => {
    const saleStart = moment(store.sale_start);
    const saleEnd = moment(store.sale_end);
    const now = moment();
    if (store.sale_end) {
      return now.isBetween(saleStart, saleEnd, undefined, '[]');
    }
    return now.isSameOrAfter(saleStart);
  };

  const renderExternalStores = () => {
    return externalStores.map(store => (
      <tr key={`externalStore-${store.id}`}>
        <td><Link to={`/external_stores/edit/${store.id}`}>{store.name}</Link></td>
        <td>
          <TooltipTextIcon className="outline-0" id={`tooltip-${store.id}`} color="#555555" />
          <UncontrolledTooltip placement="top" target={`tooltip-${store.id}`}>
            {store.description}
          </UncontrolledTooltip>
        </td>
        <td><Link target="_blank" to={store.external_url}>{store.external_url}</Link></td>
        <td>{formatDateTime(store.sale_start)}</td>
        <td>{store.sale_end ? formatDateTime(store.sale_end) : '-'}</td>
        <td><CheckIcon state={isActive(store)} /></td>
        <td><TableMenu route={`/external_stores/edit/${store.id}`} onDeleteClick={() => handleModal(store.id)} /></td>
      </tr>
    ));
  };

  const deleteExternalStore = async () => {
    setLoading(true);
    try {
      await Api.externalStores.delete(deleteID);
      handleModal();
      getExternalStores();
      toast.success(p('externalStoreDeleted'));
    } catch (err) {
      handleModal();
      getExternalStores();
      toast.error(err);
    }
  };

  return (
    <Container>
      <DeleteModal
        type="ekstern butikk"
        handleModal={handleModal}
        visible={visible}
        modalAction={deleteExternalStore}
      />
      <Loading loading={loading} />
      <Col>
        <p className="page-title">{p('externalStores')}</p>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <div className="flex space-between">
              <p className="bold">{p('externalStores')}</p>
              <Link to="/external_stores/create"><Button size="sm" color="primary">{p('createExternalStore')}</Button></Link>
            </div>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>{t('shared.name')}</th>
                  <th>{t('shared.description')}</th>
                  <th>{p('externalLink')}</th>
                  <th>{t('shared.saleStart')}</th>
                  <th>{t('shared.saleEnd')}</th>
                  <th>{t('shared.active')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                { renderExternalStores() }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default ExternalStorePage;
