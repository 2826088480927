import moment from 'moment';
import { partial } from '../../../util/translation/translation';

const validateLotteryForm = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  if (!values.vendor || values.vendor.length === 0) {
    errors.vendor = p('missingVendorValue');
  }

  if (!values.teamId) {
    errors.teamId = p('missingTeamId');
  }

  if (!values.startDate) {
    errors.startDate = p('dateFieldCantBeEmpty');
  }

  if (!values.endDate) {
    errors.endDate = p('dateFieldCantBeEmpty');
  }

  if (values.startDate && values.endDate && moment(values.startDate).isAfter(values.endDate)) {
    errors.endDate = `${p('startBeforeEnd')}`;
  }

  if (values.startDate && values.endDate && moment(values.endDate).isBefore(values.startDate)) {
    errors.endDate = `${p('endBeforeStart')}`;
  }

  if (!values.price) {
    errors.price = p('priceFieldEmpty');
  }
  return errors;
};

export default validateLotteryForm;
