import { registerLocale, setDefaultLocale } from 'react-datepicker';
import nbDates from 'date-fns/locale/nb';
import svDates from 'date-fns/locale/sv';
import enDates from 'date-fns/locale/en-GB';

registerLocale('nb', nbDates);
registerLocale('sv', svDates);
registerLocale('en', enDates);

const changeLocale = () => {
  const currLocale = localStorage.getItem('language') || 'en';
  setDefaultLocale(currLocale);
};
changeLocale();


const no = require('./nb/common.json');
const en = require('./en/common.json');
const sv = require('./sv/common.json');

const FALLBACK = no;
const FALLBACKVALUE = 'nb';

const getRequire = (locale) => {
  switch (locale) {
    case 'no':
    case 'nb':
      return no;
    case 'en':
      return en;
    case 'sv':
      return sv;
    default:
      return en;
  }
};

const localeReducer = (currentLocale, locale) => currentLocale || getRequire(locale);

const getTranslatePage = (locales) => locales.reduce(localeReducer, false) || FALLBACK;
const getTranslations = () => ({
  no,
  en,
  sv,
});

const t = (key, obj = {}, tpage = null) => {
  let tp;
  if (tpage) {
    tp = tpage;
  } else {
    tp = localStorage.getItem('language') ? getTranslatePage([localStorage.getItem('language')]) : getTranslatePage(navigator.languages);
  }
  const keys = key.split('.');
  const text = keys.reduce(
    (total, e) => total[e],
    tp,
  );
  const returnText = Object.keys(obj).length > 0 ? Object.keys(obj).reduce((to, e) => {
    const re = new RegExp(`%{(${e})}`, 'g');
    return to.replace(re, obj[e]);
  }, text) : text;
  if (returnText === undefined || returnText === null) {
    if (tp === FALLBACK) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(key);
      } else {
        return `Missing translation for ${key}`;
      }
    } else {
      return t(key, obj, FALLBACK);
    }
  }
  const reg = /%{(.*)}/g;
  const matches = returnText.match(reg) || [];
  if (matches?.length > 0) { throw new Error(`Missing template substitution ${matches.join(', ')}, input values:  ${Object.keys(obj).join(',')}`); }
  return returnText;
};
const partial = (str) => (key, obj = {}) => t(`${str}.${key}`, obj);
const toLocaleNumber = (n) => {
  const [locale] = navigator.languages;
  return n?.toLocaleString(locale) ?? '-';
};
const getAvailableLanguages = () => [{ value: 'nb', label: '🇳🇴 Norsk' }, { value: 'en', label: '🇬🇧 English' }, { value: 'sv', label: '🇸🇪 Svenska' }];
const getDefault = () => 'en';


export {
  partial,
  toLocaleNumber,
  getAvailableLanguages,
  getTranslations,
  getDefault,
  FALLBACKVALUE,
};
export default t;
