import React, { useState, useEffect } from 'react';
import { Row, Container, Col, Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { Pie, HorizontalBar } from 'react-chartjs-2';
import moment from 'moment';
import ExportVariantIcon from 'mdi-react/ExportVariantIcon';
import fileDownload from 'js-file-download';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import Api from '../../../util/api';
import t, { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';
import QuestionType, { QuestionTypeEnum } from '../../events/components/QuestionnaireBuilder/QuestionType';
import TextFieldModal from './TextFieldModal';
import { getBaseDateTime } from '../../../util/date-and-time';
import Pagination from '../../../shared/components/pagination/Pagination';

const PieChart = ({ item }) => {
    const data = item.answers.map((answer) => answer.count);
    const chartOptions = {
        responsive: true,
        legend: {
            labels: {
                boxWidth: 16,
            },
            position: 'right',
        },
        maintainAspectRatio: false,
    };

    const labels = item.answers.map(
        (answer) => `${answer.label}`,
    );
    return (
      <div className="chart-container">
        <div className="chart-style">
          <Pie
            data={{
                        datasets: [
                            {
                                data,
                                backgroundColor: [
                                    '#EF6C6C',
                                    '#8D66F5',
                                    '#2388FB',
                                    '#47D78A',
                                    '#F0B26A',
                                ],
                                borderWidth: 0,
                            },
                        ],
                        labels,
                    }}
            options={chartOptions}
          />
        </div>
      </div>
    );
};
const BarChart = ({ item }) => {
    const data = item.answers.map((answer) => answer.count);
    const chartOptions = {
        responsive: true,
        legend: false,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                precision: 0,
              },
            }],
          },
      };
    const labels = item.answers.map(
        (answer) => `${answer.label}`,
    );
    return (
      <div className="chart-container">
        <div className="chart-style">
          <HorizontalBar
            options={chartOptions}
            data={{
              labels,
              datasets: [
                {
                  data,
                  maxBarThickness: 16,
                  backgroundColor: [
                    '#EF6C6C',
                    '#8D66F5',
                    '#2388FB',
                    '#47D78A',
                    '#F0B26A',
                ],
                },
              ],
            }}
          />
        </div>
      </div>
    );
};
const TextChart = ({ item, itemPress }) => {
    const qS = partial('question');
    const textFieldQuestionLimit = 5;
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: 600 }}>
          <Row>
            <div className="recent-answers">
              <p>{qS('recentAnswers')}</p>
            </div>
          </Row>
          {item.answers.map(
          (answer, index) =>
            answer.text !== '' &&
            index < textFieldQuestionLimit && (
              <Row key={answer.text}>
                <div style={{
                width: '100%',
                display: 'flex',
                padding: '16px',
                borderRadius: '4px',
                border: '1px solid #e8eaed',
                backgroundColor: '#fff',
                minHeight: '48px',
              }}
                >
                  <p style={{
                  color: '#121b2b',
                  width: '100%',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  margin: 0,
                }}
                  >
                    {answer.text}
                  </p>
                </div>
              </Row>
            ),
        )}
          {item.answers.length > textFieldQuestionLimit && (
            <Row className="justify-content-center">
              <div style={{ width: 200, marginTop: 8 }}>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => itemPress(item)}
                >
                  {qS('viewAllResponses')}
                </Button>
              </div>
            </Row>
        )}
        </div>
      </div>

    );
  };


const AnswerItem = ({ item, itemPress }) => {
    switch (item.type) {
        case QuestionTypeEnum.DROPDOWN: return <PieChart item={item} />;
        case QuestionTypeEnum.RADIO_GROUP: return <PieChart item={item} />;
        case QuestionTypeEnum.SCORE_PREDICTOR: return <PieChart item={item} />;
        case QuestionTypeEnum.CHECKBOX: return <BarChart item={item} />;
        case QuestionTypeEnum.PLAYER_PICKER: return <BarChart item={item} />;
        case QuestionTypeEnum.TEXT_FIELD: return <TextChart item={item} itemPress={itemPress} />;
        default: return null;
    }
};

const mapQuestionType = (type) => {
    switch (type) {
        case 'TEXT_INPUT': return QuestionTypeEnum.TEXT_FIELD;
        case 'CHECKBOX': return QuestionTypeEnum.CHECKBOX;
        case 'DROPDOWN': return QuestionTypeEnum.DROPDOWN;
        case 'RADIO_BUTTONS': return QuestionTypeEnum.RADIO_GROUP;
        case 'SCORE_PREDICTOR': return QuestionTypeEnum.SCORE_PREDICTOR;
        case 'PLAYER_PICKER': return QuestionTypeEnum.PLAYER_PICKER;
        default: return null;
    }
};
const QuestionaireStatsPage = ({ questionaireId, title }) => {
    const [statsLoading, setStatsLoading] = useState(false);
    const [tableDataLoading, setTableDataLoading] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({
      total: 0,
      currentPage: 1,
      perPage: 10,
    });
    const [order, setOrder] = useState({
      orderBy: 5,
      desc: true,
    });

    const [mode, setMode] = useState('user');
    const [questionNumber, setQuestionNumber] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [stats, setStats] = useState([]);
    const s = partial('shared');
    const qS = partial('question');

    const getQuestionaireStats = async () => {
        setStatsLoading(true);
        try {
            const [statsData, allQuestions] = await Promise.all([
              Api.questionaire.getStatsForQuestionaire(questionaireId),
              Api.questionaire.getQuestionsForQuestionaire(questionaireId),
            ]);
            const mappedData = statsData.map((item) => {
              if (item.type === 'SCORE_PREDICTOR') {
                const foundQuestion = allQuestions.find((question) => question.id === item.questionId);
                const currentQuestion = foundQuestion?.value?.availableOptions.sort((a, b) => a.id - b.id).map((option) => option.title);
                const constructedPrefix = currentQuestion.join(' - ');
                const newAnswers = [];
                for (let i = 0; i < item.answers.length; i++) {
                  newAnswers.push({
                    ...item.answers[i],
                    label: `${constructedPrefix} ${item.answers[i].label}`,
                  });
                }
                return ({ ...item, type: mapQuestionType(item.type), answers: newAnswers });
              }
              if (item.type === 'PLAYER_PICKER') {
                const newAnswers = [];
                for (let i = 0; i < item.answers.length; i++) {
                  newAnswers.push({
                    ...item.answers[i],
                    label: item.answers[i].label,
                  });
                }
                return ({ ...item, type: mapQuestionType(item.type), answers: newAnswers });
              }
              return ({ ...item, type: mapQuestionType(item.type) });
            });
            setStats(mappedData);
        } catch (error) {
            toast.error(s('somethingWentWrong'));
        } finally {
            setStatsLoading(false);
        }
    };
    const fetchTableData = async (currentPage = 1, pageSize = 10, orderObject = {
      orderBy: 5,
      desc: true,
    }) => {
      setTableDataLoading(true);
      try {
        const resTableData = await Api.questionaire.getTableAnswersForQuestionaire({ page: currentPage, take: pageSize }, { orderBy: orderObject.orderBy, direction: orderObject.desc ? 'DESC' : 'ASC' }, questionaireId);
        setTableData(resTableData.data);
        setPagination(old => ({ ...old, total: resTableData.count }));
        setTableDataLoading(false);
      } catch (err) {
        toast.error(s('somethingWentWrong'));
        setTableDataLoading(false);
      }
    };
    useEffect(() => {
      fetchTableData();
    }, []);

    useEffect(() => {
        getQuestionaireStats();
    }, [questionaireId]);
    const onChangePage = (pager) => {
      if (pager.pageSize !== pagination.perPage || pager.currentPage !== pagination.currentPage) {
        setPagination(old => ({
          ...old,
          currentPage: pager.currentPage,
          perPage: pager.pageSize,
        }));
        fetchTableData(pager.currentPage, pager.pageSize, order);
      }
    };

    const renderSort = (cellOrder) => {
      if (order.orderBy !== cellOrder) {
        return undefined;
      }
      return order.desc ?
        <ArrowDownIcon size={16} />
        :
        <ArrowUpIcon size={16} />;
    };
    const handleSort = (incommingOrder) => {
      const newOrder = {
        orderBy: incommingOrder,
        desc: incommingOrder === order.orderBy ? !order.desc : true,
      };
      setOrder(newOrder);
      setPagination({
        total: 0,
        currentPage: 1,
        perPage: 10,
      });
      fetchTableData(1, 10, newOrder);
    };

    const handleQuestionPress = (_number) => (_answer) => {
        setQuestionNumber(_number);
        setSelectedAnswer(_answer);
    };
    const handleCSVExport = async () => {
      try {
        const { rows } = await Api.questionaire.getAnswersForQuestionaire(questionaireId);

        const [firstRow, ...restRows] = rows;
        const [_first, _last, _phone, _email, _company, _answeredAt, ...questionTitles] = firstRow;
        let csv = `${s(_first)};${s(_last)};${s(_phone.toLowerCase())};${s(_email)};${s(_company)};${t(`questionaire.${_answeredAt}`)};${questionTitles.join(';')}\n`;
        restRows.map((row) => {
          const rowCopy = [...row];
          rowCopy[5] = moment(rowCopy[5]).format('MM-DD-YYYY HH:mm');
          return rowCopy;
        }).forEach((row) => {
          csv += `${row.join(';')}\n`;
        });
        fileDownload(`\uFEFF${csv}`, `${title}.csv`);
      } catch (e) {
        console.error(e);
        toast.error(s('somethingWentWrong'));
      }
    };

    return (
      <>
        <TextFieldModal selectedAnswer={selectedAnswer} isVisible={!!selectedAnswer} questionNumber={questionNumber} close={() => { setSelectedAnswer(null); setQuestionNumber(null); }}/>

        <Container>
          <Loading loading={statsLoading || tableDataLoading} />
          <Col className="mb-3">
            <Row>
              <Button outline={mode !== 'user'} size="sm" color="primary" onClick={() => setMode('user')}>{qS('userView')}</Button>
              <Button outline={mode !== 'stats'} size="sm" color="primary" onClick={() => setMode('stats')}>{qS('statsView')}</Button>
              <div style={{ flex: 1 }} />
              <Button
                onClick={handleCSVExport}
                color="secondary"
                size="sm"
                outline
              >
                <ExportVariantIcon
                  style={{
                height: '16px',
                marginTop: -2,
              }}
                />
                {qS('exportCSV')}
              </Button>
            </Row>
            {mode === 'user' &&
              <Row>
                <Container style={{ padding: 0 }}>
                  <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
                    <Table responsive striped >
                      <thead>
                        <tr>
                          {tableData[0]?.map((item, i) => {
                            let insights = null;
                                if (i > 5) {
                                  insights = item;
                                } else if (item === 'answeredAt') {
                                    insights = t('questionaire.answeredAt');
                                  } else {
                                    insights = s(item?.toLowerCase());
                                  }

                                return (
                                  <th style={{ color: '#4D576A' }} className="sortable" onClick={() => handleSort(i)}>
                                    <div className="flex alignItems-center">{insights} {renderSort(i)}</div>
                                  </th>);
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.slice(1)?.map((row, index) =>
                          <tr style={{ background: (index) % 2 ? '#fff' : '#F9FAFB' }} >
                            {row?.map((item, i) => i === 5 ? <td>{getBaseDateTime(item)}</td> : <td>{item}</td>)}
                          </tr>)}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    items={pagination.total}
                    onChangePage={onChangePage}
                    initialPage={pagination.currentPage}
                  />
                </Container>
              </Row>
            }
            {mode === 'stats' && stats.map((item, idx) =>
              <Row key={`question-${item.title}`}>
                <div
                  className="summary-question-card mb-3"
                >
                  <div className="content-area">
                    <Row>
                      <Col className="question-header">
                        <div className="question-id">
                          <p>{String((idx + 1)).padStart(2, '0')}</p>
                        </div>
                        <div className="question-description">
                          <h5>{item.title}</h5>
                          <p>
                            {`${qS('answers')} ${item.totalAnswers} ${qS('users')} ${item.totalUsers}`}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-3 d-flex justify-content-end">
                        <QuestionType type={item.type} />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="content-area">
                    <div className="question-content">
                      <AnswerItem item={item} itemPress={handleQuestionPress(String((idx + 1)).padStart(2, '0'))}/>
                    </div>
                  </div>
                </div>
              </Row>,
                )}
          </Col>

        </Container>
      </>

    );
};

export default QuestionaireStatsPage;
