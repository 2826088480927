export default {
  club_name: 'HK Eskil',
  club_name_short: 'HKE',
  stadium_name: 'STIGA Sports Arena A1',
  staging_url: 'https://eskil.api.fangroup.io',
  prod_url: 'https://eskil.api.fangroup.io',
  api: '/api',
  facebook: 'eskilibk',
  basename: '/eskil',
  club_logo: '/assets/teams/eskil.png',
  app_name: 'eskil',
  websale_name: 'eskil',
  ntb_team_id: 10012,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'eskil',
  monolithEnabled: false,
};
