import React from 'react';
import PropTypes from 'prop-types';
import printerIcon from '../../../shared/img/icons/printer-icon.svg';
import Checkbox from '../../../shared/components/custom/Checkbox';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
// import config from '../../../config';

const p = partial('box_officeShare');
const s = partial('shared');
const OrderOverview = ({
  priceGroups,
  selectedField,
  selectedRow,
  seats,
  mini,
  toggleMini,
  enabledQR,
  toggleQR,
  reserveTicketsAndPrint,
  state,
}) => {
  const config = useConfig();
  const filteredPriceGroups = priceGroups.filter(priceGroup => priceGroup.amount !== 0);

  let price = 0;
  if (selectedField) {
    for (let i = 0; i < filteredPriceGroups.length; i++) {
      price += filteredPriceGroups[i].amount * ((filteredPriceGroups[i].percentage / 100) * Number(selectedField.field_type.price));
    }
  }
  price = price.toFixed(2);

  const renderPriceGroups = () => {
    return filteredPriceGroups.map((priceGroup, index) => (
      <h2 key={`OrderOverviewSeat-${priceGroup.id}`}>{priceGroup.amount} {priceGroup.title}{priceGroups.length !== index + 1 ? ',' : ''}&nbsp;</h2>
    ));
  };

  const renderSeating = () => {
    const fieldText = selectedField ? `${s('field')} ${selectedField.name} ` : '';
    const isUnmarked = selectedField && selectedField.field_type.name === 'unmarked';
    let rowText = selectedRow ? `${s('row')} ${selectedRow.number}` : '';
    let seatsText = seats.filter(seat => seat.active).map(seat => seat.seats_seat_id).join(', ');

    if (isUnmarked) {
      rowText = `${p('freePlacement')}`;
      seatsText = '';
    }

    return (
      <h2>
        {fieldText} {rowText} {seatsText.length !== 0 ? `${s('seats')} ${seatsText}` : ''}
      </h2>
    );
  };

  return (
    <div className="order-overview">
      <div className="column">
        <p>{s('quantity')}</p>
        <div className="row">
          {renderPriceGroups()}
        </div>
      </div>
      <div className="column">
        <p>{s('seats')}</p>
        <div className="row">
          {renderSeating()}
        </div>
      </div>
      <div className="column">
        <p>{p('totalPrice')}</p>
        <h1>{config.currencyShortSmall}. {price}</h1>
      </div>
      <div className="column">
        <div className="column column-qr">
          <Checkbox
            single
            toggled={enabledQR}
            handleToggle={toggleQR}
            index={1}
          />
          <p>{p('collect')}QR</p>
        </div>
        <div className="column column-qr">
          <Checkbox
            single
            toggled={mini}
            handleToggle={toggleMini}
            index={1}
          />
          <p>{p('mini')}</p>
        </div>
      </div>
      <button onClick={reserveTicketsAndPrint} className="column" disabled={!state}>
        <img src={printerIcon} alt="" />
        <p>{p('finish&PrintCaps')}</p>
      </button>
    </div>
  );
};

OrderOverview.propTypes = {
  state: PropTypes.bool.isRequired,
  priceGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedField: PropTypes.shape({
    name: PropTypes.string,
    field_type: PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.string,
    }),
  }),
  selectedRow: PropTypes.shape({
    number: PropTypes.number,
  }),
  seats: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    seats_seat_id: PropTypes.number,
  })).isRequired,
  reserveTicketsAndPrint: PropTypes.func.isRequired,
  enabledQR: PropTypes.bool.isRequired,
  toggleQR: PropTypes.func.isRequired,
};

OrderOverview.defaultProps = {
  selectedField: null,
  selectedRow: null,
};

export default OrderOverview;
