export default {
  club_name: 'Storhamar Hockey',
  club_name_short: 'SIL',
  stadium_name: 'CC Amfi',
  staging_url: 'https://sil.api.fangroup.io',
  prod_url: 'https://sil.api.fangroup.io',
  api: '/api',
  facebook: 'StorhamarHockey',
  basename: '/sil',
  club_logo: '/assets/teams/sil.png',
  app_name: 'silapp',
  websale_name: 'sil',
  ntb_team_id: 134,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'sil',
  monolithEnabled: false,
};
