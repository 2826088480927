import React, { useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Container, Col, Button, Row } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TimetableIcon from 'mdi-react/TimetableIcon';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateApi';
import t, { partial } from '../../../util/translation/translation';
import RenderTextAreaField from '../../../shared/components/custom/TextArea';
import renderDateTimePickerField from '../../../shared/components/form/DateTimePicker';

const EditScheduledMessageForm = ({
  handleSubmit,
  initialize,
  messageValues,
  type,
}) => {
  const s = partial('shared');

  useEffect(() => {
    if (messageValues) {
      initialize({
        scheduledDate: new Date(messageValues.scheduledDate),
        title: type !== 'sms' ? messageValues.messageTitle : '',
        body: messageValues.messageBody,
      });
    }
  }, [messageValues]);


  return (
    <Container>
      <Row>
        <h3 className="page-title mb-0">{t('ScheduledMessagesPage.scheduleMessage')}</h3>
      </Row>
      <Row>
        <form onSubmit={handleSubmit} className="form">
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label ">{s('title')}</span>
                {type === 'sms' ?
                  <Field
                    style={{ margin: '-10px !important' }}
                    name="title"
                    component={renderField}
                    placeholder={`${s('title')}`}
                    disabled
                  /> :
                  <div className="form__form-group-field" style={{ margin: '-5px -10px' }}>
                    <Field
                      style={{ margin: '-10px !important' }}
                      name="title"
                      component={renderField}
                      placeholder={`${s('title')}`}
                      emoji
                    />
                  </div>
                    }
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group" >
                <span className="form__form-group-label ">{s('message')}</span>
                <div className="form__form-group-field" style={{ margin: '-5px -10px' }}>
                  <Field
                    name="body"
                    component={RenderTextAreaField}
                    placeholder={s('message')}
                    emoji
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group" style={{ zIndex: 100 }}>
                <span className="form-header">{t('ScheduledMessagesPage.scheduleTime')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="scheduledDate"
                    component={renderDateTimePickerField}
                    timeFormat="p"
                    dateFormat="Pp"
                    timeIntervals={15}
                    minDate={new Date()}
                  />
                  <div className="form__form-group-icon">
                    <TimetableIcon />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Col md={12} className="px-0">
            <Button type="submit" color="primary">
              {s('save')}
            </Button>
            <Link
              className="color-unset"
              to="/messages?tab=2"
            >
              <Button type="button">
                {s('close')}
              </Button>
            </Link>
          </Col>
        </form>
      </Row>
    </Container>
  );
};



const reduxF = reduxForm({
  form: 'edit_scheduled_message_form',
  validate,
});

export default withRouter(connect(state => ({
  formValues: getFormValues('edit_scheduled_message_form')(state),
}))(reduxF(EditScheduledMessageForm)));
