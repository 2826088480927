import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { Row, ButtonGroup, Button, Col } from 'reactstrap';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import renderField from '../../../shared/components/custom/Field';
import RowForm from './RowForm';
import { partial } from '../../../util/translation/translation';


const RowsAndSeats = (props) => {
  const x = partial('RowsAndSeats');
  const d = partial('shared');
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [isOpen, setIsOpen] = useState(0);

  const toggleEditShow = (id) => {
    if (id === isOpen) {
      setIsOpen(0);
      return;
    }
    setIsOpen(id);
  };

  const initializeComponent = async () => {
    setLoading(true);
    let res;
    try {
      res = await Api.stadium.getRows(props.fieldID);
    } catch (err) {
      toast.error(err || x('errorFetchingNecessaryData'));
    }
    setLoading(false);
    if (!res) { return; }
    const sortedRows = res.data.sort((row1, row2) => row2.number - row1.number).map(e => ({ ...e, value: e.seats.length }));
    setRows(sortedRows);
  };

  useEffect(() => {
    initializeComponent();
  }, []);

  const handleSeats = async (mode) => {
    setLoading(true);
    const payload = selectedSeats.map((seat) => {
      const rawSeat = { ...seat };
      rawSeat.seats = { seat_ids: rawSeat.seats.map(s => s.id), mode };
      return rawSeat;
    });

    const { fieldID } = props;
    try {
      setLoading(true);
      await Promise.all(payload.map(value => Api.stadium.updateSeats(fieldID, value.rowId, value.seats)));
    } catch (err) {
      toast.error(err || x('couldNotUpdateSeats'));
      setLoading(false);
      return;
    }
    setSelectedSeats([]);
    setLoading(false);
    await initializeComponent();
    toast.success(x('seatsUpdated'));
  };

  const handleSeat = id => (seats) => {
    const rowIndex = rows.findIndex(row => row.id === id);
    const rawRows = [...rows];
    rawRows[rowIndex].seats = rawRows[rowIndex].seats.map((seat) => {
      const rawSeat = { ...seat };
      rawSeat.selected = !!seats.find(s => s.id === seat.id);
      return rawSeat;
    });
    const findRow = selectedSeats.findIndex(seatRow => seatRow.rowId === id);
    const rawSeatRows = [...selectedSeats];
    if (findRow === -1) {
      rawSeatRows.push({
        rowId: id,
        seats,
      });
    } else {
      rawSeatRows[findRow].seats = seats;
    }
    setSelectedSeats(rawSeatRows);
    setRows(rawRows);
  };

  const editRowSubmit = async (values, dispatcher, rowProps) => {
    setLoading(true);
    const promises = [];
    const { number, description, seats } = values;
    const { id, ...rest } = rowProps.row;
    if (seats && seats.amount && parseInt(seats.amount, 10) > 0) {
      const rawSeats = { ...seats, seat_state: { id: 1 } };
      if (!seats.start_count) {
        rawSeats.start_count = rows.find(row => row.id === id).seats.reduce((t, e) => Math.max(t, e.seat_id), 0) + 1;
      }
      promises.push(Api.stadium.createSeats(id, rawSeats));
    }
    if ((number !== rest.number.toString()) || description !== rest.description) {
      const payload = {};
      if (number) {
        payload.number = number;
      }
      if (description) {
        payload.description = description;
      }
      if (props.fieldID) {
        payload.field_id = props.fieldID;
      }
      promises.push(Api.stadium.updateRow(id, payload));
    }
    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        await initializeComponent();
      } catch (err) {
        toast.error(err || x('couldNotUpdateSeats'));
        setLoading(false);
        return;
      }
      setLoading(false);
      toast.success(x('rowSetupIsUpdated'));
    }
  };

  const deleteRow = async (id) => {
    setLoading(false);
    try {
      await Api.stadium.deleteRow(id);
    } catch (err) {
      setLoading(false);
      toast.error(err || x('couldNotDeleteRow'));
      return;
    }
    await initializeComponent();
    setLoading(false);
    toast.success(x('rowDeleted'));
  };

  const RenderRows = ros => ros.rows.map((row, index) => {
    const NewForm = reduxForm({
      form: `editRow${row.id}`, onSubmit: editRowSubmit, enableReinitialize: true, keepDirtyOnReinitialize: true, initialValues: { description: row.description, number: row.number },
    })(RowForm);
    return (<NewForm key={`rowform${row.id}`} deleteRow={deleteRow} index={index} row={row} handleSeats={handleSeat(row.id)} toggleEdit={toggleEditShow} isOpen={isOpen} />);
  });

  const createRows = async (values) => {
    setLoading(true);
    const payload = {
      number: values.update,
      number_of_seats: values.seat || 0,
      field_id: props.fieldID,
    };
    try {
      await Api.stadium.createRow(payload);
    } catch (err) {
      toast.error(x('couldNotCreateRows'));
      setLoading(false);
      return;
    }
    setLoading(false);
    await initializeComponent();
    toast.success(x('rowWasCreated'));
  };

  const CreateRow = reduxForm({ form: 'createRows', onSubmit: createRows })(p => (
    <form onSubmit={p.handleSubmit}>
      <div className="flex-row form" style={{ marginBottom: 10 }}>
        <div className="marginRight-15">
          <p>{x('rowNumber')}</p>
          <Field
            component={renderField}
            name="update"
            type="number"
          />
        </div>
        <div>
          <p>{x('numberOfSeats')}</p>
          <Field
            component={renderField}
            name="seat"
            type="number"
          />
        </div>
      </div>
      <Button color="success" type="submit">
        {d('create')}
      </Button>
    </form>));

  return (
    <>
      <Row>
        <Col>
          <h3 style={{ marginTop: '5px', fontWeight: 'bold' }}>Felt {props.field.name}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <CreateRow />
        </Col>
      </Row>
      <Loading loading={loading} />
      { selectedSeats.some(seatRows => seatRows && seatRows.seats && seatRows.seats.some(seats => seats.selected)) &&
      <div style={{ marginLeft: '3px', marginBottom: '20px' }}>
        <ButtonGroup>
          <Button onClick={() => handleSeats('OPEN')} className="margin-0" color="success" style={{ backgroundColor: '#4ce179' }}>{x('activateSeats')}</Button>
          <Button onClick={() => handleSeats('DEACTIVATED')} className="margin-0" color="secondary">{x('deactivateSeats')}</Button>
          <Button onClick={() => handleSeats('DELETE')} className="margin-0" color="danger">{x('deleteSeats')}</Button>
        </ButtonGroup>
      </div>
      }
      <RenderRows rows={rows} />
    </>
  );
};

RowsAndSeats.propTypes = {
  fieldID: PropTypes.string.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default reduxForm({
  form: 'rows-and-seats',
})(RowsAndSeats);
