import React from 'react';
import { Table } from 'reactstrap';
import PropTypes, { any } from 'prop-types';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import Checkbox from '../../../shared/components/custom/Checkbox';
import { partial } from '../../../util/translation/translation';

const MatchFields = (props) => {
  const p = partial('MatchFields');
  const s = partial('shared');
  const handleToggle = (index) => {
    const payload = {
      field_id: props.fields[index].id,
      excluded: !props.fields[index].excluded,
    };
    props.handleMatchField(payload);
  };

  const renderMatchFields = () => {
    return props.fields?.map((field, index) => (
      <tr key={`RenderMatchFields-${field.id}`}>
        <td>{field.name}</td>
        <td>{field.field_type.display_name}</td>
        <td>{field.rows_count ? field.rows_count : 0}</td>
        <td>{field.seats_count ? field.seats_count : 0}</td>
        <td>{field.buyable ? <CheckCircleIcon color="#399351" /> : <CloseCircleIcon color="#EB4747" />}</td>
        <td>
          <Checkbox
            single
            toggled={field.excluded}
            handleToggle={handleToggle}
            index={index}
            className="checkbox-colored-click"
          />
        </td>
        <td>
          {!!field.buyable === true && !!field.excluded === false ? (
            <CheckCircleIcon color="#399351" />
          ) : (
            <CloseCircleIcon color="#EB4747" />
          )}
        </td>
      </tr>
    ));
  };

  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th>{s('name')}</th>
          <th>{s('type')}</th>
          <th>{p('amountOfRows')}</th>
          <th>{p('amountOfSeats')}</th>
          <th>{p('globallyBuyable')}</th>
          <th>{p('excludeField')}</th>
          <th>{p('Buyable')}</th>
        </tr>
      </thead>
      <tbody>{renderMatchFields()}</tbody>
    </Table>
  );
};

MatchFields.propTypes = {
  fields: PropTypes.arrayOf(any).isRequired,
  handleMatchField: PropTypes.func.isRequired,
  match: PropTypes.shape({
    id: PropTypes.number,
    stadium_id: PropTypes.number,
  }).isRequired,
};

export default MatchFields;
