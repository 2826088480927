import React, { useState, useEffect, useMemo } from 'react';
import { Container, Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import CommentTextOutlineIcon from 'mdi-react/CommentTextOutlineIcon';
import CommentOutlineIcon from 'mdi-react/CommentOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import Loading from '../../../shared/components/custom/Loading';
import Checkbox from '../../../shared/components/custom/Checkbox';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import CommentModal from './CommentModal';
import ShareModal from '../../../shared/components/custom/ShareModal';
import { getBaseDate } from '../../../util/date-and-time';

const WaitlistPage = ({ eventId }) => {
  const [waiters, setWaiters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedWaiter, setSelectedWaiter] = useState(null);
  const isSomeoneSelected = useMemo(() => waiters.some((waiter) => waiter.toggled), [waiters]);

  const p = partial('EventsPage');
  const s = partial('shared');
  const epp = partial('EventParticipantsPage');


  const getWaitingList = async () => {
    setLoading(true);
    try {
      const [resWaiters, clubCompanies] = await Promise.all([Api.events.getWaitingParticipants(eventId), Api.companies.getClubCompanies()]);
      setWaiters(resWaiters.map((item) => ({ ...item, toggled: false, company: clubCompanies.find((comp) => comp.id === item.companyId) })));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    getWaitingList();
  }, []);

  const handleBatchDelete = async () => {
    setLoading(true);
    try {
      const partipantsToDelete = waiters.filter((item) => item.toggled);
      await Promise.all(partipantsToDelete.map((participant) => Api.events.deleteWaitingParticipant(participant.id)));
    } catch (err) {
      toast.error(epp('participantDeletingERROR'));
    } finally {
      getWaitingList();
    }
  };
  const handleBatchPromote = async () => {
    setLoading(true);
    try {
      const partipantsToPromote = waiters.filter((item) => item.toggled);
      await Promise.all(partipantsToPromote.map((participant) => Api.events.promoteEventParticipant(participant.id)));
    } catch (err) {
      toast.error(epp('participantDeletingERROR'));
    } finally {
      getWaitingList();
    }
  };


  const toggleWaiter = (index, desiredValue = null) => {
    const waitersCopy = [...waiters];
    if (desiredValue !== null) {
      waitersCopy[index].toggled = desiredValue;
    } else {
      waitersCopy[index].toggled = !waitersCopy[index].toggled;
    }
    setWaiters(waitersCopy);
  };
  const renderWaitlist = () => {
    return waiters.map((waiter, i) => {
      return (
        <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`waiter-${waiter.id}`}>
          <td>
            <Checkbox index={1} toggled={waiter.toggled} single handleToggle={() => toggleWaiter(i)} />
          </td>
          <td>
            {getBaseDate(waiter.createdAt)}
          </td>
          <td>
            {`${waiter.firstname} ${waiter.lastname}`}
          </td>
          <td>{waiter.phoneNumber}</td>
          <td>{waiter?.company?.name || ''}</td>
          <td>
            {waiter.friendAmount}
          </td>
          <td>
            {!waiter.additionalNotes ? <CommentOutlineIcon onClick={() => setSelectedWaiter(waiter)} /> : <CommentTextOutlineIcon color="#3EA3FC" onClick={() => setSelectedWaiter(waiter)} />}
          </td>
        </tr>
      );
    });
  };

  const handleCloseCommentModal = () => {
    setSelectedWaiter(null);
  };
  return (
    <Container>
      <ShareModal
        visible={selectedWaiter}
        title={p('addNotes')}
        handleModal={handleCloseCommentModal}
      >
        <CommentModal
          selectedParticipantForComment={selectedWaiter}
          handleCloseModal={handleCloseCommentModal}
          getPaticipants={getWaitingList}
          isWaitlist
        />
      </ShareModal>
      <Loading loading={loading} />
      <div className="card__title my-2">
        <div>
          <Button className="px-2" size="sm" onClick={handleBatchDelete} disabled={!isSomeoneSelected} color={isSomeoneSelected ? 'danger' : 'secondary'}>
            <DeleteIcon className="m-0" />
          </Button>
          <Button size="sm" onClick={handleBatchPromote} disabled={!isSomeoneSelected} color={isSomeoneSelected ? 'success' : 'secondary'}>
            {p('promote')}
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: 10, paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>

        <Table responsive striped>
          <thead>
            <tr>
              <th />
              <th>{s('date')}</th>
              <th>{s('name')}</th>
              <th>{s('phone')}</th>
              <th>{s('company')}</th>
              <th>{s('guests')}</th>
              <th>{s('comments')}</th>
            </tr>
          </thead>
          <tbody>
            {renderWaitlist()}
          </tbody>
        </Table>

      </div>

    </Container>
  );
};

export default WaitlistPage;


