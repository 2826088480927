import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ExportVariantIcon from 'mdi-react/ExportVariantIcon';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';
import { formatDate } from '../../../util/date-and-time';
import seasonsState from '../../../recoil/seasons/selector';
import useConfig from '../../../util/useConfig';

const MatchTicketsReport = () => {
  const config = useConfig();
  const [seasons, setSeasons] = useRecoilState(seasonsState);
  const [soldMatchTickets, setSoldMatchTickets] = useState({ matches: [] });
  const [sortOrder, setSortOrder] = useState(1);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [matchTypes, setMatchTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('');

  const p = partial('MatchTicketsReport');
  const e = partial('ReportsPage');
  const s = partial('shared');

  const getInitialData = async () => {
    setLoading(true);
    try {
      const resSeasons = await Api.seasons.getSeasons();
      setSeasons(resSeasons.data);
      if (resSeasons.data.length !== 0) {
        setSelectedSeason(resSeasons.data[0]);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err || e('fetcingMatchTicketsFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const findSumByMethod = (payments, method) => +(payments.find(payment => payment.came_from === method)?.sum || 0);

  const getSoldMatchTickets = async (seasonID) => {
    setLoading(true);
    try {
      const resSoldMatchTickets = await Api.reports.getSoldMatchTickets(seasonID);
      const formattedResSoldMatchTickets = resSoldMatchTickets.data.matches.map(match => ({
        id: match.id,
        away_team: match.away_team,
        match_starts_at: match.match_starts_at,
        match_type: match.match_type,
        sold_amount: match.sold_amount,
        payments: match.payments,
        app: findSumByMethod(match.payments, 'App') + findSumByMethod(match.payments, null) + findSumByMethod(match.payments, 'Stripe'),
        kiosk: findSumByMethod(match.payments, 'Boxoffice'),
        websale: findSumByMethod(match.payments, 'Websale'),
        billable: findSumByMethod(match.payments, 'Billable'),
        sum: match.total_price,
      }));

      const tmpMatchTypes = resSoldMatchTickets.data.matches.reduce((acc, curr) => {
        if (!acc.find(a => a === curr.match_type)) {
          acc.push(curr.match_type);
        }
        return acc;
      }, []);
      setMatchTypes(tmpMatchTypes);
      setSoldMatchTickets({ matches: formattedResSoldMatchTickets });
      setLoading(false);
    } catch (err) {
      toast.error(err || p('fetchingReportInformationFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((seasons.length !== 0 || selectedSeason) && selectedSeason) {
      getSoldMatchTickets(selectedSeason.id);
    }
  }, [seasons, selectedSeason]);

  const sumTickets = useMemo(() =>
    soldMatchTickets.matches.reduce((acc, match) => {
      acc.sold_amount += Number(match.sold_amount);
      acc.app += Number(match.app);
      acc.websale += Number(match.websale);
      acc.billable += Number(match.billable);
      acc.kiosk += Number(match.kiosk);
      acc.sum += Number(match.sum);
      return acc;
    },
  { sold_amount: 0, app: 0, websale: 0, billable: 0, kiosk: 0, sum: 0 }), [soldMatchTickets]);

  const handleSort = (type) => {
    setSortOrder(sortOrder === 1 ? -1 : 1);
    switch (type) {
      case 'away_team':
        setSoldMatchTickets({ ...soldMatchTickets, matches: soldMatchTickets.matches.sort((a, b) => (a[type].localeCompare(b[type]) * sortOrder)) });
        break;
      case 'match_starts_at':
        setSoldMatchTickets({ ...soldMatchTickets, matches: soldMatchTickets.matches.sort((a, b) => (moment(a[type]) - (moment(b[type])) * sortOrder)) });
        break;
      case 'match_type':
        setSoldMatchTickets({ ...soldMatchTickets, matches: soldMatchTickets.matches.sort((a, b) => (a[type].localeCompare(b[type]) * sortOrder)) });
        break;
      case 'sold_amount':
      case 'app':
      case 'websale':
      case 'kiosk':
      case 'billable':
      case 'sum':
        setSoldMatchTickets({ ...soldMatchTickets, matches: soldMatchTickets.matches.sort((a, b) => ((a[type] - b[type]) * sortOrder)) });
        break;
      default:
    }
  };

  const formatNumber = (number = 0) => (Number(number)?.toLocaleString()?.split(',')?.join(' ') ?? '-');

  const listMatchTypes = () => {
    return matchTypes.map(matchType => (
      <DropdownItem onClick={() => setSelectedFilter(selectedFilter === matchType ? '' : matchType)} key={`listMatchTypes-${matchType}`}>{matchType}</DropdownItem>
    ));
  };

  const exportReport = async () => {
    setLoading(true);
    try {
      const test = await Api.reports.exportSoldMatchTickets(selectedSeason.id);
      fileDownload(`\uFEFF${test.data}`, `${p('salesReportFilename')}.csv`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || p('exportingReportFailed'));
    }
  };
  const listSeasons = () => {
    return seasons.map(season => (
      <DropdownItem onClick={() => setSelectedSeason(season)} key={`listSeasons-${season.id}`}>{s('theSeason')} {season.name}</DropdownItem>
    ));
  };

  const listMatchTicketsReports = () => {
    let rawSoldMatchTickets = [...soldMatchTickets.matches];
    if (selectedFilter) {
      rawSoldMatchTickets = rawSoldMatchTickets.filter(match => match.match_type === selectedFilter);
    }
    return rawSoldMatchTickets.map(match => (
      <tr key={match.id}>
        <td className="bold">{match.away_team}</td>
        <td>{formatDate(match.match_starts_at)}</td>
        <td>{match.match_type}</td>
        <td>{formatNumber(match.sold_amount)}</td>
        <td>{formatNumber(match.app)} {config.currencyCaps}</td>
        <td>{formatNumber(match.websale)} {config.currencyCaps}</td>
        <td>{formatNumber(match.kiosk)} {config.currencyCaps}</td>
        <td>{formatNumber(match.billable)} {config.currencyCaps}</td>
        <td>{formatNumber(match.sum)} {config.currencyCaps}</td>
      </tr>

    ));
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="card__title margin-0">
        <h5 className="bold-text">{p('salesReportSingleTicketsCaps')}</h5>
        <div className="flex row">
          <Button onClick={exportReport} color="primary" className="flex align-center">
            <ExportVariantIcon
              style={{
                height: '20px',
                width: 'auto',
                fill: 'white',
                position: 'relative',
                top: '-2px',
              }}
            />
            {p('exportCSV')}
          </Button>
        </div>
      </div>
      <div>
        <h5 className="bold-text" style={{ marginBottom: '5px' }}>{s('periodCaps')}</h5>
        <div className="flex space-between">
          <UncontrolledDropdown className="marginRight-15">
            <DropdownToggle className="icon icon--right" outline>{s('theSeason')} {selectedSeason?.name} <ChevronDownIcon /></DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              { listSeasons() }
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown>
            <DropdownToggle className="icon icon--right" outline>{selectedFilter || p('filterList') } <ChevronDownIcon /></DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <DropdownItem onClick={() => setSelectedFilter('')}>{s('allCaps')}</DropdownItem>
              { listMatchTypes() }
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <Table responsive bordered>
        <thead>
          <tr className="center-list" style={{ cursor: 'pointer' }}>
            <th onClick={() => handleSort('away_team')}>{s('opponent')}</th>
            <th onClick={() => handleSort('match_starts_at')}>{s('date')}</th>
            <th onClick={() => handleSort('match_type')}>{s('matchType')}</th>
            <th onClick={() => handleSort('sold_amount')}>{p('soldTickets')}</th>
            <th onClick={() => handleSort('app')}>{s('app')}</th>
            <th onClick={() => handleSort('websale')} >{s('websale')}</th>
            <th onClick={() => handleSort('kiosk')}>{s('boxOffice')}</th>
            <th onClick={() => handleSort('billable')}>{s('invoiced')}</th>
            <th onClick={() => handleSort('sum')} align="right">{s('sumCaps')}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bold">
            <td>{s('sumCaps')}</td>
            <td />
            <td />
            <td>{formatNumber(sumTickets.sold_amount)}</td>
            <td>{formatNumber(sumTickets.app)} {config.currencyCaps}</td>
            <td>{formatNumber(sumTickets.websale)} {config.currencyCaps}</td>
            <td>{formatNumber(sumTickets.kiosk)} {config.currencyCaps}</td>
            <td>{formatNumber(sumTickets.billable)} {config.currencyCaps}</td>
            <td>{formatNumber(sumTickets.sum)} {config.currencyCaps}</td>
          </tr>
          {listMatchTicketsReports()}
        </tbody>
      </Table>
    </>
  );
};

export default MatchTicketsReport;
