import React from 'react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import ClockAlertIcon from 'mdi-react/ClockOutlineIcon';


const TabHeader = ({ count, heading, iconSize, limit }) => {
  return (
    <Container className="d-flex justify-content-center align-items-center">

      <span style={{ paddingRight: '20px' }}>{heading}</span> { count ?
        <Row style={{ color: 'red', fontSize: '14px' }}>
          <ClockAlertIcon size={iconSize}/>
          <span>
            {count > limit ? `${limit}+` : count}
          </span>
        </Row>
      : <></>}
    </Container>
  );
};

TabHeader.propTypes = {
  count: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  iconSize: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};

export default TabHeader;
