import React from 'react';
import {
  Card,
  Table,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import { partial } from '../../../util/translation/translation';
import { capitalizeFirstLetter, isInfiniteDate } from '../../../util/functions';
import { formatWeekDateTime } from '../../../util/date-and-time';
import Pagination from '../../../shared/components/pagination/Pagination';


const s = partial('shared');
const p = partial('UserTiers');
const UserTiersList = ({ handleModal, tierUsers, totalItems, setReverse, reverse, onChangePage }) => {
  const renderTierList = () => {
    return tierUsers.map((user) => {
      const parsedDueDate = isInfiniteDate(user.tier.dueDate) ? p('neverEnding') : formatWeekDateTime(user.tier.dueDate);
      return (
        <tr key={`User-${user.userCloudId}`}>
          <td>{user.firstname}</td>
          <td>{user.lastname}</td>
          <td><Link to={`/users/edit/${user.userCloudId}?tab=3`}>{user.phoneNumber}</Link></td>
          <td>
            { user.tier.name}
          </td>
          <td>
            {parsedDueDate}
          </td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <p><DotsHorizontalIcon /></p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/users/edit/${user.userCloudId}?tab=3`}>
                  <DropdownItem id={user.userCloudId}>{s('edit')}</DropdownItem>
                </Link>
                <DropdownItem onClick={() => { handleModal(true, user.tier.affiliationId); }} className="danger">{s('delete')}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };
  const renderSort = () => {
    return reverse ?
      <ArrowUpIcon size={16} />
      :
      <ArrowDownIcon size={16} />;
  };

  return (
    <Card>
      <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
        <Table striped responsive>
          <thead>
            <tr>
              <th >{s('firstname')}</th>
              <th >{s('lastname')}</th>
              <th >{s('username')}</th>
              <th>{capitalizeFirstLetter(p('tier'))}</th>
              <th className="sortable" onClick={() => setReverse(!reverse)}>{capitalizeFirstLetter(s('endDate'))} {renderSort()}</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>{renderTierList()}</tbody>
        </Table>
      </div>
      <Pagination
        items={totalItems}
        onChangePage={onChangePage}
      />
    </Card>
  );
};

export default UserTiersList;
