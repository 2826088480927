import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { EmptyGraphData, Widget, WidgetHeader } from '../shared';
import Api from '../../../../util/api';
import Loading from '../../../shared/Spinner';
import Event from './Event';
import { partial } from '../../../../util/translation/translation';

const UpcomingEvents = () => {
  const dT = partial('DashboardPage');
  const sT = partial('shared');
    const { data, isLoading } = useQuery({ queryKey: ['upcoming-events'], queryFn: () => Api.events.upcomingEvents() });

    const renderEvents = (
      data?.data?.events?.length > 0 ? data?.data?.events?.map((event) => <Event key={event.id} event={event} />) : <EmptyGraphData message={dT('noData')} />
    );

    return (
      <Widget>
        <WidgetHeader title={`${sT('events')} (${data?.data?.totalEvents})`} isBorder={false} bottomSpace="sm">
          <Link to="/events" className="events-link">{dT('viewAll')}</Link>
        </WidgetHeader>
        {isLoading ? <Loading /> : renderEvents}
      </Widget>
    );
};
export default UpcomingEvents;
