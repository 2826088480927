/* eslint no-nested-ternary: 0 */
import React, { Component } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  static propTypes = {
    toggled: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({
      flex: PropTypes.string,
    }),
    single: PropTypes.bool,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: '',
    style: {},
    single: false,
    disabled: false,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.toggled !== this.props.toggled;
  }
  getCheckBoxClassName = () => {
    if (this.props.disabled) {
      return 'checkboxInput-disabled';
    } else {
      return this.props.toggled && this.props.index !== -1
        ? 'checkboxInput'
        : 'checkbox';
    }
  };
  render() {
    return (
      <div
        className={`${this.props.className} ${
          this.props.toggled && 'active'
        } height-18`}
        style={this.props.style}
      >
        {this.props.single ? (
          <>
            <input
              onClick={() =>
                this.props.handleToggle(this.props.index, !this.props.toggled)
              }
              className={this.getCheckBoxClassName()}
              type="checkbox"
              value={this.props.index}
              disabled={this.props.disabled}
            />
            <span className="checkbox-icon pointerEvents-none">
              {this.props.toggled ? (
                <CheckIcon color="white" size="16px" className="visible" />
              ) : (
                <CheckIcon color="#42e2b5" size="16px" className="hidden" />
              )}
            </span>
          </>
        ) : (
          <input
            onClick={() =>
              this.props.handleToggle(this.props.index, !this.props.toggled)
            }
            disabled={this.props.disabled}
            className={
              this.props.toggled && this.props.index !== -1
                ? 'checkboxToggled'
                : 'checkbox'
            }
            type="checkbox"
            value={this.props.index}
          />
        )}
        {!this.props.single && (
          <span className="checkbox-icon pointerEvents-none">
            {this.props.index !== -1 ? (
              this.props.toggled ? (
                <CheckIcon color="white" size="16px" className="visible" />
              ) : (
                <CheckIcon color="#0469DC" size="16px" className="hidden" />
              )
            ) : this.props.toggled ? (
              <CheckIcon color="#0469DC" size="16px" className="visible" />
            ) : (
              <CheckIcon color="#0469DC" size="16px" className="hidden" />
            )}
          </span>
        )}
      </div>
    );
  }
}

export default Checkbox;
