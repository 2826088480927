import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import EmailLogInForm from './components/EmailLogInForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import Loading from '../../shared/components/custom/Loading';
// import config from '../../config';
import t, { partial } from '../../util/translation/translation';
import app from '../_app/FirebaseConfig';
import Api from '../../util/api';
import useConfig from '../../util/useConfig';

const EmailLoginPage = () => {
  const config = useConfig();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('login');
  const history = useHistory();
  const p = partial('LoginPage');
  const s = partial('shared');
  function handleError(error) {
    switch (error) {
      case 'auth/invalid-email': return toast.error(p('userNotFound'));
      case 'auth/invalid-verification-code': return toast.error(p('pleaseEnterCorrectCode'));
      case 'auth/too-many-requests': return toast.error(p('tooManyRequests'));
      case 'auth/wrong-password': return toast.error(t('LoginPage.pleaseEnterCorrectPassword'));
      case 'INVALID_PASSWORD': return toast.error(t('LoginPage.pleaseEnterCorrectPassword'));
      default: return toast.error(s('somethingWentWrong'));
    }
  }
  async function emailLogin({ email, password }) {
    try {
      setLoading(true);
      const lowerCaseEmail = email?.toLowerCase();
      const trimmedEmail = lowerCaseEmail?.trim();
      try {
        await Api.login.doesUserExist({ email: trimmedEmail });
      } catch {
        throw Object.assign(new Error(), { code: 'auth/invalid-email' });
      }
      const { data: { idToken } } = await Api.authorization.signInWithEmailPass(
        trimmedEmail,
        password,
      );
      const { data: { token: customToken } } = await Api.authorization.getCustomToken(idToken);
      const auth = getAuth(app);
      await signInWithCustomToken(auth, customToken);
      setLoading(false);
    } catch (err) {
      handleError(err?.message ? err?.message : err?.code);
      setLoading(false);
    }
  }
  async function sendResetEmail({ email }) {
    setLoading(true);
    try {
      try {
        await Api.login.doesUserExist({ email });
      } catch {
        throw Object.assign(new Error(), { code: 'auth/invalid-email' });
      }
      await Api.authorization.sendPasswordResetEmail(email, config);
      setLoading(false);
      setTab('login');
      toast.success(p('resetEmailSent'));
    } catch (err) {
      handleError(err?.message ? { code: err?.message } : err);
      setLoading(false);
    }
  }
  const RenderTabs = () => {
    switch (tab) {
      case 'forgot': return <ResetPasswordForm onSubmit={sendResetEmail} goBack={() => setTab('login')} />;
      case 'login': return <EmailLogInForm goToForgot={() => setTab('forgot')} goToPassword={() => history.push('/log_in')} onSubmit={emailLogin} />;
      default: return null;
    }
  };
  return (
    <div className="account">
      <Loading loading={loading} />
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">{p('welcomeTo')}
              <span className="account__logo"> {config.club_name}
              </span>
            </h3>
          </div>
          <RenderTabs />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.admin.isAuthenticated,
});

export default connect(mapStateToProps)(EmailLoginPage);
