export default {
  club_name: 'Gothia Cup',
  club_name_short: 'GCUP',
  stadium_name: 'SKF Arena',
  staging_url: 'https://gothiacup.api.fangroup.io',
  prod_url: 'https://gothiacup.api.fangroup.io',
  api: '/api',
  facebook: 'gothiacup',
  basename: '/gothiacup',
  club_logo: '/assets/teams/gothiacup.png',
  app_name: 'gothiacup',
  websale_name: 'gothiacup',
  ntb_team_id: 10032,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'gothiacup',
  monolithEnabled: false,
};
