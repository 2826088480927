import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');

const validate = (values) => {
  const errors = {};
  if (!values.orgName) {
    errors.orgName = `${p('companyNameFieldEmpty')}`;
  }
  if (!values.orgNumber) {
    errors.orgNumber = `${p('organizationNumberFieldEmpty')}`;
  }

  return errors;
};

export default validate;
