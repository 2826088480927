import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');

  const errors = {};
  if (!values.title) {
    errors.title = p('titleFieldCantBeEmpty');
  }
  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }
  if (!values.intro) {
    errors.intro = p('introFieldCantBeEmpty');
  }
  if (!values.type) {
    errors.type = p('termTypeFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
