import React, { useEffect, useState } from 'react';
import { Col, Button, Row, Table } from 'reactstrap';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import PropTypes from 'prop-types';
import DragIcon from 'mdi-react/DragIcon';
import TrashCan from 'mdi-react/TrashCanIcon';
import Pen from 'mdi-react/PenIcon';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderFileInputField from '../../../shared/components/form/FileInput';
import { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';

const LotteryPrizesForm = ({
  prizes, initialize, deletePrize, handleSubmit, setPrizes, sponsors, reset, submitSucceeded, lotteryID,
}) => {
  const [selectedImg, setSelectedImg] = useState(undefined);

  const s = partial('shared');
  const p = partial('LotteryForm');

  useEffect(() => {
    if (submitSucceeded) {
      reset();
      initialize();
    }
  }, [submitSucceeded]);

  const editPrize = (prize) => {
    const foundSponsor = sponsors.find(sponsor => sponsor.id === prize.company?.id);
    reset();
    initialize({
      prizeName: prize.name,
      sponsorID: foundSponsor,
      prizeValue: prize.value,
      ...prize,
    });
    setPrizes(prizes.filter(pr => pr.name !== prize.name));
    setSelectedImg(prize.url);
  };

  const renderPrizes = () => {
    return prizes.map((prize, index) => (
      <Draggable
        key={`prize-${prize.id}`}
        draggableId={`prize-${prize.id}`}
        index={index}
      >
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? 'rbdnd-dragging' : ''}
            key={`prize-${prize.id}`}
          >
            <td>{<DragIcon color="#99999" />}{` ${index + 1}. ${p('prize')}`}</td>
            <td><div className="flex-Vcenter">{prize.name}</div></td>
            <td><div className="flex-Vcenter">{prize.value}</div></td>
            <td><div className="flex-Vcenter">{prize.company?.name || ''}</div></td>
            <td><img src={prize.url} alt="" /></td>
            <td><Pen size={15} onClick={() => editPrize(prize)} /></td>
            <td><TrashCan size={15} onClick={deletePrize(prize)} /></td>
          </tr>
        )}
      </Draggable>
    ));
  };
  const prizeOnDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const draggedPrize = prizes[source.index];
    const newPrizes = [...prizes];
    newPrizes.splice(source.index, 1);
    newPrizes.splice(destination.index, 0, draggedPrize);
    setPrizes(newPrizes);
    const payload = { prizes: newPrizes.map((np, index) => ({ id: np.id, order: index })) };
    Api.lottery.setPrizeOrder(lotteryID, payload);
  };
  return (
    <form
      className="form"
      onSubmit={handleSubmit}
    >
      <Col>
        <Row>
          <Col lg={5} md={12}>
            <div>
              <h4 style={{ marginTop: '50px', marginBottom: '10px' }} className="bold-text">{p('prizesCaps')}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={5} md={12}>
            <div className="form__form-group">
              <span className="form-header">{p('prizeName')}</span>
              <Field
                name="prizeName"
                component={renderField}
                placeholder={s('name')}
                disabled={!lotteryID}
              />
            </div>
          </Col>
          <Col lg={1} md={2}>
            <div className="form__form-group">
              <span className="form-header">{p('value')}</span>
              <Field
                name="prizeValue"
                component={renderField}
                disabled={!lotteryID}
              />
            </div>
          </Col>
          <Col lg={2} md={7}>
            <div className="form__form-group">
              <span className="form-header">{s('sponsor')}</span>
              <Field
                name="sponsorID"
                component={renderSelectField}
                placeholder={s('chooseSponsor')}
                options={sponsors}
                disabled={!lotteryID}
              />
            </div>
          </Col>
          <Col xl={2} lg={2} md={2}>
            <div className="form__form-group">
              <span className="form-header">{p('prizeImage')}</span>
              <Field
                name="prizeImage"
                component={renderFileInputField}
                onChange={() => setSelectedImg(undefined)}
              />
            </div>
            {selectedImg && (
            <div>
              <img src={selectedImg} alt=" " />
            </div>
            )}
          </Col>
          <Col>
            <Button color="primary" type="submit" disabled={!lotteryID}>{p('addPrize')}</Button>
          </Col>
        </Row>
        <Row>
          <DragDropContext onDragEnd={prizeOnDragEnd}>
            <Table style={{ marginBottom: '25px' }} responsive bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{p('prizes')}</th>
                  <th>{p('value')}</th>
                  <th>{s('sponsor')}</th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <Droppable droppableId="group-dnd">
                {(provided) => {
                  return (
                    <>
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {renderPrizes()}
                        {provided?.placeholder && provided.placeholder}
                      </tbody>
                    </>
                  );
                }}
              </Droppable>
            </Table>
          </DragDropContext>
        </Row>
      </Col>
    </form>
  );
};

LotteryPrizesForm.propTypes = {
  ...reduxFormProps,
  sponsors: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  prizes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    prize_image: PropTypes.object,
    prize_image_id: PropTypes.number,
    sponsor_id: PropTypes.number,
    value: PropTypes.number,
  })).isRequired,
};

const reduxF = reduxForm({
  form: 'lottery_prize',
});

export default reduxF(LotteryPrizesForm);
