import React, { Component } from 'react';
import { Container, TabContent, TabPane } from 'reactstrap';
import KioskList from './components/KioskList';
import KioskSettings from './components/KioskSettings';
import { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';

const s = partial('shared');

class ClickAndCollect extends Component {
  constructor() {
    super();
    const urlParams = new URLSearchParams(window.location.search).get('tab') || '1';
    this.state = {
      activeTab: urlParams.toString(),
    };
  }

  toggle = (tab) => {
    window.history.pushState({}, '', `${window.location.pathname}?tab=${tab}`);
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <Container>
        <NavTab activeTab={this.state.activeTab} tabs={[s('kiosks'), s('products')]} setTab={this.toggle} />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <KioskList />
          </TabPane>
          <TabPane tabId="2">
            <KioskSettings />
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}

export default ClickAndCollect;
