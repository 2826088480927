import React, { useState } from 'react';
import { Table, Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import DownloadIcon from 'mdi-react/DownloadIcon';
import Api from '../../../../util/api';
import { partial } from '../../../../util/translation/translation';
import ShareModal from '../../../../shared/components/custom/ShareModal';
import AddCategoryForm from './AddCategoryForm';
import IconButton from '../../../../shared/components/custom/IconButton';
import DeleteModal from '../../../../shared/components/custom/DeleteModal';
import useModal from '../../../../shared/hooks/useModal';


const EventCategoryCard = ({ categories, fetchCategories, setLoading, exportEventCategoriesForAllCompanies }) => {
  const p = partial('EventCategories');
  const f = partial('EventsForm');
  const s = partial('shared');
  const [handleModal, visible, deleteId] = useModal();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditConfig, setIsEditConfig] = useState(false);
  const [isCloneConfig, setIsCloneConfig] = useState(false);
  const [modelTitle, setModelTitle] = useState(p('createEventCategory'));
  const [allInputs, setAllInputs] = useState([]);
  const handleEditPressed = (category) => {
    if (isCloneConfig) {
      setModelTitle('clone event');
    } else if (isEditConfig) {
      setModelTitle(p('editEventCategory'));
    }
    setSelectedCategory(category);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
    setModalVisible(false);
  };
  const handleSubmit = async (values) => {
    const payload = {
      maxAccountAttendees: Number(values.maxPerAccount) || 0,
      maxAttendees: Number(values.maxParticipants),
      title: values.title,
      shouldCompanyUpdate: isCloneConfig,
    };

    const isUpdate = !!selectedCategory;
    setLoading(true);
    try {
      if (isCloneConfig) {
        await Api.eventCategories.clone(selectedCategory.id, payload);
      } else if (isUpdate) {
        await Api.eventCategories.update(selectedCategory.id, payload);
      } else {
        await Api.eventCategories.create(payload);
      }
      if (allInputs.length > 0) {
        await Api.eventCategories.bulkUpdateCompanyConfiguartions(allInputs);
        setAllInputs([]);
      }


      toast.success(isUpdate ? p('eventCategoryUpdated') : p('eventCategoryCreated'));
      handleCloseModal();
      fetchCategories();
    } catch (err) {
      setLoading(false);
      toast.error(isUpdate ? p('couldnotUpdateCategory') : p('couldnotCreateCategory'));
    }
  };
  const handleRemoveCategory = async (category) => {
    setLoading(true);
    try {
      await Api.eventCategories.delete(category.id);
      toast.success(p('eventCategoryRemoved'));
      handleModal();
      fetchCategories();
    } catch (err) {
      setLoading(false);
      toast.error(p('couldnotRemoveCategory'));
    }
  };
  return (
    <div>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={p('eventCategory')}
        modalAction={() => handleRemoveCategory(deleteId)}
      />
      <ShareModal
        visible={modalVisible}
        title={modelTitle}
        handleModal={handleCloseModal}
      >
        <AddCategoryForm
          onSubmit={handleSubmit}
          selectedCategory={selectedCategory}
          isEditConfig={isEditConfig}
          isCloneConfig={isCloneConfig}
          allInputs={allInputs}
          setAllInputs={setAllInputs}
        />
      </ShareModal>
      <div className="flex justify-content-end">
        <Button
          color="secondary"
          outline
          size="sm"
          placement="right"
          onClick={exportEventCategoriesForAllCompanies}
        >
          <DownloadIcon/>
          {p('exportCompanyCategories')}(CSV)
        </Button>
      </div>
      <div className="flex justify-content-end">
        <Button
          size="sm"
          color="primary"
          onClick={() => { setModalVisible(true); setIsEditConfig(false); setIsCloneConfig(false); }}
        >
          {p('createEventCategory')}
        </Button>
      </div>
      <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
        <Table responsive striped>
          <thead>
            <tr>
              <th>{s('name')}</th>
              <th>{f('defaultMaxNumberOfParticipant')}</th>
              <th>{f('numberOfParticipantOnEvent')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              categories.map((category) =>
                <tr key={category.id}>
                  <td>{category.title}</td>
                  <td>{category.maxAccountAttendees}</td>
                  <td>{category.maxAttendees}</td>
                  <td>
                    <ButtonToolbar className="float-right">
                      <ButtonGroup className="btn-group--icons edit-delete-btn-group">
                        <IconButton
                          onClick={() => { handleEditPressed(category); setIsEditConfig(true); setIsCloneConfig(false); }}
                          type={IconButton.types.EDIT}
                          id={`edit-${category.id}`}
                        />
                        <IconButton onClick={() => { handleEditPressed(category); setIsCloneConfig(true); }} type={IconButton.types.CLONE} id={`clone-${category.id}`}/>
                        {/* <IconButton onClick={() => handleModal(participant.id)} type={IconButton.types.DELETE} id={`delete-${participant.id}`}/> */}
                        <IconButton onClick={() => handleModal(category)} type={IconButton.types.DELETE} id={`delete-${category.id}`}/>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </td>
                </tr>)
            }
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EventCategoryCard;
