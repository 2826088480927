import React from 'react';
import { getFontSize, getSquareImageFromSize, getSquareStyleFromSize, isSpotlightExclusive } from './SpotlightUtils';
import ExclusiveLabel from './ExclusiveLabel';

const styles = {
  squareContainer: (size) => ({
    width: getSquareStyleFromSize(size).width,
    backgroundColor: '#FFFFFF',
    borderRadius: size === 'FULL_SCREEN' ? 0 : 8,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: size === 'FULL_SCREEN' ? null : 'rgb(0 0 0 / 30%) 5px 5px 5px -6px',
  }),
  smallSquareImageContainer: {
    width: 70,
    height: 70,
    borderRadius: 8,
    objectFit: 'cover',
  },
  squareImageContainer: (size) => ({
    width: getSquareImageFromSize(size).width,
    height: getSquareImageFromSize(size).height,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    objectFit: 'cover',
  }),
};
const SquareItem = ({ tile, size, access, format, descriptionAvailable }) => {
  const { headingImage, description } = tile;
  const isYoutube = tile?.content?.value === 'YOUTUBE_VIDEO';
  const videoId = `https://www.youtube.com/embed/${tile?.contentType}?controls=0&iv_load_policy=3&rel=0`;
  const isExclusive = isSpotlightExclusive(access);
  return (
    <div style={styles.squareContainer(size)}>
      <div >
        {isYoutube ?
          <iframe
            title="Youtube frame"
            style={
              size === 'SMALL'
                ? styles.smallSquareImageContainer
                : styles.squareImageContainer(size)
            }
            src={videoId}
          /> :
          <img
            resizeMode="cover"
            src={headingImage?.preview}
            style={
              size === 'SMALL'
                ? styles.smallSquareImageContainer
                : styles.squareImageContainer(size)
            }
            alt=""
          />}

        {(size !== 'FULL_SCREEN' && size !== 'SMALL' && descriptionAvailable) && (
          <div style={{ height: getSquareStyleFromSize(size).descHeight, paddingTop: '5px' }}>
            <p style={{ fontWeight: '500', fontSize: getFontSize(size), color: 'black', overflow: 'hidden', padding: '0 10px 10px 10px', textOverflow: 'ellipsis', width: '100%', lineHeight: '1.5em', height: '3em' }}>{description}</p>
          </div>
        )}
        <ExclusiveLabel visible={isExclusive} format={format} />
      </div>
    </div>
  );
};

export default SquareItem;

