import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card, CardBody, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import moment from 'moment';
import AlertIcon from 'mdi-react/AlertIcon';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import SeasonForm from './components/SeasonForm';
import SeasonPriceForm from './components/SeasonPriceForm';
import SeasonReservationForm from './components/SeasonReservationForm';
import { partial } from '../../util/translation/translation';
import { formatDateTime } from '../../util/date-and-time';

const SeasonEditPage = (props) => {
  const [season, setSeason] = useState({});
  const [seasonPriceGroups, setSeasonPriceGroups] = useState([]);
  const [ntbStages, setNtbStages] = useState([]);
  const [priceGroups, setPriceGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const { seasonID } = useParams();
  const p = partial('seasonObjectsShared');

  const initializeComponent = async () => {
    try {
      const [resSeason, resSeasonPrices, resPriceGroups, resNtbStages] = await Promise.all([
        Api.seasons.getSeasonByID(props.match.params.seasonID),
        Api.seasons.getSeasonPrices(props.match.params.seasonID),
        Api.settings.getPriceGroups(),
        Api.clubadmin.seasons.all(),
      ]);
      const mappedPriceGroups = resPriceGroups.data.map(priceGroup => ({
        ...priceGroup,
        label: priceGroup.title,
        value: priceGroup.id.toString(),
      }));
      const mapStages = ntb => ({
        label: `${formatDateTime(ntb.seasonStart)} - ${formatDateTime(ntb.seasonEnd)}`,
        value: `${ntb.id.toString()}`,
      });
      setNtbStages(resNtbStages.data.map(mapStages));
      setSeason(resSeason.data);
      setSeasonPriceGroups(resSeasonPrices.data);
      setPriceGroups(mappedPriceGroups);
      setLoading(false);
    } catch (err) {
      toast.error(err || p('wrongWhenRetrivingSeasonalInfo'));
      setLoading(false);
    }
  };

  useEffect(() => {
    initializeComponent();
  }, []);

  const convertValues = (values) => {
    return Object.entries(values).reduce((acc, value) => {
      let variable;
      return value.reduce((object, element, index) => {
        const obj = object;
        if (index === 0) {
          variable = element.split('-');
        } else {
          if (obj.priceGroups === undefined) {
            obj.priceGroups = [];
          }
          if ((variable[0] === 'checkbox' || variable[0] === 'input' || variable[0] === 'releasable') && !obj.priceGroups.some(pG => pG.id === variable[1])) {
            obj.priceGroups.push({ id: variable[1] });
          }
          const pGIndex = obj.priceGroups.findIndex(pG => pG.id === variable[1]);

          switch (variable[0]) {
            case 'input':
              obj.priceGroups[pGIndex].title = seasonPriceGroups[pGIndex].title;
              obj.priceGroups[pGIndex].description = seasonPriceGroups[pGIndex].description;
              obj.priceGroups[pGIndex].season_id = seasonPriceGroups[pGIndex].season_id;
              obj.priceGroups[pGIndex].price = element;
              break;

            case 'checkbox':
              obj.priceGroups[pGIndex].buyable = element;
              break;

            case 'releasable':
              obj.priceGroups[pGIndex].releasable = element;
              break;

            default:
              obj[value[0]] = element;
              break;
          }
        }
        return object;
      }, acc);
    }, {});
  };

  const handleSubmit = async (messyValues) => {
    const values = convertValues(messyValues);
    const seasonPayload = {
      name: values.name,
      start_date: values.startDate,
      end_date: values.endDate,
      ticket_sale_start: values.saleStart,
      ticket_sale_end: values.saleEnd,
      ntb_id: values.ntb_id?.value,
      external_url: values.externalURL,
    };
    setLoading(true);
    try {
      await Api.seasons.updateSeason(props.match.params.seasonID, seasonPayload);
    } catch (err) {
      toast.error(err || p('wrongWhenChangingSeason'));
      return;
    }

    try {
      await Api.seasons.updateSeasonPrices({ season_prices: values.priceGroups });
    } catch (err) {
      toast.error(err || p('wrongWhenChangingSeasonPrices'));
    }
    toast.success(`${p('seasonHasBeenUpdated')}`);
    setLoading(false);
  };

  const handlePriceGroupSubmit = async ({
    title, description, price, priceGroup, buyable, releasable,
  }) => {
    setLoading(true);
    try {
      await Api.seasonTickets.createSeasonPriceGroup({
        season_id: props.match.params.seasonID,
        title,
        description,
        price,
        price_group_id: priceGroup.id,
        buyable,
        releasable,
      });
      initializeComponent();
    } catch (err) {
      toast.error(err || p('wrongWhenCreatingPriceGroup'));
      setLoading(false);
    }
  };

  const handleSeasonReservationSubmit = async (values) => {
    const payload = {
      override_active_season_id: values.overrideActiveSeasonId.value,
      skip_companies: values.skipCompanies,
    };
    setLoading(true);
    try {
      await Api.seasons.upsertSeasonTicketReservations(seasonID, payload);
      toast.success(`${p('seasonHasBeenUpdated')}`);
    } catch (err) {
      toast.error(err || p('wrongWhenChangingSeason'));
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <p className="page-title">{p('editSeason')}</p>
        </Col>
      </Row>
      { season?.ticket_sale_start && moment().isAfter(season.ticket_sale_start) && (
      <Row>
        <Col >
          <Alert color="warning">
            <AlertIcon size={20} />
            Endringer i sesongpriser for denne sesongen kan få følger for alle sesongkortholdere
          </Alert>
        </Col>
      </Row>
      ) }
      <Row>
        <Col>
          <Card>
            <CardBody>
              <SeasonForm
                season={season}
                seasonPriceGroups={seasonPriceGroups}
                ntbStages={ntbStages}
                type="edit"
                onSubmit={handleSubmit}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Card>
            <CardBody>
              <SeasonReservationForm onSubmit={handleSeasonReservationSubmit} />
            </CardBody>
          </Card>
        </Col>
        <Col sm={12} md={6}>
          <Card>
            <CardBody>
              <SeasonPriceForm onSubmit={handlePriceGroupSubmit} priceGroups={priceGroups} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

SeasonEditPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonID: PropTypes.string,
    }),
  }).isRequired,
};

export default SeasonEditPage;
