import React, { useState, useEffect } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';

const DiscountReport = ({ seasons }) => {
  const [selectedSeason, setSelectedSeason] = useState(undefined);
  const [seasonsCache, setSeasonsCache] = useState({});
  const [seasonData, setSeasonData] = useState({ tickets: { columns: [], rows: [] }, seasonTickets: { columns: [], rows: [] } });
  const p = partial('DiscountReport');
  const s = partial('shared');

  const listHeaders = () => seasonData.tickets.columns.map(column => (
    <th key={`tc${column.id}`}>{column.name}</th>
  ));

  const listRows = () => seasonData.tickets.rows.map(row => (
    <tr key={`tc${row.id}`}>
      <td>{row.name}</td>
      {row.items.map((item, i) => <td key={`tcr${row.id}${seasonData.tickets.columns[i].id}`}>{item}</td>)}
      <td>{row.items.reduce((t, i) => t + i, 0)}</td>
    </tr>
  ));

  const listSeason = () => seasons.map(season => (
    <DropdownItem onClick={() => setSelectedSeason(season)} key={`listDiscountSeasons-${season.id}`}>{s('theSeason')} {season.name}</DropdownItem>
  ));

  const listSeasonRows = () => seasonData.seasonTickets.rows.map(row => (
    <tr key={`sr${row.id}`} >
      <td>{row.name}</td>
      {row.items.map((item, i) => <td key={`scr${row.id}i${seasonData.seasonTickets.columns[i].id}`} >{item}</td>)}
      <td>{row.items.reduce((t, i) => t + i, 0)}</td>
    </tr>
  ));

  const listSeasonHeaders = () => seasonData.seasonTickets.columns.map(column => (
    <th key={`sc${column.id}`}>{column.name}</th>
  ));

  useEffect(() => {
    if (selectedSeason) {
      if (!seasonsCache[selectedSeason.id]) {
        const promise = async () => {
          const seasonContent = await Api.reports.getDiscountReport(selectedSeason.id);
          setSeasonData(seasonContent.data);
          setSeasonsCache(prevState => ({ ...prevState, [seasonContent.id]: seasonContent }));
        };
        promise();
      } else {
        setSeasonData(seasonsCache[selectedSeason.id]);
      }
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (seasons.length > 1) {
      const [season] = seasons;
      setSelectedSeason(season);
    }
  }, [seasons]);
  return (
    <>

      <div>
        <div className="card__title marginBottom-30">
          <h5 className="bold-text">{p('ticketDiscountsCaps')}</h5>
        </div>
        <h5 className="bold-text" style={{ marginBottom: '5px' }}>{s('periodCaps')}</h5>
        <UncontrolledDropdown className="marginRight-15">
          <DropdownToggle className="icon icon--right" outline>{selectedSeason ? `${s('theSeason')}: ${selectedSeason.name}` : `${s('theSeason')}: -`}<ChevronDownIcon /></DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            { listSeason() }
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <Table responsive bordered>
        <thead>
          <tr>
            <th>{s('match')}</th>
            {listHeaders()}
            <th>{s('total')}</th>
          </tr>
          {listRows()}
        </thead>
        <tbody />
      </Table>

      <div className="marginTop-30">
        <div className="card__title marginBottom-30 marginTop-30">
          <h5 className="bold-text">{p('seasonCardDiscountsCaps')}</h5>
        </div>
      </div>

      <Table responsive bordered>
        <thead>
          <tr>
            <th>{s('theSeason')}</th>
            {listSeasonHeaders()}
            <th>{s('total')}</th>
          </tr>
          {listSeasonRows()}
        </thead>
        <tbody />
      </Table>

    </>
  );
};

DiscountReport.propTypes = {
  seasons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default DiscountReport;

