import React, { useState, useEffect } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { Field, Form } from 'redux-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import renderField from '../../../shared/components/custom/Field';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import { partial } from '../../../util/translation/translation';

const RowForm = (props) => {
  const p = partial('RowForm');
  const s = partial('shared');
  const [row, setRow] = useState(props.row);
  const [modal, setModal] = useState({
    visible: false,
    id: null,
  });

  const modalAction = async () => {
    if (modal.visible === false) { return; }
    props.deleteRow(row.id);
    setModal({
      ...modal, visible: false,
    });
  };

  const handleModal = (id = false) => {
    setModal({ ...modal, visible: id !== false, id: id !== false && id });
  };

  useEffect(() => {
    props.initialize(props.initialValues);
  }, [props.initialValues]);

  const selectSeat = (seatIndex) => {
    const tmpRow = { ...row };
    tmpRow.seats[seatIndex].selected = !tmpRow.seats[seatIndex].selected;
    setRow(tmpRow);
    const selected = row.seats.filter(seat => seat.selected);
    props.handleSeats(selected);
  };

  const handleDelete = () => {
    setModal({
      ...modal, visible: true,
    });
    // return props.deleteRow(row.id);
  };

  const applySeatClass = (seatState) => {
    switch (seatState) {
      case 'OPEN':
        return 'stadium-seat-available';
      case 'BOUGHT':
        return 'stadium-seat-available';
      case 'RESERVED':
        return 'stadium-seat-available';
      case 'DEACTIVATED':
        return 'stadium-seat-deactivated';
      case 'NONEXISTENT':
        return 'stadium-seat-nonexistent';
      default:
        return '';
    }
  };


  const renderSeats = () => {
    return row.seats.map((seat, index) => (
      <button
        className={classnames('stadium-seat', { 'stadium-seat-selected': seat.selected }, applySeatClass(seat.seat_state.name))}
        onClick={() => selectSeat(index)}
        key={`RenderSeats-${seat.id}`}
      >
        {seat.seat_id}
      </button>
    ));
  };

  const checkIsOpen = id => id === props.isOpen;

  return (
    <div
      className="flex flex-column stadium-row"
      key={`Render-row-${row.id}`}
    >
      <DeleteModal
        visible={modal.visible}
        type="raden"
        handleModal={handleModal}
        modalAction={modalAction}
      />
      <Row>
        <Col
          lg={3}
          md={3}
          sm={12}
        >
          <div className="form editRowContainer">
            <h4 className="rowNumber">{p('rowNumber')}: {props.row.number}</h4>
            <div className="flex-7" />
            <Button
              className="editButton"
              outline
              active={checkIsOpen(row.id)}
              color="primary"
              size="sm"
              onClick={() => props.toggleEdit(row.id)}
            >{s('edit')}
            </Button>
            <div className="flex-1" />
          </div>
        </Col>
        <Col
          lg={9}
          md={12}
          sm={12}
        >
          <Form onSubmit={props.handleSubmit}>
            <Collapse isOpen={checkIsOpen(row.id)}>
              <Row className="form editRowInfoContainer">
                <div className="flex-3" />
                <div className="flex-4">
                  <p>{p('changeRowNumber')}</p>
                  <Field
                    component={renderField}
                    label={p('rowNumber')}
                    name="number"
                    type="number"
                  />
                </div>
                <div className="flex-1" />
                <div className="flex-6">
                  <p>{p('changeDescription')}</p>
                  <Field
                    component={renderField}
                    name="description"
                    label={s('description')}
                    type="text"
                  />
                </div>
                <div className="flex-1" />
                <div className="flex-4">
                  <p>{p('createSeats')}</p>
                  <Field
                    component={renderField}
                    name="seats.amount"
                    type="number"
                  />
                </div>
                <div className="flex-1" />
                <div className="flex-4">
                  <p>{p('fromSeat')}</p>
                  <Field
                    component={renderField}
                    name="seats.start_count"
                    type="number"
                  />
                </div>
                <div className="flex-3" />
                <div className="editRowActionButtonContainer">
                  <Button type="submit">{s('save')}</Button>
                  <Button color="danger" onClick={handleDelete}>{p('deleteRow')}</Button>
                </div>

              </Row>
            </Collapse>
          </Form>
        </Col>
      </Row>
      <div className="flex">
        <div className="flex flex-1 flex-wrap">
          {renderSeats()}
        </div>
      </div>
    </div>
  );
};

RowForm.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSeats: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    number: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
  initialize: PropTypes.func.isRequired,
  isOpen: PropTypes.number.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
};
export default RowForm;
