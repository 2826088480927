import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const s = partial('shared');

const validateDetailsForm = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = `${p('nameFieldEmpty')}'!'`;
  }
  if (!values.tax) {
    errors.tax = `${s('taxCaps')} ${p('fieldEmpty')}'!'`;
  }
  if (values.tax) {
    if (values.tax > 100 || values.tax < 0) {
      errors.tax = `${s('taxCaps')} ${p('wrongNumber')}'!'`;
    }
  }

  return errors;
};

export default validateDetailsForm;
