export default {
  club_name: 'Västervik IK',
  club_name_short: 'vastervik',
  stadium_name: '',
  staging_url: 'https://vastervik.api.fangroup.io',
  prod_url: 'https://vastervik.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/vastervik',
  club_logo: '/assets/teams/vastervik.png',
  app_name: 'vastervik',
  websale_name: 'vastervik',
  ntb_team_id: 10043,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'vastervik',
  monolithEnabled: false,
};
