import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Close from 'mdi-react/CloseCircleOutlineIcon';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import QuestionForm from './QuestionForm';
import { questionValueToPayload } from './utils';

const EditQuestionModal = ({ showModal, onClose, editableQuestion, onSave }) => {
  const qT = partial('question');
  const s = partial('shared');

  const promptTitle = editableQuestion ? qT('edit') : qT('addBtn');
  const handleSubmit = (values, questionValue) => {
    const payload = {
      type: values.type.value,
      description: values.description,
      imageUrl: values?.headingImage?.croppedBase64 || values?.headingImage?.preview,
      title: values.title,
      value: questionValueToPayload(questionValue),
      imageHeaderType: values?.imageHeaderType?.value,
      headingVideos: values?.headingVideos || [],
    };
    if (values.headingImage?.croppedBase64) {
      const sizeInBytes = 4 * Math.ceil((values.headingImage.croppedBase64.length / 3)) * 0.5624896334383812;
      const sizeInKb = sizeInBytes / 1000;
      const convKbToKB = sizeInKb * 0.125;

      if (convKbToKB > 10000) {
        toast.error(s('imageTooLarge'));
        onClose();
        return;
      }
    }
    onSave(payload);
  };

  return (
    <Modal
      modalClassName="question-modal"
      isOpen={!!showModal}
      toggle={onClose}
    >
      <ModalHeader className="navbar-modal-header">
        <div className="modal-header-title">{promptTitle}</div>
        <div className="float-right">
          <button className="marginBottom-0 builder-button" onClick={onClose}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <QuestionForm type={editableQuestion ? 'edit' : 'create'} question={editableQuestion} onSubmit={handleSubmit} onClose={onClose}/>
      </ModalBody>
    </Modal>
  );
};
export default EditQuestionModal;
