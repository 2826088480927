import { partial } from '../../../util/translation/translation';


const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.neverEnd && !values.dueDate) {
    errors.dueDate = p('endDateFieldCantBeEmpty');
  }
  return errors;
};

export default validate;
