import React, { useEffect } from 'react';
import { Col, Row, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../../../../shared/components/custom/Field';
import renderRadioButtonField from '../../../../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../../../../shared/components/form/Select';
import renderSearchSelectField from '../../../../../../../shared/components/custom/SearchSelect';
import { partial } from '../../../../../../../util/translation/translation';
import validate from '../validate';
import Api from '../../../../../../../util/api';

const EditSeasonTicketForm = (props) => {
  const p = partial('screensFormShare');
  const s = partial('shared');
  const t = partial('TicketStates');
  useEffect(async () => {
    if (props.seasonTicket.id === undefined) {
      return;
    }
    const ticketState = {
      label: t(props.seasonTicket.ticket_state.name.toLowerCase()),
      value: props.seasonTicket.ticket_state.id.toString(),
    };
    const priceGroup = {
      label: props.seasonTicket.season_price.title,
      value: props.seasonTicket.season_price.id.toString(),
    };
    let owner;
    if (props.seasonTicket.new_company_id) {
      const company = await Api.companies.getCompany(props.seasonTicket.new_company_id);
      owner = {
        isCompany: true,
        label: `${company.name} (${company.organization_number})`,
        value: company.id,
      };
    } else if (props.seasonTicket.owner) {
      owner = {
        isCompany: false,
        label: `${props.seasonTicket.owner?.firstname} ${props.seasonTicket.owner?.lastname} (${props.seasonTicket.owner?.username})`,
        value: props.seasonTicket.user.user_cloud_id,
      };
    } else if (props.seasonTicket.company) {
      owner = {
        isCompany: true,
        label: `${props.seasonTicket.company.name} (${props.seasonTicket.company.number})`,
        value: props.seasonTicket.company.migration_id,
      };
    }
    const user = {
      label: `${props.seasonTicket.user.firstname} ${props.seasonTicket.user.lastname} (${props.seasonTicket.user.username})`,
      value: props.seasonTicket.user.user_cloud_id,
    };
    props.initialize({
      ticketState,
      priceGroup,
      total: props.seasonTicket.total.toString(),
      shareable: props.seasonTicket.shareable.toString(),
      vip: props.seasonTicket.vip.toString(),
      releasable: props.seasonTicket.releasable.toString(),
      tags: props.seasonTicket.tags,
      comment: props.seasonTicket.comment,
      owner,
      user,
    });
  }, [props.seasonTicket]);
  const { handleSubmit } = props;
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('ticketSale')}</span>
              <div className="marginTop-5" />
              <Field
                name="ticketState"
                component={renderSelectField}
                options={props.ticketStates}
                placeholder={p('chooseTicketStatus')}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('priceGroup')}</span>
              <div className="marginTop-5" />
              <Field
                name="priceGroup"
                component={renderSelectField}
                options={props.seasonPrices}
                placeholder={p('choosePriceGroup')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <span className="form-header">{p('totalTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="total" component={renderRadioButtonField} label={s('yes')} radioValue="1" />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field name="total" component={renderRadioButtonField} label={s('no')} radioValue="0" defaultChecked />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <span className="form-header">{p('shareableTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="shareable"
                  component={renderRadioButtonField}
                  label={s('yes')}
                  radioValue="1"
                  defaultChecked
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field name="shareable" component={renderRadioButtonField} label={s('no')} radioValue="0" />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <span className="form-header">{p('vipTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="vip" component={renderRadioButtonField} label={s('yes')} radioValue="1" />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field name="vip" component={renderRadioButtonField} label={s('no')} radioValue="0" defaultChecked />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <span className="form-header">{p('hasPickupDeadline')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="releasable" component={renderRadioButtonField} label={s('yes')} radioValue="1" />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="releasable"
                  component={renderRadioButtonField}
                  label={s('no')}
                  radioValue="0"
                  defaultChecked
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{p('tags(commaSeparated)')}</span>
              <div className="marginTop-5" />
              <Field name="tags" component={renderField} placeholder={p('tags(commaSeparated)')} />
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('comment')}</span>
              <div className="marginTop-5" />
              <Field name="comment" component="textarea" placeholder={s('comment')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('owner')}</span>
              <div className="marginTop-5" />
              <Field
                name="owner"
                component={renderSearchSelectField}
                placeholder={p('searchPersonOrBusiness')}
                options={props.ownerOptions}
                search={(text) => props.getOwner(text)}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('user')}</span>
              <div className="marginTop-5" />
              <Field
                name="user"
                component={renderSearchSelectField}
                placeholder={p('searchPersonOrBusiness')}
                options={props.userOptions}
                search={(text) => props.getUser(text)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button type="submit" color="primary">
              {s('save')}
            </Button>
            <Link className="color-unset" to={`/tickets${window.location.search}`}>
              <Button type="button">{s('close')}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

EditSeasonTicketForm.propTypes = {
  getOwner: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  ownerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  ticketStates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  seasonPrices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  seasonTicket: PropTypes.shape({
    id: PropTypes.number,
    ticket_state: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
    }),
    season_price: PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.number,
    }),
    total: PropTypes.number,
    releasable: PropTypes.number,
    shareable: PropTypes.number,
    vip: PropTypes.number,
    tags: PropTypes.string,
    comment: PropTypes.string,
    company_id: PropTypes.number,
    owner_id: PropTypes.number,
    company: PropTypes.shape({
      name: PropTypes.string,
      number: PropTypes.number,
    }),
    owner: PropTypes.shape({
      id: PropTypes.number,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      username: PropTypes.string,
    }),
    user: PropTypes.shape({
      id: PropTypes.number,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      username: PropTypes.string,
    }),
  }).isRequired,
  initialize: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'edit_season_ticket',
  validate,
})(EditSeasonTicketForm);
