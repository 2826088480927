import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Col,
  Table, UncontrolledDropdown, DropdownMenu,
  DropdownToggle, DropdownItem,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';

const TeamList = (props) => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const getTeams = async () => {
    setLoading(true);
    try {
      const resTeams = await Api.settings.getTeams();
      setTeams(resTeams.data);
      setLoading(false);
    } catch (err) {
      toast.error(p('couldNotFetchTeam'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  const renderTeamList = () => teams.map(team => (
    <tr className="center-list" key={`team-${team.id}`}>
      <td>
        <Link to={`/settings/team/edit/${team.id}`} className="flex-Vcenter">
          <img className="teamLogoTable" src={team.logo?.url} alt="logo" />
          {team.shortname}
        </Link>
      </td>
      <td className="vertical-alignMiddle">{team.name}</td>
      <td>
        <UncontrolledDropdown className="dashboard__table-more">
          <DropdownToggle>
            <p><DotsHorizontalIcon /></p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <Link to={`/settings/team/edit/${team.id}`}>
              <DropdownItem>{s('edit')}</DropdownItem>
            </Link>
            <DropdownItem
              onClick={() => props.handleModal(true, team.id, s('theTeam'), 'deleteTeam', getTeams)}
              className="danger"
            >
              {s('delete')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  ));

  return (
    <Col>
      <Loading loading={loading} />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{s('team')}</p>
            <Link
              className="btn btn-primary products-list__btn-add"
              to="/settings/team/create"
            >
              {p('createTeam')}
            </Link>
          </div>

          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('abbreviation')}</th>
                <th>{s('name')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderTeamList()}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

TeamList.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

export default TeamList;
