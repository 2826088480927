import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Api from '../../util/api';

const SeasonAdminListPage = () => {
  const [seasons, setSeasons] = useState([]);
  const fetchSeasons = async () => {
    try {
      const { data } = await Api.clubadmin.seasons.all();
      setSeasons(data);
    } catch (e) {
      toast.error(e);
    }
  };

  const renderSeasons = (_seasons) => _seasons.map(season => (
    <tr key={`season:${season.id}`}>
      <td><Link to={`/clubadmin/season/edit/${season.id}`}>{season.year}</Link></td>
      <td>{season.type}</td>
    </tr>
  ));

  useEffect(() => {
    fetchSeasons();
  }, []);
  return (
    <>
      <div className="flex space-between" style={{ float: 'right' }}>
        <Link to="/clubadmin/season/create"><Button color="primary" size="sm">Create Season</Button></Link>
      </div>
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>
              Season Year
            </th>
            <th>
              Season Type
            </th>
          </tr>
        </thead>
        <tbody>
          { renderSeasons(seasons) }
        </tbody>
      </Table>
    </>
  );
};

export default SeasonAdminListPage;
