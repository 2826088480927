import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }

  if (!values.saleStart) {
    errors.saleStart = p('saleStartFromFieldCantBeEmpty');
  }

  const priceGroupsArrayErrors = [];
  if (values.priceGroups && Array.isArray(values.priceGroups)) {
    values.priceGroups.forEach((priceGroup, index) => {
      const ruleErrors = {};
      if (!priceGroup || !priceGroup.price) {
        ruleErrors.price = p('priceGroupFieldCantBeEmpty');
        priceGroupsArrayErrors[index] = ruleErrors;
      }
    });
  }
  if (priceGroupsArrayErrors.length) {
    errors.priceGroups = priceGroupsArrayErrors;
  }

  return errors;
};

export default validate;
