import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { Col, Row, Container, Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import validateExternalStore from './validateExternalStore';
import renderField from '../../../shared/components/custom/Field';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import renderChromeColorPickerField from '../../../shared/components/form/ChromeColorPicker';
import { hexToRgb } from '../../../util/functions';
import t, { partial } from '../../../util/translation/translation';

const defaultButtonColor = '#000000';
const defaultButtonTextColor = '#ffffff';
const p = partial('ExternalStoreForm');

const ExternalStoreForm = ({
  handleSubmit, externalStore, initialize,
}) => {
  useEffect(() => {
    if (externalStore) {
      initialize({
        name: externalStore.name,
        description: externalStore.description,
        externalUrl: externalStore.external_url,
        baseUrl: externalStore.base_url,
        saleStart: moment(externalStore.sale_start).format('YYYY-MM-DDTHH:mm'),
        saleEnd: externalStore.sale_end ? moment(externalStore.sale_end).format('YYYY-MM-DDTHH:mm') : null,
        tagKey: externalStore.tag_key,
        tagValue: externalStore.tag_value,
        fastLink: externalStore.fast_link.toString(),
        buttonText: externalStore.button_text,
        buttonColor: {
          hex: externalStore.button_color ? externalStore.button_color : defaultButtonColor,
          rgb: hexToRgb(externalStore.button_color ? externalStore.button_color : defaultButtonColor),
        },
        buttonTextColor: {
          hex: externalStore.button_text_color ? externalStore.button_text_color : defaultButtonTextColor,
          rgb: hexToRgb(externalStore.button_text_color ? externalStore.button_text_color : defaultButtonTextColor),
        },
        image: externalStore.image ? [
          {
            ...externalStore.image,
            preview: externalStore.image.url,
          },
        ] : [],
      });
    }
  }, [externalStore]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <Row>
          <Col lg={7} md={12}>
            <Row>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form-header">{t('shared.name')}*</span>
                  <Field
                    name="name"
                    placeholder={t('shared.name')}
                    component={renderField}
                  />
                </div>
              </Col>
              <Col md={6}>
                <span className="marginRight-8">{p('appliesTo')} </span><TooltipWithIcon id="externalStoreData" text={p('appliesToTooltip')} />
                <div className="radio-horizontal flex-HSpaceBetween">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="fastLink"
                        label={t('shared.website')}
                        component={renderRadioButtonField}
                        radioValue="1"
                        defaultChecked
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="fastLink"
                        component={renderRadioButtonField}
                        label={p('supplierData')}
                        radioValue="0"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span className="form-header">{p('externalResource')}*</span>
                  <Field
                    name="externalUrl"
                    placeholder={p('linkExternalResource')}
                    component={renderField}
                  />
                  <Link to="/assets/files/ExternalStore.txt" target="_blank" download>{p('downloadDataStructure')}</Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span>{p('baseUrl')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="baseUrl"
                      component={renderField}
                      placeholder={p('baseUrl')}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form__form-group">
                  <span>{t('shared.description')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="description"
                      component={renderTextAreaField}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>{p('buttonText')}*</span>
                <div className="form__form-group-field">
                  <Field
                    name="buttonText"
                    component={renderField}
                    placeholder={p('buttonText')}
                  />
                </div>
              </Col>
            </Row>
            <Row className="row-spacing">
              <Col lg={12} md={12}>
                <div className="flex flex-wrap">
                  <div style={{ marginRight: '30px' }}>
                    <span>{p('buttonColor')}*</span>
                    <div className="form__form-group-field">
                      <Field
                        name="buttonColor"
                        component={renderChromeColorPickerField}
                        defaultColor={defaultButtonColor}
                        defau
                      />
                    </div>
                  </div>
                  <div>
                    <span>{p('buttonTextColor')}*</span>
                    <div className="form__form-group-field">
                      <Field
                        name="buttonTextColor"
                        defaultColor={defaultButtonTextColor}
                        component={renderChromeColorPickerField}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span>{p('availableFrom')}*</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                    <Field
                      name="saleStart"
                      component={renderField}
                      type="datetime-local"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <span>{p('availableTo')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <TimetableIcon />
                  </div>
                  <Field
                    name="saleEnd"
                    component={renderField}
                    type="datetime-local"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={5} md={12}>
            <Row>
              <Col>
                <Card className="upload-files-box">
                  <CardBody className="no-shadow">
                    <div className="card__title marginTop-0">
                      <h5 className="bold-text">{p('uploadShopPic')}</h5>
                      <h5 className="subhead">{p('viewing')}: 750px/750px</h5>
                    </div>
                    <Field
                      name="image"
                      component={renderDropZoneField}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary">{externalStore ? t('shared.save') : t('shared.create')}</Button>
            <Link to="/external_stores"><Button color="secondary">{t('shared.close')}</Button></Link>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

ExternalStoreForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  externalStore: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    external_url: PropTypes.string,
    sale_start: PropTypes.string,
    sale_end: PropTypes.string,
  }),
};

ExternalStoreForm.defaultProps = {
  externalStore: null,
};

const reduxF = reduxForm({
  form: 'external_store_form',
  validate: validateExternalStore,
});

export default reduxF(ExternalStoreForm);
