export default {
  club_name: 'Tre kronor',
  club_name_short: 'trekronor',
  stadium_name: '',
  staging_url: 'https://trekronor.api.fangroup.io',
  prod_url: 'https://trekronor.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/trekronor',
  club_logo: '/assets/teams/trekronor.png',
  app_name: 'trekronor',
  websale_name: 'trekronor',
  ntb_team_id: 10047,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'trekronor',
  monolithEnabled: false,
};
