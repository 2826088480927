import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Col, Table,
  // UncontrolledDropdown,
  // DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
// import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../../util/api';
import useModal from '../../../shared/hooks/useModal';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import Loading from '../../../shared/components/custom/Loading';
import { partial } from '../../../util/translation/translation';

const ControlImagesList = () => {
  const [handleModal, visible, deleteID] = useModal();
  const [controlImages, setControlImages] = useState([]);
  const [loadingControlImages, setLoadingControlImages] = useState(true);
  const p = partial('settingsObjectsShared');
  const s = partial('shared');

  const getControlImages = async () => {
    setLoadingControlImages(true);
    try {
      const resControlImages = await Api.controlImages.getControlImages();
      setControlImages(resControlImages.data);
      setLoadingControlImages(false);
    } catch (err) {
      toast.error(err || p('errorWhenFetchingControllPictures'));
      setLoadingControlImages(false);
    }
  };

  useEffect(() => {
    getControlImages();
  }, []);

  const deleteControlImage = async () => {
    setLoadingControlImages(true);
    try {
      await Api.controlImages.deleteControlImage(deleteID);
      getControlImages();
    } catch (err) {
      toast.error(err || p('errorWhenDeletingControllPicture'));
      getControlImages();
    }
    handleModal();
  };

  const renderControlImages = () => {
    return controlImages.map(controlImage => (
      <tr key={`contorlImage-${controlImage.id}`}>
        {/* <td><Link to={`/settings/control_images/edit/${controlImage.id}`}>{controlImage.name}</Link></td> */}
        <td>{controlImage.name}</td>
        <td>{controlImage.image_used_count}</td>
        {/* <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link
                to={`/settings/control_images/edit/${controlImage.id}`}
              >
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem onClick={() => handleModal(controlImage.id)} className="danger">{s('delete')}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td> */}
      </tr>
    ));
  };

  return (
    <Col>
      <Loading loading={loadingControlImages} />
      <DeleteModal
        type="kontrollbilde"
        visible={visible}
        modalAction={deleteControlImage}
        handleModal={handleModal}
      />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <p className="page-title">{p('controllImages')}</p>
            {/* <Link className="btn btn-primary products-list__btn-add" to="/settings/control_images/create">
              {p('createControllImage')}
            </Link> */}
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{p('controllImage')}</th>
                <th>{s('used')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderControlImages()}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ControlImagesList;
