import React from 'react';
import { CircleItem, PortraitItem, SquareItem, LandscapeItem } from './SpotlightPreviewComponents';
import SpotlightTextTile from './SpotlightPreviewComponents/SpotlightTextTile';

const SpotlightAppPreview = ({ tile, group, access, descriptionAvailable }) => {
    if (!tile || !group || !access) {
      return null;
    }
    switch (group.format) {
        case 'SQUARE':
          return <SquareItem tile={tile} size={group.size} access={access} format={group.format} descriptionAvailable={descriptionAvailable} />;
        case 'PORTRAIT':
          return <PortraitItem tile={tile} size={group.size} access={access} format={group.format} descriptionAvailable={descriptionAvailable} />;
        case 'CIRCLE':
          return <CircleItem tile={tile} size={group.size} access={access} format={group.format} />;
        case 'LANDSCAPE':
          return <LandscapeItem tile={tile} size={group.size} access={access} format={group.format} descriptionAvailable={descriptionAvailable} />;
          case 'TEXT_BANNER':
            return <SpotlightTextTile tile={tile} access={access}/>;
        default:
          return null;
    }
};
export default SpotlightAppPreview;
