import { selector } from 'recoil';
import configAtom from './atom';

const config = selector({
  key: 'configState',
  get: ({ get }) => get(configAtom),
  set: ({ set }, show) => set(configAtom, show),

});
const ConfigState = config;
export { ConfigState };
export default config;
