import moment from 'moment';
import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.homeTeam) {
    errors.homeTeam = p('homeTeamError');
  }

  if (!values.awayTeam) {
    errors.awayTeam = p('awayTeamError');
  }

  if (!values.matchStartsAt) {
    errors.matchStartsAt = p('matchStartsAtError');
  }

  if (!values.doorsOpenAt) {
    errors.doorsOpenAt = p('doorsOpenAtError');
  }

  if (!values.matchType) {
    errors.matchType = p('matchTypeError');
  }

  if (!values.saleStart) {
    errors.saleStart = p('saleStartError');
  }

  if (values.presaleCheck && values.saleStart) {
    if (!values.presale) {
      errors.presale = p('presaleError');
    } else if (moment(values.presale).isSameOrBefore(moment(values.saleStart))) {
      errors.presale = p('presaleAfterSaleStartError');
    } else if (moment.duration(moment(values.presale).diff(moment(values.saleStart))).asHours() % 1 !== 0) {
      errors.presale = p('presaleMustBeRound');
    }
  }

  if (!values.saleEnd) {
    errors.saleEnd = p('saleEndError');
  }

  if (values.saleStartWeb >= values.matchStartsAt) {
    errors.saleStartWeb = p('saleStartWebError');
  }

  if (!values.season) {
    errors.season = p('seasonError');
  }

  if (values.claimStartDate) {
    if (values.claimEndDate && moment(values.claimStartDate).isAfter(moment(values.claimEndDate))) {
      errors.claimStartDate = p('claimStartDateErrorAfterReleaseDate');
    }
    if (values.matchStartsAt && moment(values.claimStartDate).isAfter(moment(values.matchStartsAt))) {
      errors.claimStartDate = p('claimStartDateErrorAfterMatchStart');
    }
  }

  return errors;
};

export default validate;
