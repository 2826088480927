import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RenderSwitchField = ({
  input, disabled, label, defaultChecked,
}) => {
  useEffect(() => {
    if (defaultChecked === true) {
      input.onChange(defaultChecked);
    }
  }, [defaultChecked]);
  return (
    <div className="switch-btn">
      <label
        className={classNames('switch-btn--container', { active: input.value })}
        htmlFor={input.name}
      >
        <input
          className="switch-btn--input"
          type="checkbox"
          id={input.name}
          name={input.name}
          onChange={input.onChange}
          checked={input.value}
          disabled={disabled}
        />
        <span className="switch-btn--button" />
      </label>
      <span
        className={classNames('switch-btn--label', { active: input.value })}
      >
        {label[input.value ? 0 : 1]}
      </span>
    </div>
  );
};

RenderSwitchField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.arrayOf(PropTypes.string),
  defaultChecked: PropTypes.bool,
};

RenderSwitchField.defaultProps = {
  meta: null,
  disabled: false,
  label: '',
  defaultChecked: false,
};

export default RenderSwitchField;
