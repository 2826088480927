export const SET_EDIT_MATCH_DATA = 'SET_EDIT_MATCH_DATA';
export const SET_EDIT_MATCH_TICKET_DATA = 'SET_EDIT_MATCH_TICKET_DATA';

export function setEditMatchData(match) {
  return {
    type: SET_EDIT_MATCH_DATA,
    match,
  };
}

export function setEditMatchTicketData(seat) {
  return {
    type: SET_EDIT_MATCH_TICKET_DATA,
    seat,
  };
}
