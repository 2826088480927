import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabPane } from 'reactstrap';

const LazyTabPane = ({ children, activeTab, tabId }) => {
  const [hasBeenVisited, setHasBeenVisited] = useState(false);

  useEffect(() => {
    if (!hasBeenVisited && activeTab === tabId) {
      setHasBeenVisited(true);
    }
  }, [activeTab]);

  return (
    <TabPane tabId={tabId}>
      {hasBeenVisited && children}
    </TabPane>
  );
};

LazyTabPane.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
};

export default LazyTabPane;
