import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../../../util/api';
import Loading from '../../shared/Spinner';
import { DoughnutChart, Widget, WidgetHeader, EmptyGraphData } from './shared';
import helpers from '../helpers';
import { partial } from '../../../util/translation/translation';

const CompanyLevels = () => {
    const dT = partial('DashboardPage');
    const sT = partial('shared');
    const { data, isLoading } = useQuery({ queryKey: ['company-count-level'], queryFn: () => Api.companies.getCompanyCountByLevel() });
    const [chartData, setChartData] = useState({});

    useEffect(() => {
      if (data?.data) {
        const companies = data.data;
        const { labels, dataset, colors } = helpers.getSortedChartData(companies, 'level', 'companycount');
        setChartData({
          labels,
          datasets: [
              {
                  data: dataset,
                  backgroundColor: colors,
              },
          ],
      });
      }
    }, [data]);

    const renderChart = (
      chartData.datasets && chartData.datasets[0].data.length > 0 ? <DoughnutChart chartData={chartData} /> : <EmptyGraphData message={dT('noData')} />
    );

    return (
      <Widget>
        <WidgetHeader title={sT('companies')} subhead={dT('companiesByLevelSubheading')} />
        {isLoading ? <Loading /> : renderChart}
      </Widget>);
};

export default CompanyLevels;
