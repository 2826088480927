import { partial } from '../../../util/translation/translation';

const validateGroupsForm = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.name) {
    errors.name = p('nameFieldEmpty');
  }

  return errors;
};

export default validateGroupsForm;
