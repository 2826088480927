import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import t, { partial } from '../../util/translation/translation';
import NewsCategoryForm from './components/NewsCategoryForm';

const s = partial('shared');

const NewsCategoryFormPage = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { categoryID } = useParams();
    const [category, setCategory] = useState(null);

  useEffect(async () => {
    if (!categoryID) {
      return;
    }
    setLoading(true);
    try {
      const foundCategory = await Api.news.getCategoryById(categoryID);
      setLoading(false);
      setCategory(foundCategory);
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
      setLoading(false);
    }
  }, [categoryID]);
  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
        name: values.name,
      };
    try {
      if (categoryID) {
        await Api.news.updateCategory(categoryID, payload);
      } else {
        await Api.news.createCategory(payload);
      }
      setLoading(false);
      history.replace('/news?tab=2');
    } catch (err) {
      toast.error(t('api.newsCategory.nameExists'));
      setLoading(false);
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col md={12}>
          <h3 className="page-title">{s('create')}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <NewsCategoryForm
                onSubmit={onSubmit}
                category={category}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsCategoryFormPage;
