import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import renderSelectField from '../../../shared/components/form/Select';
import validate from './validateAccess';
import t, { partial } from '../../../util/translation/translation';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';

const AuctionHouseAccessForm = (props) => {
  const o = partial('OfferForm');
  const s = partial('shared');
  const e = partial('EventsForm');
  const n = partial('NewsForm');
  const { handleSubmit, initialize, groupsLevelsTiers, access, accessFormValues = {}, change } = props;
  const { tiers, levels, groups } = groupsLevelsTiers;
  const targetAudience = useMemo(() => accessFormValues?.targetAudience, [accessFormValues]);
  const getTargetAudience = () => {
    if (access.levels || access.groups || access.tiers || access.segments) {
      return 'targetAudience';
    }
    return 'all';
  };
  const getSelectedFields = (accessObject, objectField, title, searchArray) => {
    if (Array.isArray(accessObject[objectField])) {
      if (accessObject[objectField].length === 0) {
        return [{ label: e(`all${title}`), value: [] }];
      } else {
        return accessObject[objectField].map((accessItem) => searchArray.find((item) => item.value === accessItem));
      }
    } else {
      return [{ label: s('ignore'), value: null }];
    }
  };
  useEffect(() => {
    initialize({
      targetAudience: getTargetAudience(),
      level: getSelectedFields(access, 'levels', 'Levels', levels),
      tier: getSelectedFields(access, 'tiers', 'Tiers', tiers),
      group: getSelectedFields(access, 'groups', 'Groups', groups),
      noAccessView: typeof access.noAccessView === 'boolean' ? access.noAccessView : true,
    });
  }, [access]);
  const adjustDropdowns = (fieldName) => {
    if (accessFormValues[fieldName] && accessFormValues[fieldName].length > 1) {
      const [old, ...newOnes] = accessFormValues[fieldName];
      const newestOne = accessFormValues[fieldName][accessFormValues[fieldName].length - 1];
      if (old.value === null || Array.isArray(old.value)) {
        change(fieldName, newOnes);
      }
      if (newestOne.value === null || Array.isArray(newestOne.value)) {
        change(fieldName, [newestOne]);
      }
    }
  };
  useEffect(() => {
    adjustDropdowns('group');
    adjustDropdowns('level');
    adjustDropdowns('tier');
  }, [accessFormValues.level, accessFormValues.group, accessFormValues.tier]);
  const handleAudienceChange = (newAudience) => {
    const ignore = [{ label: s('ignore'), value: null }];
    if (newAudience === 'all') {
      change('level', ignore);
      change('tier', ignore);
      change('group', ignore);
    } else {
      change('level', [{ label: e('allLevels'), value: [] }]);
      change('tier', [{ label: e('allTiers'), value: [] }]);
      change('group', [{ label: e('allGroups'), value: [] }]);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="form">
      <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginTop: 15, marginBottom: 15, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('access')}</div>
          </div>
        </div>
        <div style={{ padding: '10px 10px 10px 10px' }}>
          <Col>
            <Row>
              <Col >
                <span className="form">{n('targetAudienceTitle')}</span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <Field
                      name="targetAudience"
                      component={renderRadioButtonField}
                      radioValue="all"
                      label={n('targetAudience-all')}
                      defaultChecked
                      onChange={handleAudienceChange}
                    />
                    <Field
                      name="targetAudience"
                      label={n('targetAudience-custom')}
                      component={renderRadioButtonField}
                      radioValue="targetAudience"
                      onChange={handleAudienceChange}
                    />
                  </div>
                </div>
              </Col>
              <Col >
                <span className="form">
                  {n('noAccessView')}
                  &nbsp;
                  <TooltipWithIcon id="no-access-view-tooltip" text={t('NewsFormPage.noAccessViewInfo')} />
                </span>
                <div className="form__form-group-field">
                  <div className="form__form-group">
                    <Field
                      name="noAccessView"
                      component={renderRadioButtonField}
                      // eslint-disable-next-line react/jsx-boolean-value
                      radioValue={true}
                      label={s('all')}
                      defaultChecked
                    />
                    <Field
                      name="noAccessView"
                      label={o('targetAudience')}
                      component={renderRadioButtonField}
                      radioValue={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {targetAudience !== 'all' ?
              <Row>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('partnerlevelList')}</span>
                    <Field
                      name="level"
                      placeholder={o('selectLevels')}
                      options={levels}
                      component={renderSelectField}
                      multiple
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{o('partnergroupList')}</span>
                    <Field
                      name="group"
                      placeholder={o('selectGroup')}
                      options={groups}
                      component={renderSelectField}
                      multiple
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form-header">{s('userTiers')}</span>
                    <Field
                      name="tier"
                      placeholder={o('selectTier')}
                      options={tiers}
                      component={renderSelectField}
                      multiple
                    />
                  </div>
                </Col>
              </Row> :
              null}
          </Col>
        </div>
      </div>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'auction_house_access_form',
  validate,
});

const mapStateToProps = (state) => ({
  accessFormValues: getFormValues('auction_house_access_form')(state),
});

export default connect(mapStateToProps)(reduxF(AuctionHouseAccessForm));
