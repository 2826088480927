import React, { useEffect, useState } from 'react';
import { Table, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';
import DragIcon from 'mdi-react/DragIcon';
import skeletonTableLoader from '../../shared/components/custom/SkeletonLoaders';
import { partial } from '../../util/translation/translation';
import { getBaseDate } from '../../util/date-and-time';
import Api from '../../util/api';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import EmptyStateInfo from '../../shared/components/custom/EmptyStateInfo';
import useModal from '../../shared/hooks/useModal';


const UNLIMITED_DATE = '9999-12-31T00:00:00.000Z';
const TileListPage = ({
  groupId, setLoading,
}) => {
  const [handleModal, visible, deleteId] = useModal();
  const [tiles, setTiles] = useState([]);
  const s = partial('shared');
  const p = partial('HighlightBannerPage');
  const fetchTiles = async () => {
    setLoading(true);
    try {
      const tileData = await Api.spotlight.getTilesForGroup(groupId);
      const sortedTiles = tileData.sort((a, b) => Number(a.order.split('@')[1]) - Number(b.order.split('@')[1]));
      setTiles(sortedTiles);
      const spotlightIds = tileData.map((item) => item.id);
      const { data: pressData } = await Api.spotlight.getSpotlightPressAmount({ spotlightIds });
      const sorted = sortedTiles.map((item) => ({ ...item, presses: pressData.find((spotlightPress) => spotlightPress.itemId === item.id)?.presses || 0 }));
      setTiles(sorted);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (groupId) {
      fetchTiles();
    }
  }, [groupId]);
  const handleDeleteTile = async () => {
    setLoading(true);
    try {
      await Api.spotlight.deleteTile(deleteId);
      const tileData = await Api.spotlight.getTilesForGroup(groupId);
      const sorted = tileData.sort((a, b) => Number(a.order.split('@')[1]) - Number(b.order.split('@')[1]));
      handleModal();
      setTiles(sorted);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const renderTiles = () => {
    return tiles.map((n, index) => {
      const isActive = moment().isBetween(moment(n.publicationDate), moment(n.expirationDate));
      let order = Number(n.order.split('@')[1]);
      order = (Number.isNaN(order) ? '' : order + 1);
      return (
        <Draggable
          key={`tile-${n.id}`}
          draggableId={`tile-${n.id}`}
          index={index}
        >
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={snapshot.isDragging ? 'rbdnd-dragging' : ''}
              key={`tile-${n.id}`}
            >
              <td><DragIcon color="#99999" /> {order}</td>
              <td><Link to={`/spotlight/group/edit/${groupId}/tile/edit/${n.id}`}>{n.title}</Link></td>
              <td>{getBaseDate(n.publicationDate)}</td>
              <td>{n.expirationDate !== UNLIMITED_DATE ? getBaseDate(n.expirationDate) : '-'}</td>
              <td>
                <Badge color={isActive ? 'success' : 'danger'}>
                  {isActive ? s('active') : s('inActive')}
                </Badge>
              </td>
              <td>
                {n.presses === undefined ?
                  skeletonTableLoader(1, 1, 50)
                  : n.presses}
              </td>
              <td>
                <UncontrolledDropdown className="dashboard__table-more">
                  <DropdownToggle>
                    <DotsHorizontalIcon />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown__menu">
                    <Link to={`/spotlight/group/edit/${groupId}/tile/edit/${n.id}`}>
                      <DropdownItem>{s('edit')}</DropdownItem>
                    </Link>
                    <DropdownItem
                      onClick={() => handleModal(n.id)}
                      className="danger"
                    >
                      {s('delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          )}
        </Draggable>
      );
    });
  };

  const onDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const draggedTile = tiles[source.index];
    const tileCopy = [...tiles];
    tileCopy.splice(source.index, 1);
    tileCopy.splice(destination.index, 0, draggedTile);
    const orderedIds = tileCopy.map((item) => item.id);
    setTiles(tileCopy.map((item, idx) => ({ ...item, order: `${item.order.split('@')[0]}@${idx}` })));
    setLoading(true);
    try {
      await Api.spotlight.updateOrder(groupId, { tileIds: orderedIds });
      toast.success(s('Toast.successfullEdit'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(p('errorUpdatingOrder'));
    }
  };
  return (
    <>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type="tile"
        modalAction={handleDeleteTile}
      />
      {tiles?.length > 0 &&
        <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th >
                    <div className="flex alignItems-center">{s('heading')}</div>
                  </th>
                  <th >
                    <div className="flex alignItems-center">{s('publicationDate')}</div>
                  </th>
                  <th >
                    <div className="flex alignItems-center">{s('expirationDate')}</div>
                  </th>
                  <th>{s('status')}</th>
                  <th>{s('clicks')}</th>
                  <th />
                </tr>
              </thead>
              <Droppable droppableId="group-dnd">
                {(provided) => {
                  return (
                    <>
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {renderTiles()}
                        {provided.placeholder}
                      </tbody>
                    </>
                  );
                }}
              </Droppable>


            </Table>
          </DragDropContext>
        </div>
      }
      {tiles?.length === 0 &&
        <div className="mb-5">
          <EmptyStateInfo icon="layout" url={`/spotlight/group/edit/${groupId}/tile/create`} linkName={p('createTile')} title={p('createTitle')} text={p('createText')} />
        </div>
      }
    </>
  );
};

export default TileListPage;
