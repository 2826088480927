import React, { useEffect } from 'react';
import { Field, FieldArray, reduxForm, propTypes as reduxProps } from 'redux-form';
import moment from 'moment';
import { Col, Container, Card, CardBody, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { toast } from 'react-toastify';
import renderField from '../../../shared/components/custom/Field';
import RuleFields from './RuleFields';
import validate from './validate';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import { partial } from '../../../util/translation/translation';
// import config from '../../../config';
import useConfig from '../../../util/useConfig';

const s = partial('shared');
const p = partial('VolumeDiscountsForm');
const VolumeDiscountsForm = ({
  priceGroups, handleSubmit, volumeDiscountInfo, initialize, seasonPriceGroups, change,
}) => {
  const config = useConfig();
  const modifiers = [
    { label: `${p('equals')}'(=)'`, value: 'eq' },
    { label: `${p('biggerThanOrEqual')}'(>=)'`, value: 'gteq' },
  ];

  useEffect(() => {
    if (volumeDiscountInfo) {
      const {
        name, price, description, sale_start: saleStart, sale_end: saleEnd,
      } = volumeDiscountInfo.package;
      const { show_in_app: showInApp } = volumeDiscountInfo;
      const invalidSeasonPriceGroup = volumeDiscountInfo.package.active === true && volumeDiscountInfo.active === false;
      if (invalidSeasonPriceGroup) { // Check if season pricegroups are not in this season
        toast.warning(p('seasonPriceGroupInvalid'));
      }
      initialize({
        name,
        price,
        description,
        saleStart: moment(saleStart).format('YYYY-MM-DDTHH:mm'),
        saleEnd: saleEnd && moment(saleEnd).format('YYYY-MM-DDTHH:mm'),
        rules: invalidSeasonPriceGroup === false && volumeDiscountInfo.items && volumeDiscountInfo.items.length > 0 ? volumeDiscountInfo.items.map(rule => ({
          modifier: modifiers.find(mods => mods.value === rule.modifier),
          priceGroup: volumeDiscountInfo.season === 0 ?
            priceGroups.find(priceGroup => priceGroup.value === rule.price_group_id.toString())
            :
            seasonPriceGroups.find(priceGroup => priceGroup.value === rule.season_price_id.toString()),
          quantity: rule.quantity,
        })) : [],
        seasonTicketSale: volumeDiscountInfo.season.toString(),
        hideInApp: !showInApp,
      });
    }
  }, [volumeDiscountInfo]);

  const resetRules = () => {
    change('rules', []);
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <form onSubmit={handleSubmit} className="form">
            <Container>

              <Row>
                <Col lg={6} >
                  <div className="form__form-group">
                    <span className="form">{p('discountName')}</span>
                    <div className="marginTop-5" />
                    <Field
                      name="name"
                      component={renderField}
                      placeholder={p('discountName')}
                    />
                  </div>
                </Col>
                <Col lg={6} >
                  <div className="form__form-group">
                    <span className="form-header">{p('discountIn')} {config.currencyShortSmall}</span>
                    <div className="marginTop-5" />
                    <Field
                      name="price"
                      component={renderField}
                      placeholder={`${p('discountIn')} ${config.currencyShortSmall}`}
                      type="number"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form__form-group">
                    <span className="form-header">{s('description')}</span>
                    <div className="marginTop-5" />
                    <Field
                      name="description"
                      component="textarea"
                      placeholder={s('description')}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <Row>
                    <Col xl={6} lg={12}>
                      <div className="form__form-group">
                        <span className="form">{p('availableFrom')}</span>
                        <div className="marginTop-5" />
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <TimetableIcon />
                          </div>
                          <Field
                            name="saleStart"
                            component={renderField}
                            type="datetime-local"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={12}>
                      <span className="form">{p('availableTo')}</span>
                      <div className="marginTop-5" />
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                        <Field
                          name="saleEnd"
                          component={renderField}
                          type="datetime-local"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <span className="form">{p('appliesTo')}</span>
                  <div className="marginTop-5" />
                  <div>
                    <div className="radio-horizontal">
                      <div className="form__form-group margin-0">
                        <div className="form__form-group-field">
                          <Field
                            name="seasonTicketSale"
                            component={renderRadioButtonField}
                            label={p('singleTicketSales')}
                            radioValue="0"
                            defaultChecked
                            onChange={resetRules}
                          />
                        </div>
                      </div>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <Field
                            name="seasonTicketSale"
                            component={renderRadioButtonField}
                            label={p('seasonPassSales')}
                            radioValue="1"
                            onChange={resetRules}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="marginTop-5" />
                  <div className="form__form-group-field">
                    <Field
                      name="hideInApp"
                      component={renderCheckBoxField}
                      label={p('dontShowInDiscountOverview')}
                      className="colored-click"
                    />
                    <div className="marginLeft-8">
                      <TooltipWithIcon
                        id="balance-price"
                        text={p('discountInfo')}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="marginTop-20" />
              <Row>
                <Col md={12} >
                  <FieldArray
                    name="rules"
                    component={RuleFields}
                    priceGroups={priceGroups}
                    seasonPriceGroups={seasonPriceGroups}
                    modifiers={modifiers}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button type="submit" color="primary">{s('save')}</Button>
                  <Link className="color-unset" to="/discounts"><Button type="button">{s('close')}</Button></Link>
                </Col>
              </Row>
            </Container>
          </form>
        </CardBody>
      </Card>

    </Col>
  );
};

VolumeDiscountsForm.defaultProps = {
  volumeDiscountInfo: null,
  meta: null,
};

VolumeDiscountsForm.propTypes = {
  ...reduxProps,
  priceGroups: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  seasonPriceGroups: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    error: PropTypes.string,
  }),
  volumeDiscountInfo: PropTypes.shape({
    package: PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    }),
  }),
};

const reduxF = reduxForm({
  form: 'createDiscountForm',
  validate,
});

export default withRouter(connect(state => ({
  discounts: state.discounts,
}))(reduxF(VolumeDiscountsForm)));
