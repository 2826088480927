import t, { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateMatchContact = (values) => {
  const errors = {};
  if (!values?.title) {
    errors.title = `${p('titleFieldCantBeEmpty')}!`;
  }

  if (!values?.content) {
    errors.content = `${p('messageFieldCantBeEmpty')}!`;
  }

  if (values?.timeToSend === 'SCHEDULED' && (!values?.scheduleTime || new Date(values.scheduleTime) < Date.now())) {
    errors.scheduleTime = `${t('ScheduledMessagesPage.selectValidDate')}`;
  }

  return errors;
};

export default validateMatchContact;
