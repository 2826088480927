export default {
  club_name: 'Hammarby Fotboll',
  club_name_short: 'HIF',
  stadium_name: 'Tele2 Arena',
  staging_url: 'https://hammarby.api.fangroup.io',
  prod_url: 'https://hammarby.api.fangroup.io',
  api: '/api',
  facebook: 'HammarbyFotboll/',
  basename: '/hammarby',
  club_logo: '/assets/teams/hammarby.png',
  app_name: 'hammarby',
  websale_name: 'hammarby',
  ntb_team_id: 10023,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'hammarby',
  monolithEnabled: false,
};
