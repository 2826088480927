export default {
  club_name: 'Lillehammer Hockey',
  club_name_short: 'lillehammer',
  stadium_name: '',
  staging_url: 'https://lillehammer.api.fangroup.io',
  prod_url: 'https://lillehammer.api.fangroup.io',
  api: '/api',
  facebook: '/lillehammerhocke',
  basename: '/lillehammer',
  club_logo: '/assets/teams/lillehammer.png',
  app_name: 'lillehammer',
  websale_name: 'lillehammer',
  ntb_team_id: 10056,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'lillehammer',
  monolithEnabled: false,
};
