import React, { useEffect, useState } from 'react';
import {
  Container, Col, Card, CardBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import TeamForm from './components/TeamForm';
import Loading from '../../shared/components/custom/Loading';
import { partial } from '../../util/translation/translation';
import Api from '../../util/api';
import { convertImageToBlob } from '../../util/image-functions';

const p = partial('settingsObjectsShared');

const CreateTeamPage = () => {
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState(undefined);
  const { teamId } = useParams();
  const history = useHistory();

  const handleSubmit = async (values) => {
    const image = await convertImageToBlob(values.logo[0]);
    const payload = {
      ...values,
      ntb_id: values?.ntb_id?.value,
      icon: image,
    };
    setLoading(true);
    try {
      await Api.settings.createTeam(payload);
      history.push('settings?tab=1');
      toast.success(p('teamHasBeenCreated'));
    } catch (err) {
      toast.error(err || p('errorWhenCreatingTeam'));
    } finally {
      setLoading(true);
    }
  };

  const fetchTeam = async id => {
    setLoading(true);
    try {
      const { data } = await Api.settings.getTeam(id);
      setTeam(data);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      fetchTeam(teamId);
    }
  }, [teamId]);
  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <h3 className="page-title">{p('newTeam')}</h3>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <TeamForm
              team={team}
              onSubmit={handleSubmit}
            />
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};


export default CreateTeamPage;
