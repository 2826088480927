import React, { useEffect, useState } from 'react';

const Drawer = ({
  isOpen,
  onClose,
  children,
  position = 'right',
  width = '400px',
  overlayColor = 'rgba(0, 0, 0, 0.5)',
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleTransitionEnd = () => {
    if (!isOpen) {
      setIsAnimating(false);
    }
  };

  if (!isOpen && !isAnimating) return null;

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: overlayColor,
    opacity: isOpen ? 1 : 0,
    transition: 'opacity 300ms ease-in-out',
  };

  const drawerStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    transition: 'transform 300ms ease-in-out',
    transform: isOpen ? 'translateX(0)' : `translateX(${position === 'right' ? '100%' : '-100%'})`,
    [position]: 0,
  };

  return (
    <div style={containerStyle}>
      <div
        style={overlayStyle}
        onClick={onClose}
        role="button"
        tabIndex={0}
      />
      <div
        style={drawerStyle}
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </div>
    </div>
  );
};

const DrawerHeader = ({ children, onClose }) => {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderBottom: '1px solid #e5e7eb',
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: 600,
  };

  const buttonStyle = {
    padding: '8px',
    borderRadius: '50%',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const buttonHoverStyle = {
    backgroundColor: '#f3f4f6',
  };

  return (
    <div style={headerStyle}>
      <div style={titleStyle}>{children}</div>
      <button
        onClick={onClose}
        style={buttonStyle}
        onMouseEnter={(e) => {
          Object.assign(e.target.style, buttonHoverStyle);
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = 'transparent';
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};

const DrawerBody = ({ children }) => {
  const bodyStyle = {
    padding: '24px',
    overflowY: 'auto',
    height: 'calc(100% - 65px)', // Subtract header height
  };

  return (
    <div style={bodyStyle}>
      {children}
    </div>
  );
};

Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;

export default Drawer;
