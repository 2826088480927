import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TitleWithImage = ({ path, image, title }) => {
    return (
      <Link to={`${path}`} className="flex alignItems-center">
        {image && <img className="rounded-image-dashboard" src={image} alt="logo" />}
        {title}
      </Link>
    );
};

TitleWithImage.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TitleWithImage;
