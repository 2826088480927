import React, { Component } from 'react';
import { Container, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import TeamList from './components/TeamList';
import GlobalPricesList from './components/GlobalPricesList';
import MatchTypesList from './components/MatchTypesList';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import Import from './Import';
import NavTab from '../../shared/components/custom/NavTab';
import ControlImagesList from './components/ControlImagesList';
import { partial } from '../../util/translation/translation';
import TermsList from './components/TermsList';
import Config from '../../config';

const p = partial('settingsObjectsShared');
const s = partial('shared');

class SettingsPage extends Component {
  static propTypes = {
    me: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    me: null,
  }
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('tab') || '1';
    this.state = {
      activeTab,
      loading: false,
      modalVisible: false,
      deleteID: null,
      modalType: '',
      modalAction: '',
      modalUpdateList: () => {},
      removeWarning: undefined,
    };
  }

  componentDidUpdate() {
    this.updateTab();
  }

  setActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleModal = (modalVisible, deleteID, modalType, modalAction, modalUpdateList, removeWarning) => {
    this.setState({
      modalVisible,
      deleteID,
      modalType,
      modalAction,
      modalUpdateList,
      removeWarning,
    });
  };

  updateTab = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('tab');
    if (this.state.activeTab !== activeTab) {
      this.setState({ activeTab });
    }
  };

  deleteTeam = () => {
    this.setState({ loading: true }, () => {
      Api.settings.deleteTeam(this.state.deleteID)
        .then(() => {
          this.state.modalUpdateList();
          this.handleModal(false);
          this.setState({ loading: false });
          toast.success(p('teamWasDeleted'));
        }).catch(() => {
          this.handleModal(false);
          this.setState({ loading: false });
          toast.error(p('errorWhenDeletingMatch'));
        });
    });
  };

  deletePriceGroup = () => {
    this.setState({ loading: true }, () => {
      Api.settings.deletePriceGroup(this.state.deleteID)
        .then(() => {
          this.state.modalUpdateList();
          this.handleModal(false);
          this.setState({ loading: false });
          toast.success(p('priceGroupWasDeleted'));
        }).catch(() => {
          this.handleModal(false);
          this.setState({ loading: false });
          toast.error(p('errorWhenDeletingMatch'));
        });
    });
  };

  deleteMatchType = () => {
    this.setState({ loading: true }, () => {
      Api.settings.deleteMatchType(this.state.deleteID)
        .then(() => {
          this.state.modalUpdateList();
          this.handleModal(false);
          this.setState({ loading: false });
          toast.success(p('matchTypeWasDeleted'));
        }).catch(() => {
          this.handleModal(false);
          toast.error(p('errorWhenDeletingMatch'));
          this.setState({ loading: false });
        });
    });
  };

  renderTabs = () => {
    const permissions = this.props?.me?.permissions ?? [];
    const hasSettingsRole = permissions.includes('access-settings');
    const hasTicketsRole = permissions.includes('access-tickets');
    const tabs = [];
    if (!Config.monolithEnabled) {
      return [s('terms')];
    }
    if (hasSettingsRole) tabs.push(s('teams'), s('priceGroups'), p('matchTypes'), p('controllImages'), s('terms'));
    if (hasTicketsRole) tabs.push('Import');
    return tabs;
  };

  renderTabComponents = () => {
    const permissions = this.props?.me?.permissions ?? [];
    const hasSeasonTicketsRole = permissions.includes('access-settings');
    const hasTicketsRole = permissions.includes('access-tickets');
    if (!Config.monolithEnabled) {
      return (
        <TabPane tabId="1">
          <TermsList />
        </TabPane>
      );
    }
    if (hasSeasonTicketsRole && !hasTicketsRole) {
      return (
        <>
          <TabPane tabId="1">
            <TeamList
              handleModal={this.handleModal}
              handleTeam={this.handleTeam}
            />
          </TabPane>
          <TabPane tabId="2">
            <GlobalPricesList
              priceGroups={this.state.priceGroups}
              handleModal={this.handleModal}
              handlePriceGroup={this.handlePriceGroup}
            />
          </TabPane>
          <TabPane tabId="3">
            <MatchTypesList
              matchTypes={this.state.matchTypes}
              handleModal={this.handleModal}
              handleMatchType={this.handleMatchType}
            />
          </TabPane>
          <TabPane tabId="4">
            <ControlImagesList />
          </TabPane>
        </>
      );
    } else if (!hasSeasonTicketsRole && hasTicketsRole) {
      return (
        <TabPane tabId="1">
          <Import />
        </TabPane>
      );
    } else if (!hasSeasonTicketsRole && !hasTicketsRole) {
      return <></>;
    }
    return (
      <>
        <TabPane tabId="1">
          <TeamList
            handleModal={this.handleModal}
            handleTeam={this.handleTeam}
          />
        </TabPane>
        <TabPane tabId="2">
          <GlobalPricesList
            priceGroups={this.state.priceGroups}
            handleModal={this.handleModal}
            handlePriceGroup={this.handlePriceGroup}
          />
        </TabPane>
        <TabPane tabId="3">
          <MatchTypesList
            matchTypes={this.state.matchTypes}
            handleModal={this.handleModal}
            handleMatchType={this.handleMatchType}
          />
        </TabPane>
        <TabPane tabId="4">
          <ControlImagesList />
        </TabPane>
        <TabPane tabId="5">
          <TermsList />
        </TabPane>
        <TabPane tabId="6">
          <Import />
        </TabPane>
      </>
    );
  };

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <DeleteModal
          type={this.state.modalType}
          visible={this.state.modalVisible}
          handleModal={this.handleModal}
          removeWarning={this.state.removeWarning}
          modalAction={this[this.state.modalAction]}
        />
        <NavTab
          activeTab={this.state.activeTab}
          setTab={this.setActiveTab}
          tabs={this.renderTabs()}
        />
        <TabContent activeTab={this.state.activeTab}>
          {this.renderTabComponents()}
        </TabContent>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  me: state.admin.me,
});

export default connect(mapStateToProps)(SettingsPage);
