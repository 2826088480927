import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Button,
  DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import moment from 'moment';
import ExportVariantIcon from 'mdi-react/ExportVariantIcon';
import fileDownload from 'js-file-download';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { toast } from 'react-toastify';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import Checkbox from '../../../shared/components/custom/Checkbox';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';
import Pagination from '../../../shared/components/pagination/Pagination';
import t, { partial } from '../../../util/translation/translation';
import { formatDate, formatDateTime } from '../../../util/date-and-time';

const InvoiceTab = () => {
  const [billables, setBillables] = useState({
    total: 0,
    per_page: 10,
    data: [],
    current_page: 1,
  });
  const [loadingBillable, setLoadingBillable] = useState(false);
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [loadingSeasons, setLoadingSeasons] = useState(true);
  const [selectedSeason, setSelectedSeason] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [matches, setMatches] = useState([]);
  const [loadingMatches, setLoadingMatches] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState({});
  const p = partial('InvoicePage');

  const getBillables = async (page = billables.current_page, pageSize = billables.per_page) => {
    if (selectedSeason.id !== undefined) {
      setLoadingBillable(true);
      try {
        const billRes = await Api.invoices.getBillable(
          page,
          pageSize,
          searchQuery,
          selectedSeason.id,
          selectedMatch.id && selectedMatch.id,
        );

        setBillables(billRes.data);
        setLoadingBillable(false);
      } catch (err) {
        setLoadingBillable(false);
        toast.error(err || p('errorFetchingTickets'));
      }
    }
  };

  const handleToggle = async (billableID) => {
    setLoadingProcessing(true);
    const selectedBillable = billables.data.find(bill => bill.id === billableID);
    if (selectedBillable) {
      try {
        await Api.invoices.setHandled(billableID, { processed: selectedBillable.processed === 0 ? 1 : 0 });
        getBillables(billables);
        setLoadingProcessing(false);
      } catch (err) {
        toast.error(err || `${p('somethingWentWrong')}!`);
      }
    }
  };

  const changePage = (pager) => {
    if (pager.pageSize !== billables.per_page || pager.currentPage !== billables.current_page) {
      getBillables(pager.currentPage, pager.pageSize);
    }
  };

  const exportCSV = async () => {
    setLoadingExport(true);
    try {
      const csv = await Api.invoices.exportBillable();
      fileDownload(`\uFEFF${csv.data}`, p('invoiceCSV'));
      setLoadingExport(false);
    } catch (err) {
      setLoadingExport(false);
      toast.error(err || p('errorFetchingList'));
    }
  };

  const getSeasons = async () => {
    try {
      const resSeasons = await Api.seasons.getSeasons();
      setSeasons(resSeasons.data);
      if (resSeasons.data[0]) {
        setSelectedSeason(resSeasons.data[0]);
      }
      setLoadingSeasons(false);
    } catch (err) {
      toast.error(err || p('errorFetchingSeasons'));
      setLoadingSeasons(false);
    }
  };

  const getMatches = async () => {
    if (selectedSeason.id) {
      setLoadingMatches(true);
      try {
        const resMatches = await Api.seasons.getSeasonByID(selectedSeason.id);
        setMatches(resMatches.data.matches);
        setLoadingMatches(false);
      } catch (err) {
        toast.error(err || p('errorFetchingMatches'));
        setLoadingMatches(false);
      }
    }
  };

  useEffect(() => {
    getSeasons();
  }, []);

  useEffect(() => {
    getMatches();
    getBillables();
  }, [selectedSeason]);

  useEffect(() => {
    if (selectedSeason.id !== undefined) {
      getBillables();
    }
  }, [selectedMatch]);

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      getBillables();
    }
  };

  const renderTickets = (seasonTickets, tickets) => {
    const seasonTicketList = seasonTickets.map(seasonTicket => (
      <tr key={`season-ticket-${seasonTicket.id}`}>
        <td />
        <td colSpan="4" style={{ paddingLeft: '60px' }}>
          <strong>{seasonTicket.season_price.title}&nbsp;</strong>
          - {p('theSeasonCaps')} {seasonTicket.season.name} &nbsp;
        </td>
      </tr>
    ));
    const ticketList = tickets.map(ticket => (
      <tr key={`ticket-${ticket.id}`}>
        <td />
        <td colSpan="4" style={{ paddingLeft: '60px', textTransform: 'capitalize' }}>
          <strong>
            {ticket.price_group.title}
            {' '}
          </strong>
          {ticket.seat_match && ticket.seat_match.match.home_team && ticket.seat_match.match.away_team &&
            `- ${ticket.seat_match.match.home_team.name} - ${ticket.seat_match.match.away_team.name}`
          }
          {' '}
          {ticket.seat_match && ticket.seat_match.match &&
            moment(ticket.seat_match.match.match_starts_at).format('dddd D. MMM')
          }
        </td>
      </tr>
    ));
    return seasonTicketList.concat(ticketList);
  };

  const renderBillables = () => {
    return billables.data.map(bill => (
      <React.Fragment key={`billabe-${bill.id}`}>
        <tr
          style={bill.processed === 0 ? { backgroundColor: '#FAFBFE' } : {}}
          className={bill.processed ? 'listSelected transitionDuration-03' : 'transitionDuration-03'}
        >
          <td>
            <Checkbox index={bill.id} toggled={!!bill.processed} handleToggle={handleToggle} />
          </td>
          <td>
            <strong>
              {bill.company && bill.company.name}
            </strong>
          </td>
          <td>
            <strong>
              {bill.user && `${bill.user.firstname} ${bill.user.lastname} (${bill.user.username})`}
            </strong>
          </td>
          <td>
            <strong>
              {formatDateTime(bill.bought_at)}
            </strong>
          </td>
          <td align="right">
            <strong>
              {bill.price.toFixed(2)} NOK
            </strong>
          </td>
        </tr>
        { renderTickets(bill.season_tickets, bill.tickets) }
      </React.Fragment>
    ));
  };

  const renderSeasons = () => {
    return seasons.map(season => (
      <DropdownItem
        className="maxWidth-400"
        onClick={() => setSelectedSeason(season)}
        key={`listSeasons-${season.id}`}
      >
        {t('shared.theSeason')} {season.name}
      </DropdownItem>
    ));
  };

  const renderMatches = () => {
    return matches.map(match => (
      <DropdownItem onClick={() => setSelectedMatch(match)} key={`listMatches-${match.id}`}>
        {match.home_team.name} - {match.away_team.name} {formatDate(match.match_starts_at)}
      </DropdownItem>
    ));
  };

  return (
    <Card>
      <Loading loading={!!(loadingBillable || loadingProcessing || loadingExport || loadingSeasons || loadingMatches)} />
      <CardBody>
        <div className="card__title margin-0">
          <div className="flex">
            <div>
              <h5 className="bold-text" style={{ marginBottom: '5px' }}>{t('shared.period')}</h5>
              <UncontrolledDropdown className="marginRight-15">
                <DropdownToggle className="icon icon--right" outline>{t('shared.theSeason')} {selectedSeason.name}<ChevronDownIcon /></DropdownToggle>
                <DropdownMenu className="dropdown__menu dropdownBox">
                  { renderSeasons() }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div>
              <h5 className="bold-text" style={{ marginBottom: '5px' }}>{t('shared.match')}</h5>
              <UncontrolledDropdown className="marginRight-15">
                <DropdownToggle className="icon icon--right" outline>
                  {selectedMatch.id
                    ? `${selectedMatch.home_team.name} - ${selectedMatch.away_team.name} ${formatDate(selectedMatch.match_starts_at)}`
                    : p('selectMatch')
                  }
                  <ChevronDownIcon />
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu dropdownBox dropdown-heightLimit">
                  <DropdownItem onClick={() => setSelectedMatch({})}>{p('selectMatch')}</DropdownItem>
                  { renderMatches() }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="flex-Vcenter">
            <div className="form marginRight-15">
              <div className="form__form-group products-list__search">
                <input
                  placeholder={`${t('shared.search')}...`}
                  value={searchQuery}
                  onChange={event => setSearchQuery(event.target.value)}
                  onKeyPress={handleSearch}
                  name="search"
                />
                <MagnifyIcon />
              </div>
            </div>
            <Button onClick={exportCSV} color="primary">
              <ExportVariantIcon
                style={{
                  height: '20px',
                  width: 'auto',
                  fill: 'white',
                  position: 'relative',
                  top: '-2px',
                }}
              />
              {t('shared.export')} CSV
            </Button>
          </div>
        </div>
        <Table striped responsive>
          <thead>
            <tr>
              <th style={{ maxWidth: '30px' }}>{p('handled')}</th>
              <th>{t('shared.company')}</th>
              <th>{t('shared.user')}</th>
              <th>{p('buyDate')}</th>
              <th style={{ textAlign: 'right' }}>{p('totalSum')}</th>
            </tr>
          </thead>
          <tbody>
            { renderBillables() }
          </tbody>
        </Table>
        <Pagination
          items={billables.total}
          pageSize={Number(billables.per_page)}
          onChangePage={changePage}
        />
      </CardBody>
    </Card>
  );
};

export default InvoiceTab;
