const offerCreators = {};

const setOfferCreator = (offerId, creatorName) => {
    if (!offerCreators[offerId]) {
        offerCreators[offerId] = creatorName;
    }
};

const getOfferCreator = (offerId) => {
    if (offerId in offerCreators) {
        return offerCreators[offerId];
    } else {
        return null;
    }
};

export default { setOfferCreator, getOfferCreator };
