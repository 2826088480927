export default {
  club_name: 'Djurgårdens IF',
  club_name_short: 'DIF',
  stadium_name: 'Hovet',
  staging_url: 'https://dif.api.fangroup.io',
  prod_url: 'https://dif.api.fangroup.io',
  api: '/api',
  facebook: 'DJURGARDEN',
  basename: '/dif',
  club_logo: '/assets/teams/dif.png',
  app_name: 'dif',
  websale_name: 'dif',
  ntb_team_id: 604,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'dif',
  monolithEnabled: false,
};
