export default {
  club_name: 'Stjernen Hockey',
  club_name_short: 'STJ',
  stadium_name: 'Stjernehallen',
  staging_url: 'https://stjernen.api.fangroup.io',
  prod_url: 'https://stjernen.api.fangroup.io',
  api: '/api',
  facebook: 'StjernenHockey',
  basename: '/stjernen',
  club_logo: '/assets/teams/stjernen.png',
  app_name: 'stjernen',
  websale_name: 'stj',
  ntb_team_id: 129,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'stjernen',
  monolithEnabled: true,
};
