import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import useConfig from '../../util/useConfig';

const App = () => {
  const [_loading, setLoading] = useState({
    loading: true,
    loaded: false,
    basename: undefined,
  });
  const config = useConfig();
  useEffect(() => {
    setLoading({
      loading: !config?.basename,
      loaded: !!config?.basename,
      basename: config?.basename && config.basename !== _loading.basename ? config.basename : _loading.basename,
    });
  }, [config]);
  const { loading, loaded, basename } = _loading;
  return (
    <div>
      {!loaded &&
      <div className={`load${loading ? '' : ' loaded'}`}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#0469DC" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>
        }
      {basename &&
        <BrowserRouter basename={`${basename}`}>
          <ScrollToTop>
            <div>
              <Router/>
            </div>
          </ScrollToTop>
        </BrowserRouter>
      }
    </div>
  );
};

// class App extends Component {
//   constructor() {
//     super();
//     this.state = {
//       loading: true,
//       loaded: false,
//     };
//   }
//
//   componentDidMount() {
//     window.addEventListener('load', () => {
//       this.setState({ loading: false, loaded: true });
//       // setTimeout(() => this.setState({ loaded: true }), 500);
//     });
//   }
//
//   render() {
//     const { loaded, loading } = this.state;
//     return (
//       <div>
//         {!loaded &&
//         <div className={`load${loading ? '' : ' loaded'}`}>
//           <div className="load__icon-wrap">
//             <svg className="load__icon">
//               <path fill="#0469DC" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
//             </svg>
//           </div>
//         </div>
//         }
//         <div>
//           <Router />
//         </div>
//       </div>
//     );
//   }
// }

export default hot(module)(App);
