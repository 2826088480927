import React, { useState } from 'react';
import PropTypes from 'prop-types';
import t, { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
// import config from '../../../config';

const p = partial('Auction');
const Auction = (props) => {
  const [draggedItem, setDraggedItem] = useState({});
  function onDragStart(e, index) {
    setDraggedItem(props.auctionOrders[index]);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
  }

  function onDragOver(index) {
    const draggedOverItem = props.auctionOrders[index];
    if (draggedItem === draggedOverItem) {
      return;
    }

    const items = props.auctionOrders.filter(item => item !== draggedItem);
    items.splice(index, 0, draggedItem);
    props.setAuctionOrders(items);
  }

  function onDragEnd() {
    setDraggedItem({});
  }

  return (
    <div className="auction">
      <div className="list-auctions">
        <div className="list-auctions-header">
          <h4>{p('allAuctions')}</h4>
          <button onClick={props.saveAuctionOrder} className="primary">{p('saveOrder')}</button>
        </div>
        <div className="list-auctions-body">
          <table>
            <thead>
              <tr>
                <th />
                <th>{t('shared.product')}</th>
                <th>{t('shared.start')}</th>
                <th>{t('shared.end')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <ListAuctions
                auctionOrders={props.auctionOrders}
                getAuctionBids={props.getAuctionBids}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                draggedItem={draggedItem}
              />
            </tbody>
          </table>
        </div>
      </div>
      <div className="list-bids">
        <div className="list-bids-header">
          <h4>{ props.selectedAuctionOrder.id === undefined ? p('selectAuction') : props.selectedAuctionOrder.product && props.selectedAuctionOrder.product.name }</h4>
        </div>
        <div className="list-bids-body">
          { props.auctionBids.length === 0 ?
            <p style={{ textAlign: 'center', marginTop: '10px' }}>{props.selectedAuctionOrder.id !== undefined && p('noBidAuction')}</p>
            :
            <table>
              <thead>
                <tr>
                  <th>{t('shared.date')}</th>
                  <th>{t('shared.user')}</th>
                  <th>{t('shared.phone')}</th>
                  <th>Sum</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <ListBids
                  auctionBids={props.auctionBids}
                  handleModal={props.handleModal}
                />
              </tbody>
            </table>
          }
        </div>
      </div>
    </div>
  );
};

Auction.propTypes = {
  auctionOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getAuctionBids: PropTypes.func.isRequired,
  auctionBids: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleModal: PropTypes.func.isRequired,
  selectedAuctionOrder: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  saveAuctionOrder: PropTypes.func.isRequired,
  setAuctionOrders: PropTypes.func.isRequired,
};

const ListAuctions = (props) => {
  return props.auctionOrders.map((order, i) => {
    return (
      <tr
        key={`ListAuctions-${order.id}`}
        className={order === props.draggedItem ? 'placeholder list-auctions-item' : 'list-auctions-item'}
        draggable
        onDragStart={e => props.onDragStart(e, i)}
        onDragEnd={props.onDragEnd}
        onDragOver={() => props.onDragOver(i)}
      >
        <td>:::</td>
        <td>{order.product && order.product.name}</td>
        <td>{order.start_date_readable}</td>
        <td>{order.end_date_readable}</td>
        <td><button className="primary" onClick={() => props.getAuctionBids(order)}>{p('showBid')}</button></td>
      </tr>
    );
  });
};

ListAuctions.propTypes = {
  auctionOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getAuctionBids: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  draggedItem: PropTypes.shape({}).isRequired,
};

const ListBids = (props) => {
  const config = useConfig();
  return props.auctionBids.map((bid) => {
    return (
      <tr key={`ListBids-${bid.id}`} className="list-bids-item">
        <td>{bid.created_at_readable}</td>
        <td>{bid.user && `${bid.user.firstname} ${bid.user.lastname}`}</td>
        <td>{bid.user && `${bid.user.country_code} ${bid.user.username}`}</td>
        <td>{`${config.currencyShort} ${bid.amount}`}</td>
        <td><button className="danger" onClick={() => props.handleModal(true, bid.auction_id, bid.id)}>{t('shared.delete')}</button></td>
      </tr>
    );
  });
};

ListBids.propTypes = {
  auctionBids: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default Auction;
