import React from 'react';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import PropTypes from 'prop-types';

const SortDirection = ({ active, direction }) => {
  if (!active) {
    return <></>;
  }
  return direction ?
    <ArrowUpIcon size={16} />
    :
    <ArrowDownIcon size={16} />;
};

SortDirection.propTypes = {
  active: PropTypes.bool.isRequired,
  direction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
};

export default SortDirection;
