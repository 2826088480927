const OfferTypes = {
    TargetAudience: {
        partner: 'partner',
        private: 'private',
        both: 'both',
        all: 'all',
    },
    Tiers: {
        allTiers: 'all',
    },
    OfferContact: {
        allTiers: 'all',
        allUsers: 'All users',
        allLevels: 'All levels',
        allGroups: 'All groups',
    },
    CommunicationTypes: {
        push: 'push',
        sms: 'sms',
        email: 'email',
    },
};
export const TargetAudience = {
    partner: 'partner',
    private: 'private',
    both: 'both',
};
export default OfferTypes;
