import {
  SET_ENABLED_FEATURES,
} from '../actions/enabledFeaturesActions';

const initialState = {
  hasFetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ENABLED_FEATURES:
      return {
        ...state,
        ...action.features,
        hasFetched: true,
      };
    default:
      return state;
  }
}
