import { partial } from '../../../util/translation/translation';

const validatePlayerForm = (values) => {
  const p = partial('validateErrors');
  const errors = {};

  if (!values.firstname) {
    errors.firstname = p('firstnameEmpty');
  }

  if (!values.lastname) {
    errors.lastname = p('lastnameEmpty');
  }

  if (values.position && values.position.length > 3) {
    errors.position = p('positionToLong');
  }

  if (values.height && values.height.length > 3) {
    errors.height = p('heightToLong');
  }

  if (values.weight && values.weight.length > 3) {
    errors.weight = p('weightToLong');
  }

  if (!values.jerseyNumber) {
    errors.jerseyNumber = p('shirtNumberEmpty');
  }
  if (!values.shoots) {
    errors.shoots = p('shootSideEmpty');
  }
  /*
  if (!values.nationality) {
    errors.nationality = p('nationalityEmpty');
  }
  */
  if (!values.birthday) {
    errors.birthday = p('dateOfBirthEmpty');
  }
  if (values.image) {
    if (!values?.image?.[0]?.url?.startsWith('http') && !values.crop?.height) {
      errors.crop = p('mustBeCropped');
    }
  }
  return errors;
};

export default validatePlayerForm;
