import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, ButtonToolbar, DropdownItem } from 'reactstrap';
import moment from 'moment';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/custom/Loading';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import RenderMatches from './components/RenderMatches';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';

const MatchesPage = () => {
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [futureMatches, setFutureMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);
  const [modal, setModal] = useState({
    deleteID: null,
    visible: false,
  });
  const p = partial('MatchesPage');
  const s = partial('shared');

  const sortMatches = (a, b) => {
    if (moment(a.match_starts_at) === moment(b.match_starts_at)) { return 0; }
    return moment(a.match_starts_at) > moment(b.match_starts_at) ? 1 : -1;
  };

  const getSeasons = async () => {
    setLoading(true);
    try {
      const resSeasons = await Api.seasons.getSeasons();
      setSeasons(resSeasons.data);
      if (resSeasons.data.length > 0) {
        setSelectedSeason(resSeasons.data[0]);
      } else {
        setLoading(false);
      }
    } catch (err) {
      toast.error(err || p('fetchingSeasonError'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getSeasons();
  }, []);

  const getMatches = async (seasonID = selectedSeason.id) => {
    setLoading(true);
    try {
      const resMatches = await Api.matches.getMatches(seasonID);

      // const futureIds = resMatches.data.future_matches.map(item => item.id);
      // const pastIds = resMatches.data.past_matches.map(item => item.id);
      // const matchIds = [...futureIds, ...pastIds];
      // const { data: universalLinkData } = await Api.matches.getUniversalLinkEnters({ matchIds });
      // resMatches.data.future_matches = resMatches.data.future_matches.map((match) => {
      //   const foundItem = universalLinkData.find(item => Number(item.matchId) === Number(match.id));
      //   return (
      //     {
      //       ...match,
      //       universalLinkEnters: foundItem ? foundItem.universalLinkViews : 0,
      //     }
      //   );
      // });
      // resMatches.data.past_matches = resMatches.data.past_matches.map((match) => {
      //   const foundItem = universalLinkData.find(item => Number(item.matchId) === Number(match.id));
      //   return (
      //     {
      //       ...match,
      //       universalLinkEnters: foundItem ? foundItem.universalLinkViews : 0,
      //     }
      //   );
      // });

      const sortedFutureMatches = resMatches.data.future_matches.sort(sortMatches);
      const sortedPastMatches = resMatches.data.past_matches.sort(sortMatches);
      setFutureMatches(sortedFutureMatches);
      setPastMatches(sortedPastMatches.reverse());
      setLoading(false);
    } catch (err) {
      toast.error(err || p('couldNotFetchMatches'));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSeason && selectedSeason.id) {
      getMatches(selectedSeason.id);
    }
  }, [selectedSeason]);

  const deleteMatch = async () => {
    setLoading(true);
    try {
      await Api.matches.deleteMatch(modal.deleteID);
      toast.success(p('matchDeleted'));
      setModal({
        visible: false,
        deleteID: null,
      });
      getMatches();
    } catch (err) {
      toast.error(err || p('matchDeleteError'));
      getMatches();
    }
  };

  const handleModal = (state, id) => {
    setModal({
      visible: state,
      deleteID: id,
    });
  };

  const listSeasons = () => {
    return seasons.map(season => (
      <DropdownItem
        key={`season-${season.id}`}
        onClick={() => setSelectedSeason(season)}
      >
        {season.name}
      </DropdownItem>
    ));
  };

  return (
    <Container>
      <Loading loading={loading} />
      <DeleteModal
        type="kampen"
        visible={modal.visible}
        handleModal={handleModal}
        modalAction={deleteMatch}
      />
      <Row>
        <Col>
          <p className="page-title">
            {s('matches')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="card__title">
                <div>
                  <h5 className="bold-text">
                    {p('comingMatches')}
                  </h5>
                  <UncontrolledDropdown className="marginRight-15">
                    <DropdownToggle className="icon icon--right" outline>
                      { selectedSeason
                        ? <p>{s('seasonCaps')} {selectedSeason.name}<ChevronDownIcon /></p>
                        : <p>{s('seasonCaps')} -</p>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu">
                      { listSeasons() }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div>
                  <ButtonToolbar className="marginTop-0">
                    <Link className="btn btn-primary products-list__btn-add" to={`/matches/create/${selectedSeason?.ntb_id}`}>
                      {p('createMatch')}
                    </Link>
                  </ButtonToolbar>
                </div>
              </div>
              <RenderMatches
                matches={futureMatches}
                handleModal={handleModal}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <DeleteModal
              type="kampen"
              visible={modal.visible}
              handleModal={handleModal}
              modalAction={deleteMatch}
            />
            <CardBody>
              <div className="card__title marginTop-0">
                <h5 className="bold-text">
                  {p('earlierMatches')}
                </h5>
              </div>
              <RenderMatches
                matches={pastMatches}
                handleModal={handleModal}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MatchesPage;
