import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import useConfig from '../../util/useConfig';
import Api from '../../util/api';

const ListVendors = () => {
  const config = useConfig();
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    const fetchVendorsForTeam = async () => {
      try {
        const { data } = await Api.teams.allVendors();
        setVendors(data);
      } catch (e) {
        toast.error(e);
      }
    };
    if (config) {
      fetchVendorsForTeam();
    }
  }, [config]);

  return (
    <Container>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Vendors</h5>
            <Link to="/vendors/create"><Button size="sm" color="primary" className="flex space-between" style={{ float: 'right' }}>Create Vendor</Button></Link>
          </div>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Payment method</th>
              </tr>
            </thead>
            <tbody>
              {
        vendors.map((vendor) => (
          <tr key={`vendor${vendor.id}`}>
            <td><Link to={`/vendors/edit/${vendor.id}`}>{vendor.name}</Link></td>
            <td>{vendor.paymentMethod}</td>
          </tr>
        ))
      }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};


export default ListVendors;
