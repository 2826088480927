import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, UncontrolledButtonDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { groupBy, uniq } from 'ramda';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import CustomDoughnut from '../../../shared/components/doughnut/CustomDoughnut';

let graphData = {}; // Have to put graphData outside of the component for some reason. Seems like react-charts does not support usestate yet.

const LotteryTurnover = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [redraw, setRedraw] = useState(false); // React-chart-js does not detect the change of graphData and needs a rerender. yes, this is a good solution since it works
  const [totalSales, setTotalSales] = useState({ years: [] });
  const p = partial('LotteryTurnover');

  const getTotalSale = async (y) => {
    try {
      const { data } = await Api.lottery.getTotalSales(y || year);
      setTotalSales({ years: uniq(data.map((e) => e.years)), data: groupBy(e => e.years, data) });
    } catch (err) {
      toast.error(err);
    }
  };
  const getSelectedYear = (y) => {
    getTotalSale(y);
    setYear(y);
  };
  useEffect(() => {
    getTotalSale();
  }, []);

  useEffect(() => {
    if (totalSales.years.length > 0) {
      graphData = {
        total: totalSales.data[year].reduce((t, e) => t + +e.total, 0),
        textSize: '24px roboto',
        labels: totalSales.data[year].map((d) => d.name),
        datasets: [
          {
            label: 'lottery_sales',
            data: totalSales.data[year].map((t) => +t.total),
            backgroundColor: [
              '#F6DA6F',
              '#FF4861',
              '#FF8602',
              '#70BBFD',
              '#BD10E0',
              '#399351',
              '#000000',
              '#D4D4D4',
            ],
          },
        ],
      };
      setRedraw(!redraw);
    }
  }, [totalSales]);

  const renderSaleYear = () => {
    return totalSales.years.map(y => (
      <DropdownItem key={y} onClick={() => getSelectedYear(y)}>{y}</DropdownItem>
    ));
  };

  return (
    <Card style={{ maxHeight: '500px' }}>
      <CardBody>
        <div className="card__title flex-column">
          <h5 className="bold-text" >{p('totalTurnover')}</h5>
          <br />
          <UncontrolledButtonDropdown>
            <DropdownToggle className="reportsButton" color="white" outline caret>
              {year}
            </DropdownToggle>
            <DropdownMenu>
              { renderSaleYear() }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
        { totalSales.years.length !== 0 &&
          <CustomDoughnut graphData={graphData} />
        }
      </CardBody>
    </Card>
  );
};

export default LotteryTurnover;
