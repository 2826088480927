import { partial } from '../../util/translation/translation';

const offerValidations = (values) => {
  const errors = {};
  const o = partial('OfferForm');

  if (!values.title) {
    errors.title = o('titleRequired');
  }

  if (!values.description) {
    errors.description = o('descriptionRequired');
  }

  if (!values.category || values.category === '') {
    errors.category = o('categoryRequired');
  }

  if (!values.startDate) {
    errors.startDate = o('startDateRequired');
  }

  if (!values.endDate) {
    errors.endDate = o('endDateRequired');
  }

  if (!values.imageUrl) {
    errors.imageUrl = o('imageRequired');
  }

  if (values.promoType === 'code' && !values.promoCode) {
    errors.promoCode = o('promoCodeRequired');
  }

  if (values.promoType === 'qrstring' && !values.promoQrString) {
    errors.promoQrString = o('promoQrRequired');
  }

  if (values.promoType === 'imageurl' && (!values.promoImageUrl || (values.promoImageUrl && !values.promoImageUrl.preview))) {
    errors.promoImageUrl = o('imageRequired');
  }

  if (values.promoType === 'link' && !(values.promoLink?.substring(0, 8) === 'https://' || values.promoLink?.substring(0, 7) === 'http://')) {
    errors.promoLink = 'Invalid link URL';
  }

  return errors;
};

export default offerValidations;
