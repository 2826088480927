export default {
  club_name: 'Lunds BK',
  club_name_short: 'lundsbk',
  stadium_name: '',
  staging_url: 'https://lundsbk.api.fangroup.io',
  prod_url: 'https://lundsbk.api.fangroup.io',
  api: '/api',
  facebook: '',
  basename: '/lundsbk',
  club_logo: '/assets/teams/lundsbk.png',
  app_name: 'lundsbk',
  websale_name: 'lundsbk',
  ntb_team_id: 10050,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'lundsbk',
  monolithEnabled: false,
};
