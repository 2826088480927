import React, { useEffect, useState } from 'react';
import {
  Container, Col, Card, CardBody, Table, Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/custom/Loading';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import { formatDate } from '../../util/date-and-time';

const SeasonsPage = () => {
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const p = partial('seasonObjectsShared');
  const s = partial('shared');

  const getSeasons = async () => {
    setLoading(true);
    let resSeasons;
    try {
      resSeasons = await Api.seasons.getSeasons();
    } catch (err) {
      toast.error(err || p('wrongWhenFetchingSeasons'));
      return;
    }
    setSeasons(resSeasons.data);
    setLoading(false);
  };

  useEffect(() => {
    getSeasons();
  }, []);

  const listSeasons = () => {
    return seasons.map(season => (
      <tr key={`ListSeasons-${season.id}`}>
        <td><Link to={`seasons/edit/${season.id}`}>{season.name}</Link></td>
        <td>{formatDate(season.start_date)} - {formatDate(season.end_date)}</td>
        <td>{formatDate(season.ticket_sale_start)} - {formatDate(season.ticket_sale_end)}</td>
      </tr>
    ));
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <p className="page-title">{s('seasons')}</p>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <div className="flex space-between" style={{ float: 'right' }}>
              <Link to="/seasons/create"><Button size="sm" color="primary">{p('createSeason')}</Button></Link>
            </div>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>{s('season')}</th>
                  <th>{p('seasonPeriod')}</th>
                  <th>{p('salePeriod')}</th>
                </tr>
              </thead>
              <tbody>
                { listSeasons() }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default SeasonsPage;
