import React from 'react';
import PropTypes from 'prop-types';
import CheckboxMarkedCircle from 'mdi-react/CheckboxMarkedCircleIcon';
import CloseCircle from 'mdi-react/CloseCircleIcon';

const CheckIcon = ({ state = false }) => {
  if (state === true) {
    return <CheckboxMarkedCircle size={12} className="icon-min-20" color="#399351" />;
  }
  return <CloseCircle size={12} className="icon-min-20" color="#FF4861" />;
};

CheckIcon.propTypes = {
  state: PropTypes.bool.isRequired,
};

export default CheckIcon;
