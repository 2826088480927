import React, { useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Field, reduxForm, propTypes as reduxFormProps } from 'redux-form';
import moment from 'moment';
import validateTokenPackageForm from './validateBalancePackageForm';
import renderField from '../../../shared/components/custom/Field';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import { partial } from '../../../util/translation/translation';

const p = partial('balancePackageShared');
const s = partial('shared');
const BalancePackageForm = ({
  balancePackage, handleSubmit, initialize, type,
}) => {
  useEffect(() => {
    if (balancePackage.package && balancePackage.package.id) {
      initialize({
        name: balancePackage.package.name,
        sale_start: moment(balancePackage.package.sale_start).format('YYYY-MM-DDTHH:mm'),
        sale_end: balancePackage.package.sale_end ? moment(balancePackage.package.sale_end).format('YYYY-MM-DDTHH:mm') : '',
        price: balancePackage.package.price,
        balance: balancePackage.saldo,
        description: balancePackage.package.description,
        image: balancePackage.image_url ? [{ preview: balancePackage.image_url }] : [],
      });
    }
  }, [balancePackage]);

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <>
          <Col md={12} lg={8}>
            <Row>
              <Col md={12}>
                <div className="form__form-group">
                  <span className="form-header">{s('name')}</span>
                  <div className="marginTop-5" />
                  <Field
                    name="name"
                    component={renderField}
                    placeholder={p('balancePackageName')}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('salesStart')}</span>
                  <div className="marginTop-5" />
                  <Field
                    name="sale_start"
                    component={renderField}
                    type="datetime-local"
                    placeholder={p('availableFrom')}
                  />
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('salesEnd')}</span>
                  <div className="marginTop-5" />
                  <Field
                    name="sale_end"
                    component={renderField}
                    type="datetime-local"
                    placeholder={p('availableTo')}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span className="form-header marginRight-8">
                    {s('price')}
                  </span>
                  <TooltipWithIcon
                    id="balance-price"
                    text={p('determinesPackagePrice')}
                  />
                  <div className="marginTop-5" />
                  <Field
                    name="price"
                    component={renderField}
                    type="number"
                    placeholder={p('balancePackagePrice')}
                  />
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span className="form-header marginRight-8">
                    {s('balance')}
                  </span>
                  <TooltipWithIcon
                    id="balance-balance"
                    text={p('determinesBalanceRefill')}
                  />
                  <div className="marginTop-5" />
                  <Field
                    name="balance"
                    component={renderField}
                    type="number"
                    placeholder={s('balance')}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={4}>
            <div className="form__form-group">
              <span className="form-header">{s('image')}</span>
              <div className="marginTop-5" />
              <Field
                name="image"
                component={renderDropZoneField}
              />
            </div>
          </Col>
          <Col md={12} style={{ marginTop: '10px' }}>
            <Button color="primary">{type === 'create' ? `${s('create')}` : `${s('save')}`}</Button>
            <Link to="/balances"><Button color="secondary">{s('close')}</Button></Link>
          </Col>
        </>
      </form>
    </>
  );
};

BalancePackageForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
  balancePackage: PropTypes.shape({
    package: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      sale_start: PropTypes.string,
      sale_end: PropTypes.string,
      description: PropTypes.string,
    }),
    saldo: PropTypes.number,
    end_date: PropTypes.string,
    season: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    price: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    image_url: PropTypes.string,
  }),
};

BalancePackageForm.defaultProps = {
  type: 'create',
  balancePackage: {},
};

const reduxF = reduxForm({
  form: 'balance_package_form',
  validate: validateTokenPackageForm,
});

export default withRouter(reduxF(BalancePackageForm));
