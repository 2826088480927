import React, { memo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { partial } from '../../../../util/translation/translation';

const DoughnutChart = ({ chartData, legendPosition = 'bottom', legendVisibility = true }) => {
    const rT = partial('Reports');
    const chartRef = useRef(null);

    const calculateCenterTotal = (chart) => {
        let total = 0;
        const { datasets } = chart.data;
        const meta = chart.getDatasetMeta(0);

        datasets[0].data.forEach((value, index) => {
          if (!meta.data[index].hidden) {
            total += value;
          }
        });

        return total;
    };

    const legendOptions = chartRef.current ? {
        labels: {
            boxWidth: 14,
        },
        display: legendVisibility,
        position: legendPosition,
        onClick: (_e, item) => {
            const chart = chartRef.current;
            for (let i = 0, iLength = (chart.props.data.datasets || []).length; i < iLength; ++i) {
                const meta = chart.chartInstance.chart.getDatasetMeta(i);
                if (meta.data[item.index]) {
                    meta.data[item.index].hidden = !meta.data[item.index].hidden;
                }
            }
            chart.chartInstance.chart.update();
        },
    } : {
        labels: {
            boxWidth: 14,
        },
        display: legendVisibility,
        position: legendPosition,
    };

    const chartOptions = {
        responsive: true,
        legend: legendOptions,
        maintainAspectRatio: false,
    };

    const afterDraw = () => {
        const { chart, ctx } = chartRef.current.chartInstance;
        const chartCenterX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const chartCenterY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '700 22px Inter';
        ctx.fillStyle = '#4D576A';
        ctx.fillText(calculateCenterTotal(chart), chartCenterX, chartCenterY - 5);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '14px Inter';
        ctx.fillStyle = '#98A0AE';
        ctx.fillText(rT('total'), chartCenterX, chartCenterY + 15);
      };

    return (
      <div style={{ height: 280 }}>
        <Doughnut ref={chartRef} width={200} plugins={{ afterDraw }} data={chartData} options={chartOptions} />
      </div>
    );
};

DoughnutChart.defaultProps = {
    legendPosition: 'bottom',
    legendVisibility: true,
};

DoughnutChart.propTypes = {
    chartData: PropTypes.shape({
        lables: PropTypes.arrayOf(PropTypes.string),
        datasets: PropTypes.arrayOf(PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.number),
            backgroundColor: PropTypes.arrayOf(PropTypes.string),
        })),
    }).isRequired,
    legendPosition: PropTypes.string,
    legendVisibility: PropTypes.bool,
};

export default memo(DoughnutChart);
