import { useState, useEffect, memo, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import Api from '../../../util/api';
import OfferCache from '../cache';

const Creator = ({ offerId, companyId }) => {
    const [name, setName] = useState('');

    const { data } = useQuery({ queryKey: ['get-company-by-id'], enabled: !!companyId, queryFn: () => Api.companies.getCompany(companyId) });

    const fetchCompany = useCallback(async () => {
        const creator = OfferCache.getOfferCreator(offerId);
        if (creator) {
            setName(creator);
        } else if (companyId && data) {
            setName(data.name);
            OfferCache.setOfferCreator(offerId, data.name);
        } else {
            setName('Club');
            OfferCache.setOfferCreator(offerId, 'Club');
        }
    }, [companyId]);

    useEffect(() => {
        fetchCompany();
    }, []);

    return name;
};
export default memo(Creator);
