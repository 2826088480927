export default {
  club_name: 'Södertälje Basketbollklubb',
  club_name_short: 'SBBK',
  stadium_name: 'Täljehallen',
  staging_url: 'https://sodertalje.api.fangroup.io',
  prod_url: 'https://sodertalje.api.fangroup.io',
  api: '/api',
  facebook: 'Sodertaljebasketbollklubb1',
  basename: '/sodertalje',
  club_logo: '/assets/teams/sodertalje.png',
  app_name: 'sodertalje',
  websale_name: 'sodertalje',
  ntb_team_id: 10018,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: false,
  leagueSportalityAvailable: false,
  cloudIdName: 'sodertalje',
  monolithEnabled: false,
};
