import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardBody, Container } from 'reactstrap';
import MatchForm from './components/MatchForm';
import Api from '../../util/api';

const MatchAdminCreatePage = () => {
  const { matchId } = useParams();
  const [match, setMatch] = useState(undefined);
  const [teams, setTeams] = useState([]);

  const fetchMatch = async id => {
    try {
      const { data } = await Api.clubadmin.match.fetch(id);
      const { data: _teams } = await Api.clubadmin.teams.getAssosciatedTeams(id);
      setMatch(data);
      setTeams(_teams);
    } catch (e) {
      toast.error(e);
    }
  };
  useEffect(() => {
    if (matchId) {
      fetchMatch(matchId);
    }
  }, [matchId]);


  return match ? (
    <Container>
      <Card>
        <CardBody>
          <MatchForm match={match} teams={teams} />
        </CardBody>
      </Card>
    </Container>
  ) : null;
};

export default MatchAdminCreatePage;
