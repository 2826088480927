import React, { useState } from 'react';
import { Alert, Button, Col, Row, Table } from 'reactstrap';
import CheckBox from '../../../shared/components/custom/Checkbox';

const UpdateSeasonTickets = ({ handleModal, handle, seasonTickets = [] }) => {
  const [selectedIds, setSelectedIds] = useState(seasonTickets.map(st => st.id));
  const toggle = id => () => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(e => e !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  return (
    <div>
      <Row>
        <Col>
          <Alert color="info">
            <h3 className="alert-heading alert-info">Frigitte seter har sesongkort-tilknytning</h3>
            Noen av dine frigjorte seter har sesongkort på setene, vil du tildele kampbillett på setet til sesongkortholderene?
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th />
                <th>Bruker</th>
                <th>Prisgruppe</th>
                <th>Felt</th>
                <th>Rad</th>
                <th>Sete</th>
              </tr>
            </thead>
            <tbody>
              {
                            seasonTickets?.map(seasonTicket => (
                              <tr key={seasonTicket.id}>
                                <td>
                                  <CheckBox index={-1} toggled={selectedIds.includes(seasonTicket.id)} handleToggle={toggle(seasonTicket.id)} />
                                </td>
                                <td>{`${seasonTicket.user.firstname} ${seasonTicket.user.lastname}`}</td>
                                <td>{seasonTicket.season_price.title}</td>
                                <td>{seasonTicket.seat.row.field.name}</td>
                                <td>{seasonTicket.seat.row.number}</td>
                                <td>{seasonTicket.seat.seat_id}</td>
                              </tr>
                            ))
                        }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="marginTop-5">
        <Col>
          <Button color="success" onClick={() => handle(selectedIds)}>Tildel</Button>
          <Button color="danger" onClick={handleModal}>Ikke Tildel</Button>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateSeasonTickets;
