import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateTileForm = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('nameFieldEmpty');
  }
  if (!values.headingImage) {
    errors.headingImage = p('picFieldCantBeEmpty');
  }
  if (!values.subtitle) {
    errors.subtitle = p('subTitleFieldCantBeEmpty');
  }
  if (!values.endDate) {
    errors.endDate = p('endDateFieldCantBeEmpty');
  }
  if (!values.startDate) {
    errors.startDate = p('startDateFieldCantBeEmpty');
  }
  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }

  return errors;
};
export default validateTileForm;
