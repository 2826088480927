import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Col, Container, Row, CardBody, Card, TabPane, TabContent } from 'reactstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import SpotlightGroupForm from './components/SpotlightGroupForm';
import t, { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import TileListPage from './TileListPage';

const SpotlightGroupFormPage = () => {
  const history = useHistory();
  const [entity, setEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const { entityID } = useParams();
  const p = partial('HighlightBannerFormPage');
  const s = partial('shared');
  const getData = async () => {
    setLoading(true);
    try {
      const groupData = await Api.spotlight.getGroupById(entityID);
      setEntity(groupData);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entityID) {
      getData();
    }
  }, [entityID]);
  const onSubmit = async (values) => {
    const payload = {
      title: values.title,
      format: values.format || 'CIRCLE',
      scrollable: true,
      size: values.size,
      descriptionAvailable: values?.format === 'TEXT_BANNER' ? true : (values.descriptionAvailable === 'true'),
      titleVisible: !values.hideTitle,
    };
    setLoading(true);
    try {
      let resp;
      if (entityID) {
        await Api.spotlight.updateGroup(entityID, payload);
        toast.success(p('slideUpdated'));
      } else {
        resp = await Api.spotlight.createGroup(payload);
        toast.success(p('slideCreated'));
      }
      setLoading(false);
      history.replace(`/spotlight/group/edit/${entityID || resp.data.id}?tab=2`);
    } catch (err) {
      if (err?.data?.message === 'group.groupExists') {
        toast.error(t('ErrorMessage.nameAlereadyExists'));
      }
      toast.error(err);
      setLoading(false);
    }
  };
  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{s('spotlight')}</h3>
        </Col>
        {entityID &&
          <Col>
            <Link className="btn btn-primary products-list__btn-add float-right" to={`/spotlight/group/edit/${entityID}/tile/create`}>{t('HighlightBannerPage.createTile')}</Link>
          </Col>
        }
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: 0, borderRadius: 6 }}>
              <NavTab
                tabs={[
                { tab: s('details') },
                { tab: p('tiles'), disabled: !entityID },
              ]}
                style={{ marginBottom: 0 }}
                setTab={setActiveTab}
                activeTab={activeTab}
                size="sm"
              />
              <div className="tabs tabs--justify tabs--bordered-top overflow-unset px-3 pb-3">
                <div className="tabs__wrap">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <SpotlightGroupForm
                        entity={entity}
                        onSubmit={onSubmit}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <TileListPage
                        groupId={entityID}
                        setLoading={setLoading}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SpotlightGroupFormPage;
