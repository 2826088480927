import React, { useEffect, useState } from 'react';
import {
  Card, CardBody, Table, Col, ButtonToolbar,
  UncontrolledDropdown, DropdownToggle, Button,
  DropdownMenu, DropdownItem, UncontrolledTooltip,
} from 'reactstrap';
import TooltipTextIcon from 'mdi-react/TooltipTextIcon';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { toast } from 'react-toastify';
import Pagination from '../../../shared/components/pagination/Pagination';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import Loading from '../../../shared/components/custom/Loading';
import Api from '../../../util/api';
import SortDirection from '../../../shared/components/custom/SortDirection';
import { partial } from '../../../util/translation/translation';
// import config from '../../../config';
import useConfig from '../../../util/useConfig';

const s = partial('shared');
const p = partial('product&CategoryShare');

const KioskSettings = () => {
  const config = useConfig();
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState({
    total: 0, current_page: 1, per_page: 10, data: [],
  });
  const [orderBy, setOrderBy] = useState('name');
  const [reverse, setReverse] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ type: '', id: null });
  const [searchText, setSearchText] = useState('');
  const [categoryFilter, setCategoryFilter] = useState(null);

  const getProductCategories = async (doLoading) => {
    try {
      if (doLoading) {
        setLoading(true);
      }
      const resProductCategories = await Api.cnc.getProductCategories();
      setProductCategories(resProductCategories.data);
      if (doLoading) {
        setLoading(false);
      }
    } catch (err) {
      toast.error(`${p('productCategoryFetchingERROR')}!`);
      setLoading(false);
    }
  };

  const getProducts = async (page = products.current_page, pageSize = products.per_page) => {
    setLoading(true);
    try {
      getProductCategories(false);
      const { data: resProducts } = await Api.cnc.getProducts(page, pageSize, orderBy, reverse, searchText, categoryFilter?.id ?? '');
      // const productIds = resProducts.data.map(prod => prod.id);
      // const { data: analyticsData } = await Api.cnc.getProductsAnalyticsData({ productIds });
      // const combinedData = resProducts.data.map(prod => (
      //   {
      //     ...prod,
      //     ...analyticsData[`${prod.id}`],
      //   }
      // ));
      // const combinedProducts = {
      //   ...resProducts,
      //   data: combinedData,
      // };
      setProducts(resProducts);
      setLoading(false);
    } catch (err) {
      toast.error(err || `${p('productFetchingERROR')}!`);
      setLoading(false);
    }
  };

  const sortOrder = type => () => {
    if (type === orderBy) {
      setReverse(Number(!reverse));
    } else {
      setOrderBy(type);
      setReverse(0);
    }
  };

  const handleDelete = async () => {
    if (!deleteItem.id) { return; }
    switch (deleteItem.type) {
      case `${s('theProduct')}`:
        try {
          setLoading(true);
          await Api.cnc.deleteProduct(deleteItem.id);
          toast.success(`${p('productDeleted')}`);
          setVisible(false);
          setDeleteItem({ type: '', id: null });
          setLoading(false);
          getProducts(1);
        } catch (err) {
          toast.error(err || `${p('productDeletingERROR')}`);
          setLoading(false);
          getProducts(1);
        }
        break;
      case `${s('theCategory')}`:
        setLoading(true);
        try {
          await Api.cnc.deleteCategory(deleteItem.id);
          toast.success(`${p('categoryDeleted')}`);
          setVisible(false);
          setDeleteItem({ type: '', id: null });
          getProductCategories(true);
        } catch (err) {
          toast.error(err || `${p('categoryDeletingERROR')}`);
          setVisible(false);
          setDeleteItem({ type: '', id: null, removeWarning: undefined });
          getProductCategories(true);
        }
        break;
      default:
        break;
    }
  };

  const openModal = (type, id, removeWarning) => {
    if (type !== false) {
      setDeleteItem({ type, id, removeWarning });
      setVisible(true);
    } else {
      setDeleteItem({ type: '', id: null });
      setVisible(false);
    }
  };

  const renderProducts = () => {
    return products.data.map(product => (
      <tr className="center-list" key={`RenderProducts-${product.id}`}>
        <td>{product.image ? <img style={{ height: '30px' }} src={product.image.url} alt={product.name} /> : '-'}</td>
        <td>{product.name ? <Link to={`/cnc/products/edit/${product.id}`}>{product.name}</Link> : '-'}</td>
        <td>{product.subtitle ? product.subtitle : '-' }</td>
        <td>{product.category ? product.category.name : '-' }</td>
        <td>{product.price ? `${config.currencyShort} ${product.price}` : '-' }</td>
        {/* <td>{product.views ? product.views : 0 }</td>
        <td>{product.adds ? product.adds : 0 }</td>
        <td>{product.buys ? `${config.currencyShort} ${product.buys.toFixed(2)}` : '-' }</td> */}
        <td>{product.comment ?
          <>
            <TooltipTextIcon id={`Product-tooltip-${product.id}`} color="#555555" />
            <UncontrolledTooltip fade={false} placement="top" target={`Product-tooltip-${product.id}`}>
              {product.comment}
            </UncontrolledTooltip>
          </>
          :
          '-'
        }
        </td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <Link to={`/cnc/products/edit/${product.id}`}>
                <DropdownItem>{s('edit')}</DropdownItem>
              </Link>
              <DropdownItem
                className="danger"
                onClick={() => openModal(`${s('theProduct')}`, product.id)}
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };

  const onChangePage = (pager) => {
    if (pager.pageSize !== products.per_page || pager.currentPage !== products.current_page) {
      getProducts(pager.currentPage, pager.pageSize);
    }
  };

  useEffect(() => {
    getProducts();
  }, [orderBy, reverse, categoryFilter]);

  return (
    <Col md={12}>
      <Loading loading={loading} />
      <DeleteModal
        visible={visible}
        type={deleteItem.type}
        removeWarning={deleteItem.removeWarning}
        handleModal={openModal}
        modalAction={handleDelete}
      />
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <div>
              <h5 className="bold-text">{s('products')}</h5>
              <UncontrolledDropdown>
                <DropdownToggle outline>
                  <p>{s('show')} {categoryFilter?.name ?? `${s('all')}`}</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem onClick={() => setCategoryFilter(null)}>{s('all')}</DropdownItem>
                  {productCategories.map(category => (
                    <DropdownItem onClick={() => setCategoryFilter(category)} key={`category-filter-${category.id}`}>{category.name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <ButtonToolbar className="margin-top-0">
              <div className="form">
                <div className="form__form-group products-list__search">
                  <input
                    placeholder={p('searchProduct')}
                    value={searchText}
                    onKeyPress={event => event.key === 'Enter' && getProducts(1)}
                    onChange={event => setSearchText(event.target.value)}
                    name="search"
                  />
                  <MagnifyIcon />
                </div>
              </div>
              <Link to="/cnc/products/create"><Button color="primary" size="sm">{p('createProduct')}</Button></Link>
            </ButtonToolbar>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th className="sortable" onClick={sortOrder('image_id')}>
                  {s('image')}
                  <SortDirection active={orderBy === 'image_id'} direction={reverse} />
                </th>
                <th className="sortable" onClick={sortOrder('name')}>
                  {s('name')}
                  <SortDirection active={orderBy === 'name'} direction={reverse} />
                </th>
                <th className="sortable" onClick={sortOrder('subtitle')}>
                  {s('additionalInfo')}
                  <SortDirection active={orderBy === 'subtitle'} direction={reverse} />
                </th>
                <th className="sortable" onClick={sortOrder('category_id')}>
                  {s('category')}
                  <SortDirection active={orderBy === 'category_id'} direction={reverse} />
                </th>
                <th className="sortable" onClick={sortOrder('price')}>
                  {s('price')}
                  <SortDirection active={orderBy === 'price'} direction={reverse} />
                </th>
                {/* <th className="sortable">
                  {s('views')}
                </th>
                <th className="sortable">
                  {p('cartAdds')}
                </th>
                <th className="sortable">
                  {p('income')}
                </th> */}
                <th className="sortable" onClick={sortOrder('comment')}>
                  {s('comment')}
                  <SortDirection active={orderBy === 'comment'} direction={reverse} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {renderProducts()}
            </tbody>
          </Table>
          <Pagination
            items={products.total}
            pageSize={10}
            onChangePage={onChangePage}
          />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="card__title marginTop-0">
            <h5 className="bold-text">{p('productCategories')}</h5>
            <Link className="btn btn-primary products-list__btn-add" to="/cnc/categories/create">{p('createCategory')}</Link>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{s('name')}</th>
                <th>{s('products')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <RenderProductCategories productCategories={productCategories} openModal={openModal} />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

const RenderProductCategories = props => props.productCategories.map(category => (
  <tr key={`RenderProductCategories-${category.id}`}>
    <td><Link to={`/cnc/categories/edit/${category.id}`}>{category.name}</Link></td>
    <td>{category.products_count}</td>
    <td>
      <UncontrolledDropdown className="dashboard__table-more">
        <DropdownToggle>
          <DotsHorizontalIcon />
        </DropdownToggle>
        <DropdownMenu className="dropdown__menu">
          <Link to={`/cnc/categories/edit/${category.id}`}>
            <DropdownItem>{s('edit')}</DropdownItem>
          </Link>
          <DropdownItem
            className="danger"
            onClick={() => props.openModal(`${s('theCategory')}`, category.id, p('deleteCategoryDescription'))}
          >
            {s('delete')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </td>
  </tr>
));

export default KioskSettings;

