import React from 'react';
import { Button, Modal } from 'reactstrap';
import CheckCircleOutline from 'mdi-react/CheckCircleOutlineIcon';
import PropTypes from 'prop-types';
import { partial } from '../../../util/translation/translation';

const p = partial('LinkModal');
const s = partial('shared');
const LinkModal = (props) => {
  return (
    <Modal
      isOpen={props.visible}
      toggle={() => {
        props.handleModal(false);
      }}
    >
      <div className="modal">
        <div className="modal-container">
          <div className="modal-icon">
            <CheckCircleOutline size="128" color="#007bff" />
          </div>
          <div className="modal-text marginBottom-1">
            <h3>{p('sureLink')}</h3>
          </div>
          <div className="modal-buttons">
            <Button
              className="marginBottom-0"
              color="primary"
              onClick={() => {
                props.modalAction();
              }}
            >
              {p('connect')}
            </Button>{' '}
            <Button
              className="marginBottom-0"
              color="secondary"
              onClick={() => {
                props.handleModal(false);
              }}
            >
              {s('close')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

LinkModal.defaultProps = {
  modalAction: null,
};
LinkModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  modalAction: PropTypes.func,
};

export default LinkModal;
