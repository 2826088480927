export default {
  club_name: 'Lillehammer',
  club_name_short: 'LIK',
  stadium_name: 'Eidsiva Arena',
  staging_url: 'https://lik.api.fangroup.io',
  prod_url: 'https://lik.api.fangroup.io',
  api: '/api',
  facebook: 'lillehammerhockey',
  basename: '/lik',
  club_logo: '/assets/teams/lik.png',
  app_name: 'lik',
  websale_name: 'lik',
  ntb_team_id: 131,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: false,
  cloudIdName: 'lik',
  monolithEnabled: false,
};
