import React, { useEffect, useState } from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Button } from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import useModal from '../../../shared/hooks/useModal';
import Api from '../../../util/api';
import RenderFileInputField from '../../../shared/components/form/FileInput';

const SegmentUserListPage = ({
    segmentId, setLoading,
}) => {
  const [handleModal, visible, deleteObject] = useModal();
  const [csvUsers, setCsvUsers] = useState({});
  const [users, setUsers] = useState([]);
  const s = partial('shared');
  const p = partial('HighlightBannerPage');
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const userData = await Api.spotlight.getUsersForSegment(segmentId);
      const { data: usersByIds } = await Api.users.getUsersByCloudIds(userData);
      setUsers(usersByIds);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (segmentId) {
        fetchUsers();
    }
  }, [segmentId]);
  const handleDeleteSegmentUser = async () => {
    setLoading(true);
    try {
      await Api.spotlight.deleteUserFromSegment(deleteObject.segmentId, deleteObject.userId);
      handleModal();
      fetchUsers();
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const handleUploadCsv = async () => {
    setLoading(true);
    try {
      const { added, removed } = await Api.spotlight.importSegmentUsers(segmentId, csvUsers.file);
      toast.success(p('importUsersPopup', { added, removed }));
      fetchUsers();
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const renderUsers = () => {
    return users.map((user, i) => (
      <tr
        style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }}
        key={`user-${user.userCloudId}`}
      >
        <td>{user.firstname}</td>
        <td>{user.lastname}</td>
        <td>{user.phoneNumber}</td>
        <td>
          <UncontrolledDropdown className="dashboard__table-more">
            <DropdownToggle>
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <DropdownItem
                onClick={() => handleModal({ segmentId, userId: user.userCloudId })}
                className="danger"
              >
                {s('delete')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    ));
  };


  return (
    <>
      <DeleteModal
        visible={visible}
        handleModal={handleModal}
        type={s('user')}
        modalAction={handleDeleteSegmentUser}
      />
      <Row>
        <Col md="3">
          <h3 className="page-title">{s('upload')} {s('users')}</h3>
        </Col>
        <Col md="3">
          <RenderFileInputField
            meta={{}}
            input={{
                name: 'csvUsers',
                value: csvUsers,
                onChange: setCsvUsers,
              }}
          />
        </Col>
        <Col md="3">
          <Button
            color="primary"
            disabled={!Object.keys(csvUsers).length}
            onClick={handleUploadCsv}
          >
            {s('upload')}
          </Button>

        </Col>
      </Row>
      <div style={{ display: 'flex', paddingBottom: 5, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
        <Table responsive striped >
          <thead>
            <tr style={{ color: '#4D576A' }}>
              <th className="bold">
                <div className="flex alignItems-center">{s('firstname')}</div>
              </th>
              <th className="bold" style={{ color: '#4D576A' }}>{s('lastname')}</th>
              <th className="bold" style={{ color: '#4D576A' }}>{s('phone')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {renderUsers()}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default SegmentUserListPage;
