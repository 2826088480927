import React from 'react';
import { Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonToolbar } from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DragIcon from 'mdi-react/DragIcon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import t, { partial } from '../../../util/translation/translation';

const NewsCategoryList = ({
    categoryList, handleModal, handleOrder,
}) => {
  const s = partial('shared');
  const renderCategories = () => {
    return categoryList.map((category, index) => (
      <Draggable
        key={`category-${category.id}`}
        draggableId={`category-${category.id}`}
        index={index}
      >
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={`category-${category.id}`}
            className={snapshot.isDragging ? 'rbdnd-dragging' : ''}
          >
            <td><DragIcon color="#999999" /></td>
            <td><Link to={`/news_category/edit/${category.id}`}>{category.name}</Link></td>
            <td>
              <UncontrolledDropdown className="dashboard__table-more">
                <DropdownToggle>
                  <DotsHorizontalIcon />
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <Link to={`/news_category/edit/${category.id}`}>
                    <DropdownItem>{s('edit')}</DropdownItem>
                  </Link>
                  <DropdownItem
                    onClick={() => handleModal(true, category.id, 'news_category')}
                    className="danger"
                  >
                    {s('delete')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
      )}
      </Draggable>
    ));
  };
  const categoryOnDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const draggedCategory = categoryList[source.index];
    const newCategories = [...categoryList];
    newCategories.splice(source.index, 1);
    newCategories.splice(destination.index, 0, draggedCategory);
    handleOrder(newCategories);
  };
  return (
    <div className="mt-4">
      <div className="flex space-between">
        <div>
          <h3 className="page-title mb-0">{t('shared.newsCategory')}</h3>
        </div>
        <div>
          <ButtonToolbar className="margin-top-0">
            <Link className="btn btn-primary products-list__btn-add" to="/news_category/create">{s('create')} {s('category')}</Link>
          </ButtonToolbar>
        </div>
      </div>
      <div style={{ display: 'flex', paddingBottom: 5, marginBottom: 10, background: '#fff', borderRadius: 6, boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)' }}>
        <DragDropContext onDragEnd={categoryOnDragEnd}>
          <Table responsive striped>
            <thead>
              <tr>
                <th>#</th>
                <th >
                  <div className="flex alignItems-center">{s('name')}</div>
                </th>
                <th />
              </tr>
            </thead>
            <Droppable droppableId="category-dnd">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {renderCategories()}
                  {provided.placeholder}
                </tbody>
                      )}
            </Droppable>
          </Table>
        </DragDropContext>
      </div>
    </div>
  );
};



export default NewsCategoryList;
