import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Api from '../../util/api';
import ClubForm from './components/ClubForm';
import Loading from '../../shared/components/custom/Loading';
import { formatDateTime } from '../../util/date-and-time';

const ClubAdminCreatePage = () => {
  const [loading, setLoading] = useState(false);
  const [club, setClub] = useState(undefined);
  const [season, setSeason] = useState(undefined);
  const [ntbStages, setNtbStages] = useState([]);

  const { clubId } = useParams();
  const history = useHistory();
  const handleSubmit = (cb) => values => cb(values);
  const onSubmit = async (values) => {
    const newSeasonValue = values.config.seasonId.value;
    // eslint-disable-next-line no-param-reassign
    delete values.config.seasonId;
    const payload = clubId ? {
      id: clubId,
      ...values,
      config: {
        ...values.config,
        club_logo: values.config.club_logo[0]?.value,
        isoCountry: values.config.isoCountry.value,
        featureFlags: values.config.featureFlags.map((ff) => ff.value),
      },
      teams: values.teams.map(team => team.value),
    } : {
      ...values,
      config: {
        ...values.config,
        club_logo: values.config.club_logo[0]?.value,
        isoCountry: values.config.isoCountry.value,
        featureFlags: values.config.featureFlags.map((ff) => ff.value),
      },
      teams: values.teams.map(team => team.value),
    };
    setLoading(true);
    try {
      const { data } = clubId ? await Api.clubadmin.update(payload, clubId) : await Api.clubadmin.create(payload);
      if (season) {
        await Api.club.editSetting({
          id: season.id,
          key: 'seasonId',
          value: newSeasonValue,
        }, clubId);
      } else {
        await Api.club.createSetting({
          key: 'seasonId',
          value: newSeasonValue,
        }, clubId);
      }
      setClub(data);
      history.replace(`/clubadmin/edit/${data.id}`);
    } catch (e) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchClub = async () => {
      try {
        const [{ data: _club }, resSeason, resNtbStages] = await Promise.all([Api.clubadmin.get(clubId), Api.club.getSettingsByKey('seasonId', clubId), Api.clubadmin.seasons.all()]);
        const mapStages = ntb => ({
          label: `${formatDateTime(ntb.seasonStart)} - ${formatDateTime(ntb.seasonEnd)}`,
          value: `${ntb.id.toString()}`,
        });
        setNtbStages(resNtbStages.data.map(mapStages));
        setClub(_club);
        setSeason(resSeason.data[0]);
      } catch (e) {
        toast.error(e);
      }
    };
    if (clubId) {
      fetchClub();
    }
  }, [clubId]);

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{clubId ? 'Edit' : 'Create'} Club</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <ClubForm club={club} season={season} submitter={onSubmit} onSubmit={handleSubmit} ntbStages={ntbStages} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ClubAdminCreatePage;
