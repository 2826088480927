import React, { useMemo } from 'react';
import { Col, Button, Card, CardBody, CardText, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import t, { partial } from '../../util/translation/translation';
import { getTitleAndBody, getOriginLink } from '../../shared/components/messageList/utils';

const PushData = () => {
  const { state: messageState } = useLocation();
  const { title, body } = getTitleAndBody(messageState);
  const { success_count, opened_count, openedPercentage, labels } = messageState.mappedPushStat;
  const { title: linkTitle, link } = getOriginLink(messageState);
  const chartData = useMemo(() => {
    return {
      labels: labels.map(label => moment(label.date).format('DD.MM. HH')),
      datasets: [
        {
          label: 'Opened',
          data: labels.map(ds => ds.views),
          backgroundColor: '#4ce1b666',
          borderColor: '#4ce1b6',
        },
      ],
    };
  }, [labels]);
  const s = partial('shared');
  const DoubleRowName = ({ row1, row2 }) => {
    return (
      <>
        <CardText className="bold-text"> {row1}</CardText>
        <CardText style={{ marginTop: '0px' }}>{row2}</CardText>
      </>
    );
  };

  return (
    <Card>
      <CardBody>
        <div className="flex">
          <Col lg={3} md={12}>
            <div className="card__title">
              <h4 className="bold-text">{s('message')}</h4>
            </div>
            <DoubleRowName row1={t('shared.title')} row2={title}/>
            <DoubleRowName row1={t('shared.message')} row2={body}/>
            <DoubleRowName row1={t('InsightPage.sent').toUpperCase()} row2={success_count} />
            <DoubleRowName row1={t('InsightPage.open').toUpperCase()} row2={opened_count} />
            <DoubleRowName row1={`${t('InsightPage.open').toUpperCase()}%`} row2={`${openedPercentage}%`} />
            <>
              <CardText className="bold-text"> {t('InsightPage.label').toUpperCase()}</CardText>
              <div style={{ marginTop: -8, marginLeft: -15, marginBottom: -10 }}>
                <NavLink href={link} disabled={!link}>
                  {linkTitle}
                </NavLink>
              </div>
            </>
            <DoubleRowName row1={s('publicationDate')} row2={moment(messageState?.createdAt).format('DD MMM. YYYY HH:mm')}/>
          </Col>
          <div className="margin-10" />
          <Col lg={9} md={12}>
            <div className="card__title">
              <h4 className="bold-text">{}</h4>
            </div>
            <Line data={chartData} />
          </Col>
        </div>
        <div style={{ marginTop: 10 }}>
          <Link to="/messages?tab=2"><Button>{s('close')}</Button></Link>
        </div>
      </CardBody>
    </Card>
  );
};


export default PushData;
