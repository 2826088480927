import React, { useEffect } from 'react';
import { Col, Row, Container, Button, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderField from '../../../../../../../shared/components/custom/Field';
import renderRadioButtonField from '../../../../../../../shared/components/form/RadioButton';
import renderSelectField from '../../../../../../../shared/components/form/Select';
import renderSearchSelectField from '../../../../../../../shared/components/custom/SearchSelect';
import validate from '../validate';
import t, { partial } from '../../../../../../../util/translation/translation';
import renderCheckBoxField from '../../../../../../../shared/components/form/CheckBox';

const AssignSeasonTicketForm = (props) => {
  const p = partial('screensFormShare');
  const s = partial('shared');
  const { handleSubmit, onClose, editUsersTickets, ticketStates, seasonPrices, available } = props;
  useEffect(() => {
    props.change('editUsersTickets', editUsersTickets);
  }, [editUsersTickets]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        {!available ? (
          <Row>
            <Col>
              <Alert color="warning">
                <h4 className="alert-heading">Kampbillett konflikt</h4>
                Dette setet har allerede noen kampbilletter. Dersom du tildeler dette sesongkortet vil dette være uten
                alle kampbillettene
              </Alert>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{t('SeasonTicketsList.ticketStatus')}</span>
              <div className="marginTop-5" />
              <Field
                name="ticketState"
                component={renderSelectField}
                options={ticketStates}
                placeholder={editUsersTickets ? p('keepCurrentValue') : p('chooseTicketStatus')}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('priceGroup')}</span>
              <div className="marginTop-5" />
              <Field
                name="priceGroup"
                component={renderSelectField}
                options={seasonPrices}
                placeholder={editUsersTickets ? p('keepCurrentValue') : p('choosePriceGroup')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <span className="form-header">{p('totalTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="total" component={renderRadioButtonField} label={s('yes')} radioValue="1" />
              </div>
            </div>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="total"
                  component={renderRadioButtonField}
                  label={s('no')}
                  radioValue="0"
                  defaultChecked={!editUsersTickets}
                />
              </div>
            </div>
            {editUsersTickets && (
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="total"
                    component={renderRadioButtonField}
                    label={p('keepCurrentValue')}
                    radioValue={undefined}
                    defaultChecked
                  />
                </div>
              </div>
            )}
          </Col>
          <Col md={3}>
            <span className="form-header">Delbar billett</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="shareable"
                  component={renderRadioButtonField}
                  label={s('yes')}
                  radioValue="1"
                  defaultChecked={!editUsersTickets}
                />
              </div>
            </div>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="shareable" component={renderRadioButtonField} label={s('no')} radioValue="0" />
              </div>
            </div>
            {editUsersTickets && (
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="shareable"
                    component={renderRadioButtonField}
                    label={p('keepCurrentValue')}
                    radioValue={undefined}
                    defaultChecked
                  />
                </div>
              </div>
            )}
          </Col>
          <Col md={3}>
            <span className="form-header">{p('vipTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="vip" component={renderRadioButtonField} label={s('yes')} radioValue="1" />
              </div>
            </div>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="vip"
                  component={renderRadioButtonField}
                  label={s('no')}
                  radioValue="0"
                  defaultChecked={!editUsersTickets}
                />
              </div>
            </div>
            {editUsersTickets && (
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="vip"
                    component={renderRadioButtonField}
                    label={p('keepCurrentValue')}
                    radioValue={undefined}
                    defaultChecked
                  />
                </div>
              </div>
            )}
          </Col>
          <Col md={3}>
            <span className="form-header">{p('hasPickupDeadline')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field name="releasable" component={renderRadioButtonField} label={s('yes')} radioValue="1" />
              </div>
            </div>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="releasable"
                  component={renderRadioButtonField}
                  label={s('no')}
                  radioValue="0"
                  defaultChecked={!editUsersTickets}
                />
              </div>
            </div>
            {editUsersTickets && (
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="releasable"
                    component={renderRadioButtonField}
                    label={p('keepCurrentValue')}
                    radioValue={undefined}
                    defaultChecked
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form-header">{p('tags(commaSeparated)')}</span>
              <div className="marginTop-5" />
              <Field name="tags" component={renderField} placeholder={p('tags(commaSeparated)')} />
            </div>
          </Col>
          <Col md={3}>
            <span className="form-header">{p('pushToReciever')}</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field name="push" component={renderCheckBoxField} className="colored-click" />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('comment')}</span>
              <div className="marginTop-5" />
              <Field name="comment" component="textarea" placeholder={s('comment')} />
            </div>
          </Col>
        </Row>
        {!editUsersTickets && (
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form-header">{s('owner')}</span>
                <div className="marginTop-5" />
                <Field
                  name="owner"
                  component={renderSearchSelectField}
                  placeholder={p('searchPersonOrBusiness')}
                  options={props.ownerOptions}
                  search={(text) => props.getOwner(text)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form-header">{s('user')}</span>
                <div className="marginTop-5" />
                <Field
                  name="user"
                  component={renderSearchSelectField}
                  placeholder={p('searchPersonOrBusiness')}
                  options={props.userOptions}
                  search={(text) => props.getUser(text)}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Button type="submit" color="primary">
              {s('save')}
            </Button>
            {onClose ? (
              <Button type="button" onClick={onClose}>
                {s('close')}
              </Button>
            ) : (
              <Link
                className="color-unset"
                to={`/tickets${window.location.search
                  .split('&')
                  .filter((e) => !e.startsWith('available'))
                  .join('&')}`}
              >
                <Button type="button">{s('close')}</Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </form>
  );
};

AssignSeasonTicketForm.propTypes = {
  getOwner: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  available: PropTypes.bool,
  ownerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  ticketStates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  seasonPrices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  editUsersTickets: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};

AssignSeasonTicketForm.defaultProps = {
  onClose: null,
  available: true,
};

export default reduxForm({
  form: 'assign_season_ticket',
  validate,
})(AssignSeasonTicketForm);
