import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, Label } from 'reactstrap';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import useConfig from '../../../util/useConfig';

const StripeForm = ({ handleSubmit, teams = [], vendor, initialize }) => {
  const config = useConfig();
  useEffect(() => {
    if (vendor) {
      initialize({
        ...vendor,
        teamId: { label: vendor.team.name, value: vendor.team.id },
      });
    }
  });
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="name">Name</Label>
          <Field
            name="name"
            component={renderField}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="teamId">Connected team</Label>
          <Field
            name="teamId"
            component={renderSelectField}
            options={teams.map((team) => ({ label: team.name, value: `${team.id}` }))}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.key">Key</Label>
          <Field
            name="config.key"
            component={renderField}
            type=""
          />
        </div>
      </Col>

      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.currency">Currency</Label>
          <Field
            name="config.currency"
            component={renderField}
            value={config.currency}
            type=""
          />
        </div>
      </Col>

      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="config.secret">Secret</Label>
          <Field
            name="config.secret"
            component={renderField}
          />
        </div>
      </Col>
      <Button color="success">{vendor ? 'Edit' : 'Create'}</Button>
    </form>

  );
};
const rf = reduxForm({
  form: 'stripe_form',
});
export default rf(StripeForm);
