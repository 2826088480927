import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import SeasonTicketsList from './components/SeasonTicketsList';
import Api from '../../../../util/api';
import Loading from '../../../../shared/components/custom/Loading';
import DeleteModal from '../../../../shared/components/custom/DeleteModal';
import ShareModal from '../../../../shared/components/custom/ShareModal';
import AssignSeasonTicketForm from './screens/assign/components/form';
import { partial } from '../../../../util/translation/translation';
import useConfig from '../../../../util/useConfig';

const p = partial('screensIndexShare');
const t = partial('TicketStates');
const f = partial('TicketFilters');

const options = [
  { type: 'shareable', label: f('canBeShared'), value: 1, filterValue: 1 }, { type: 'shareable', label: f('cannotBeShared'), value: 2, filterValue: 0 },
  { type: 'VIP', label: f('VIP'), value: 3, filterValue: 1 }, { type: 'VIP', label: f('notVIP'), value: 4, filterValue: 0 },
  { type: 'total', label: f('total'), value: 5, filterValue: 1 }, { type: 'total', label: f('notTotal'), value: 6, filterValue: 0 },
];

const SeasonTickets = () => {
  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const [seasonTickets, setSeasonTickets] = useState({ current_page: '0', per_page: '100', data: [] });
  const [selectedField, setSelectedField] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [userOptions, setUserOptions] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [ticketStates, setTicketStates] = useState([]);
  const [seasonPrices, setSeasonPrices] = useState([]);
  const [releaseTicketsModalVisible, setReleaseTicketsModalVisible] = useState(false);
  const [shareTicketsModalVisible, setShareTicketsModalVisible] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [shareSeasonTickets, setShareSeasonTickets] = useState([]);
  const [deleteSeasonTickets, setDeleteSeasonTickets] = useState([]);
  const [masterToggled, setMasterToggled] = useState(false);
  const [editUsersTickets, setEditUsersTickets] = useState(false);
  const [filter, setFilter] = useState({
    ticketState: null,
    seasonPrice: null,
    options: [],
    sort: { type: 'seats', order: 'asc' },
  });
  const config = useConfig();
  const assignTicketStates = ticketStates.filter(ticketState => ([
    'ACTIVE',
    'RESERVED',
  ].includes(ticketState.key)));

  const history = useHistory();
  const getSeasonTickets = async (page = 1, pageSize = seasonTickets.per_page, _sort = filter.sort) => {
    const getByType = (type) => (option) => option.type === type;
    setLoading(true);
    try {
      const shareable = filter.options.filter(getByType('shareable'));
      const vip = filter.options.filter(getByType('VIP'));
      const total = filter.options.filter(getByType('total'));
      const shareableFilter = shareable.length === 1 ? shareable[0].filterValue : '';
      const vipFilter = vip.length === 1 ? vip[0].filterValue : '';
      const totalFilter = total.length === 1 ? total[0].filterValue : '';
      const foundCompanies = companies.filter(company => company.name.toLowerCase().includes(searchText.toLowerCase()));
      const foundCompanyIds = foundCompanies.map(company => company.id);
      const resSeasonTickets = await Api.seasonTickets.getSeasonTickets({
        season_id: selectedSeason.id,
        field_id: selectedField?.id ?? '',
        row_id: selectedRow?.id ?? '',
        is_shareable: shareableFilter,
        ticket_state_id: filter.ticketState?.value ?? '',
        season_price_id: filter.seasonPrice?.value ?? '',
        is_vip: vipFilter,
        is_total: totalFilter,
        q: searchText,
        page,
        count: pageSize,
        type: _sort.type,
        order: _sort.order,
        company_ids: foundCompanyIds,
      });
      setSeasonTickets(JSON.parse(JSON.stringify(resSeasonTickets.data)));
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const handleTicketStates = (tmpTicketStates) => {
    return tmpTicketStates.map((ticketState) => ({
      label: t(ticketState.name.toLowerCase()),
      value: ticketState.id.toString(),
      key: ticketState.name,
    }));
  };

  const handleSeasonPrices = (tmpSeasonPrices, season) => {
    const filteredSeasonPrices = tmpSeasonPrices.filter((seasonPrice) => seasonPrice.season_id === season.id);
    return filteredSeasonPrices.map((price) => {
      return {
        label: price.title,
        value: price.id.toString(),
      };
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const [resFields, resSeasons, resTicketStates, resSeasonPrices, resCompanies] = await Promise.all([
        Api.seasonTickets.getFields(),
        Api.seasonTickets.getSeasons(),
        Api.seasonTickets.getTicketStates(),
        Api.seasonTickets.getSeasonPrices(),
        Api.companies.getClubCompanies(),
      ]);

      const url = new URL(window.location.href);
      const defaultField = resFields.data.find(field => field.name.toString() === url.searchParams.get('field'));
      const defaultSeason = resSeasons.data.find(season => season.id.toString() === url.searchParams.get('season')) || resSeasons.data?.[0];

      const ticketStatesResolver = handleTicketStates(resTicketStates.data);
      const seasonPricesResolver = handleSeasonPrices(resSeasonPrices.data, defaultSeason);

      const fieldRows = defaultField?.rows ?? [];
      const defaultRow = fieldRows.find(row => row.number.toString() === url.searchParams.get('row'));

      setSearchText(url.searchParams.get('search') || '');
      setFields(resFields.data);
      setSelectedField(defaultField);
      setRows(fieldRows);
      setSelectedRow(defaultRow);
      setSeasons(resSeasons.data);
      setSelectedSeason(defaultSeason);
      setTicketStates(ticketStatesResolver);
      setSeasonPrices(seasonPricesResolver);
      setCompanies(resCompanies);
    } catch (err) {
      toast.error(err || p('fetchingnNecessaryDataFailed'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      getSeasonTickets();
    }
  }, [selectedSeason, selectedField, selectedRow, filter]);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (selectedSeason) {
      url.searchParams.set('season', selectedSeason.id);
    }

    if (selectedField) {
      url.searchParams.set('field', selectedField.name);
    }

    if (selectedRow) {
      url.searchParams.set('row', selectedRow.number);
    }

    history.push(`${history.location.pathname}${url.search}`);
  }, [selectedSeason, selectedField, selectedRow]);

  const onChangePage = (pager) => {
    if (seasonTickets.current_page === '0') {
      return;
    }
    if (pager.currentPage !== seasonTickets.current_page || pager.pageSize !== seasonTickets.per_page) {
      getSeasonTickets(pager.currentPage, pager.pageSize);
    }
  };

  const getOwners = async (text) => {
    try {
      const [resCompanies, resUsers] = await Promise.all([
        Api.companies.searchCompanies(text.toLowerCase()),
        Api.users.getTeamUsers(1, 10, { phoneNumber: 'asc' }, text, config),
      ]);
      const ownerOptionsMap = [
        ...resCompanies.data.map((company) => ({
          label: company.name,
          value: company.id,
          isCompany: company.id,
        })),
        ...resUsers.users.map((user) => ({
          label: `${user.firstname} ${user.lastname} (${user.phoneNumber})`,
          value: user.userCloudId,
        })),
      ];

      setOwnerOptions(ownerOptionsMap);
    } catch (err) {
      toast.error(err || p('fetchingSponsorsFailed'));
    }
  };

  const getUsers = async (text) => {
    try {
      const resUsers = await Api.users.getTeamUsers(1, 10, { phoneNumber: 'asc' }, text, config);
      const userOptionsMap = resUsers.users.map((user) => ({
        label: `${user.firstname} ${user.lastname} (${user.phoneNumber})`,
        value: user.userCloudId,
      }));
      setUserOptions(userOptionsMap);
    } catch (err) {
      toast.error(err || p('fetchingUsersFailed'));
    }
  };

  const handleSeason = (season) => {
    setSelectedSeason(season);
    getSeasonTickets();
  };

  const handleField = (pickedField) => {
    if (pickedField === null) {
      const url = new URL(window.location.href);
      url.searchParams.delete('field');
      url.searchParams.delete('row');
      history.replace(`${history.location.pathname}${url.search}`);
    }
    setSelectedField(pickedField);
    setRows(pickedField?.rows ?? []);
    setSelectedRow(null);
  };

  const handleRow = (pickedRow) => {
    if (pickedRow === null) {
      const url = new URL(window.location.href);
      url.searchParams.delete('row');
      history.replace(`${history.location.pathname}${url.search}`);
    }
    setSelectedRow(pickedRow);
  };

  const handleInput = (event) => setSearchText(event.target.value);

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      const url = new URL(window.location.href);
      url.searchParams.set('search', searchText);
      history.replace(`${history.location.pathname}${url.search}`);
      getSeasonTickets();
    }
  };

  const handleToggle = (index, toggled) => {
    const allSeasonTickets = [...seasonTickets.data];
    let hasSelectedAll = true;
    if (index === -1) {
      for (let i = 0; i < allSeasonTickets.length; i++) {
        allSeasonTickets[i].toggled = !masterToggled;
      }
    } else {
      allSeasonTickets[index].toggled = !toggled;
    }

    for (let i = 0; i < allSeasonTickets.length; i++) {
      if (allSeasonTickets[i].toggled === false) {
        hasSelectedAll = false;
      }
    }

    setSeasonTickets({ ...seasonTickets, data: allSeasonTickets });
    setMasterToggled(hasSelectedAll);
  };

  const handleShareTicketsModal = (state, newShareSeasonTickets = []) => {
    setShareTicketsModalVisible(state);
    setShareSeasonTickets(newShareSeasonTickets);
  };

  const submitShareSeasonTickets = async (formValues) => {
    setShareTicketsModalVisible(false);
    const payload = {
      seat_ids: shareSeasonTickets.map((seat) => seat.id),
      season_id: selectedSeason.id,
      user_id: formValues.user?.value ? formValues.user?.value : null,
      total: formValues.total === '1',
      ticket_state_id: formValues.ticketState?.value ? Number(formValues.ticketState.value) : null,
      season_price_id: formValues.priceGroup?.value ? Number(formValues.priceGroup.value) : null,
      vip: formValues.vip ? Number(formValues.vip) : null,
      shareable: formValues.shareable ? !!Number(formValues.shareable) : null,
      releasable: formValues.releasable === '1',
      tags: formValues.tags,
      comment: formValues.comment,
      notify_user: formValues.push,
    };
    if (formValues.total === undefined && editUsersTickets) {
      delete payload.total;
    }
    if (formValues.shareable === undefined && editUsersTickets) {
      delete payload.shareable;
    }
    if (formValues.vip === undefined && editUsersTickets) {
      delete payload.vip;
    }
    if (formValues.releasable === undefined && editUsersTickets) {
      delete payload.releasable;
    }
    if (formValues.ticketState === undefined && editUsersTickets) {
      delete payload.ticket_state_id;
    }
    if (formValues.priceGroup === undefined && editUsersTickets) {
      delete payload.season_price_id;
    }
    if (formValues.owner) {
      payload[formValues.owner.isCompany ? 'new_company_id' : 'owner_id'] = formValues.owner.value;
    }
    setLoading(true);
    try {
      if (editUsersTickets) {
        payload.ticket_ids = shareSeasonTickets.map((ticket) => ticket.season_ticket_id);
        await Api.seasonTickets.updateUsersSeasonTickets(payload);
      } else {
        await Api.seasonTickets.assignSeasonTickets(payload);
      }
      toast.success(p('ticketsAssigned'));
      getSeasonTickets(seasonTickets.current_page);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const updateSort = (type) => {
    let change = 'desc';
    if (filter.sort.type === type) {
      change = filter.sort.order === 'asc' ? 'desc' : 'asc';
    }
    setFilter(prev => ({ ...prev, sort: { type, order: change } }));
  };

  const handleReleaseTicketsModal = (state, newDeleteSeasonTickets = []) => {
    setReleaseTicketsModalVisible(state);
    setDeleteSeasonTickets(newDeleteSeasonTickets);
  };

  const submitDeleteSeasonTickets = async () => {
    setReleaseTicketsModalVisible(false);
    const payload = {
      ticket_ids: deleteSeasonTickets.filter((seat) => seat.season_ticket_id).map((seat) => seat.season_ticket_id),
    };
    try {
      await Api.seasonTickets.deleteSeasonTickets(payload);
      getSeasonTickets(seasonTickets.current_page);
      toast.success(p('ticketsDiverted'));
    } catch (err) {
      toast.error(err || p('errorDivertingTickets'));
    }
  };

  const handlePrint = async (seasonTicketId) => {
    setLoading(true);
    try {
      const res = await Api.seasonTickets.printSeasonTicket(seasonTicketId);
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        printWindow.document.write(res.data);
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.focus();
          printWindow.print();
        };
      } else {
        throw new Error('Failed to open new window');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const populateTickets = async () => {
    const selectedSeasonTickets = seasonTickets.data.filter((st) => st.toggled).map((st) => st.season_ticket_id);
    setLoading(true);
    try {
      const res = await Api.seasonTickets.populateSeasonTickets({ seasonTicketIds: selectedSeasonTickets });
      toast.success(p('createdTickets', { completed: res.data.completed, errors: res.data.errors.length }));
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DeleteModal
        visible={releaseTicketsModalVisible}
        type="sesongbilletten"
        handleModal={handleReleaseTicketsModal}
        modalAction={submitDeleteSeasonTickets}
        modalType="release"
      />
      <ShareModal visible={shareTicketsModalVisible} handleModal={handleShareTicketsModal}>
        <AssignSeasonTicketForm
          ownerOptions={ownerOptions}
          userOptions={userOptions}
          ticketStates={assignTicketStates}
          getOwner={getOwners}
          getUser={getUsers}
          seasonPrices={seasonPrices}
          onSubmit={submitShareSeasonTickets}
          editUsersTickets={editUsersTickets}
          onClose={() => handleShareTicketsModal(false)}
        />
      </ShareModal>
      <Loading loading={loading} />
      <SeasonTicketsList
        seasonTickets={seasonTickets}
        seasons={seasons}
        selectedSeason={selectedSeason}
        handleSeason={handleSeason}
        fields={fields}
        rows={rows}
        selectedField={selectedField}
        selectedRow={selectedRow}
        setEditUsersTickets={setEditUsersTickets}
        editUsersTickets={editUsersTickets}
        handleField={handleField}
        handleRow={handleRow}
        searchText={searchText}
        handleInput={handleInput}
        handleSearch={handleSearch}
        handleReleaseTicketsModal={handleReleaseTicketsModal}
        handleShareTicketsModal={handleShareTicketsModal}
        handleToggle={handleToggle}
        onChangePage={onChangePage}
        handlePrint={handlePrint}
        sort={filter.sort}
        updateSort={updateSort}
        populateTickets={populateTickets}
        filter={filter}
        setFilter={setFilter}
        ticketStates={ticketStates}
        seasonPrices={seasonPrices}
        options={options}
        companies={companies}
      />
    </>
  );
};

export default SeasonTickets;
