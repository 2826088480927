import React from 'react';
import { Card, CardBody } from 'reactstrap';

const Widget = ({ children }) => {
    return (
      <Card>
        <CardBody className="widget-wrapper">
          { children }
        </CardBody>
      </Card>
    );
};
export default Widget;
