import React, { useEffect, useMemo } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { Col, Row, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import validateDiscountCode from './validateDiscountCode';
import renderField from '../../../shared/components/custom/Field';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
import renderSelectField from '../../../shared/components/form/Select';
// import config from '../../../config';

const s = partial('shared');
const p = partial('DiscountCodeForm');
const DiscountCodeForm = ({
  handleSubmit, formValues, discountCode, initialize, matches,
}) => {
  const config = useConfig();

  const matchToOption = match => ({
    label: `${match.home_team?.shortname} - ${match.away_team?.shortname} (${moment(match?.match_starts_at).format('DD-MM-YYYY')})`,
    value: match.id.toString(),
  });

  useEffect(() => {
    if (discountCode) {
      let type = 'season';
      if (discountCode.season === 0) {
        type = (discountCode?.matches ?? []).length === 0 ? 'allMatches' : 'specificMatches';
      }
      initialize({
        name: discountCode.package.name,
        code: discountCode.code,
        price: discountCode.package.price,
        amount: discountCode.amount,
        description: discountCode.package.description,
        saleStart: moment(discountCode.package.sale_start).format('YYYY-MM-DDTHH:mm'),
        saleEnd: discountCode.package.sale_end ? moment(discountCode.package.sale_end).format('YYYY-MM-DDTHH:mm') : undefined,
        usePercentage: !!discountCode.use_percentage,
        season: type,
        specificMatches: discountCode.matches?.map(matchContainer => matchToOption(matchContainer.match)) ?? [],
      });
    }
  }, [discountCode]);

  const matchOptions = useMemo(() => matches.map(matchToOption), [matches]);

  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('name')}</span>
              <Field
                name="name"
                placeholder={s('name')}
                component={renderField}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{p('code')}</span>
              <Field
                name="code"
                placeholder={s('discountCode')}
                component={renderField}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={6}>
            <span>{p('numberOfDiscounts')} &nbsp; <TooltipWithIcon id="amount" text={p('discountsInfo')} /></span>
            <div className="form__form-group-field">
              <Field
                name="amount"
                component={renderField}
                type="number"
                placeholder={p('numberOfDiscounts')}
              />
            </div>
          </Col>
          <Col xl={3} lg={6}>
            <span>{p('discountedAmount')}</span>
            <div className="form__form-group-field">
              <Field
                name="price"
                component={renderField}
                type="number"
                placeholder={p('discountedAmount')}
              />
              <div className="form__form-group-icon">&nbsp;{formValues.usePercentage ? '%' : config.currencyShort}&nbsp;</div>
            </div>
          </Col>
          <Col xl={3} lg={6}>
            <div className="form__form-group">
              <span>{p('availableFrom')}</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <TimetableIcon />
                </div>
                <Field
                  name="saleStart"
                  component={renderField}
                  type="datetime-local"
                />
              </div>
            </div>
          </Col>
          <Col xl={3} lg={6}>
            <span>{p('availableTo')}</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <TimetableIcon />
              </div>
              <Field
                name="saleEnd"
                component={renderField}
                type="datetime-local"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span className="form">{s('description')}</span>
            <div className="form__form-group-field">
              <Field
                name="description"
                component={renderTextAreaField}
                placeholder={s('description')}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="mb-4">
              <span className="form">{p('usePercentage')}</span>
              <div className="form__form-group-field">
                <Field
                  name="usePercentage"
                  component={renderCheckBoxField}
                  className="colored-click"
                />
              </div>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <span className="form margin-0">{p('appliesTo')}:</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="season"
                  label={p('seasonTickets')}
                  component={renderRadioButtonField}
                  radioValue="season"
                  defaultChecked
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="season"
                  component={renderRadioButtonField}
                  label={p('allMatchTickets')}
                  radioValue="allMatches"
                />
              </div>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="season"
                    label={p('specificMatchTickets')}
                    component={renderRadioButtonField}
                    radioValue="specificMatches"
                  />
                </div>
                {
                  formValues.season === 'specificMatches' && (
                    <div className="form__form-group-field">
                      <Field
                        name="specificMatches"
                        component={renderSelectField}
                        options={matchOptions}
                        paceholder={p('chooseMatches')}
                        multiple
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary">{discountCode ? s('save') : s('create')}</Button>
            <Link to="/discounts?tab=2"><Button color="secondary">{s('close')}</Button></Link>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

DiscountCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    usePercentage: PropTypes.bool,
  }),
  discountCode: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    amount: PropTypes.number,
    price: PropTypes.string,
    use_percentage: PropTypes.number,
    season: PropTypes.number,
    sale_start: PropTypes.string,
    sale_end: PropTypes.string,
    package: PropTypes.shape({
      description: PropTypes.string,
      price: PropTypes.number,
    }),
  }),
  initialize: PropTypes.func.isRequired,
};

DiscountCodeForm.defaultProps = {
  formValues: {},
  discountCode: null,
};

const reduxF = reduxForm({
  form: 'DiscountCodeForm',
  validate: validateDiscountCode,
});

export default reduxF(connect(state => ({
  formValues: getFormValues('DiscountCodeForm')(state),
}))(DiscountCodeForm));
