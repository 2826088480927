import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import config from '../../../config';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import useConfig from '../../../util/useConfig';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const config = useConfig();
  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
          <Link to="/">
            <img style={{ height: '48px', width: 'auto', marginTop: '6px' }} src={config.club_logo} alt="logo" id="team-logo" />
          </Link>
        </div>
        <div className="topbar__right">
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
