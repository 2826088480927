import React, { Component } from 'react';
import { Container, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { any } from 'prop-types';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import moment from 'moment';
import AssignTicketForm from './components/AssignTicketForm';
import Api from '../../../../../../util/api';
import Loading from '../../../../../../shared/components/custom/Loading';
import PresaleForm from './components/PresaleForm';
import { partial } from '../../../../../../util/translation/translation';

const p = partial('screensIndexShare');
const s = partial('shared');
const t = partial('TicketStates');

class AssignMatchTicket extends Component {
  static propTypes = {
    assignMatchTicketValues: PropTypes.objectOf(any),
    match: PropTypes.shape({
      params: PropTypes.shape({
        matchID: PropTypes.string,
        seatID: PropTypes.string,
      }),
    }).isRequired,
    presaleMatchTicketValues: PropTypes.shape({
      reservationDate: PropTypes.string,
    }),
  }

  static defaultProps = {
    assignMatchTicketValues: undefined,
    presaleMatchTicketValues: {},
  }

  state = {
    userOptions: [],
    ticketStates: [],
    matchPrices: [],
    loading: true,
    activeTab: '1',
    seasonTicket: null,
  };

  componentDidMount() {
    this.initializeComponent();
  }

  onSubmit = () => {
    const payload = {
      seat_ids: [this.props.match.params.seatID],
      match_id: this.props.match.params.matchID,
      ticket_state_id: this.props.assignMatchTicketValues.ticketState.value ? Number(this.props.assignMatchTicketValues.ticketState.value) : null,
      price_group_id: this.props.assignMatchTicketValues.priceGroup.value ? Number(this.props.assignMatchTicketValues.priceGroup.value) : null,
      user_id: this.props.assignMatchTicketValues.user.value ? Number(this.props.assignMatchTicketValues.user.value) : null,
      vip: this.props.assignMatchTicketValues.vip ? Number(this.props.assignMatchTicketValues.vip) : null,
      shareable: this.props.assignMatchTicketValues.shareable ? !!Number(this.props.assignMatchTicketValues.shareable) : null,
      notify_user: this.props.assignMatchTicketValues.push ? !!true : false,
    };
    this.setState({ loading: true }, () => {
      Api.tickets.assignTickets(payload).then(() => {
        toast.success(p('ticketWasAssign'));
        this.setState({ loading: false });
      }).catch((err) => {
        this.setState({ loading: false });
        toast.error(err || p('errorAssigningTickets'));
      });
    });
  }

  onSubmitPresale = async (_input) => {
    if (this.state.seasonTicket === null) {
      await this.getSeasonTickets();
    }
    const duration = Math.floor(moment.duration(moment(this.props.presaleMatchTicketValues.reservationDate).diff(moment())).asHours());
    if (duration < 0) {
      toast.error(p('reservationMustBeBeforeDate'));
      return;
    }
    const payload = {
      match_id: this.props.match.params.matchID,
      user_id: _input.user.value,
      season_ticket_id: this.state.seasonTicket && this.state.seasonTicket.season_ticket_id,
      duration: `${duration}h`,
      duration_date: _input.date,
      notify_user: false,
    };
    this.setState({ loading: true }, async () => {
      try {
        await Api.tickets.createReservations(payload);
        toast.success(p('reservationCreated'));
        this.setState({ loading: false });
      } catch (err) {
        this.setState({ loading: false });
        toast.error(p('errorCreatingReservations'));
      }
    });
  }
  // eslint-disable-next-line react/sort-comp
  debounce = (cb) => {
    let id = '';
    return (value) => {
      clearTimeout(id);
      id = setTimeout(() => {
        cb(value);
      }, 400);
    };
  };
  getUser = this.debounce(async (text) => {
    const { users, club } = await Api.users.getTeamUsers(1, 100, { phoneNumber: 'ASC' }, text);
    const userOptions = users.map((user) => {
      const foundTeam = user.teams.find((item) => item.team.name === club);
      return ({
        label: `${user.firstname} ${user.lastname} (${user.phoneNumber})`,
        value: foundTeam.monolithUserId?.toString(),
      });
    });
    this.setState({ userOptions });
  })

  getSeasonTickets = async (userID) => {
    if (!userID) {
      return null;
    }
    try {
      const currentSeason = await Api.seasonTickets.getCurrentSeason();
      const seasonTickets = await Api.tickets.getSeasonTickets(userID, currentSeason.data.id);
      let seasonTicket = false;
      seasonTicket = false;
      for (let i = 0; i < seasonTickets.data.length; i++) {
        if (seasonTickets.data[i].id === Number(this.props.match.params.seatID)) {
          seasonTicket = seasonTickets.data[i];
          break;
        }
      }
      if (seasonTicket !== false) {
        this.setState({ seasonTicket });
      }
      return seasonTicket;
    } catch (err) {
      return false;
    }
  }

  handleTicketStates = (ticketStates) => {
    const acceptedStates = [
      'ACTIVE',
      'INACTIVE',
      'RESERVED/RELEASABLE',
    ];
    const filterTicketStates = (_acceptedStates) => ({ name }) => _acceptedStates.includes(name);
    return ticketStates.filter(filterTicketStates(acceptedStates)).map((ticketState) => {
      return {
        label: t(ticketState.name.toLowerCase()),
        value: ticketState.id.toString(),
      };
    });
  }

  handleMatchPrices = (matchPrices) => {
    return matchPrices.map((price) => {
      return {
        label: price.title,
        value: price.id.toString(),
      };
    });
  }

  initializeComponent = () => {
    Promise.all([
      Api.tickets.getTicketStates(),
      Api.tickets.getPriceGroups(),
    ]).then((res) => {
      const ticketStates = this.handleTicketStates(res[0].data);
      const matchPrices = this.handleMatchPrices(res[1].data);
      this.setState({
        ticketStates,
        matchPrices,
        loading: false,
      });
    }).catch((err) => {
      this.setState({ loading: false });
      toast.error(err || p('errorFetchingInfo'));
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <Container>
        <Loading loading={this.state.loading} />
        <Col md={12}>
          <h3 className="page-title">{p('assignMatchTicket')}</h3>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--justify tabs--bordered-top">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        {s('ticket')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        {s('presale')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="margin-10" />
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <AssignTicketForm
                    userOptions={this.state.userOptions}
                    getUser={this.getUser}
                    ticketStates={this.state.ticketStates}
                    matchPrices={this.state.matchPrices}
                    onSubmit={this.onSubmit}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <PresaleForm
                    userOptions={this.state.userOptions}
                    getUser={this.getUser}
                    ticketStates={this.state.ticketStates}
                    matchPrices={this.state.matchPrices}
                    onSubmit={this.onSubmitPresale}
                    getSeasonTickets={this.getSeasonTickets}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}
export default connect(state => ({
  assignMatchTicketValues: getFormValues('assign_match_ticket')(state),
  presaleMatchTicketValues: getFormValues('presale_match_ticket')(state),
}))(AssignMatchTicket);
