import React, { useState } from 'react';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import TimetableIcon from 'mdi-react/TimetableIcon';
import renderCheckBoxField from '../../../../../../../shared/components/form/CheckBox';
import renderField from '../../../../../../../shared/components/custom/Field';
import renderSearchSelectField from '../../../../../../../shared/components/custom/SearchSelect';
import validate from '../validatePresale';
import { partial } from '../../../../../../../util/translation/translation';

const PresaleForm = (props) => {
  const p = partial('assignTicketForm&PresaleFormShare');
  const s = partial('shared');
  const [seasonTicket, setSeasonTicket] = useState(null);

  const { handleSubmit } = props;

  const fetchSeasonTicket = async (e) => {
    setSeasonTicket(null);
    const sTicket = await props.getSeasonTickets(e.value);
    setSeasonTicket(sTicket);
  };

  const renderSeasonTicket = () => {
    switch (seasonTicket) {
      case null:
        return (
          <p />
        );
      case false:
        return (
          <p className="danger">{p('userNoSeasonTicketOnThisSeat')}</p>
        );
      default:
        return (
          <div className="presale-seasonticket">
            <p>{seasonTicket.field_name} - {seasonTicket.row_number} - {seasonTicket.seat_id}</p>
            <p><strong>{s('user')}</strong>: {seasonTicket.owner_firstname} {seasonTicket.owner_lastname} ({seasonTicket.owner_username})</p>
            <p><strong>{s('owner')}</strong>: {seasonTicket.user_firstname} {seasonTicket.user_lastname} ({seasonTicket.user_username})</p>
          </div>
        );
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('user')}</span>
          <div className="marginTop-5" />
          <Field
            name="user"
            component={renderSearchSelectField}
            placeholder={p('searchNameOrNumber')}
            options={props.userOptions}
            search={text => props.getUser(text)}
            onChange={fetchSeasonTicket}
          />
        </div>
        <div className="form__form-group">
          <span className="form-header">{p('reservationDuration')}</span>
          <div className="marginTop-5" />
          <div className="form__form-group-field">
            <Field
              name="reservationDate"
              component={renderField}
              type="datetime-local"
            />
            <div className="form__form-group-icon">
              <TimetableIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="sendPush"
              component={renderCheckBoxField}
              className="colored-click"
              label={p('pushToReciever')}
            />
          </div>
        </div>
      </Col>
      <Col md={6}>
        { renderSeasonTicket() }
      </Col>
      <Col md={12}>
        <Button disabled={!seasonTicket} type="submit" color="primary">{p('createReservation')}</Button>
        <Link className="color-unset" to={`/tickets${window.location.search}`}><Button type="button">{s('close')}</Button></Link>
      </Col>
    </form>
  );
};

PresaleForm.propTypes = {
  getUser: PropTypes.func.isRequired,
  userOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getSeasonTickets: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'presale_match_ticket',
  validate,
})(PresaleForm);
