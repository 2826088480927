import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from './validators/fieldTypeValidate';
import RenderField from '../../../shared/components/custom/Field';
import RenderCheckBoxField from '../../../shared/components/form/CheckBox';
import RenderChromeColorPickerField from '../../../shared/components/form/ChromeColorPicker';
import { partial } from '../../../util/translation/translation';

const FieldTypeForm = (props) => {
  const p = partial('FieldTypeForm');
  const s = partial('shared');
  useEffect(() => {
    if (props.fieldType && props.fieldType.id !== undefined) {
      props.initialize({
        name: props.fieldType.name,
        displayName: props.fieldType.display_name,
        price: props.fieldType.price,
        unmarked: !!props.fieldType.is_unmarked,
        color: {
          hex: props.fieldType.colour ? props.fieldType.colour : '#ffffff',
          rgb: props.hexToRgb(props.fieldType.colour ? props.fieldType.colour : '#ffffff'),
        },
      });
    }
  }, [props.fieldType]);

  return (
    <form className="form" onSubmit={props.handleSubmit}>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('name')}</span>
          <Field
            name="name"
            component={RenderField}
            placeholder={p('fieldTypeName')}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{p('viewName')}</span>
          <Field
            name="displayName"
            component={RenderField}
            placeholder={p('viewName')}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form-header">{s('price')}</span>
          <Field
            name="price"
            component={RenderField}
            type="number"
            placeholder={p('priceOfField')}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <span className="form__form-group-label">{p('fieldColor')}</span>
          <div className="form__form-group-field">
            <Field
              name="color"
              defaultColor="#ffffff"
              component={RenderChromeColorPickerField}
            />
          </div>
        </div>
      </Col>
      <Col md={5} sm={8}>
        <div className="form__form-group">
          <Field
            name="unmarked"
            component={RenderCheckBoxField}
            className="colored-click"
            label={p('unMarkedField')}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">{s('create')}</Button>
        <Link className="color-unset" to="/stadiums?tab=2">
          <Button type="button">{s('close')}</Button>
        </Link>
      </Col>
    </form>
  );
};

FieldTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hexToRgb: PropTypes.func.isRequired,
  fieldType: PropTypes.shape({
    id: PropTypes.number,
    colour: PropTypes.string,
    name: PropTypes.string,
    display_name: PropTypes.string,
    price: PropTypes.string,
    is_unmarked: PropTypes.number,
  }).isRequired,
  initialize: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'field_type_form',
  validate,
});

export default withRouter(reduxF(FieldTypeForm));
