export default {
  club_name: 'Brynäs IF',
  club_name_short: 'BIF',
  stadium_name: 'Monitor ERP Arena',
  staging_url: 'https://brynas.api.fangroup.io',
  prod_url: 'https://brynas.api.fangroup.io',
  api: '/api',
  facebook: 'brynasif',
  basename: '/brynas',
  club_logo: '/assets/teams/brynas.png',
  app_name: 'brynas',
  websale_name: 'brynas',
  ntb_team_id: 10005,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'SEK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: true,
  cloudIdName: 'brynas',
  monolithEnabled: false,
};
