import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TopbarMenuLink from './TopbarMenuLink';
import AuthService from '../../_app/AuthService';
import userLanguageState from '../../../recoil/userLanguage/selector';
import { partial, getAvailableLanguages } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';


const s = partial('shared');
const TopbarProfile = ({ me, dispatch }) => {
  const config = useConfig();
  const [collapse, setCollapse] = useState(false);
  const [language, setLanguage] = useState(false);
  const setUserLanguages = useRecoilState(userLanguageState)[1];

  const toggle = () => {
    setCollapse(!collapse);
  };
  const toggleLanguages = () => {
    setLanguage(!language);
  };
  const logOut = () => {
    AuthService.signout(dispatch, config);
  };
  const changeLanguage = value => () => {
    setUserLanguages(value);
  };
  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={toggle}>
        <div className="topbar__avatar-icon">{`${me?.firstname?.[0]?.toUpperCase() ?? ''}${me?.lastname?.[0]?.toUpperCase() ?? ''}`}</div>
        <p className="topbar__avatar-name">{me?.firstname} {me?.lastname}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button className="topbar__back" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <button className="btn-noStyle topbar__link" onClick={toggleLanguages}>
            <span className="topbar__link-icon sidebar__link-icon icon-flag" />
            <div>Languages</div>
          </button>
          <Collapse className="languageBar" isOpen={language}>
            {getAvailableLanguages().map((languageL) => {
              return <button key={`topBarLanguage-${languageL.value}`} className="topbar__link btn-noStyle" onClick={changeLanguage(languageL.value)}>{languageL.label}</button>;
            })}
          </Collapse>
          <TopbarMenuLink title={s('logOut')} icon="exit" path="/log_in" action={logOut} />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  me: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

TopbarProfile.defaultProps = {
  me: null,
};

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
  me: state.admin.me,
}))(TopbarProfile));
