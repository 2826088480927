import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import renderField from '../../../shared/components/custom/Field';
import t from '../../../util/translation/translation';

const ResetPasswordForm = ({ handleSubmit, isDisabled }) => {
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('LoginPage.resetPassword')}</span>
        <div className="form__form-group-field">
          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder={t('EmailLogInForm.password')}
            autoComplete="current-password"
          />
        </div>
        <div className="form__form-group-field">
          <Field
            name="repeatPassword"
            component={renderField}
            type="password"
            placeholder={t('LoginPage.repeatPassword')}
            autoComplete="current-password"
          />
        </div>
      </div>
      <Button color="primary" className="width-100p" disabled={isDisabled}>
        {t('LoginPage.resetPassword')}
      </Button>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'reset_password_form',
});

export default reduxF(ResetPasswordForm);
