import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');

  const errors = {};
  if (!values.name) {
    errors.name = p('matchTypeNameFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
