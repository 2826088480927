import React, { Component } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import AlternativeNavbar from './components/AlternativeNavbar';
import ListKiosks from './components/ListKiosks';
import Auction from './components/Auction';
import Kiosk from './components/Kiosk';
import HistoryModal from './components/HistoryModal';
import ProductsModal from './components/ProductsModal';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import DeleteModal from '../../shared/components/custom/DeleteModal';
import { partial } from '../../util/translation/translation';
import '../../scss/containers/kiosk.scss';

const p = partial('KioskPage');
const s = partial('shared');

let kioskOrderInterval;

class KioskPage extends Component {
  constructor() {
    super();
    this.state = {
      selectedKiosk: {},
      auctionOrders: [],
      auctionBids: [],
      kiosks: [],
      loading: false,
      onlyShowOwn: false,
      kioskOrders: {
        active: [],
        processing: [],
        ready: [],
      },
      modalVisible: false,
      modalInfo: {
        auctionID: null,
        bidID: null,
      },
      selectedAuctionOrder: {},
      historyModal: false,
      productsModal: false,
      orderHistory: {
        data: [],
        total: 0,
        per_page: '0',
        current_page: 0,
      },
      kioskProducts: [],
      refundModal: {
        visible: false,
        id: null,
      },
      printOrder: null,
      automaticPrint: true,
    };
  }

  componentDidMount() {
    this.getKiosks();
    this.updateTimestamps();
  }

  componentWillUnmount() {
    clearInterval(kioskOrderInterval);
  }

  getAuctionOrders = (_auctionOrderID) => {
    this.setState({ loading: false }, async () => {
      try {
        const resAuctionOrders = await Api.kiosks.getAuctionOrders(_auctionOrderID);
        this.setState({ selectedAuctionOrder: {}, auctionOrders: resAuctionOrders.data, loading: false });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getAuctionBids = (_auctionOrder) => {
    this.setState({ loading: true }, async () => {
      const resAuctionBids = await Api.kiosks.getAuctionBids(_auctionOrder.id);
      this.setState({ selectedAuctionOrder: _auctionOrder, auctionBids: resAuctionBids.data, loading: false });
    });
  }

  getKioskOrders = async (showLoading = false) => {
    if (this?.state?.selectedKiosk?.auction !== 0) {
      clearInterval(kioskOrderInterval);
      return;
    }
    if (showLoading) {
      this.setState({ loading: true });
    }
    try {
      const resKioskOrders = await Api.kiosks.getKioskOrders(this.state.selectedKiosk.id);
      const kioskOrders = this.sortKioskOrders(resKioskOrders);
      this.updateTimestamps();
      this.setState({ kioskOrders, loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  getKiosks = async () => {
    this.setState({ loading: true, selectedKiosk: {}, kiosks: [] });
    try {
      const resKiosks = await Api.kiosks.getKiosks();
      this.setState({ kiosks: resKiosks.data, loading: false });
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  getOrderHistory = (_date, _name, page = 1, pageSize = this.state.orderHistory.per_page) => {
    this.setState({
      loading: true,
    }, async () => {
      try {
        let type = 'q';
        if (_date !== '') {
          type = 'date';
        }
        const resOrderHistory = await Api.kiosks.getOrderHistory(this.state.selectedKiosk.id, page, pageSize, type, type === 'q' ? _name : _date);
        this.setState({ loading: false, orderHistory: resOrderHistory.data });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getKioskProducts = () => {
    this.setState({ loading: true }, async () => {
      try {
        const resKioskProducts = await Api.kiosks.getKioskProducts(this.state.selectedKiosk.id);
        for (let i = 0; i < resKioskProducts.data.length; i++) {
          resKioskProducts.data[i].hidden = false;
        }
        this.setState({ kioskProducts: resKioskProducts.data, loading: false });
      } catch (err) {
        this.setState({ loading: false });
        toast.error(err || `${p('errorFetchingProducts')}!`);
      }
    });
  }

  setSelectedKiosk = (kiosk) => {
    clearInterval(kioskOrderInterval);
    if (kiosk.id === undefined) {
      this.setState({ auctionOrders: [], kioskOrders: [] });
    } else if (kiosk.auction === 1) {
      this.getAuctionOrders(kiosk.id);
      this.setState({ selectedKiosk: kiosk });
    } else if (kiosk.auction === 0) {
      this.setState({ selectedKiosk: kiosk }, () => {
        this.getKioskOrders(true);
      });
      kioskOrderInterval = setInterval(this.getKioskOrders, 5000);
    }
  }

  setOnlyShowOwn = () => {
    this.setState({ onlyShowOwn: !this.state.onlyShowOwn });
  }

  setAuctionOrders = (_auctionOrders) => {
    this.setState({ auctionOrders: _auctionOrders });
  }

  setkioskProducts = (_products = []) => {
    this.setState({ kioskProducts: _products });
  }

  setKioskProductHidden = (_productIndex) => {
    const { kioskProducts } = this.state;
    kioskProducts[_productIndex].hidden = !kioskProducts[_productIndex].hidden;
    this.setState({ kioskProducts });
  }

  handleModal = (_modalState, _auctionID = null, _bidID = null) => {
    let modalInfo = {
      auctionID: null,
      bidID: null,
    };

    if (_auctionID !== null && _bidID !== null) {
      modalInfo = {
        auctionID: _auctionID,
        bidID: _bidID,
      };
      this.setState({ modalVisible: _modalState, modalInfo });
    } else {
      this.setState({ modalVisible: _modalState, modalInfo });
    }
  }

  toggleHistoryModal = () => {
    this.setState(prevState => ({
      historyModal: !prevState.historyModal,
    }));
  }

  toggleProductsModal = () => {
    this.setState(prevState => ({
      productsModal: !prevState.productsModal,
    }));
  }

  toggleAutomaticPrint = () => {
    this.setState(prevState => ({
      automaticPrint: !prevState.automaticPrint,
    }));
  }

  deleteBid = () => {
    const { auctionID, bidID } = this.state.modalInfo;
    if (auctionID === null && bidID === null) {
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        await Api.kiosks.deleteAuctionBid(auctionID, bidID);
        let { auctionBids } = this.state;
        auctionBids = auctionBids.filter((bid) => {
          if (bid.id === bidID) {
            return false;
          }
          return true;
        });
        this.setState({ auctionBids, modalVisible: false });
      } catch (err) {
        toast.error(err || `${p('errorErasingBid')}!`);
      }
      this.setState({ loading: false });
    });
  }

  handleKioskUpdate = (_data) => {
    if (_data.kioskID !== this.state.selectedKiosk.id) {
      return;
    }
    let index;
    const types = ['ready', 'processing', 'active'];
    for (let i = 0; i < types.length; i++) {
      // eslint-disable-next-line no-loop-func
      const order = this.state.kioskOrders[types[i]].find((_element, _index) => {
        index = _index;
        return _element.id === _data.order.id;
      });
      if (order) {
        const orderInfo = {
          index,
          type: order.status,
        };
        this.moveOrder(orderInfo, _data.order.status, _data.order);
        return;
      }
    }
  }

  handleRefresh = () => {
    const { selectedKiosk } = this.state;
    this.setState({ loading: true }, () => {
      if (selectedKiosk.auction === 0) { // REGULAR KIOSK
        Promise.all([
          Api.kiosks.getKioskByID(selectedKiosk.id),
          Api.kiosks.getKioskOrders(selectedKiosk.id),
        ]).then((res) => {
          const kioskOrders = this.sortKioskOrders(res[1]);
          this.setState({ selectedKiosk: res[0].data, kioskOrders, loading: false });
        }).catch((err) => {
          this.setState({ loading: false });
          toast.error(err || `${p('errorUpdatingData')}!`);
        });
      } else if (selectedKiosk.auction === 1) { // AUCTION KIOSK
        Promise.all([
          Api.kiosks.getKioskByID(selectedKiosk.id),
          Api.kiosks.getAuctionOrders(selectedKiosk.id),
        ]).then((res) => {
          this.setState({ selectedKiosk: res[0].data, auctionOrders: res[1].data, loading: false });
        }).catch((err) => {
          this.setState({ loading: false });
          toast.error(err || `${p('errorUpdatingData')}!`);
        });
      }
    });
  }

  handleKioskState = () => {
    this.setState({ loading: true }, async () => {
      try {
        const selectedKiosk = await Api.kiosks.updateKioskStatus(this.state.selectedKiosk.id);
        this.setState({ selectedKiosk: selectedKiosk.data, loading: false });
      } catch (err) {
        this.setState({ loading: false });
        toast.error(err || p('errorUpdatingKiosk'));
      }
    });
  }

  handleRefundModalState = (_state = !this.state.refundModal.visible, id) => {
    const { refundModal } = this.state;
    refundModal.visible = _state;
    if (id) {
      refundModal.id = id;
    }
    this.setState({ refundModal });
  }

  handleUpdateProductState = async (_categoryIndex, _productIndex) => {
    const { kioskProducts } = this.state;
    try {
      const state = await Api.kiosks.updateProductState(this.state.selectedKiosk.id, kioskProducts[_categoryIndex].products[_productIndex].id);
      kioskProducts[_categoryIndex].products[_productIndex].active = Number(state.data);
      this.setState({ kioskProducts });
    } catch (err) {
      toast.error(err || `${p('errorChangingProduct')}!`);
    }
  }

  refundAction = () => {
    this.setState({ loading: true }, async () => {
      const { refundModal, orderHistory } = this.state;
      try {
        await Api.kiosks.refundOrder(refundModal.id);
        for (let i = 0; i < orderHistory.data.length; i++) {
          if (orderHistory.data[i].id === refundModal.id) {
            orderHistory.data[i].status = 'refunded';
            orderHistory.data[i].display_status = p('refund');
          }
        }
        refundModal.id = null;
        refundModal.visible = false;
        this.setState({ loading: false, refundModal, orderHistory });
      } catch (err) {
        refundModal.id = null;
        refundModal.visible = false;
        toast.error(err || p('errorRefundingOrder'));
        this.setState({ loading: false, refundModal });
      }
    });
  }

  handlePrint = (order) => {
    this.setState({ printOrder: order }, () => {
      window.print();
      this.setState({ printOrder: null });
    });
  }

  moveOrder = (order, moveTo, print = false) => {
    if (order.status === moveTo) {
      return;
    }
    if ((moveTo === 'completed' || this.state.automaticPrint) && print) {
      this.handlePrint(order);
    }
    this.setState({ loading: true }, async () => {
      let moveFromType = this.state.kioskOrders[order.status];
      let moveToType = this.state.kioskOrders[moveTo];
      try {
        const fetchedKiosk = await Api.kiosks.putKioskOrderStatus(order.id, { status: moveTo });
        moveFromType = moveFromType.filter(ord => ord.id !== order.id);
        moveToType = [fetchedKiosk.data, ...moveToType];
        const updatedKioskOrders = {
          ...this.state.kioskOrders,
          [order.status]: moveFromType,
          [moveTo]: moveToType,
        };
        this.setState({ kioskOrders: updatedKioskOrders, loading: false });
      } catch (err) {
        toast.error(err || p('errorUpdatingKioskOrders'));
        this.getKioskOrders();
      }
    });
  }

  sendPush = (_orderIndex) => {
    const { kioskOrders } = this.state;
    const orderID = kioskOrders.ready[_orderIndex].id;
    try {
      this.setState({ loading: true }, async () => {
        const updatedOrder = await Api.kiosks.sendOrderPush(orderID);
        kioskOrders.ready[_orderIndex] = updatedOrder.data;
        this.setState({ loading: false, kioskOrders });
      });
    } catch (err) {
      this.setState({ loading: false });
      toast.error(err || p('errorPushMessage'));
    }
  }

  sortKioskOrders = (_kioskOrders) => {
    const resKioskOrders = _kioskOrders;
    const filKioskOrders = {
      ready: [],
      processing: [],
      active: [],
    };
    for (let i = 0; i < resKioskOrders.data.length; i++) {
      if (resKioskOrders.data[i].bought_at === '0000-00-00 00:00:00') {
        resKioskOrders.data[i].timestamp = p('notPaid');
      } else {
        const time = moment(resKioskOrders.data[i].bought_at).fromNow();
        resKioskOrders.data[i].timestamp = time;
      }
      const now = moment(new Date());
      if (resKioskOrders.data[i].last_push === '0000-00-00 00:00:00') {
        resKioskOrders.data[i].min_since_push = 0;
      } else {
        const minutes = moment.duration(now.diff(moment(resKioskOrders.data[i].last_push))).asMinutes();
        if (resKioskOrders.data[i].min_since_push !== minutes) {
          resKioskOrders.data[i].min_since_push = minutes;
        }
      }
      if (resKioskOrders.data[i].status === 'ready') {
        filKioskOrders.ready.push(resKioskOrders.data[i]);
      } else if (resKioskOrders.data[i].status === 'processing') {
        filKioskOrders.processing.push(resKioskOrders.data[i]);
      } else if (resKioskOrders.data[i].status === 'active') {
        filKioskOrders.active.push(resKioskOrders.data[i]);
      }
    }
    return filKioskOrders;
  }

  saveAuctionOrder = () => {
    this.setState({ loading: true }, async () => {
      const payload = {
        auction_ids: [],
      };
      for (let i = 0; i < this.state.auctionOrders.length; i++) {
        payload.auction_ids.push(this.state.auctionOrders[i].id);
      }
      try {
        await Api.kiosks.updateAuctionOrder(this.state.selectedKiosk.id, payload);
      } catch (err) {
        toast.error(err || p('errorSavingOrder'));
      }
      this.setState({ loading: false });
    });
  }

  saveKioskProductOrder = () => {
    const IDs = this.state.kioskProducts.reduce((a, c) => a.concat(c.products.map(po => po.id)), []);
    const payload = {
      product_ids: IDs,
    };
    this.setState({ loading: true }, async () => {
      try {
        await Api.kiosks.updateProductOrder(this.state.selectedKiosk.id, payload);
        this.setState({ loading: false, productsModal: false });
      } catch (err) {
        toast.error(err || p('errorUpdatingOrder'));
        this.setState({ loading: false, productsModal: false });
      }
    });
  }

  updateTimestamps = () => {
    const now = moment(new Date());

    const { kioskOrders } = this.state;
    const types = ['active', 'processing', 'ready'];
    for (let i = 0; i < types.length; i++) {
      for (let x = 0; x < kioskOrders[types[i]].length; x++) {
        if (kioskOrders[types[i]][x].bought_at === '0000-00-00 00:00:00') {
          kioskOrders[types[i]][x].timestamp = p('notPaid');
        } else {
          const time = moment(kioskOrders[types[i]][x].bought_at).fromNow();
          if (kioskOrders[types[i]][x].timestamp !== time) {
            kioskOrders[types[i]][x].timestamp = time;
          }
        }

        if (kioskOrders[types[i]][x].last_push === '0000-00-00 00:00:00') {
          kioskOrders[types[i]][x].min_since_push = 0;
        } else {
          const minutes = moment.duration(now.diff(moment(kioskOrders[types[i]][x].last_push))).asMinutes();
          if (kioskOrders[types[i]][x].min_since_push !== minutes) {
            kioskOrders[types[i]][x].min_since_push = minutes;
          }
        }
      }
    }
    this.setState({ kioskOrders });
  }

  render() {
    const { printOrder } = this.state;
    return (
      <>
        <div className="kiosk">
          <DeleteModal
            visible={this.state.modalVisible}
            type="budet"
            handleModal={this.handleModal}
            modalAction={this.deleteBid}
          />
          <DeleteModal
            visible={this.state.refundModal.visible}
            type="Vipps"
            modalType="refund"
            handleModal={this.handleRefundModalState}
            modalAction={this.refundAction}
          />
          <Loading loading={this.state.loading} />
          <AlternativeNavbar
            getKiosks={this.getKiosks}
            selectedKiosk={this.state.selectedKiosk}
            handleRefresh={this.handleRefresh}
            handleKioskState={this.handleKioskState}
            toggleHistoryModal={this.toggleHistoryModal}
            toggleProductsModal={this.toggleProductsModal}
            toggleAutomaticPrint={this.toggleAutomaticPrint}
            automaticPrint={this.state.automaticPrint}
          />
          {this.state.selectedKiosk.id === undefined &&
            <ListKiosks
              kiosks={this.state.kiosks}
              selectedKiosk={this.state.selectedKiosk}
              setSelectedKiosk={this.setSelectedKiosk}
            />
          }
          {this.state.selectedKiosk.auction === 1 &&
            <Auction
              auctionOrders={this.state.auctionOrders}
              auctionBids={this.state.auctionBids}
              getAuctionBids={this.getAuctionBids}
              handleModal={this.handleModal}
              selectedAuctionOrder={this.state.selectedAuctionOrder}
              saveAuctionOrder={this.saveAuctionOrder}
              setAuctionOrders={this.setAuctionOrders}
            />
          }
          {this.state.selectedKiosk.auction === 0 &&
            <>
              <HistoryModal
                kiosk={this.state.selectedKiosk}
                visible={this.state.historyModal}
                toggle={this.toggleHistoryModal}
                orderHistory={this.state.orderHistory}
                getOrderHistory={this.getOrderHistory}
                handleRefundModalState={this.handleRefundModalState}
                handlePrint={this.handlePrint}
              />
              <ProductsModal
                visible={this.state.productsModal}
                toggle={this.toggleProductsModal}
                categories={this.state.kioskProducts}
                getProducts={this.getKioskProducts}
                setProducts={this.setkioskProducts}
                setProductHidden={this.setKioskProductHidden}
                saveKioskProductOrder={this.saveKioskProductOrder}
                handleUpdateProductState={this.handleUpdateProductState}
              />
              <Kiosk
                kioskOrders={this.state.kioskOrders}
                selectedKiosk={this.state.selectedKiosk}
                moveOrder={this.moveOrder}
                onlyShowOwn={this.state.onlyShowOwn}
                setOnlyShowOwn={this.setOnlyShowOwn}
                updateTimestamps={this.updateTimestamps}
                sendPush={this.sendPush}
                automaticPrint={this.state.automaticPrint}
              />
            </>
          }
        </div>
        {printOrder &&
          <div className="print-order">
            <div className="print-order-box">
              <div className="print-order-line">
                <span className="print-order-name">
                  {this.state.selectedKiosk.name}
                </span>
                <span className="print-order-identifier">
                  #{printOrder.identifier}
                </span>
              </div>
            </div>

            <div className="print-order-box">
              <p>
                {printOrder.user?.firstname}
                {' '}
                {printOrder.user?.lastname}
                {' '}
                ({printOrder.user?.username})
              </p>
              <p>
                <span className="bold-text">
                  {p('bought')}:
                </span>
                {' '}
                {moment(printOrder.bought_at).calendar()}
                {' '}
                {printOrder.payment_method}
              </p>
              <p>
                <span className="bold-text">
                  {p('incl')}
                </span>
                {' '}
                {s('taxCaps')} {printOrder.tax}%
              </p>
            </div>

            <div className="print-order-box">
              {printOrder.products.map(product => (
                <div className="print-order-line" key={`product-${product.id}`}>
                  <p>{product.pivot.amount}x {product.name} - {product.subtitle}</p>
                  <p>{product.price}</p>
                </div>
              ))}
            </div>
          </div>
        }
      </>
    );
  }
}

export default KioskPage;
