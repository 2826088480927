import t, { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateQuestionForm = async (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('nameFieldEmpty');
  }
  // if (!values.headingImage) {
  //   errors.headingImage = p('picFieldCantBeEmpty');
  // }

  // if (!values.description) {
  //   errors.description = p('descriptionFieldCantBeEmpty');
  // }
  if (!values.type) {
    errors.description = p('questionTypeFieldCantBeEmpty');
  }
  if (values.imageHeaderType?.value === 'VIDEO' && values.headingVideos?.length) {
    if (values.headingVideos.some((videoId) => videoId.length !== 11)) {
      errors.headingVideos = t('NewsForm.youTubeError');
    }
  }
  if (Object.keys(errors).length) {
    throw errors;
  }
};
export default validateQuestionForm;
