import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  propTypes as reduxFormProps,
  getFormValues,
} from 'redux-form';
import {
  Col,
  Row,
  Container,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { toast } from 'react-toastify';
import validateEventsContact from './validateEventsContact';
import renderField from '../../../shared/components/custom/Field';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import t, { partial } from '../../../util/translation/translation';
import Api from '../../../util/api';
import DeleteModal from '../../../shared/components/custom/DeleteModal';
import useModal from '../../../shared/hooks/useModal';

const EventsContactForm = ({
  handleSubmit,
  initialize,
  formValues,
  eventID,
  scheduleMessageID,
  setScheduleMessageID,
  scheduleMessagesForEvent,
  getScheduleMessagesByServiceId,
  clearContactPage,
  setClearContactPage,
  startDate,
  setStartDate,
  setLoading,
}) => {
  const p = partial('EventsContactForm');
  const m = partial('MessagesForm');

  const { state } = useLocation();
  const [handleModal, visible, deleteId] = useModal();

  const initializeEdit = (message) => {
    setScheduleMessageID(message.id);
    if (formValues) {
      initialize({
        title:
          message?.payload?.placeholders?.TITLE ||
          message?.payload?.placeholders?.SUBJECT,
        content: message?.payload?.placeholders?.BODY,
        type: message?.payload?.channels[0]?.channel,
        receiverType: message?.payload?.eventInfo?.receiverType,
        scheduleTime: message.date
          ? setStartDate(new Date(message.date))
          : null,
        timeToSend: 'SCHEDULED',
      });
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const clearFields = () => {
    setClearContactPage(false);
    setScheduleMessageID(null);
    initialize({
      title: '',
      content: '',
      type: 'push',
      receiverType: 'PARTICIPANTS',
      scheduleTime: setStartDate(''),
      timeToSend: 'INSTANT',
    });
  };

  const removeMessage = async (id) => {
    setLoading(true);
    try {
      await Api.events.deleteMessage(eventID, id);
      handleModal();
      getScheduleMessagesByServiceId();
    } catch (err) {
      setLoading(false);
      toast.error(err || 'errorRemovingMessage');
    }
  };

  useEffect(() => {
    clearFields();
  }, [clearContactPage]);

  useEffect(() => {
    if (state?.messageId && scheduleMessagesForEvent.length > 0) {
      const messageToShow = scheduleMessagesForEvent.find(
        (msg) => msg.id === state.messageId,
      );
      initializeEdit(messageToShow);
    }
  }, [scheduleMessagesForEvent]);

  const renderSubmitButtons = () => {
    let buttonName = '';
    if (scheduleMessageID) {
      buttonName = t('ScheduledMessagesPage.updateMessage');
    } else if (formValues?.timeToSend === 'SCHEDULED') {
      buttonName = t('ScheduledMessagesPage.scheduleMessage');
    } else {
      buttonName = p('sendMessage');
    }

    return (
      <div>
        <Button type="submit" style={{ display: 'block' }} color="primary">
          {buttonName}
        </Button>
        <Link className="py-3" style={{ textDecoration: 'underline' }} to="/messages?tab=2">{t('MessagesForm.scheduledAndHistory')}</Link>
      </div>
    );
  };

  const filterPassedTime = (time) => {
    const isSameDay = moment().diff(moment(time), 'days') === 0;
      if (isSameDay) {
        return moment(time).isSameOrAfter(moment());
      }
      return true;
  };

  return (
    <form onSubmit={handleSubmit} className="form d-flex">
      <Container className="flex">
        <DeleteModal
          visible={visible}
          handleModal={handleModal}
          type="message"
          modalAction={() => removeMessage(deleteId)}
        />
        <div className="flex-1 marginRight-15">
          <Row>
            <Col md={12}>
              <div>
                <span className="form-header">{t('shared.type')}</span>
                <div className="radio-horizontal">
                  <Col md={6}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="type"
                          component={renderRadioButtonField}
                          label="SMS"
                          radioValue="sms"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="type"
                          component={renderRadioButtonField}
                          label="Email"
                          radioValue="email"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="form__form-group margin-0">
                      <div className="form__form-group-field">
                        <Field
                          name="type"
                          component={renderRadioButtonField}
                          label={p('pushMessage')}
                          radioValue="push"
                          defaultChecked
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{t('shared.title')}</span>
                {formValues?.type === 'sms' ? (
                  <Field
                    name="title"
                    placeholder={`${t('shared.title')} (${t(
                      'EventsContactForm.appNameIfEmpty',
                    )})`}
                    component={renderField}
                    disabled
                  />
                ) : (
                  <div
                    className="form__form-group-field"
                    style={{ margin: '-5px -10px' }}
                  >
                    <Field
                      name="title"
                      placeholder={`${t('shared.title')} (${t(
                        'EventsContactForm.appNameIfEmpty',
                      )})`}
                      component={renderField}
                      emoji
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{t('shared.content')}</span>
                <div
                  className="form__form-group-field"
                  style={{ margin: '-5px -10px' }}
                >
                  <Field
                    name="content"
                    placeholder={p('messageContent')}
                    component={renderTextAreaField}
                    emoji
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <span className="form-header">{p('sendTo')}</span>
              <div className="radio-horizontal">
                <Col md={6}>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="receiverType"
                        component={renderRadioButtonField}
                        label={p('participants')}
                        radioValue="PARTICIPANTS"
                        defaultChecked
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="receiverType"
                        component={renderRadioButtonField}
                        label={p('invited')}
                        radioValue="PRIVILAGED"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={9}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="receiverType"
                        component={renderRadioButtonField}
                        label={p('participantsNInvited')}
                        radioValue="ALL"
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={12}>
              <span className="form-header">
                {t('ScheduledMessagesPage.timeToSend')}
              </span>
              <div className="radio-horizontal">
                <Col md={6}>
                  <div className="form__form-group margin-0">
                    <div className="form__form-group-field">
                      <Field
                        name="timeToSend"
                        component={renderRadioButtonField}
                        label={t('shared.instant')}
                        radioValue="INSTANT"
                        defaultChecked
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="timeToSend"
                        component={renderRadioButtonField}
                        label={t('shared.scheduled')}
                        radioValue="SCHEDULED"
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={7}>
              {formValues.timeToSend === 'SCHEDULED' && (
                <div className="form__form-group" style={{ zIndex: 100 }}>
                  <span className="form-header">
                    {t('ScheduledMessagesPage.scheduleTime')}
                  </span>
                  <div className="form__form-group-field">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale="pt-BR"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      placeholderText="DD / MM / YYYY  -- : --"
                      disabledKeyboardNavigation
                    />
                    <div className="form__form-group-icon">
                      <TimetableIcon />
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="flex-1 marginLeft-8">
          <span>&nbsp;</span>
          <div className="flex align-items-center p-3 border">
            <div className="modal-icon m-2">
              <AlertCircleOutlineIcon size="38" />
            </div>

            <div>
              <p>{p('pushMessageGroups')}</p>
              <p>{m('AppStorePushGuidelines')}</p>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        {renderSubmitButtons()}
        {scheduleMessageID && (
          <Button onClick={clearFields}>{t('shared.clear')}</Button>
        )}
      </Container>
    </form>
  );
};

EventsContactForm.propTypes = {
  ...reduxFormProps,
  handleSubmit: PropTypes.func.isRequired,
  event: PropTypes.shape({}),
  formValues: PropTypes.shape({
    type: PropTypes.string,
  }),
  // checkCount: PropTypes.func.isRequired,
};

EventsContactForm.defaultProps = {
  event: null,
  formValues: {},
};

const reduxF = reduxForm({
  form: 'EventsContactForm',
  validate: validateEventsContact,
});

const mapStateToProps = (state) => ({
  formValues: getFormValues('EventsContactForm')(state),
});

export default reduxF(connect(mapStateToProps)(EventsContactForm));
