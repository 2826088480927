/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Badge, Col, Row, Card, NavLink, PopoverBody, Table, UncontrolledPopover } from 'reactstrap';
import { toast } from 'react-toastify';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ChartTimelineVariantIcon from 'mdi-react/ChartTimelineVariantIcon';
import t from '../../../util/translation/translation';
import Pagination from '../pagination/Pagination';
import Loading from '../custom/Loading';
import Api from '../../../util/api';
import { formatDateTime } from '../../../util/date-and-time';
import skeletonTableLoader from '../custom/SkeletonLoaders';
import { getMessageType, getOriginLink, getTitleAndBody } from './utils';

const SentMessagesTable = () => {
  const [order, setOrder] = useState({
    page: 1,
    size: 10,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const handleDataReceived = (incommingStatData) => {
    setMessages((previousMessages) => {
      return previousMessages.map((previousMessage) => {
        const existingMessagePushStatIds = previousMessage?.pushStats?.map((item) => item.id) || [];
        if (!existingMessagePushStatIds.includes(incommingStatData.id)) {
          return previousMessage;
        }
        const countOfSentTo = previousMessage?.pushStats?.reduce((tot, curr) => tot + curr.successCount, 0);
        const totalLabels = [...(previousMessage?.mappedPushStat?.labels || []), ...incommingStatData.labels];
        const userIdsWatched = totalLabels.flatMap((label) => label.user_ids);
        const amountOfUsers = [...new Set(userIdsWatched)].length;
        return {
          ...previousMessage,
          mappedPushStat: {
            id: incommingStatData.id,
            success_count: countOfSentTo,
            opened_count: amountOfUsers,
            openedPercentage: countOfSentTo ? Math.round(
              (amountOfUsers / countOfSentTo) * 100,
            ) : 0,
            labels: totalLabels,
          },
        };
      });
    });
  };
  const getSendMessages = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const { data: scheduledMessagesRes } = await Api.scheduleMessages.getScheduleMessages(page, pageSize, 'Sent');
      const filledData = await Promise.all(
        scheduledMessagesRes.data.map((message) => getOriginLink(message).then((originalLinkResult) => (
          {
            ...message,
            originalLinkResult,
          }
        )).catch(() => (
          {
            ...message,
            originalLinkResult: { title: null, link: null },
          }
        ))));
      const pushStatIds = scheduledMessagesRes.data.flatMap((message) => message?.pushStats?.map((pushStat) => pushStat.id)).filter((item) => !!item);
      setMessages(filledData);
      setLoading(false);
      setTotal(scheduledMessagesRes.total);
      await Api.scheduleMessages.getStatsForMessages(pushStatIds, handleDataReceived);
    } catch (err) {
      toast.error(err || 'error');
      setLoading(false);
    }
  };

  const onChangePageSystem = (pager) => {
    if (order.page !== pager.currentPage || order.size !== pager.pageSize) {
      getSendMessages(pager.currentPage, pager.pageSize);
      setOrder({
        page: pager.currentPage,
        size: pager.pageSize,
      });
    }
  };
  useEffect(() => {
    getSendMessages();
  }, []);
  const renderSentMessages = () => {
    return messages.map((message, i) => {
      let typeColor;
      const messageType = getMessageType(message);
      switch (messageType) {
        case 'push': typeColor = 'success'; break;
        case 'sms': typeColor = 'danger'; break;
        case 'email': typeColor = 'warning'; break;
        default: typeColor = 'default';
      }
      const { title, link } = message.originalLinkResult;
      const { messageTitle, messageBody } = getTitleAndBody(message);
      return (
        <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`renderScheduledMessages-${message.id}`}>
          <td>
            {messageTitle}
          </td>
          {/* eslint-disable-next-line no-nested-ternary */}
          <td>{message?.date ? formatDateTime(message?.date) : (message?.createdAt ? formatDateTime(message?.createdAt) : '-- --')}</td>
          <td><Badge color={typeColor}>{messageType}</Badge></td>
          <td>
            {message?.externalSender?.entityName &&
              <NavLink href="false" disabled="true">
                {message?.externalSender?.entityName}
              </NavLink>
            }
            {!message?.externalSender?.entityName &&
              <NavLink href={link} disabled={!link}>
                {title}
              </NavLink>
            }
          </td>
          {message?.pushStats?.length ?
            <td>
              {message?.mappedPushStat?.success_count !== undefined ?
                message?.mappedPushStat?.success_count :
                skeletonTableLoader(1, 1, 50)}
            </td> : <td />}
          {message?.pushStats?.length ?
            <td>
              {message?.mappedPushStat?.openedPercentage !== undefined ?
                <Link to={{ pathname: `/messages/insight/${message.id}`, state: message }}>
                  <ChartTimelineVariantIcon /> {`${message?.mappedPushStat?.openedPercentage} %`}
                </Link> :
                skeletonTableLoader(1, 1, 50)}
            </td> : <td />}
          <td>
            <Button size="sm" className="margin-0 py-1 px-2" id={`messageBody${message.id}`} outline><EyeIcon className="m-0" /></Button>
            <UncontrolledPopover trigger="legacy" placement="left " target={`messageBody${message.id}`}>
              <PopoverBody>
                <div className="margin-5">{t('shared.title')}: {messageTitle}<hr className="my-1" />{t('shared.description')}: {messageBody}</div>
              </PopoverBody>
            </UncontrolledPopover>
          </td>
        </tr>
      );
    });
  };
  return (
    <Card>
      <Loading loading={loading} />
      <Col>
        <Row>
          <h3 className="page-title mb-0">{t('ScheduledMessagesPage.sentMessages')}</h3>
        </Row>
      </Col>
      <div style={{ display: 'flex', paddingBottom: 5, marginTop: 5, background: '#fff', borderRadius: 6, border: '1px solid rgba(0,0,0,0.08)' }}>
        <Table responsive striped>
          <thead>
            <tr>
              <th>{t('shared.title')}</th>
              <th>{t('shared.publication')}</th>
              <th>{t('shared.type')}</th>
              <th>{t('shared.origin')}</th>
              <th>{t('InsightPage.recieved')}</th>
              <th>{t('InsightPage.open')}</th>
              <th>{t('shared.message')}</th>
            </tr>
          </thead>
          <tbody>
            {renderSentMessages()}
          </tbody>
        </Table>
      </div>
      <Pagination
        items={total}
        onChangePage={onChangePageSystem}
        initialPage={0}
      />
    </Card>
  );
};



export default SentMessagesTable;
