import React from 'react';
import {
    Container,
} from 'reactstrap';
import SpotlightGroupListPage from './SpotlightGroupListPage';


const SpotlightPage = () => {
    return (
      <Container>
        <SpotlightGroupListPage />
      </Container>
    );
};

export default SpotlightPage;
