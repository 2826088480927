import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Close from 'mdi-react/CloseCircleOutlineIcon';
import FormBuilder from './FormBuilder';
import { partial } from '../../../util/translation/translation';

const QuestionModal = ({ showModal, onClose, editableQuestion }) => {
  const qT = partial('question');
  const promptTitle = editableQuestion ? qT('edit') : qT('addBtn');

  return (
    <Modal
      modalClassName="question-modal"
      isOpen={!!showModal}
      toggle={onClose}
    >
      <ModalHeader className="navbar-modal-header">
        <div className="modal-header-title">{promptTitle}</div>
        <div className="float-right">
          <button className="marginBottom-0 builder-button" onClick={onClose}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <FormBuilder
          setShowModal={onClose}
          editableQuestion={editableQuestion}
        />
      </ModalBody>
    </Modal>
  );
};
export default QuestionModal;
