export default {
  club_name: 'Narvik',
  club_name_short: 'NIK',
  stadium_name: 'Narvik Ishall',
  staging_url: 'https://narvik.api.fangroup.io',
  prod_url: 'https://narvik.api.fangroup.io',
  api: '/api',
  facebook: 'arcticeagles',
  basename: '/narvik',
  club_logo: '/assets/teams/narvik.png',
  app_name: 'narvik',
  websale_name: 'nar',
  ntb_team_id: 137,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: false,
  cloudIdName: 'narvik',
  monolithEnabled: false,
};
