export default {
  club_name: 'Frisk Asker',
  club_name_short: 'FRISK',
  stadium_name: 'Varner Arena',
  staging_url: 'https://frisk.api.fangroup.io',
  prod_url: 'https://frisk.api.fangroup.io',
  api: '/api',
  facebook: 'Friskasker',
  basename: '/frisk',
  club_logo: '/assets/teams/frisk.png',
  app_name: 'frisk',
  websale_name: 'fa',
  ntb_team_id: 128,
  microservice_url: 'https://stats.api.fangroup.io',
  currencyCaps: 'NOK',
  currencyShort: 'Kr',
  currencyShortSmall: 'kr',
  clubSportalityAvailable: true,
  leagueSportalityAvailable: false,
  cloudIdName: 'frisk',
  monolithEnabled: false,
};
