import React, { useState, useEffect } from 'react';
import { Col, Table, Button, Modal, Row } from 'reactstrap';
import HelpOutlineIcon from 'mdi-react/HelpOutlineIcon';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CloseCircleOutline from 'mdi-react/CloseCircleOutlineIcon';
import { groupBy, uniq } from 'ramda';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import Loading from '../../../shared/components/custom/Loading';
import Pagination from '../../../shared/components/pagination/Pagination';

const LotteryStats = ({ lotteryID, lottery }) => {
  const [chartData, setChartData] = useState([]);
  const [ticketHolders, setTicketHolders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refundTickets, setRefundTickets] = useState([]);
  const [ticketUsers, setTicketUsers] = useState({});
  const s = partial('shared');
  const p = partial('LotteryStats');
  const getUserIdsFromTicketHolders = async (tickets) => {
    const userIds = uniq(tickets.map((ticket) => ticket.userId).filter((id) => !Object.keys(ticketUsers).includes(id)));
    const { data: usersByIds } = await Api.users.getUsersByCloudIds(userIds);
    const usrs = groupBy((usr) => usr.userCloudId, usersByIds);
    setTicketUsers({
      ...ticketUsers,
      ...usrs,
    });
  };

  const getTicketHolders = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const resSystem = await Api.lottery.tickets(lotteryID, page, pageSize);
      await getUserIdsFromTicketHolders(resSystem.data.data);
      setTicketHolders(resSystem.data);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const getRefundInformation = (id, userId) => async () => {
    try {
      const { data } = await Api.lottery.getRefundInformation(id, userId);
      const refundUsers = data.map(d => d.extraData.refundRequestUserId);
      const { data: refCloudUsers } = await Api.users.getUsersByCloudIds(refundUsers);
      const tikts = data.map((t) => ({
        ...t,
        refundUser: refCloudUsers.find((r) => r.userCloudId === t.extraData.refundRequestUserId),
      }));
      setRefundTickets(tikts);
    } catch (e) {
      toast.error(e);
    }
  };
  const renderTicketHolders = (ticketHolderList) => {
    return ticketHolderList?.data.map(({ userId, amount, paymentStatus }) => (
      <tr key={`ticketHolder-${userId}`}>
        <td>{`${ticketUsers[userId]?.[0].firstname} ${ticketUsers[userId]?.[0].lastname}`}</td>
        <td>{ticketUsers[userId]?.[0].phoneNumber}</td>
        <td>{ticketUsers[userId]?.[0].email}</td>
        <td>{amount}</td>
        <td>
          <span className={paymentStatus === 'REFUNDED' ? 'flex-center' : ''}>
            {`${paymentStatus}`} {paymentStatus === 'REFUNDED' ? <button className="Help-btn-refund" onClick={getRefundInformation(lotteryID, userId)}><HelpOutlineIcon size="20" /></button> : null}
          </span>
        </td>
      </tr>
    ));
  };
  const onChangePageSystem = (pager) => {
    getTicketHolders(pager.currentPage, pager.pageSize);
  };

  const getChartData = async (id) => {
    const cd = await Api.lottery.getChartData(id);
    setChartData({
      labels: cd.data.labels,
      datasets: [
        {
          label: p('distributionOfSales'),
          data: cd.data.data,
          backgroundColor: '#0469DC',
          borderColor: '#404d46',
        },
      ],
    });
  };
  useEffect(() => {
    getTicketHolders();
    if (lotteryID) { getChartData(lotteryID); }
  }, [lotteryID, lottery]);
  return (
    <>
      <Loading loading={loading} />
      <Modal isOpen={refundTickets.length > 0} onClick={() => setRefundTickets([])}>
        <div className="modal">
          <div className="modal-container">
            <div className="modal-icon">
              <CloseCircleOutline size="128" color="#ff4861" />
            </div>
            <div className="modal-text">
              <h3>{p('refundInformation')}</h3>
            </div>
            <div className="modal-text">
              <Table responsive className="overflow-auto">
                <thead>
                  <tr>
                    <th>{s('user')}</th>
                    <th>{p('refundedBy')}</th>
                    <th>{s('date')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  refundTickets.map((rt) => (
                    <tr key={`rfid:${rt.id}`}>
                      <td>
                        {`${rt.user.firstname} ${rt.user.lastname}`}
                      </td>
                      <td>{`${rt.refundUser.firstname} ${rt.refundUser.lastname}`}
                      </td>
                      <td>{moment(rt.updateAt).format('YYYY-MM-DD hh:mm:ss')}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </div>
            <div className="modal-buttons">
              <Button
                className="marginBottom-0"
                color="info"
                onClick={() => setRefundTickets([])}
              >OK
              </Button>

            </div>
          </div>
        </div>
      </Modal>
      <Row>
        <div className="margin-10" />
        <Col lg={12} md={12}>
          <div className="card__title">
            <h4 className="bold-text">{p('saleStats')}</h4>
          </div>
          <Line data={chartData} />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <div className="card__title marginTop-15">
            <h4 className="bold-text">{p('participants')}</h4>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>{p('name')}</th>
                <th>{p('phone')}</th>
                <th>{p('email')}</th>
                <th>{p('numberOfTickets')}</th>
                <th>{p('paymentStatus')}</th>
              </tr>
            </thead>
            <tbody>
              {renderTicketHolders(ticketHolders)}
            </tbody>
          </Table>
          <Pagination
            items={ticketHolders?.total ?? 0}
            pageSize={10}
            onChangePage={onChangePageSystem}
          />
        </Col>
      </Row>
      <div>
        <Link to="/lottery"><Button>{s('close')}</Button></Link>
      </div>
    </>
  );
};

LotteryStats.propTypes = {
  lotteryID: PropTypes.string.isRequired,
  lottery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    saleStart: PropTypes.string.isRequired,
    saleEnd: PropTypes.string.isRequired,
    price: PropTypes.number,
    prizes: PropTypes.array,
    image: PropTypes.string,
  }).isRequired,
};

export default LotteryStats;
