import { selector } from 'recoil';
import showDocs from './atom';

const showDocsState = selector({
  key: 'showDocsState',
  get: ({ get }) => get(showDocs),
  set: ({ set }, show) => set(showDocs, show),

});
export default showDocsState;
