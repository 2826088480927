import {
  SET_CREATE_OR_EDIT_TEAM,
} from '../actions/teamActions';

const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CREATE_OR_EDIT_TEAM:
      return action.team;
    default:
      return state;
  }
}
