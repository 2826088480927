import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'reactstrap';
import TimetableIcon from 'mdi-react/TimetableIcon';
import moment from 'moment';
import validate from './userValidate';
import renderField from '../../../shared/components/custom/Field';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { partial } from '../../../util/translation/translation';

const p = partial('UserForm');
const s = partial('shared');

class UserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      country_code: PropTypes.number,
      username: PropTypes.string,
      email: PropTypes.string,
      zip: PropTypes.string,
      date_of_birth: PropTypes.string,
      gender: PropTypes.string,
      verified: PropTypes.number,
      companies: PropTypes.array,
    }),
    initialize: PropTypes.func.isRequired,
    me: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    me: undefined,
    user: null,
  };

  state = {
    showPassword: false,
    hasInitialized: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== null && this.state.hasInitialized === false) {
      this.props.initialize({
        firstName: nextProps.user.firstname,
        lastName: nextProps.user.lastname,
        countryCode: nextProps.user?.countryCode?.toString(),
        username: nextProps.user.username,
        email: nextProps.user.email,
        postalCode: nextProps?.user?.zip?.toString(),
        birthday: nextProps.user.dateOfBirth ? moment(nextProps.user.dateOfBirth).format('YYYY-MM-DD') : '',
        gender: nextProps.user.gender,
        verified: !!nextProps.user.verified,
      });
      this.setState({ hasInitialized: true });
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="form">
          <Row className="width-100p">
            <Col>
              <div className="card__title">
                <h5 className="bold-text">{ this.props.user ? '' : p('newUser')}</h5>
              </div>
            </Col>
          </Row>
          <Row className="width-100p">
            <Col md={6}>
              <div className="form__form-group">
                <p className="form-header">{s('firstname')}</p>
                <Field
                  name="firstName"
                  component={renderField}
                  type="text"
                  placeholder={p('placeHolderFirstname')}
                  disabled={!!this.props.user}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group">
                <p className="form-header">{s('lastname')}</p>
                <Field
                  name="lastName"
                  component={renderField}
                  type="text"
                  placeholder={p('placeHolderLastName')}
                  disabled={!!this.props.user}
                />
              </div>
            </Col>
          </Row>
          <Row className="width-100p">
            <Col xl={2} lg={2} md={12} sm={12}>
              <div className="form__form-group">
                <p className="form-header">{s('countryCode')}</p>
                <Field
                  name="countryCode"
                  component={renderField}
                  type="number"
                  placeholder={s('countryCodePlaceHolder')}
                  disabled={!!this.props.user}
                />
              </div>
            </Col>
            {
              this.props.type === 'create' ?
                <>
                  <Col xl={4} lg={4} md={6} sm={12}>
                    <div className="form__form-group">
                      <p className="form-header">{p('username(Phonenumber)')}</p>
                      <Field
                        name="username"
                        component={renderField}
                        type="text"
                        placeholder="12345678"
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="form__form-group">
                      <p className="form-header">E-Mail</p>
                      <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder="E-Mail"
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="form__form-group">
                      <p className="form-header">{s('password')}</p>
                      <Field
                        name="password"
                        component={renderField}
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder={s('password')}
                      />
                    </div>
                  </Col>
                </>
                :
                <>
                  <Col xl={4} lg={4} md={12} sm={12}>
                    <div className="form__form-group">
                      <p className="form-header">{p('username(Phonenumber)')}</p>
                      <Field
                        name="username"
                        component={renderField}
                        type="text"
                        placeholder="12345678"
                        disabled={!!this.props.user}
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className="form__form-group">
                      <p className="form-header">E-Mail</p>
                      <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder=""
                        disabled={!!this.props.user}
                      />
                    </div>
                  </Col>
                </>

            }
          </Row>
          <Row className="width-100p">
            <Col md={6}>
              <div className="form__form-group">
                <p className="form-header">{s('postCode')}</p>
                <Field
                  name="postalCode"
                  component={renderField}
                  type="number"
                  placeholder="1234"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form__form-group">
                <p className="form-header">{s('dateOfBirth')}</p>
                <div className="flex">
                  <div className="flex-10">
                    <Field
                      name="birthday"
                      component={renderField}
                      type="date"
                    />
                  </div>
                  <div className="form__form-group-icon">
                    <TimetableIcon />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {this.props.userCompanies?.length > 0 &&
          <Row className="width-100p">
            <Col md={6}>
              <div className="form__form-group">
                <p className="form-header">{p('companies')}</p>
                {this.props.userCompanies?.map(company => (<p key={`companyList${company.name}`}><Link to={`/companies/edit/${company.id}?tab=1`}>{company.name}</Link></p>))}
              </div>
            </Col>
          </Row>}
          <Row className="width-100p">
            <Col>
              <div className="form">
                <p className="form-header">{s('sex')}</p>
                <div className="gender-horizontal">
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="gender"
                        component={renderRadioButtonField}
                        label={s('man')}
                        radioValue="male"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="gender"
                        component={renderRadioButtonField}
                        label={s('woman')}
                        radioValue="female"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Button type="submit" color="primary">{s('save')}</Button>
            <Link className="color-unset" to="/users"><Button type="button">{s('close')}</Button></Link>
          </Col>
        </Row>
      </form>
    );
  }
}
const reduxF = reduxForm({
  form: 'UserForm',
  validate,
});

export default withRouter(connect(state => ({
  me: state.admin.me,
}))(reduxF(UserForm)));
