import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'reactstrap';
import validateLogin from './validateLogin';
import renderField from '../../../shared/components/custom/Field';
import t from '../../../util/translation/translation';

const ResetPasswordForm = ({ handleSubmit, loading, goBack }) => {
  const GoBack = () => {
    return (
      <div
        className="form__form-group-field marginBottom-10 pointerButton"
        role="button"
        tabIndex={0}
        onClick={goBack}
      >
        <p>{t('LoginPage.goBack')}</p>
      </div>
    );
  };
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{t('LoginPage.enterEmail')}</span>
        <div className="form__form-group-field">
          <Field
            name="email"
            component={renderField}
            placeholder={t('EmailLogInForm.email')}
            autoComplete="email"
          />
        </div>
      </div>
      <GoBack/>
      <Button color="primary" className="width-100p" disabled={loading}>
        {t('LoginPage.resetPassword')}
      </Button>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'log_in_form',
  validateLogin,
});

export default reduxF(ResetPasswordForm);
