import React, { useEffect } from 'react';
import { Button, Col, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderDateTimePickerField from '../../../shared/components/form/DateTimePicker';

const MatchForm = ({ handleSubmit, initialize, match, teams }) => {
  useEffect(() => {
    if (match) {
      // console.log(formatDateTime(new Date(match.matchStart)), match.matchStart);
      initialize({
        ...match,
        administrativeTeam: { label: match.administrativeTeam.name, value: match.administrativeTeam },
        matchStart: new Date(match.matchStart),
        homeTeam: { label: match.homeTeam.name, value: match.homeTeam },
        awayTeam: { label: match.awayTeam.name, value: match.awayTeam },
      });
    }
  }, [match]);
  const mappedTeams = teams.map((t) => ({ label: t.name, value: t }));
  return (
    <form onSubmit={handleSubmit} className="form">
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="matchStart">Match start date</Label>
          <Field
            name="matchStart"
            component={renderDateTimePickerField}
            timeFormat="HH:mm:ss"
            dateFormat="yyyy-MM-dd HH:mm:ss"
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="administrativeTeam">Managed by</Label>
          <Field
            name="administrativeTeam"
            component={renderSelectField}
            options={mappedTeams}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="homeTeam">HomeTeam</Label>
          <Field
            name="homeTeam"
            component={renderSelectField}
            options={mappedTeams}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="awayTeam">Away Team</Label>
          <Field
            name="awayTeam"
            component={renderSelectField}
            options={mappedTeams}
          />
        </div>
      </Col>
      <Col md={6}>
        <div className="form__form-group">
          <Label htmlFor="round">Round</Label>
          <Field
            name="round"
            component={renderField}
            type="number"
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="info">Submit</Button>
        <Link to="/clubadmin?tab=4"><Button color="secondary">Close</Button></Link>
      </Col>
    </form>
  );
};

const rf = reduxForm({
  form: 'match_admin_form',
});

export default rf(MatchForm);
