import { partial } from '../../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');

  const errors = {};
  if (!values.title) {
    errors.title = p('titleFieldCantBeEmpty');
  }

  if (!values.percentage) {
    errors.percentage = p('pricePercentFieldCantBeEmpty');
  }

  return errors;
};

export default validate;
