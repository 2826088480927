import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import validateOneTimeCode from './validateOneTimeCode';
import renderField from '../../../shared/components/custom/Field';
import { partial } from '../../../util/translation/translation';

const p = partial('OneTimeCodeForm');
const OneTimeCodeForm = ({ username, handleSubmit }) => {
  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">{p('smsSentTo')} ({username})</span>
        <div className="form__form-group-field">
          <Field
            name="code"
            component={renderField}
            type="number"
            placeholder={p('oneTimeCode')}
          />
        </div>
      </div>
      <Button
        color="primary"
        className="width-100p"
      >
        {p('verify')}
      </Button>
    </form>
  );
};

OneTimeCodeForm.propTypes = {
  username: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const reduxF = reduxForm({
  form: 'one_time_code_form',
  validateOneTimeCode,
});

export default reduxF(OneTimeCodeForm);

