import React, { useEffect } from 'react';
import { Col, Row, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../../../../shared/components/form/Select';
import renderSearchSelectField from '../../../../../../../shared/components/custom/SearchSelect';
import validate from '../validateAssign';
import { partial } from '../../../../../../../util/translation/translation';

const AssignTicketForm = (props) => {
  const p = partial('assignTicketForm&PresaleFormShare');
  const t = partial('MatchTicketsList');
  const s = partial('shared');
  const { handleSubmit, onClose, editUsersTickets } = props;
  useEffect(() => {
    props.change('editUsersTickets', editUsersTickets);
  }, [editUsersTickets]);
  return (
    <form className="form full-width" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{t('ticketStatus')}</span>
              <div className="marginTop-5" />
              <Field
                name="ticketState"
                component={renderSelectField}
                options={props.ticketStates}
                placeholder={editUsersTickets ? p('keepCurrentValue') : p('chooseTicketStatus')}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form-header">{s('priceGroup')}</span>
              <div className="marginTop-5" />
              <Field
                name="priceGroup"
                component={renderSelectField}
                options={props.matchPrices}
                placeholder={editUsersTickets ? p('keepCurrentValue') : p('choosePriceGroup')}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <span className="form-header">{p('shareableTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="shareable"
                  component={renderRadioButtonField}
                  label={s('yes')}
                  radioValue="1"
                  defaultChecked={!editUsersTickets}
                />
              </div>
            </div>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="shareable"
                  component={renderRadioButtonField}
                  label={s('no')}
                  radioValue="0"
                />
              </div>
            </div>
            {editUsersTickets &&
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="shareable"
                    component={renderRadioButtonField}
                    label={p('keepCurrentValue')}
                    radioValue={undefined}
                    defaultChecked
                  />
                </div>
              </div>
            }
          </Col>
          <Col md={3}>
            <span className="form-header">{p('vipTicket')}</span>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="vip"
                  component={renderRadioButtonField}
                  label={s('yes')}
                  radioValue="1"
                />
              </div>
            </div>
            <div className="form__form-group margin-0">
              <div className="form__form-group-field">
                <Field
                  name="vip"
                  component={renderRadioButtonField}
                  label={s('no')}
                  radioValue="0"
                  defaultChecked={!editUsersTickets}
                />
              </div>
            </div>
            {editUsersTickets &&
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="vip"
                    component={renderRadioButtonField}
                    label={p('keepCurrentValue')}
                    radioValue={undefined}
                    defaultChecked
                  />
                </div>
              </div>
            }
          </Col>
          <Col md={6}>
            <span className="form-header">{p('pushToReciever')}</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="push"
                  component={renderCheckBoxField}
                  className="colored-click"
                />
              </div>
            </div>
          </Col>
        </Row>
        {!editUsersTickets &&
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form-header">{s('user')}</span>
                <div className="marginTop-5" />
                <Field
                  name="user"
                  component={renderSearchSelectField}
                  placeholder={p('searchNameOrNumber')}
                  options={props.userOptions}
                  search={text => props.getUser(text)}
                />
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col md={12}>
            <Button type="submit" color="primary">{s('save')}</Button>
            {onClose ?
              <Button type="button" onClick={onClose}>{s('close')}</Button>
              :
              <Link className="color-unset" to={`/tickets${window.location.search}`}><Button type="button">{s('close')}</Button></Link>
            }
          </Col>
        </Row>
      </Container>
    </form>
  );
};

AssignTicketForm.propTypes = {
  getUser: PropTypes.func.isRequired,
  userOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  ticketStates: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  matchPrices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  editUsersTickets: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};

AssignTicketForm.defaultProps = {
  onClose: null,
};

export default reduxForm({
  form: 'assign_match_ticket',
  validate,
})(AssignTicketForm);
