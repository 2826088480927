import React, { useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Col, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import renderField from '../../../shared/components/custom/Field';
import validate from './validateNewsCategory';
import t, { partial } from '../../../util/translation/translation';

const NewsCategoryForm = ({
  handleSubmit,
  category,
  initialize,
}) => {
  const s = partial('shared');
  useEffect(() => {
    if (category) {
      initialize({
        name: category.name,
      });
    }
  }, [category]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Col lg={6}>
        <div className="card__title">
          <h5 className="bold-text">{s('newsCategory')}</h5>
        </div>
        <div className="form__form-group">
          <span className="form">{t('product&CategoryShare.categoryName')}</span>
          <Field
            name="name"
            component={renderField}
            placeholder={t('product&CategoryShare.categoryName')}
          />
        </div>
      </Col>
      <Col md={12}>
        <Button type="submit" color="primary">
          {category ? s('save') : s('create')}
        </Button>
        <Link
          className="color-unset"
          to="/news?tab=2"
        >
          <Button type="button">
            {s('close')}
          </Button>
        </Link>
      </Col>
    </form>
  );
};



const reduxF = reduxForm({
  form: 'news_category_form',
  validate,
});

export default withRouter(connect(state => ({
  formValues: getFormValues('news_category_form')(state),
}))(reduxF(NewsCategoryForm)));
