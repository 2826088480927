import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Container, Table, CardText, CardTitle, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { Parser } from 'json2csv';
import Select from 'react-select';
import Api from '../../../util/api';
import { partial } from '../../../util/translation/translation';
import useConfig from '../../../util/useConfig';
import Pagination from '../../../shared/components/pagination/Pagination';
import Loading from '../../../shared/components/custom/Loading';


const Result = (props) => {
  const config = useConfig();
  const { id } = props.editMode ? (props.match || {}) : {};
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [ratings, setRatings] = useState([]);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [topPlayers, setTopPlayers] = useState(null);
  const { companies } = props;

  const t = partial('Result');
  const s = partial('shared');

  const setDataToObject = (rating, userID) => {
    return (rating.data.reduce((acc, curr) => {
      const index = userID.findIndex((user) => {
        return curr.fanrating_userId === user.userCloudId;
      });
      return index === -1 ? acc : [...acc, {
        id: userID[index].userCloudId,
        name: `${userID[index].firstname} ${userID[index].lastname}`,
        phoneNumber: `${userID[index].phoneNumber}`,
        email: userID[index].email,
        votedFor: curr.player_fullname,
        uniqueID: curr.fanrating_id,

    }];
    }, []));
  };

  const selectCompany = async () => {
    try {
      const payload = {
        companyId: selectedCompany.id,
        matchIds: [{ id: props.match.ntb_match_id }],
        teamId: config.teams.find((ct) => ct.id === props.match.away_team.ntb_id || props.match.home_team.ntb_id).id,
      };
      await Api.players.setCompanyOnRating(payload);
      toast.success(t('assignSuccess'));
    } catch {
      toast.error(t('failedToAssign'));
    }
  };

  const all = async () => {
    try {
      const teamId = config.teams.find((ct) => ct.id === props.match.away_team.ntb_id || props.match.home_team.ntb_id).id;
      const seasonId = props.seasons.find((_s) => _s.id === props.match.season_id).ntb_id;
      if (!teamId || !seasonId) {
        throw new Error(t('missing'));
      }
      const { data: futureMatches } = await Api.clubadmin.match.getClubMatchesForSeason(seasonId, [teamId]);
      const payload = {
        companyId: selectedCompany.id,
        matchIds: futureMatches.map(l => l.matches).flat().filter((m) => m.matchStatus === 'NOT_STARTED' && (m.homeTeam.id === teamId || m.awayTeam.id === teamId)).map(({ id: _id }) => ({ id: _id })),
        teamId: config.teams.find((ct) => ct.id === props.match.away_team.ntb_id || props.match.home_team.ntb_id).id,
      };
      await Api.players.setCompanyOnRating(payload);
      toast.success(t('assignSuccess'));
    } catch (e) {
      toast.error(t('failedToAssign'));
    }
  };

  const getFanRating = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const currentTeam = config.teams.find((team) => team.id === props.match?.home_team.ntb_id || team.id === props.match?.away_team.ntb_id);
      const rating = await Api.clubadmin.match.getFanRating(props.match.ntb_match_id, currentTeam.id, page, pageSize);

      const getAllRating = await Api.clubadmin.match.getTopFanRating(props.match.ntb_match_id, currentTeam.id);

      if (getAllRating.data.length > 0) {
        const sortAllRating = getAllRating.data.sort((a, b) => b.rating - a.rating);
        const [threeStar, twoStar, oneStar] = sortAllRating;
        setTopPlayers([threeStar, twoStar, oneStar]);
      }

      const userRating = await Api.clubadmin.match.getUserRating(props.match.ntb_match_id, currentTeam.id);

      const result = (userRating.data).map((user) => user.userId);
      const { data: userID } = await Api.users.getUsersByCloudIds(result);
      setData(rating.data);
      setUserData(userID);

      const tableData = setDataToObject(rating.data, userID);

        setRatings(tableData);

      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getFanratingCompany = async () => {
    try {
      const currentTeam = config.teams.find((team) => team.id === props.match?.home_team.ntb_id || team.id === props.match?.away_team.ntb_id);
      const { data: cr } = await Api.players.getCompanyOnRating(currentTeam.id, props.match.ntb_match_id);
      const company = companies.find((c) => c.value === cr.companyId);
      setSelectedCompany(company);
    } catch {
      toast.error(t('failedToFetchAssigned'));
    }
  };

  useEffect(() => {
    if (props?.match?.ntb_match_id) {
      getFanratingCompany();
    }
  }, [props?.match?.ntb_match_id]);


  useEffect(() => {
    if (id) {
      getFanRating();
    }
  }, [id]);

  const renderFanRating = () => {
    return ratings.map((rating) => (
      <tr key={`renderFanRating-${rating?.uniqueID}`}>
        <td>{`${rating?.name}`}</td>
        <td>{`+${rating?.phoneNumber}`}</td>
        <td>{rating?.email}</td>
        <td>{rating?.votedFor}</td>
      </tr>
    ));
  };

  const onChangePageSystem = (pager) => {
    getFanRating(pager.currentPage, pager.pageSize);
  };

  const exportFanRating = async () => {
    setLoading(true);
    try {
      const page = 1;
      const pageSize = 10;
      const currentTeam = config.teams.find((team) => team.id === props.match?.home_team.ntb_id || team.id === props.match?.away_team.ntb_id);

      const allRating = await Api.clubadmin.match.getAllRating(props.match.ntb_match_id, currentTeam.id, page, pageSize, true);

      const tableData = setDataToObject(allRating, userData);

      if (tableData.length <= 0) {
        toast.error(t('noVotesToDownload'));
        setLoading(false);
        return;
      }
      const fields = [
        { label: 'Name', value: 'name' },
        { label: 'Phone', value: 'phoneNumber' },
        { label: 'email', value: 'email' },
        { label: 'Voted For', value: 'votedFor' },
      ];
      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(tableData);
      fileDownload(`\uFEFF${csv}`, `${props.match.match_name}_FanRating.csv`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  return (

    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle tag="h4">{t('chooseSponsor')}</CardTitle>
              <Select
                onChange={setSelectedCompany}
                options={companies}
                value={selectedCompany}
              />
              <div className="margin-10">
                <Button color="primary" onClick={selectCompany}>OK</Button><Button onClick={all} color="success">{t('setAll')}</Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {topPlayers ? topPlayers.map((rating, index) => (
          <Col key={rating?.playerId}>
            <Card>
              <CardBody>
                <CardTitle tag="h4">{`${t('ranked')} nr ${index + 1}`}</CardTitle>

                <div className="flex-Vcenter">
                  <Col md={6}>
                    <img
                      style={{ width: '90%' }}
                      src={rating?.player_imageUrl}
                      alt=""
                    />
                  </Col>
                  <Col md={6}>
                    <CardText>{`${t('name')}: ${rating?.player_firstname} ${rating?.player_lastname}`}</CardText>
                    <CardText>{`${t('jersey')}: ${rating?.player_jerseyNumber}`}</CardText>
                    <CardText>{`${t('votes')}: ${rating?.votes}`}</CardText>
                  </Col>

                </div>

              </CardBody>
            </Card>
          </Col>
      )) : <></>}
      </Row>
      <Button onClick={exportFanRating} color="secondary" size="sm">
        {t('exportFanRating')}
      </Button>
      <Card>
        <CardBody>
          <Table responsive bordered>
            <thead>
              <tr>
                <th >
                  {s('name')}
                </th>
                <th >
                  {s('phonenumber')}
                </th>
                <th >
                  {s('email')}
                </th>
                <th >
                  {t('votedFor')}
                </th>
              </tr>
            </thead>
            <tbody>{id && renderFanRating()}</tbody>
          </Table>
          <Pagination
            items={data.total ?? 0}
            pageSize={10}
            onChangePage={onChangePageSystem}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

Result.propTypes = {};

Result.defaultProps = {};

export default Result;
