import React, { useState } from 'react';
import { Container, TabContent, TabPane } from 'reactstrap';
import OfferCard from './components/OfferCard';
import { OfferState } from './context';
import { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';

const OffersPage = () => {
  const f = partial('OffersPage');
  const [tab, setTab] = useState('1');
  return (
    <Container>
      <NavTab
        activeTab={tab}
        tabs={[f('offers')]}
        setTab={(activeTab) => setTab(activeTab)}
      />
      <TabContent activeTab={tab}>
        <TabPane tabId="1">
          <OfferState>
            <OfferCard status="active" />
            <OfferCard status="expired" />
          </OfferState>
        </TabPane>
      </TabContent>
    </Container>
  );
};
export default OffersPage;
